import {PropertyFilterProperty} from "@amzn/awsui-collection-hooks";
import {Roles} from "src/common/roles";
import {BridgingTableColumns} from "src/components/BridgingCommentary/constants";
import {BridgingTableItem} from "src/components/BridgingCommentary/interfaces";
import {getTimeZone, humanizeEpoch} from "src/utils/timeHelpers";

export const getBridgingTableColDefinition = (role: Roles | null) => {
	const columns = [
		{
			id: BridgingTableColumns.Name,
			header: BridgingTableColumns.Name,
			label: BridgingTableColumns.Name,
			cell: (e : BridgingTableItem) => e.displayName
		},
		{
			id: BridgingTableColumns.VarianceName,
			header: BridgingTableColumns.VarianceName,
			label: BridgingTableColumns.VarianceName,
			cell: (e : BridgingTableItem) => e.varianceName
		},
		{
			id: BridgingTableColumns.Requester,
			header: BridgingTableColumns.Requester,
			label: BridgingTableColumns.Requester,
			cell: (e : BridgingTableItem) => e.requester
		},
		{
			id: BridgingTableColumns.Owners,
			header: BridgingTableColumns.Owners,
			label: BridgingTableColumns.Owners,
			cell: (e : BridgingTableItem) => e.owners
		},
		{
			id: BridgingTableColumns.Status,
			header: BridgingTableColumns.Status,
			label: BridgingTableColumns.Status,
			minWidth: "140px",
			maxWidth: "140px",
			cell: (e : BridgingTableItem) => e.status
		},
		{
			id: BridgingTableColumns.LastEdit,
			header: BridgingTableColumns.LastEdit,
			label: BridgingTableColumns.LastEdit,
			cell: (e : BridgingTableItem) => humanizeEpoch({epoch: e.editDate})
		},
		{
			id: BridgingTableColumns.DueDate,
			header: `${BridgingTableColumns.DueDate} (${getTimeZone()})`,
			label: BridgingTableColumns.DueDate,
			cell: (e : BridgingTableItem) => e.dueDate
		},
	]
	if (role === Roles.Admin) {
		columns.push({
			id: BridgingTableColumns.Actions,
			header: BridgingTableColumns.Actions,
			label: BridgingTableColumns.Actions,
			cell: (e : BridgingTableItem) => e.actions
		});
	}
	return columns
}
export const ADMIN_DEFAULT_COLUMNS = [
	BridgingTableColumns.Name,
	BridgingTableColumns.Owners,
	BridgingTableColumns.DueDate,
	BridgingTableColumns.Actions
];

export const BRIDGE_OWNER_DEFAULT_COLUMNS = [
	BridgingTableColumns.Name,
	BridgingTableColumns.Owners,
	BridgingTableColumns.DueDate
];

export const FILTERING_PROPERTIES: PropertyFilterProperty[] = [
	{
		propertyLabel: BridgingTableColumns.Name,
		key: "name",
		groupValuesLabel: 'Report/Bridge item names',
		operators: [':', '!:', '=', '!='],
	},
];
