import {Button} from "@amzn/awsui-components-react";
import React from "react";
import {useDispatch} from "react-redux";
import {setFillingBridges} from "src/actions/bridgingCommentary.actions";
import {BridgeItem} from "src/components/BridgingCommentary/interfaces";
import {TableHeaderProps} from "src/components/BridgingCommentaryPage/interfaces";
import Paths from "src/components/PageConfig/Paths";



export const headerActions = (props : TableHeaderProps) =>{
    const dispatch = useDispatch();
    const takeActionHandler = (props : TableHeaderProps) => {
        const bridgeMap = props.bridgeItems.reduce(
            (bridgeMap: {[p:string]: BridgeItem}, currentBridgeItem: BridgeItem) => {
                bridgeMap[currentBridgeItem.bridge_id]	= {...currentBridgeItem};
                return bridgeMap;
            },
            {}
        );
        const selectedBridges: BridgeItem[] = [];
        props.selectedItems.forEach(selectedRow => {
            if(bridgeMap[selectedRow.id]) {
                selectedBridges.push(bridgeMap[selectedRow.id]);
            }
        });
        if(selectedBridges.length > 1) {
            dispatch(setFillingBridges(selectedBridges));
            setTimeout(() => window.location.replace(`#${Paths.FILLOUTBRIDGE}`), 20)
        } else {
            setTimeout(() => window.location.replace(
                    `#${Paths.FILLOUTBRIDGE}?bridge_id=${selectedBridges[0].bridge_id}`),
                20
            )
        }
    }
    return (<Button
        disabled={props.selectedItems.length === 0}
        onClick={() => takeActionHandler(props)}
    >
        {"Fill Bridges"}
    </Button>)
}


