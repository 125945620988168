import {Roles} from "src/common/roles";

export interface ExecuteFacetRequest {
    facet_name : string,
    facet_params : FacetParams,
    created_by : string,
    role: Roles | null

}

export interface ExecuteFacetResponse{
    facet_id : string,
    execution_id : string,
    status : FacetStatus,
    started_at? : string,
    error_message? : string
    facet_output_s3_presigned_url? : string,
    completed_at? : string,
}

export interface FetchFacetRequest {
    facet_id : string
    execution_id : string
    role: Roles | null
}

export interface FetchFacetResponse{
    // facet_id : string,
    // execution_id : string,
    status: FacetStatus,
    started_at? : string,
    completed_at? : string,
    facet_output_s3_presigned_url? : string
    error_message? : string
}



export interface FacetParams {
    [facet_type : string] : ScenarioParam
}

export interface ScenarioParam {
    [scenario : string]: {
        scenarioName : string,
        Year: string,
        Periods: string[]
    }
}

export interface CreateWorkflowRequest {
    input_type : string,
    input_id : string,
    input_version : string,
    workflow_name : string,
    description: string,
    created_by: string
    triggered_by : string,
    trigger_type : TriggerType,
    role: Roles | null
}

export interface ExecuteWorkflowRequest{
    workflow_id : string,
    workflow_version : string,
    execution_id: string,
    role: Roles | null
}

export interface ExecuteWorkflowResponse{
    workflow_id : string
    workflow_version : string,
    execution_id : string,
    s3_presigned_get_url : string,
    message : string
}

export interface FetchWorkflowRequest{
    workflow_id : string,
    workflow_version : string,
    execution_id: string,
    role: Roles | null
}

export interface FetchWorkflowResponse{
    input_id : string,
    input_version : string,
    created_by : string,
    s3_presigned_get_url : string,
    workflow_name : string,
    excel_details : ExcelDetails,
    execution_status : WorkflowExecutionStatus
}

export enum TriggerType{
    USER = "user"
}

export enum FacetStatus{
    NOT_STARTED = "NOT_STARTED",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETE = "COMPLETE",
    ERROR = "ERROR",
}

export interface ExcelDetails{
    excel_status : ExcelStatus
    s3_excel_path? : string,
    excel_s3_presigned_url? : string,
    start_timestamp? : string,
    end_timestamp? : string,
    message? : string
}

export enum ExcelStatus{
    INPROGRESS = "In-Progress",
    SUCCESS = "Success",
    FAILED = "Failed",
    NOTSTARTED = "Not-Started"
}
export interface CreateWorkflowResponse{
    input_type : string,
    input_id : string,
    input_version : string,
    created_at : string,
    workflow_name : string,
    created_by : string,
    description: string,
    workflow_id : string,
    workflow_version : string,
    execution_id : string,
    execution_timestamp : string
    execution_status : WorkflowExecutionStatus
  }

  export enum WorkflowExecutionStatus{
    NOT_STARTED = "NOT_STARTED"
  }