import {Alert, Button, FormField, Grid, SpaceBetween, StatusIndicator, TextContent} from "@amzn/awsui-components-react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getFileTransfer} from "src/actions/fileTransfer.actions";
import {setReportLibraryStatus} from "src/actions/reportLibrary.actions";
import FileUpload from "src/components/Common/FileUpload";
import {stylesheetDefaultFileName} from "src/components/CreateReportPage/constants";
import {StylesheetUploadProps} from "src/components/CreateReportPage/interfaces";
import {HEADER_COLUMNS} from "src/constants/styleSheetConstants";
import {getFileTransferApiLoading} from "src/reducers/apiLoading.reducer";
import {getFileTransferState} from "src/reducers/fileTransfer.reducer";
import {getReportLibraryStatus} from "src/reducers/reportLibrary.reducer";
import {humanizeEpoch} from "src/utils/timeHelpers";
import XLSX from 'xlsx';


const StylesheetUpload = ({reportWorkflow, reportName, reportType, stylesheet, setStylesheet, errorText} : StylesheetUploadProps) => {
    const dispatch = useDispatch();
    const fileTransferApiIsLoading = useSelector(getFileTransferApiLoading);
    const reportLibraryStatus = useSelector(getReportLibraryStatus);
    const fileTransfer = useSelector(getFileTransferState);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [downloading, setDownloading] = useState(true);
    const defaultFileName: string = stylesheetDefaultFileName[reportType]

    useEffect(() => {
        // fileTransfer changes when Admin uploads/downloads stylesheet
        // This is required as fileTransfer upload event only, middleware will send
        // pre-signed url as well fileKey, which will be then used to update report definition
        // fileKey from backend pre-signed fields: 'stylesheets/${stylesheetFileName}.xlsx'
        // split removes prefix as reportDefinition object need only fileName.
        Object.prototype.hasOwnProperty.call(fileTransfer, '[StylesheetUpload]') && setStylesheet({
            ...stylesheet,
            fileName: fileTransfer['[StylesheetUpload]'].fileKey!.split('/')[1]
        })
    }, [fileTransfer])

    // upload handler
    const fileUploadHandler = async (file: File) => {
        const fileName = `${stylesheet.reportId}.${file.name}`;
        const modifiedDate = humanizeEpoch({epoch: file.lastModified, asDate: false});
        setUploadProgress(0);
        setDownloading(false);


        const {errors, allowStylesheetUpload} = await stylesheetValidator(file)

        if(allowStylesheetUpload){
            // update stylesheet details of fileSize, fileName will still be empty at this point.
            // fileName will be updated in useEffect which is created by fileTransfer API.
            setStylesheet({
                ...stylesheet,
                fileSize: file.size,
                fileLastUpdated: modifiedDate,
                reportId: stylesheet.reportId
            })

            // dispatch an api call to upload the file
            dispatch(getFileTransfer({
                urlType: 'upload',
                fileKey: `${fileName}`,
                file,
                fileEntity: '[StylesheetUpload]',
                setUploadProgress
            }));
        }else{
            dispatch(setReportLibraryStatus({
                ...reportLibraryStatus,
                errorType: errors.errorType,
                errorMessage:  errors.errorMessage
            }));
        }
    };

    // download handler
    const fileDownloadHandler = () => {
        setUploadProgress(0);
        setDownloading(true);
        dispatch(getFileTransfer({
            urlType: "download",
            fileKey: "stylesheets/" + defaultFileName + ".xlsx",
            fileEntity: '[StylesheetDownload]'
        }));
    };

    const headerDescription = "Please upload the latest stylesheet to adjust formatting for your report"
    return (
        <SpaceBetween size='s' direction='vertical'>
            {errorText.length > 0 &&
                <Alert type='error'>
                    {errorText}
                </Alert>
            }
            <Container
                header={
                    <Header variant="h2" description={headerDescription}>
                        {`${reportWorkflow} report: ${reportName}`}
                    </Header>
                }
            >
                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6}]}>
                    <SpaceBetween direction={'vertical'} size={'m'}>
                        <FileUpload id={'_stylesheet_upload'}
                                    accept='.xls,.xlsx'
                                    label='Stylesheet upload'
                                    descriptionText='Upload your custom stylesheet file'
                                    constraintText="The file must be in excel format"
                                    errorText={errorText}
                                    buttonLoading={(uploadProgress > 0 && uploadProgress < 100) ||
                                        (!downloading && fileTransferApiIsLoading)
                                    }
                                    onChange={fileUploadHandler}
                        />
                        { stylesheet.fileName.length > 0 ?
                            <TextContent>
                                <StatusIndicator type={'success'}>
                                    {parseStylesheetFileName(stylesheet.fileName)}
                                </StatusIndicator>
                                <ul style={{listStyleType: "none", lineHeight: "14px"}}>
                                    <li>
                                        <small>{`File size is ${stylesheet.fileSize} bytes`}</small>
                                    </li>
                                    <li>
                                        <small>{`File was last modified on ${stylesheet.fileLastUpdated}`}</small>
                                    </li>
                                </ul>
                            </TextContent>
                            : <TextContent>
                                <small>
                                    Stylesheet file not uploaded. Default stylesheet file will be used.
                                </small>
                            </TextContent>
                        }
                    </SpaceBetween>
                    <FormField
                        label={'Download default'}
                        description='Download the default stylesheet file'>
                        <Button
                                iconName="download"
                                formAction="none"
                                loading={downloading && fileTransferApiIsLoading}
                                onClick={() => fileDownloadHandler()}
                        >
                            Download
                        </Button>
                    </FormField>
                </Grid>
            </Container>
        </SpaceBetween>
    );
}

export default StylesheetUpload;


export function parseStylesheetFileName(fileName: string): string {
   return fileName.length > 35 ? `${fileName.substring(0,30)}...` : fileName;
}


export const stylesheetValidator = async (file: File) => {
    //Validates Stylesheet
    const errorType = "ReportStylesheetError"

    const errors: { errorType? : string, errorMessage?: string} = {}
    // Convert bytes to KB
    const kib = 1024
    const uploadedFileSize = parseFloat((file.size / kib).toFixed(2))

    //Maximum stylesheet size allowed 100KB
    if (uploadedFileSize > 100){
        errors.errorType =  errorType
        errors.errorMessage = `Size of uploaded Style sheet file: ${uploadedFileSize}KB . Maximum allowed file 
        size: 100KB`

        return {errors, allowStylesheetUpload: false}
    }

    //Validating stylesheet headers
    const { headerValid, checkIsValidFileType, uploadedHeader } = await validateStylesheetTypeAndHeader(file);

    if (!headerValid){
        errors.errorType =  errorType
        errors.errorMessage = `Stylesheet header validation failed, Expected Headers: 
            ${HEADER_COLUMNS.join()} Received Headers: ${uploadedHeader}`
    }else if(!checkIsValidFileType){
        errors.errorType =  errorType
        errors.errorMessage = `Accepted Stylesheet filetype are only .xlsx and .xls`
    }

    return {errors, allowStylesheetUpload: Object.keys(errors).length === 0}
}

export const validateStylesheetTypeAndHeader = (file: File): Promise<{uploadedHeader: string,
    checkIsValidFileType: boolean, headerValid:boolean}> => {
    //Magic numbers for accepted fileTypes
    const xslsxType = '504b34'
    const xlsType = 'd0cf11e0'
    //This is required as part of AppSec findings which suggests to validate first few bytes of any uploaded file.
    // to identify file type instead of relying on Input tag or File interface
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function (event) {
            // This will never null as StylesheetUpload component gets triggered only when admin upload file
            const data = event.target!.result!;
            //Fetching magic bytes using which we can identify fileType.
            //AppSec requires to parse first few bytes instead of relying on fileType from File interface
            // or input html tag
            const magicBytes = new Uint8Array(data as ArrayBuffer).subarray(0, 4);
            const magicBytesHeader = magicBytes.reduce((prev, curr) => {
                return prev + curr.toString(16)
            }, "")

            const checkIsValidFileType = magicBytesHeader ===  xslsxType || magicBytesHeader === xlsType

            //XLSX can parse .xlsx and .xls file types
            // Read only header
            const workbook = XLSX.read(data, {
                type: 'binary',
                sheetRows: 1
            })
            // Admin only uploads a single sheet excel
            const workSheet = workbook.Sheets[workbook.SheetNames[0]];
            // Excel file is read as csv so each row is separated by new line character
            const uploadedHeader = XLSX.utils.sheet_to_csv(workSheet).replace("\n", "")

            resolve({uploadedHeader, checkIsValidFileType, headerValid: uploadedHeader === HEADER_COLUMNS.join()});
        }
        reader.readAsArrayBuffer(file);
    })
}
