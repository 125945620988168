import {Link} from "@amzn/awsui-components-react";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Table from "@amzn/awsui-components-react/polaris/table";
import React, {ReactNode, useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {generatePath} from "react-router-dom";
import {getReportBridges} from "src/actions/bridgingCommentary.actions";
import {getReportLibrary, getReportStatus,} from "src/actions/reportLibrary.actions";
import {ExternalReportState, ItemType, ReportDefinition, ReportStatus} from "src/common/report";
import {Roles} from "src/common/roles";
import UserAccessException from "src/components/Common/UserAccessException";
import {EmptyTableState} from "src/components/MDXLibraryPage/MDXLibraryTable";
import Paths from "src/components/PageConfig/Paths";
import {BridgeAndCommentaryStatus} from "src/components/ReportLibraryPage/components/BridgeAndCommentaryStatus";
import {ReportBridge, selectAllReportBridges} from "src/features/reportBridges/reportBridgeSlice";
import {getReportLibraryApiLoading} from "src/reducers/apiLoading.reducer";
import {AppState} from "src/reducers/AppState";
import {getReports} from "src/reducers/reportLibrary.reducer";
import {getAssumedRole} from "src/reducers/user.reducer";
import {epochToAgo} from "src/utils/timeHelpers";

export enum RecentReportTableColumns {
    BridgingAndCommentary = "Bridge & Commentary",
    ReportName = "Report / Review name",
    FinanceCycle = "Finance Cycle",
    LastEdited = "Last Edited",
    ReportAccess = "Report Access"
}

export interface ReportTableRowItem {
    // Keys used for showing the table
    id: string
    displayName: string | ReactNode
    lastModified: string | number
    reportRole: string
    reportName: string
    financeCycle: string
    bridgeAndCommentary? : ReactNode | undefined
}

export interface RecentReportsTableProps {
    reportLibrary: string,
    validRoles: Set<Roles>

}

const RecentReportsTable = (props: RecentReportsTableProps) => {
    const dispatch = useDispatch();
    const currentAssumedRole = useSelector(getAssumedRole);
    const tableLoading = useSelector(getReportLibraryApiLoading)
    const reportBridges = useSelector((state : AppState) => selectAllReportBridges(state))
    const tableData = useSelector(getReports)
    const pageSizePref = 10;

    // Use effect to fetch initial data
    useEffect(function initializeReportTable() {
        if (currentAssumedRole && props.validRoles.has(currentAssumedRole) && !tableLoading) {
            dispatch(getReportLibrary({
                reportLibrary: props.reportLibrary,
                limit: pageSizePref + 1
            }));
        }
    }, [currentAssumedRole]);

    // Use effect to fetch bridge status data
    useEffect(function fetchBridgeStatus() {
        //Only fetch bridging and commentary data when the column is needed
        if(currentAssumedRole == Roles.Admin){
            tableData.forEach(({report_id}) => {
                //fetch bridges not in the redux state
                if(!(reportBridges?.find(bridge => bridge.report_id == report_id))){
                    dispatch(getReportBridges({role: currentAssumedRole, variance_header :"", report_id: report_id}));
                }
            });
        }
    }, [tableData]);

    // Use effect to refresh in-flight reports metadata
    useEffect(function setupTimerToRefreshReports(){
        const timer = setInterval(() => {
            tableData.forEach(({report_id, report_status}) => {
                if (report_status === 'Creating' || report_status === 'Updating'){
                    dispatch(getReportStatus(report_id));
                }
            });
        }, 10 * 1000);
        return function stopTimer() {
            clearInterval(timer)
        };
    }, [tableData]);

    const columnDefinitions = useMemo(() => {
        const excludedColumns = new Set(currentAssumedRole === Roles.Admin ? [] : [RecentReportTableColumns.BridgingAndCommentary]);
        return getRecentReportTableColumnDefinitions(excludedColumns);
    }, [currentAssumedRole]);

    const tableRows = constructTableRows(tableData, reportBridges);
    return ( currentAssumedRole && props.validRoles.has(currentAssumedRole) ?
            <>
                <Table
                    variant="borderless"
                    columnDefinitions={columnDefinitions}
                    items={tableRows}
                    loading={tableLoading}
                    loadingText="Loading resources"
                    stickyHeader={true}
                    empty={<EmptyTableState />}
                />
            </>
            : <UserAccessException allowedRoles={Array.from(props.validRoles)} assumedRole={currentAssumedRole}/>
    );
};

export default RecentReportsTable;

const constructTableRows = (items: ReportDefinition[], reportBridges : ReportBridge[]) => {
    const tableRows: ReportTableRowItem[] = [];
    const processedItems = new Set<string>(); // keep track of processed report ids
    // Add the remaining rows
    items.forEach(item => {
        if(tableRows.length<5 && !processedItems.has(item.report_id) && (item.item_type === ItemType.Internal || item.name_and_type?.report_type == "SFPA" || item.name_and_type?.report_type == "DC FPA") ){
            tableRows.push(constructTableRow(
                item, reportBridges
            ));
        }
    });
    return tableRows
}


export const constructTableRow = (
    item: ReportDefinition,
    reportBridge? : ReportBridge[],
    enableReportLink = true
): ReportTableRowItem => {
    const showReport = reportBridge?.find(bridge => bridge.report_id == item.report_id)
    return {
        id: item.report_id,
        displayName: generateDisplayName(item, enableReportLink),
        lastModified: item.last_modified || "",
        reportRole: item.report_role || Roles.Admin,
        reportName: item.name_and_type?.report_name || "",
        financeCycle: item.name_and_type?.finance_cycle || "",
        bridgeAndCommentary : showReport ? BridgeAndCommentaryStatus({item : item, bridgeItems : showReport.bridgeItems}) : <Spinner/>
    }
}

const hrefLink = (reportDefinition: ReportDefinition) => {
    if(reportDefinition.item_type == ItemType.External){
        return (reportDefinition as ExternalReportState).link;
    }
    if(reportDefinition.name_and_type?.report_type == "SFPA"){
        const facet_id = reportDefinition.name_and_type?.report_name;
        const parts = reportDefinition.report_id.split("_")
        const workflow_id = parts[0]
        const execution_id = parts[1]
        const path = generatePath(`#${Paths.VIEWCUSTOMREPORT}`, {facet_id : facet_id,
            workflow_id : workflow_id, execution_id : execution_id})
        return path
    } else if(reportDefinition.name_and_type?.report_type == "DC FPA"){
        const {workflow_id, workflow_version, execution_id} = reportDefinition;
        return `#/report-builder/reports/${workflow_id}?versionId=${workflow_version}&executionId=${execution_id}`;
    }

    return `#${Paths.VIEWREPORT}?report_id=${reportDefinition.report_id}`;
}

export const generateDisplayName = (
    reportDefinition: ReportDefinition,
    enableReportLinks: boolean
) => {
    const {report_status, name_and_type} = reportDefinition;
    const href = hrefLink(reportDefinition)
    const linkProps = {};
    const reportIsReady = report_status === ReportStatus.CREATED || report_status === ReportStatus.EDITED;
    const displayComponent = (
        <span style={{paddingLeft: "6px"}}>
                {name_and_type?.report_name}
            </span>
    );
    return (enableReportLinks && reportIsReady) ? <Link href={href} {...linkProps}>{displayComponent}</Link>
        : displayComponent;
}

const COL_DEFINITIONS = [
    {
        id: RecentReportTableColumns.ReportName,
        header: RecentReportTableColumns.ReportName,
        cell: (e : ReportTableRowItem) => e.displayName,
        label: RecentReportTableColumns.ReportName
    },
    {
        id: RecentReportTableColumns.BridgingAndCommentary,
        header: RecentReportTableColumns.BridgingAndCommentary,
        cell: (e : ReportTableRowItem) => e.bridgeAndCommentary,
        label: RecentReportTableColumns.BridgingAndCommentary,
    },
    {
        id: RecentReportTableColumns.FinanceCycle,
        header: RecentReportTableColumns.FinanceCycle,
        cell: (e : ReportTableRowItem) => e.financeCycle,
        label: RecentReportTableColumns.FinanceCycle,
    },
    {
        id: RecentReportTableColumns.LastEdited,
        header: RecentReportTableColumns.LastEdited,
        cell: (e : ReportTableRowItem) => epochToAgo({epoch: e.lastModified as number}),
        label: RecentReportTableColumns.LastEdited,
    },
    {
        id: RecentReportTableColumns.ReportAccess,
        header: RecentReportTableColumns.ReportAccess,
        cell: (e : ReportTableRowItem) => e.reportRole,
        label: RecentReportTableColumns.ReportAccess,
    }
];
export const getRecentReportTableColumnDefinitions = (excludedColumns: Set<RecentReportTableColumns>) => {
    return COL_DEFINITIONS.filter(definition => !excludedColumns.has(definition.id));
}

