import {Select} from "@amzn/awsui-components-react";
import React from "react";
import {fetchOwnerGroup} from "src/components/Bridging/helpers";
import {
    BridgeItemOwnerGroupSelectionProps
} from "src/components/Bridging/interfaces";

const BridgeItemOwnerGroupSelection = (props: BridgeItemOwnerGroupSelectionProps) => {
    const {bridgeOwnerGroup, setBridgeToOwnerGroup, accountRollup, ownerGroups} = props;
    const defaultOwnerGroup = fetchOwnerGroup(accountRollup, ownerGroups)
    const [,setSelectedOwnerGroups] = React.useState({})
    let displayGroupName: {label: string, value: string}

    if(bridgeOwnerGroup.hasOwnProperty(accountRollup)){
        displayGroupName = {label: bridgeOwnerGroup[accountRollup], value: bridgeOwnerGroup[accountRollup]}
    }else{
        displayGroupName = defaultOwnerGroup
    }

    return (
        <Select
            selectedOption={displayGroupName}
            onChange={({detail}) => {
                const value = detail.selectedOption;
                const accountRollUpMap: { [p: string]: string } = {}
                bridgeOwnerGroup[accountRollup] = value?.label ?? ''
                accountRollUpMap[accountRollup] = value?.label ?? ''
                const updatedBridgeOwnerGroup = {...bridgeOwnerGroup, ...accountRollUpMap}
                setBridgeToOwnerGroup(updatedBridgeOwnerGroup)
                setSelectedOwnerGroups({label: value?.label ?? '', value: value?.value ?? ''})
            }}

            options={createSelectDropDownList(ownerGroups)}
            expandToViewport
        />
    );
}

export default BridgeItemOwnerGroupSelection

export const createSelectDropDownList = (ownerGroups: {[p: string]: string}) => {

    //OwnerGroup will have few bridgeItems mapped to same key[LDAP Teams].Below list is to show all the distinct LDAP teams
    //as Select dropdown list
    const dropDownSet : Set<string> = new Set<string>()
    const dropDownList: [{label: string, value: string}] = [{label: "", value: ""}]

    Object.keys(ownerGroups).forEach(ownerGroupKey => {
        const groupAlias = ownerGroups[ownerGroupKey]
        if(!dropDownSet.has(groupAlias)){
            dropDownList.push({label: groupAlias, value: groupAlias})
        }
        dropDownSet.add(groupAlias)
    })

    return dropDownList.sort((a, b) => a.label.localeCompare(b.label))
}
