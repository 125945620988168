import {Alert, Container, Header, Link} from "@amzn/awsui-components-react";
import * as React from 'react';
import {useSelector} from "react-redux";
import {Roles} from "src/common/roles";
import {UserAccessExceptionProps} from "src/components/Common/interface";
import {getAssumedRole, getAvailableRoles, getUser} from "src/reducers/user.reducer";
import styled from "styled-components";

const UserAccessException = (props : UserAccessExceptionProps) => {
    const {
        header = "Access Denied",
        allowedRoles = [],
        availableRoles = useSelector(getAvailableRoles),
        assumedRole= useSelector(getAssumedRole),
        user = useSelector(getUser)
    } = props;

    const canAssumeRoles = getCommonRoles(availableRoles, allowedRoles);

    return (
        <UserAccessExceptionPage>
            <Container header={<Header variant="h2">{header}</Header>}>
                <Alert type={"error"}>
                    { availableRoles.length !== 0 && `${assumedRole} role does not have access to this page. `}
                    { canAssumeRoles.length > 0 ?
                        <>
                            {`${user}@ can use one of the following role to view this page.`}
                            {getRolesList(allowedRoles)}
                        </>
                        : <>
                            {`${user}@ can request access to following role(s) using the link below.`}
                            {getRolesList(allowedRoles)}
                            {getAccessRequestLink()}
                        </>
                    }
                </Alert>
            </Container>
        </UserAccessExceptionPage>
    );
}

export default UserAccessException;

const UserAccessExceptionPage = styled.div`
  margin: 0;
  padding: 0;
  display: grid;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  place-content: center;
  align-items: center;
`;

const getAccessRequestLink = (text = "Click here to raise an access request ticket") => {
    const accessRequestUrl = "https://t.corp.amazon.com/create/templates/aab7ed03-f3b3-4978-9644-87f23a17a933";
    return <Link external href={accessRequestUrl}>{text}</Link>
}

export const getCommonRoles = (roles1: Roles[], roles2: Roles[]) => roles1.filter(role => roles2.includes(role));

const getRolesList = (roles: Roles[]) => <ul style={{margin: "4px"}}>{roles.map(role => <li>{role}</li>)}</ul>;
