import {Modal} from "@amzn/awsui-components-react";
import * as React from "react";
import {ConfirmReportSyncModalFooter} from "src/components/ViewReportPage/ConfirmReportSyncModalSubComponents";
import {ConfirmReportSyncModalProps} from "src/components/ViewReportPage/interfaces";

const ConfirmReportSyncModal = (props: ConfirmReportSyncModalProps) => {
    const {visible, syncId, onConfirm, onClose} = props;
    const header = `Confirm ${syncId.charAt(0).toUpperCase() + syncId.slice(1)} Sync`

    const messages: any = {
        report: 'Are you sure you want to sync the report data? This may change variance figures, ' +
            'and bridges may no longer match synced data. Please sync the bridges to the report once finalized.',
        bridge: 'Are you sure you want to sync the bridge data? This will update all variance figures that downstream ' +
            'teams need to explain and require a re-work of in-progress or completed analyst bridges. If a bridge ' +
            'is synced, it will move to the requested state.'
    }

    return (
        <Modal
            visible={visible}
            onDismiss={() => onClose()}
            closeAriaLabel={"Close modal"}
            header={header}
            footer={
               <ConfirmReportSyncModalFooter
                   onConfirm={onConfirm}
                   onCancel={onClose}
               />
            }
        >
            {messages[syncId]}
        </Modal>
    );
}

export default ConfirmReportSyncModal;
