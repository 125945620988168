import {Alert, Modal, SpaceBetween} from "@amzn/awsui-components-react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {postReportDefinition, resetReportLibraryStatus} from "src/actions/reportLibrary.actions";
import {ExternalReportState, ItemType, Libraries, ReportStatus} from "src/common/report";
import {Roles} from "src/common/roles";
import {ExternalReportNameAndType} from "src/components/ExternalReportPage/ExternalReportContainer";
import Paths from "src/components/PageConfig/Paths";
import {getReportLibraryStatus} from "src/reducers/reportLibrary.reducer";
import {getUser} from "src/reducers/user.reducer";
import {getEpoch} from "src/utils/timeHelpers";
import {v4 as uuid} from "uuid";

const ConfirmationModal = (
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    externalReportDescription: ExternalReportNameAndType,
    externalReportState: ExternalReportState,
    reportDefinitionsApiIsLoading: boolean
) => {

    const dispatch = useDispatch();
    const userName = useSelector(getUser);
    const {savedReportDefinitions, errorType, errorMessage} = useSelector(getReportLibraryStatus);

    useEffect(() => {
        if(savedReportDefinitions){
            onModalDismiss();
            window.location.replace(`#${Paths.ACTIVEREPORTS}`);
        }
    }, [savedReportDefinitions]);

    const onModalDismiss = () => {
        dispatch(resetReportLibraryStatus())
        setVisible(false);
    }

    const onModalConfirm = () => {
        const reportDefinitions = {
            report_id: externalReportState.report_id ? externalReportState.report_id : `report_id.${uuid()}`,
            report_status: getReportStatus(externalReportState?.report_status),
            report_library: Libraries.Active,
            report_role: Roles.Admin,
            created_at: externalReportState?.created_at ?? getEpoch(),
            created_by: externalReportState?.created_by ?? userName,
            last_modified: getEpoch(),
            last_modified_by: userName,
            name_and_type: {
                report_name: externalReportDescription.reportName,
                report_type: externalReportDescription.reportType,
                finance_cycle: externalReportDescription.financeCycle,
                year: externalReportDescription.year,
                period: externalReportDescription.period,
            },
            item_type: ItemType.External,
            link: externalReportDescription.link,
            external_report_source: externalReportDescription.reportSource
        };

        dispatch(postReportDefinition(reportDefinitions));
    }

    return (
        <Modal
            onDismiss={() => {
                setVisible(false)
            }}
            visible={visible}
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link"
                                disabled={reportDefinitionsApiIsLoading}
                                onClick={() => onModalDismiss()}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary"
                                loading={reportDefinitionsApiIsLoading}
                                onClick={() => onModalConfirm()}
                        >
                            Submit
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={`${externalReportState.report_status === "Updating" ? "Edit" : "Create"} source: ${externalReportDescription.reportName}`}
        >
            <SpaceBetween direction="vertical" size="m">
                {errorType && errorMessage &&
                    <Alert type='error'>{`${errorType}: ${errorMessage}`}</Alert>
                }
                <>
                    Please review your selections below to make sure it's correct.
                    <div>
                        <div><b>External report source</b></div>
                        <div>- {externalReportDescription.reportSource}</div>
                        <div><b>Report type</b></div>
                        <div>- {externalReportDescription.reportType}</div>
                        <div><b>Year</b></div>
                        <div>- {externalReportDescription.year}</div>
                        <div><b>Period</b></div>
                        <div>- {externalReportDescription.period}</div>
                        <div><b>Finance cycle</b></div>
                        <div>- {externalReportDescription.financeCycle}</div>
                    </div>
                </>
            </SpaceBetween>
        </Modal>
    );
}

export default ConfirmationModal;


export const getReportStatus = (reportStatus: string | undefined) => {
    if (reportStatus == null || reportStatus == ReportStatus.CREATING) {
        return ReportStatus.CREATED;
    } else if (reportStatus == ReportStatus.UPDATING) {
        return ReportStatus.EDITED;
    } else {
        return reportStatus;
    }
}
