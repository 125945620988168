import {LibraryGroup} from "src/reducers/mdxLibrary.reducer";

// Type definition for query type
export enum MDXQueryType {
    FixedTotal="Fixed total",
    FXImpact = "FX impact"
}

// Type definition for possible job attributes
export type JobAttributeType = "instance" | "cube" | "year" | "scenario";

// Definition of option type for job attribute
export type AttributeOption = {
    label: string
}

// Interface definition of single job attribute state
export interface AttributeState {
    selectedOption: AttributeOption,
    options: AttributeOption[]
}

// Interface definition of job configuration on create mdx group modal
export interface JobState {
    job_id: string,
    query_type: MDXQueryType,
    disabled: boolean,
    instance: AttributeState,
    cube: AttributeState,
    year: AttributeState,
    scenario: AttributeState,
}

// Interface definition for job status
export interface JobStatus {
    queued: boolean,
    fetcher: string,
    aggregator: string,
    cancelled: boolean,
    cancelled_at: number | undefined,
    errored_at: number | undefined,
}

// Interface definition for single MDX job in mdx library
export interface MDXJob {
    group: string,
    job_id: string,
    // status: StatusIndicatorProps.Type | undefined,
    status: JobStatus,
    last_refreshed: string,
    query_type: MDXQueryType,
    instance: string,
    cube: string,
    year: string,
    scenario: string
}

export interface ScenarioOptions {
    [instance: string]: {
        [cube: string]: {
            [year: string]: string[]
        }
    }
}

// Interface definition for MDX job options when creating mdx job in modal UI
export interface JobDropdownOptions {
    [instance: string]: {
        [cube: string]: {
            [year: string]: {
                scenarios: string[],
                fx_impact_scenarios?: string[]
            }
        }
    }
}

// Interface definition for row in mdx table
export interface MDXTableRow {
    job_id: string,
    query_type?: string,
    status?: JSX.Element,
    group: JSX.Element,
    action: JSX.Element,
    instance?: JSX.Element,
    cube?: string,
    year?: string,
    scenario?: string,
    last_refreshed?: string,
    isGroup?: boolean
}

// Interface definition for object to maintain tree-view state of a group in mdx table
export interface ExpandedGroup {
    [groupName: string]: boolean
}

// Interface definition for object to maintain tree-view state of a bridge in bridges view table
export interface ExpandedBridge {
    [bridgeName: string]: boolean
}


// Interface definition for JobDeleteModal component
export interface JobDeleteModalProps {
    job: MDXJob,
    visible: boolean,
    setVisible: (newValue: boolean) => void,
}

// Interface definition for CreateMDXGroupModal component
export interface MDXGroupModalProps {
    initialGroup: {[p:string]: LibraryGroup},
    resetInitialGroup: () => void,
    visible: boolean,
    setVisible: (newValue: boolean) => void,
}

// Interface definition for props of JobAttributeEditor component
export interface JobAttributeEditorProps {
    jobs: JobState[],
    setJobs:(jobs: (jobs: JobState[]) => JobState[]) => void,
    queryType: MDXQueryType,
    scenarioOptions: ScenarioOptions,
    duplicateIndexes: Set<number>
    loading: boolean,
}

// Interface definition for props of JobAttributeControl component
export interface JobAttributeControlProps {
    attributeState: AttributeState,
    index: number,
    jobAttribute: JobAttributeType,
    setJobs: (jobs: (jobs: JobState[]) => JobState[]) => void,
    scenarioOptions: ScenarioOptions,
    disabled: boolean
    otherProps?: object,
}
