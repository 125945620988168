import {BridgeHistoryItem, BridgeSubItem} from "src/components/FillOutBridgePage/interfaces";

export const defaultRow: BridgeSubItem = {
    row_id: "",
    touched: false,
    name: "",
    leftValue: 0,
    rightValue: 0,
    treeLevel: 0,
    varianceValue: 0,
    commentary: "",
    commentator: "",
    follow_up: {
        comment: "",
        timestamp: "",
        login: ""
    }
}

export const mergeRowIds = (currentRows: string[], oldRows: string[]): string[] => {
    const inserted = new Set<string>()
    const merged: string[] = []
    for(let i = 0; i < currentRows.length || i < oldRows.length; i++) {
        if(i < currentRows.length && !(inserted.has(currentRows[i]))){
            merged.push(currentRows[i])
            inserted.add(currentRows[i])
        }

        if(i < oldRows.length && !(inserted.has(oldRows[i]))){
            merged.push(oldRows[i])
            inserted.add(oldRows[i])
        }
    }

    return merged;
}

export const mostRecentCommenter = (bridgeHistoryItem: BridgeHistoryItem): {timestamp: number, commenter: string} => {
    const defaultCommenter = {timestamp: 0, commenter: ""}

    if (!bridgeHistoryItem) return defaultCommenter
    return Object.keys(bridgeHistoryItem).reduce((acc, rowId) => {
        const row = bridgeHistoryItem[rowId]
        if (row.last_edited_timestamp && (acc.timestamp < row.last_edited_timestamp))
            return {timestamp: row.last_edited_timestamp, commenter: row.commentator}
        return acc
    }, defaultCommenter)
}

export const getCurrentBridgeItem = (bridgeSubItems: BridgeSubItem[]): BridgeHistoryItem => {
    return bridgeSubItems.reduce((historyItem, subItem) => {
        return {
            ...historyItem,
            [subItem.row_id] : subItem
        }
    }, {})
}

export const historyItemToBridgeItem = (historyItem: BridgeHistoryItem): BridgeSubItem[] => {
    return Object.keys(historyItem).map(rowId => historyItem[rowId])
}
