import {Popover, StatusIndicator, StatusIndicatorProps} from "@amzn/awsui-components-react";
import React from "react";
import {JobStatus} from "src/components/MDXLibraryPage/interfaces";
import {aggregateJobStatus} from "src/utils/mdxLibraryHelpers";
import {humanizeEpoch} from "src/utils/timeHelpers";


interface StatusProps {
    status: JobStatus,
    lastRefreshed: string,
}

const Status = ({status, lastRefreshed}: StatusProps) => {
    // Constant map to transform statusText -> [polaris status icon name, pop over message for status icon]
    const statusTextToIconName: Record<string, [StatusIndicatorProps.Type | undefined, string]> = {
        "Success": [
            "success",
            `Job finished processing on ${humanizeEpoch({epoch: lastRefreshed, asDate: false})}`
        ],
        "Error": [
            "error",
            `Job errored out on ${humanizeEpoch({epoch: status.errored_at, asDate: false})}`
        ],
        "In-progress": [
            "in-progress",
            "Job is getting processed"
        ],
        "Pending": [
            "pending",
            "Job is waiting to be processed"
        ],
        "Cancelled": [
            "stopped",
            `Job was cancelled on ${humanizeEpoch({epoch: status.cancelled_at, asDate: false})}`
        ]
    }

    const statusText = aggregateJobStatus(status);
    const [iconName, popOverMessage] = statusTextToIconName[statusText];

    return(
        <Popover
            dismissAriaLabel="Close"
            size="small"
            header="Status Info"
            content={popOverMessage}
        >
            <StatusIndicator data-testid="job-status-indicator" type={iconName}>{statusText}</StatusIndicator>
        </Popover>
    );
};

export default Status
