import {
    Alert,
    Box,
    Button,
    FormField,
    Header,
    Input,
    Modal,
    ModalProps,
    SpaceBetween
} from "@amzn/awsui-components-react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {postJobGroup, resetLibraryStatus} from "src/actions/mdxLibrary.actions";
import {HorizontalLine} from "src/components/CreateReportPage/styles";
import {JobState, MDXGroupModalProps, MDXJob, MDXQueryType} from "src/components/MDXLibraryPage/interfaces";
import JobAttributeEditor from "src/components/MDXLibraryPage/JobAttributeEditor";
import {getJobGroupApiLoading} from "src/reducers/apiLoading.reducer";
import {getJobDropdownOptions, getMdxLibraryStatus} from "src/reducers/mdxLibrary.reducer";
import {
    getInitialJobStatus,
    readJobStateLabels,
    separateJobDropdownOptions,
    transformJobsToJobStateList,
    validateJobGroup
} from "src/utils/mdxLibraryHelpers";
import {sanitizeString} from "src/utils/stringHelpers";


const MDXGroupModal = ({initialGroup, resetInitialGroup, visible, setVisible}: MDXGroupModalProps) => {
    const dispatch = useDispatch();
    // Redux data selectors
    const apiCallInProgress = useSelector(getJobGroupApiLoading);
    const {createdGroup, updatedGroup, errorType, errorMessage} = useSelector(getMdxLibraryStatus);
    const jobDropdownOptions = useSelector(getJobDropdownOptions);
    // React states
    const [groupName, setGroupName] = useState("")
    const [alertVisible, setAlertVisible] = useState(false);
    const [fixedTotalJobs, setFixedTotalJobs] = useState<JobState[]>([]);
    const [fxImpactJobs, setFXImpactJobs] = useState<JobState[]>([]);
    // Determine if modal is for update or creation
    const updatingGroup = Object.keys(initialGroup).length > 0;

    // Use effect for initial modal setup
    useEffect(function initializeModal() {
        if (updatingGroup) {
            const groupName = Object.keys(initialGroup)[0];
            const fixedTotalJobs = initialGroup[groupName].jobs.filter(jobItem => jobItem.query_type === MDXQueryType.FixedTotal);
            const fxImpactJobs = initialGroup[groupName].jobs.filter(jobItem => jobItem.query_type === MDXQueryType.FXImpact);
            setGroupName(groupName);
            setFixedTotalJobs(transformJobsToJobStateList(fixedTotalJobs, true));
            setFXImpactJobs(transformJobsToJobStateList(fxImpactJobs, true));
        }
        dispatch(resetLibraryStatus());
    }, [initialGroup])

    // Use effect to handle group update/create result
    useEffect(function handleApiCallResult () {
        (createdGroup || updatedGroup) && onModalDismiss({reason: "cancel"});
        errorType && setAlertVisible(true);
    }, [createdGroup, errorType, updatedGroup]);

    // Handler for modals submit button
    const onSubmitButtonClick = () => {
        const jobAction = updatingGroup ? 'update' : 'create'
        const payloadJobs: MDXJob[] = [];
        [...fixedTotalJobs, ...fxImpactJobs].forEach(jobState => {
            payloadJobs.push({
                group: groupName,
                last_refreshed: "-",
                status: getInitialJobStatus(),
                ...readJobStateLabels(jobState),
            })
        });
        // Dispatch an API call to create new job group
        dispatch(postJobGroup(groupName, payloadJobs, jobAction));
    };

    // Handler for modal close buttons
    const onModalDismiss = (detail: ModalProps.DismissDetail) => {
        // If user clicks on 'X' button on modal, clicks 'cancel' button on modal ui,
        // or presses 'esc' on their keyboard, clean the modal UI and close
        const reason = detail.reason;
        if (reason === 'keyboard' || reason === 'closeButton' || reason === 'cancel'){
            setFixedTotalJobs([]);
            setFXImpactJobs([]);
            resetInitialGroup();
            setGroupName("");
            setAlertVisible(false);
            dispatch(resetLibraryStatus())
            setVisible(false);
        }
    }

    /* Constants and Booleans */
    // validate job group locally
    const {fixedTotalDropdownOptions, fxImpactDropdownOptions} = separateJobDropdownOptions(jobDropdownOptions);
    const fixedTotalJobsValidator = validateJobGroup(groupName, fixedTotalJobs);
    const fxImpactJobsValidator = validateJobGroup(groupName, fxImpactJobs);
    // Check if group can be submitted to server
    const canSubmitGroup = (fixedTotalJobs.length > 0 || fxImpactJobs.length > 0) && groupName.length > 0 &&
        fixedTotalJobsValidator.validGroupName && fixedTotalJobsValidator.allJobsConfigured &&
        fixedTotalJobsValidator.duplicateIndexes.size === 0  && fxImpactJobsValidator.allJobsConfigured &&
        fxImpactJobsValidator.duplicateIndexes.size === 0
    const groupNameConstraintText = "Group name must be unique, start with letter, & only contain characters " +
        "from a-z, A-Z, 0-9 and _";
    const groupNameErrorText = !fxImpactJobsValidator.validGroupName &&
        ("Invalid group name" + ". " + groupNameConstraintText);

    return (
        <Modal
            onDismiss={e => onModalDismiss(e.detail)}
            visible={visible}
            closeAriaLabel="Close modal"
            size="large"
            header={
                <Header variant="h1">
                    <SpaceBetween direction="vertical" size="s">
                        <span>
                            {`${updatingGroup ? 'Update' : 'Create'} MDX Group`}
                        </span>
                        <FormField
                            label="MDX query group name"
                            errorText={groupNameErrorText}
                        >
                            <Input
                                value={groupName}
                                placeholder="Enter group name"
                                onChange={event => setGroupName(sanitizeString(event.detail.value))}
                                disabled={updatingGroup || apiCallInProgress }
                            />
                        </FormField>
                    </SpaceBetween>
                </Header>
            }
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => onModalDismiss({reason: "cancel"})} disabled={apiCallInProgress}>
                            Cancel
                        </Button>
                        <Button variant="primary"
                                onClick={onSubmitButtonClick}
                                loading={apiCallInProgress}
                                disabled={!canSubmitGroup}
                        >
                            Submit
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction='vertical' size='s'>
                <Alert type="error"
                       onDismiss={() => {
                           dispatch(resetLibraryStatus())
                           setAlertVisible(false)
                       }}
                       visible={alertVisible}
                       dismissAriaLabel="Close alert"
                       dismissible={true}
                >
                    {errorMessage}
                </Alert>

                <HorizontalLine/>

                <FormField label='Fixed total queries' stretch={true}>
                    <JobAttributeEditor jobs={fixedTotalJobs}
                                        setJobs={setFixedTotalJobs}
                                        queryType={MDXQueryType.FixedTotal}
                                        duplicateIndexes={fixedTotalJobsValidator.duplicateIndexes}
                                        scenarioOptions={fixedTotalDropdownOptions}
                                        loading={apiCallInProgress}
                    />
                </FormField>

                <HorizontalLine/>

                <FormField label='Fx impact queries' stretch={true}>
                    <JobAttributeEditor jobs={fxImpactJobs}
                                        setJobs={setFXImpactJobs}
                                        queryType={MDXQueryType.FXImpact}
                                        duplicateIndexes={fxImpactJobsValidator.duplicateIndexes}
                                        scenarioOptions={fxImpactDropdownOptions}
                                        loading={apiCallInProgress}
                    />
                </FormField>
            </SpaceBetween>
        </Modal>
    );
};

export default MDXGroupModal;
