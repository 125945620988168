import styled from "styled-components";

export const Div = styled.div`
  padding-left: 4em;
  padding-right: 4em;
`;

export const ActionCardsDiv = styled.div`
  display: grid;
  grid-column-gap: 2em;
  grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
  grid-row-gap: 2em;
`;

export const SingleActionCardDiv = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-column-gap: 2em;
  grid-template-rows: 3em 13em 1fr;
  justify-items: start;
`;

export const ColorDiv = styled.div`
  color: #5f6b7a;
`;

export const ImageSectionsDiv = styled.div`
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 3em;
  grid-template-columns: repeat(auto-fit,minmax(210px,1fr));
`;

export const SingleImageSectionDiv = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 1em;
  grid-template-rows: 2em 12em 2em 10em 1fr;
  justify-items: start;
`;

export const IconHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;

export const HowItWorksDiv= styled.div`
  padding-left: 4em;
  padding-right: 4em;
`;

export const TextWithLetterBullets = styled.div`
  display: flex;
  column-gap: 5px;
`;

export const StyledLetter = styled.div`
  color: #0972D3;
  border-radius: 100%;
  background-color: #F2F8FD;
  height: fit-content;
  padding-left: 5px;
  padding-right: 5px;
`;

export const StyledWord = styled.div`
  border-radius: 100px;
  background-color: #0972D3;
  color: white;
  padding: 4px 12px 4px 12px;
`;
