export const PERIODS = [
    { label: "Q1", value:"Q1" },
    { label: "Q2", value:"Q2" },
    { label: "Q3", value:"Q3" },
    { label: "Q4", value:"Q4" },
    { label: "FY", value:"FY" },
    { label: "Jan", value:"Jan" },
    { label: "Feb", value:"Feb" },
    { label: "Mar", value:"Mar" },
    { label: "Apr", value:"Apr" },
    { label: "May", value:"May" },
    { label: "Jun", value:"Jun" },
    { label: "Jul", value:"Jul" },
    { label: "Aug", value:"Aug" },
    { label: "Sep", value:"Sep" },
    { label: "Oct", value:"Oct" },
    { label: "Nov", value:"Nov" },
    { label: "Dec", value:"Dec"}
]


export const FINANCE_PERIODS = [
    { label: "MEC" },
    { label: "OP1" },
    { label: "OP2" },
    { label: "R&O" },
    { label: "3YF" },
    { label: "Other" }
]

export const REPORT_TYPES = [
    { label: "AWS Financial Statement Reporting" },
    { label: "AWS Financial Statement Reporting V2" }
]

export function getYears() {
    const currentYear = new Date().getFullYear();
    const result = [];
    for (let i = currentYear - 5; i <= currentYear + 1; i++) {
        result.push({label: i.toString()})
    }
    return result;
}

export type StylesheetDefaultFileNameKeys = "AWS Financial Statement Reporting"
    | "AWS Financial Statement Reporting V2";

export const stylesheetDefaultFileName: Record<StylesheetDefaultFileNameKeys, string> = {
    "AWS Financial Statement Reporting": "default",
    "AWS Financial Statement Reporting V2": "default_V2"
}