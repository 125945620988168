import { RowNumberingType } from "@amzn/aws-fintech-fluid-table";
import {Alert, ContentLayout, SpaceBetween} from "@amzn/awsui-components-react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import * as React from "react";
import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteTheme,
    patchTheme,
    patchThemeItems,
    setTheme,
    setThemeItems,
    setThemesAlert,
    updateThemeItem
} from "src/actions/themes.actions";
import Paths from "src/components/PageConfig/Paths";
import {ConfirmDeleteModal} from "src/components/Themes/header/ConfirmDeleteModal";
import {DriverCommentarySelect} from "src/components/Themes/header/interfaces";
import ThemeActionBar from "src/components/Themes/header/ThemeActionBar";
import {themeActionHandlers} from "src/components/Themes/header/themeActionHandlers";
import ThemeHeader from "src/components/Themes/header/ThemeHeader";
import {useBatchSave, useThemeData} from "src/components/Themes/hooks";
import {ThemeColumn, ThemeItem, ThemeStates, ThemeTableEvent} from "src/components/Themes/interfaces";
import ThemeTable from "src/components/Themes/table/ThemeTable";
import {ThemeNotFound} from "src/components/Themes/ThemeNotFound";
import {selectBreadcrumbs} from "src/features/breadcrumbs/breadcrumbSlice";
import {useBreadcrumb} from "src/features/breadcrumbs/hooks/useBreadcrumb";


export interface CommentarySettings {
    driverCommentarySelect?: DriverCommentarySelect,
    sourceCommentary?: boolean
}


const themeEventMergeFn = (prev: ThemeTableEvent, next: ThemeTableEvent) => {
        const itemId = Object.keys(next)[0]
        const prevData = prev[itemId];
        const nextData = next[itemId];

    return 'move' in prevData && 'move' in nextData ? {
        ...prevData,
        ...{...nextData, move: {new: nextData.move?.new, old: prevData.move?.old}}
    }
    : {...prev[itemId], ...next[itemId]}
}

export const ThemesPage = () => {
    const dispatch = useDispatch();
    const {theme, themeItems, alert} = useThemeData()
    // Handles row edits
    const {addToBatch} = useBatchSave<ThemeTableEvent>((events) => dispatch(patchThemeItems(theme.id, events)), themeEventMergeFn)
    const [commentarySettings, setCommentarySettings] = React.useState<CommentarySettings>({
        driverCommentarySelect: DriverCommentarySelect.both,
        sourceCommentary: false
    })
    const [rowNumbering, setRowNumbering] = React.useState<RowNumberingType>('relative')
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const headerText = `${theme.leftScenario} vs. ${theme.rightScenario}: ${theme.name} Theme Bridge`
    const currentBreadCrumbs = useSelector(selectBreadcrumbs);
    useBreadcrumb({crumbs: [...currentBreadCrumbs, {text: headerText, href: `#${Paths.THEMES}?themeId=${theme.id}`}]});

    const setColumns = useCallback((columns: ThemeColumn[]) => {
        dispatch(setTheme({...theme, columns}))
        // Calling API directly since no table computation occurs
        dispatch(patchTheme(theme.id, undefined, {columns}))
    }, [theme])
    const setItems = useCallback((themeItems: ThemeItem[])  => dispatch(setThemeItems(themeItems)), [])
    const updateItem = useCallback((location: string, itemUpdates: object) => dispatch(updateThemeItem(location, itemUpdates)), [])
    const actionHandlers = themeActionHandlers(
      theme.id,
      themeItems,
      addToBatch,
      (settings: CommentarySettings) => setCommentarySettings(prevState => ({...prevState, ...settings})),
      (type: RowNumberingType) => setRowNumbering(type),
      () => setShowDeleteModal(true)
    )
    const updateSourceCommentary = useCallback((sourceCommentary: boolean) => setCommentarySettings(prevState => ({...prevState, sourceCommentary})), [])

    return alert && alert.message === 'ThemeNotFound' ? <ThemeNotFound /> :
        <ContentLayout header={<Header variant={'h1'}>{headerText}</Header>}>
            <SpaceBetween size={'m'}>
                {alert && <Alert dismissible onDismiss={() => dispatch(setThemesAlert(undefined))} type={alert.type}>{alert.reason}</Alert>}
                <ConfirmDeleteModal onConfirm={() => dispatch(deleteTheme(theme.id))} onClose={() => setShowDeleteModal(false)} visible={showDeleteModal} theme={theme}/>
                <Container header={<ThemeHeader theme={theme} actionHandlers={actionHandlers}/>}>
                    {theme.state !== ThemeStates.Published && <ThemeActionBar
                      actionHandlers={actionHandlers}
                      commentarySettings={commentarySettings}
                      leftScenario={theme.leftScenario}
                      rightScenario={theme.rightScenario}
                      state={theme.state}
                      reportId={theme.reportId}
                      rowNumbering={rowNumbering}
                    />}
                    <ThemeTable
                      state={theme.state}
                      items={themeItems}
                      setItems={setItems}
                      columns={theme.columns}
                      setColumns={setColumns}
                      addEvent={addToBatch}
                      updateItem={updateItem}
                      updateSourceCommentary={updateSourceCommentary}
                      commentarySettings={commentarySettings}
                      rowNumbering={rowNumbering}
                    />
                </Container>
            </SpaceBetween>
        </ContentLayout>
}
