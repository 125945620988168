import {PropertyFilter, Tabs, TabsProps} from "@amzn/awsui-components-react";
import React from "react";
import {useSelector} from "react-redux";
import {Roles} from "src/common/roles";
import {StatusFilterProps} from "src/components/BridgingCommentary/interfaces";
import {AdminTabStatus, OwnerTabStatus} from "src/components/BridgingCommentary/summaryComponents/interfaces";
import {
    getFilterCounterText,
    PROPERTY_FILTERING_I18N_CONSTANTS
} from "src/components/ReportLibraryPage/ReportTableConfig";
import {getAssumedRole} from "src/reducers/user.reducer";

export const BridgeStatusFilter = (statusFilterProps : StatusFilterProps) => {
    const assumedRole = useSelector(getAssumedRole);
    const BridgeTabStatus = (assumedRole == Roles.BridgeOwner) ? OwnerTabStatus : AdminTabStatus;
    const {propertyFilterProps, setActiveBridgeStatus, setFilteringInProgress, filteredItemsCount} = statusFilterProps;
    const bridgeTabs = Object.values(BridgeTabStatus).map( (status )=> ({"label": status, "id": status}))
    const onTabsChange = (eventDetail: TabsProps.ChangeDetail) => {
        setActiveBridgeStatus(eventDetail.activeTabId)
    };
    return (<>
        {<PropertyFilter
            i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
            {...propertyFilterProps}
            onChange={event => {
                setFilteringInProgress(event.detail.tokens.length > 0);
                propertyFilterProps.onChange(event)
            }}
            countText={getFilterCounterText(filteredItemsCount || 0)}
        />}
        {
            <Tabs
                disableContentPaddings
                tabs={
                    bridgeTabs
                }
                onChange={event => {onTabsChange(event.detail);}}
            />
        }
    </>)
}