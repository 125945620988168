import {Modal} from "@amzn/awsui-components-react";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getBridgesBeingViewed,
    RESET_BRIDGE_BEING_VIEWED_CURRENTLY, setSelectedBridgeId, setSelectedRow,
    setViewAllBridgesModalVisible,
    setViewBridgeModalVisible
} from "src/actions/bridgingCommentary.actions";
import {defaultBridgeSubItem} from "src/components/FillOutBridgePage/interfaces";
import {ViewBridgeModalProps} from "src/components/ViewReportPage/SingleBridgeView/interfaces";
import {ModalContentDiv} from "src/components/ViewReportPage/SingleBridgeView/Styled";
import {ViewBridgeTableHeader} from "src/components/ViewReportPage/SingleBridgeView/TableSubComponents";
import {
    ViewBridgeModalFooter,
    ViewBridgeModalHeader
} from "src/components/ViewReportPage/SingleBridgeView/ViewBridgeModalSubComponents";
import ViewBridgeTable from "src/components/ViewReportPage/SingleBridgeView/ViewBridgeTable";
import {getBridgeBeingViewedCurrentlyLoading, getCommentsApiLoading} from "src/reducers/apiLoading.reducer";
import {getBridgeBeingViewedCurrently} from "src/reducers/bridgingCommentary.reducer";
import {getViewReport} from "src/reducers/reportLibrary.reducer";
import {getAssumedRole} from "src/reducers/user.reducer";


const ViewBridgeModal = ({visible}: ViewBridgeModalProps) => {
    const dispatch = useDispatch();
    // Selectors
    const reportDefinition = useSelector(getViewReport).reportDefinitions;
    const {bridge_item, bridge_sub_items, commentators} = useSelector(getBridgeBeingViewedCurrently);
    const bridgeBeingViewedCurrentlyLoading = useSelector(getBridgeBeingViewedCurrentlyLoading);
    const fetchingComments = useSelector(getCommentsApiLoading);
    const role = useSelector(getAssumedRole);

    // Constants
    // This should always be present since it is initialized with bridge
    // Adding default to prevent errors in the case that APIs are loading
    const firstSubItem = bridge_sub_items[0] || defaultBridgeSubItem

    // states
    const [expandedView, setExpandedView] = useState(false);
    // Handler for modal close buttons
    const onModalDismiss = () => {
        // reset viewing bridge modal artifacts
        dispatch({type: RESET_BRIDGE_BEING_VIEWED_CURRENTLY, payload: {}});
        setExpandedView(false);
        // close the modal
        dispatch(setViewBridgeModalVisible(false));
    };
    // Handler for opening veiw all bridges modal
    const viewAllBridgesAction = () => {
        role && dispatch(getBridgesBeingViewed({
            role: role,
            variance_header: bridge_item.variance_headers.variance_header
        }, bridge_item.report_id))
        // Show current bridge as default when rendering ViewAllBridgesModal
        dispatch(setSelectedBridgeId(bridge_item.bridge_id))
        dispatch(setSelectedRow(bridge_sub_items[0])) // There should always be at least 1 sub item - the summary sub item
        dispatch(setViewBridgeModalVisible(false));
        dispatch(setViewAllBridgesModalVisible(true));
    };

    return (
        <Modal
            onDismiss={() => onModalDismiss()}
            visible={visible}
            size="large"
            closeAriaLabel="Close modal"
            header={!bridgeBeingViewedCurrentlyLoading &&
                <ViewBridgeModalHeader bridgeItem={bridge_item} firstSubItem={firstSubItem}/>
            }
            footer={!bridgeBeingViewedCurrentlyLoading &&
                <ViewBridgeModalFooter
                    bridgeItem={bridge_item}
                    viewAllBridgesHandler={viewAllBridgesAction}
                    dismissModalHandler={onModalDismiss}
                />
            }
        >
            <ViewBridgeTableHeader
                bridgeItem={bridge_item}
                reportDefinition={reportDefinition}
                commentators={commentators}
            />
            <ModalContentDiv  id={"modal-bridge-content"}>
                <ViewBridgeTable
                    bridgeItem={bridge_item}
                    subItems={bridge_sub_items}
                    loading={bridgeBeingViewedCurrentlyLoading || fetchingComments}
                    expandedView={expandedView}
                    setExpandedView={setExpandedView}
                />
            </ModalContentDiv>
        </Modal>
    );
};

export default ViewBridgeModal;