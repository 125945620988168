import {SECONDS_IN_TWO_DAYS, WEEKDAY} from "src/constants/timeConstants";

const TRUE = true;
const EMPTY_STRING = "";

interface Args {
    epoch: string | number,
    milli?: boolean,
    withYear?: boolean
    shortDate?: boolean
}
export const dueInTwoDays = (epoch : number , dueTime : number) => {
    const epochInSeconds = Math.floor(Number(dueTime) / 1000)
    const seconds = epochInSeconds - Math.floor(new Date().getTime() / 1000.0) ;
    return seconds < SECONDS_IN_TWO_DAYS;
}
export const getDateTime = (date: string, time : string) : number => {
    //assuming this new Date is always a valid date
    const fullDate = new Date(`${date} ${time}`);
    return fullDate.getTime();
}
export const validateDueDate = (date: string, time: string): boolean => {
    const timeCrumbs = time.split(":")
    const dateCrumbs = date.split("-");
    if(timeCrumbs.length !== 2 || dateCrumbs.length !== 3 || timeCrumbs[0].length !== 2 || timeCrumbs[1].length !== 2
        || dateCrumbs[0].length !== 4 || dateCrumbs[1].length !== 2 || dateCrumbs[2].length !== 2
    ) {
        return false;
    }
    const fullDate = new Date(`${date} ${time}`);
    // Past date and weekends are not allowed
    return fullDate.getTime() >= getEpoch();

}
export const humanizeEpoch = ({epoch, asDate = false}: {epoch: any, asDate?: boolean | undefined}) => {
    if (isNaN(epoch) || parseInt(epoch) <= 0){
        return "";
    }
    const newDate = new Date(parseInt(epoch));
    const options: any = {year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short'};
    const saveTime = new Date(newDate).toLocaleDateString(undefined, options);
    return asDate ? saveTime : String(saveTime);
};

export const epochToNewDate = (epoch: any, days : number) => {
    const date = new Date(parseInt(epoch))
    const newDate = date.setDate(date.getDate() + days)
    const options: any = {year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short'};
    const saveTime = new Date(newDate).toLocaleDateString(undefined, options);
    const weekday = WEEKDAY[new Date(newDate).getDay()];
    return weekday + ", " + saveTime
}

export const addDaysToTimeStamp = (epoch: any, days : number) => {
    const date = new Date(parseInt(epoch))
    return date.setDate(date.getDate() + days)
}
export const epochToDate = (epoch: any, sep = "-") => {
    const newDate = new Date(parseInt(epoch))
    const year = new Date(newDate).toLocaleDateString(undefined, {year: "numeric"});
    const month = new Date(newDate).toLocaleDateString(undefined, {month: "2-digit"});
    const day = new Date(newDate).toLocaleDateString(undefined, {day: "2-digit"});
    return `${year}${sep}${month}${sep}${day}`
}

export const epochToHhMm = (epoch: any) => {
    const newDate = new Date(parseInt(epoch))
    return new Date(newDate).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    })

}

export const dateAndTimeToEpoch = (date: string, hourAndMin: string) => {
    // Date from DatePicker component will be of format YYYY-MM-DD
    //hourAndMin from TimeInput component will be of format hh:mm

    const [year, month, day] = date.split('-')
    const [hours, minutes] = hourAndMin.split(':')
    return new Date(+year, +month - 1, +day, +hours, +minutes, +'00').getTime()
}

// export function humanizeISODateTime(date: any) {
//     const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
//     const offset = date.getTimezoneOffset() / 60;
//     const hours = date.getHours();
//     newDate.setHours(hours - offset);
//     const options: any = {month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short'};
//     const saveTime = new Date(newDate).toLocaleDateString(undefined, options);
//     return String(saveTime);
// }
//
//

export const epochToAgo = ({epoch, milli = TRUE}: Args) => {
    if (!epoch) {
        return EMPTY_STRING
    }
    // milli epoch has 13 digits
    const epoch10Digit = milli ? Math.floor(Number(epoch) / 1000) : Number(epoch);
    const seconds = Math.floor(new Date().getTime() / 1000.0) - epoch10Digit;
    return secondsToAgo(seconds)
};

export const secondsToAgo = (seconds: number | string) => {
    const secs = Number(seconds);

    const calculateYears = () => {
        const y = Math.floor(secs / (3600 * 24 * 7 * 4 * 12));
        return y > 0 ? y + (y === 1 ? " year" : " years") : "";
    };

    const calculateMonths = () => {
        const mo = Math.floor(secs / (3600 * 24 * 7 * 4));
        return mo > 0 ? mo + (mo === 1 ? " month" : " months") : "";
    };

    const calculateWeeks = () => {
        const w = Math.floor(secs / (3600 * 24 * 7));
        return w > 0 ? w + (w === 1 ? " week" : " weeks") : "";
    };

    const calculateHours = () => {
        const h = Math.floor(secs % (3600 * 24) / 3600);
        return h > 0 ? h + (h === 1 ? " hour" : " hours") : "";
    };

    const calculateMinutes = () => {
        const m = Math.floor(secs % 3600 / 60);
        return m > 0 ? m + (m === 1 ? " min" : " mins") : "";
    };

    const calculateSeconds = () => {
        const s = Math.floor(secs % 60);
        return s + (s === 1 ? " sec" : " secs");
    };

    const timeUnits = [
        calculateYears(),
        calculateMonths(),
        calculateWeeks(),
        calculateHours(),
        calculateMinutes(),
        calculateSeconds(),
    ];

    const formattedTime = timeUnits.find((unit) => unit !== "");

    return formattedTime === "" ? "just now" : formattedTime + " ago";
};
export const getEpoch = () => new Date().getTime();

export const getTimeZone = () => {
    const newDate = new Date(getEpoch());
    const options: any = {timeZoneName: 'short'};
    const saveTime = new Date(newDate).toLocaleDateString(undefined, options);
    return saveTime.split(",")[1].trim();
}

export const getRelativeTime = (epoch: string) => {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const previousEpoch = parseInt(epoch)
    const difference = getEpoch() - previousEpoch;

    if (difference < msPerDay) {
        // Less than a day has passed:
        return new Date(previousEpoch).toLocaleTimeString('en-us', {hour: '2-digit', minute: '2-digit'})
    } else if (difference < msPerMonth) {
        // Less than a month has passed:
        return`${Math.floor(difference / msPerDay)}d ago`;
    } else if (difference < msPerYear) {
        // Less than a year has passed:
        return`${Math.floor(difference / msPerMonth)}m ago`;
    } else {
        // More than a year has passed:
        return`${Math.floor(difference / msPerYear)}y ago`;
    }
}
