import {MultiselectProps} from "@amzn/awsui-components-react";
import React from "react";
import {StylesheetDefaultFileNameKeys} from "src/components/CreateReportPage/constants";
import {ReportNameAndTypeDescription} from "src/components/CreateReportPage/ReportNameAndType";


export interface StepErrors {
    1: {[p:string]: string}
    2: string
    3: string
}

type ReportWorkflow = "Create" | "Edit";

export interface ReportNameAndTypeProps {
    reportWorkflow: ReportWorkflow
    reportDescription: ReportNameAndTypeDescription
    setReportDescription: React.Dispatch<React.SetStateAction<ReportNameAndTypeDescription>>
    errorTexts: {[p:string]: string}
    setStepErrors: React.Dispatch<React.SetStateAction<StepErrors>>
}

export interface MDXGroupSelectionProps {
    reportWorkflow: ReportWorkflow
    reportName: string,
    selectedMdxGroup: string,
    setSelectedMdxGroup: React.Dispatch<React.SetStateAction<string>>
    errorText: string,
    setStepErrors: React.Dispatch<React.SetStateAction<StepErrors>>;
}

export interface StylesheetUploadProps {
    reportWorkflow: ReportWorkflow
    reportName: string
    reportType: StylesheetDefaultFileNameKeys
    stylesheet: Stylesheet
    setStylesheet: React.Dispatch<React.SetStateAction<Stylesheet>>
    errorText: string
}

export interface ConfigureColumnsProps {
    reportWorkflow: ReportWorkflow
    reportName: string
    columns: Column[]
    setColumns: React.Dispatch<React.SetStateAction<Column[]>>
    selectedMdxGroup: string
    errorText: string
    setStepErrors: React.Dispatch<React.SetStateAction<StepErrors>>
}

export interface Column {
    column_position: number
    column_name: string
    column_type: ColumnType
    enabled: boolean

    year?: string
    scenario?: string
    period?: MultiselectProps.Options

    baseline_year?: string
    baseline_scenario?: string
    baseline_period?: MultiselectProps.Options

    parent_column_position?: number

    fx_impact?: {
        period?: MultiselectProps.Options
        year: string
        scenario: string
    }
}

export enum ColumnType {
    FXImpact= "FXImpact",
    FixedTotal = "FixedTotal",
    Variance = "Variance",
    ColumnBreak = "ColumnBreak"
}

export interface Stylesheet {
    fileName: string,
    fileSize: number,
    fileLastUpdated: string,
    reportId: string
}
