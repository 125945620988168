import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import {AppState} from "src/reducers/AppState";
import {forBridgeMetadata, forInViewBridgeId} from "src/reducers/bridgingCommentary.reducer";
import styled from "styled-components";

export const ContributorMetadataDiv = styled.div`
  font-style: italic;
  font-size: smaller;
  font-weight: lighter;
  display: grid;
  align-items: center;
  padding: 0 0.5em; // align left side with HeaderWithCrumbs
`;

export function ContributorMetaData() {
    const bridgeId = useSelector(forInViewBridgeId, shallowEqual);
    const metadata = useSelector((s: AppState) => forBridgeMetadata(s, bridgeId))
    const {commentators} = metadata;
    const countOfCommentators = commentators.length;
    // when there is one person show their name instead of count
    const _countText = countOfCommentators === 1 ? commentators[0] : `${countOfCommentators} analysts`;
    return <ContributorMetadataDiv id={"contributor-metadata"}>{`Bridged by ${_countText}`}</ContributorMetadataDiv>;
}