import { Box, ColumnLayout, Container, Form, FormField, Header, Multiselect, Select, SelectProps, SpaceBetween} from "@amzn/awsui-components-react"
import { MultiselectProps } from "@amzn/awsui-components-react-v3"
import React, { useState } from "react"

import { DATA_SOURCES, FacetData, SCENARIO_IDS, SCENARIOS} from "../constants/facets"
import { ExecuteFacetRequest } from "../interfaces/facetParams"

export interface FacetParamsInput {
  facetRequest : ExecuteFacetRequest
  setFacetRequest : React.Dispatch<React.SetStateAction<ExecuteFacetRequest>>
  errorText : string
}

export const configureParams = (facetParams : FacetParamsInput) => {
    return {
      title : "Select Data & Scenarios",
      description : "Set the parameters for the scenarios in your report",
      content : <Params {...facetParams}></Params>,
      errorText : facetParams.errorText
  }
  }

interface ScenarioInput {
  name : string,
  scenarioType : string
  facetRequest : ExecuteFacetRequest
  setFacetRequest : React.Dispatch<React.SetStateAction<ExecuteFacetRequest>>
}

const PERIOD_OPTIONS = [
  { label: "Jan", value:"1"},
  { label: "Feb", value:"2" },
  { label: "Mar", value:"3"},
  { label: "Apr", value:"4"},
  { label: "May", value:"5"},
  { label: "Jun", value:"6"},
  { label: "Jul", value:"7"},
  { label: "Aug", value:"8"},
  { label: "Sep", value:"9"},
  { label: "Oct", value:"10"},
  { label: "Nov", value:"11"},
  { label: "Dec", value:"12"}
]


const ScenarioSelect = (scenario : ScenarioInput) => {

  const defaultScenario = (scenario.scenarioType == "scenario1") ? SCENARIOS[scenario.name][1] : SCENARIOS[scenario.name][0]
  const [selectedOption, setSelectedOption] = useState<{label? : string | undefined, value?: string | undefined}>(defaultScenario);

  return <Select
  selectedOption={selectedOption}
    onChange={({ detail }) =>{
      setSelectedOption(detail.selectedOption)

      scenarioHandler(detail, scenario)
    }}
    options = {SCENARIOS[scenario.name]}
  />
}

const takeActionHandler = (detail: SelectProps.ChangeDetail , input : PeriodFormInput, scenarioName : string, fieldName: string) => {

  const {facetRequest, setFacetRequest} = input

  const newRequest : ExecuteFacetRequest = facetRequest

  Object.keys(facetRequest.facet_params).forEach(facet_type => {
      newRequest.facet_params[facet_type][scenarioName] = {
            ...newRequest.facet_params[facet_type][scenarioName],
            [fieldName] : detail.selectedOption.value,
      }
    })

  setFacetRequest({...newRequest})
}


const scenarioHandler = (detail: SelectProps.ChangeDetail, scenario : ScenarioInput ) => {

  const {facetRequest, setFacetRequest, scenarioType, name} = scenario
  const newRequest : ExecuteFacetRequest = facetRequest

  const facet_type = SCENARIO_IDS[name]
      newRequest.facet_params[facet_type][scenarioType] = {
            ...newRequest.facet_params[facet_type][scenarioType],
            scenarioName : detail.selectedOption.value!,
    }

  setFacetRequest({...newRequest})
}

const YearSelect = (input : PeriodFormInput) => {

  const [selectedOption, setSelectedOption] = useState<SelectProps.Option | null>(null);

  const {scenario} = input
  return <Select
  selectedOption={selectedOption}
    onChange={({detail})=> {

      setSelectedOption(detail.selectedOption)
      takeActionHandler(detail, input, scenario, "Year")

      }}
    options={[
      { label: "2022", value: "2022" },
      { label: "2023", value: "2023" },
      { label: "2024", value: "2024" },
    ]}
    placeholder="Select Year"
  />
}

const PeriodFormField = (input : PeriodFormInput) => {

  const [selectedOptions, setSelectedOptions] = useState<MultiselectProps.Options>([]);    
  const {scenario} = input

    return (
    <FormField>
    <Multiselect
  selectedOptions={selectedOptions} 
    onChange={({ detail }) =>{
      setSelectedOptions(detail.selectedOptions)
      multiSelectActionHandler(detail, input, scenario)
    }}
    errorText= ""
    options={PERIOD_OPTIONS}
    placeholder="Select months"
  />
    </FormField>)
}


const multiSelectActionHandler = (detail: MultiselectProps.MultiselectChangeDetail , input : PeriodFormInput, scenarioName : string) => {

  const {facetRequest, setFacetRequest} = input
  const periods = detail.selectedOptions.map(period => period.value!)
  const newRequest : ExecuteFacetRequest = facetRequest

  Object.keys(facetRequest.facet_params).forEach(facet_type => {
      newRequest.facet_params[facet_type][scenarioName] = {
            ...newRequest.facet_params[facet_type][scenarioName],
            [`Periods`] : periods
      }
    })

  setFacetRequest({...newRequest})
}

const PeriodForm = (input : PeriodFormInput) => {
  
  return(  <Form
    header={
      <Header variant="h2">
        {input.name}
      </Header>
    }variant="embedded">
          <SpaceBetween direction="vertical" size="l">
            <YearSelect {...input}/>
            <PeriodFormField {...input}/>
          </SpaceBetween>
      </Form>
  )
}


  
export const DataSource = (facetData : FacetData, facetParams : FacetParamsInput) => {

    const scenarioInput = {
      name : facetData.name
    }

    const scenario1 = {...scenarioInput, scenarioType : "scenario1", facetRequest : facetParams.facetRequest, setFacetRequest : facetParams.setFacetRequest}
    const scenario2 = {...scenarioInput, scenarioType : "scenario2", facetRequest : facetParams.facetRequest, setFacetRequest : facetParams.setFacetRequest}

    return (
      <>
      <Box variant="h2"> {facetData.name}</Box>
      <ScenarioSelect {...scenario1}></ScenarioSelect>
      <ScenarioSelect {...scenario2}></ScenarioSelect>
      </>
  )
  // }
}


export interface PeriodFormInput {
  name : string
  scenario : string 
  facetRequest : ExecuteFacetRequest
  setFacetRequest : React.Dispatch<React.SetStateAction<ExecuteFacetRequest>>
}


export const Params = (facetParams : FacetParamsInput) => {
    const params = DATA_SOURCES.map(data => DataSource(data, facetParams))
    return (
      <Container>     
        <div>
        For Period Selection, all year-to-date (YTD) periods must be selected for YTD values (e.g., Jan, Feb, Mar, Apr must be selected for April YTD bridging)
          </div>             
                <ColumnLayout columns={3}>
                    <div></div>
                    <PeriodForm name="Period in Scenario 1" scenario="scenario1" facetRequest={facetParams.facetRequest} setFacetRequest={facetParams.setFacetRequest}/>
                    <PeriodForm name="Period in Scenario 2" scenario="scenario2" facetRequest={facetParams.facetRequest} setFacetRequest={facetParams.setFacetRequest} />
                  <div></div>
                  <Header>Scenario 1</Header>
                  <Header>Scenario 2</Header>
                  {params}
                  </ColumnLayout>
  
                </Container>
      )
  }