import React, {useEffect, useMemo} from "react";
import {useDispatch} from "react-redux";
import {
    RESET_UPDATING_REPORT_DEF,
    RESET_VIEWING_REPORT,
    resetReportLibraryStatus
} from "src/actions/reportLibrary.actions";
import ReportLibraryTable from "src/components/ReportLibraryPage/ReportLibraryTable";
import {useBreadcrumb} from "src/features/breadcrumbs/hooks/useBreadcrumb";


const ReportLibrary = (
	headerTitle: string,
	description: string,
	activeHref: string,
	headerAction:  React.ReactNode,
	reportActions: Function,
	confirmationModal: JSX.Element,
	reportLibrary: string,
    showBridgeStatus : boolean
) => {

    const crumbs = useMemo(() => {
        const library = reportLibrary === 'Reference' ? 'Published' : reportLibrary;
        return  [
            { text: 'Home', href: '#' },
            { text: `${library} reports`, href: `#${library.toLowerCase()}-reports` },
        ]
    }, [reportLibrary])
    useBreadcrumb({crumbs});

    const dispatch = useDispatch();
    // on page mount reset old artifacts if any
    useEffect(function resetReportLibraryPageArtifacts() {
        dispatch(resetReportLibraryStatus());
        dispatch({type: RESET_VIEWING_REPORT, payload: {}});
        dispatch({type: RESET_UPDATING_REPORT_DEF, payload: {}});
    }, [])



    return (
        <ReportLibraryTable
            headerTitle={headerTitle}
            description={description}
            headerActions={headerAction}
            reportActions={reportActions}
            confirmationModal={confirmationModal}
            reportLibrary={reportLibrary}
            showBridgeStatus={showBridgeStatus}
        />
    );
}

export default ReportLibrary;