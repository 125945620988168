import {
    Alert,
    Box,
    ButtonDropdown,
    FormField,
    Input,
    Multiselect,
    MultiselectProps,
    Select,
    SpaceBetween,
    TextContent,
    Toggle
} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import React, {ReactNode, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Column, ColumnType, ConfigureColumnsProps} from "src/components/CreateReportPage/interfaces";
import {MDXJob} from "src/components/MDXLibraryPage/interfaces";
import {getGroups} from "src/reducers/mdxLibrary.reducer";
import {sortPeriod} from "src/utils/reportHelpers";

import {
    ConfigureColumnBox,
    FixedColumnBox,
    HorizontalLine,
    VarianceColumnBox,
    VarianceConfigurationBox,
    VerticalLine
} from "./styles";

// Type definitions for different column properties
type BasicColumnDropdownField = "year" | "scenario";
type ColumnDropdownField = BasicColumnDropdownField | "baseline_year" | "baseline_scenario";
type PeriodColumnDropdownField = "period";
type PeriodAndBaselineDropdownField = PeriodColumnDropdownField | "baseline_period"
type ColumnInputField = "column_name" | "column_position";
// Constants for this module
const columnCountLimit = 23;
const addColumnButtonOptions = [
    { text: "Fixed total", id: ColumnType.FixedTotal },
    { text: "Variance", id: ColumnType.Variance },
    { text: "Column break", id: ColumnType.ColumnBreak}
]

const ConfigureColumns = ({reportWorkflow, reportName, columns, setColumns, selectedMdxGroup, errorText, setStepErrors}: ConfigureColumnsProps) => {
    const groups = useSelector(getGroups);
    const mdxGroup = groups[selectedMdxGroup];
    // Dropdowns for year and scenario
    const [fxYearSelectOptions, setFXYearSelectOptions] = useState<Array<{label: string}>>([]);
    const [fxScenarioSelectOptions, setFXScenarioSelectOptions] = useState<Array<{label: string}>>([]);
    const [yearSelectOptions, setYearSelectOptions] = useState<Array<{label: string}>>([]);
    const [scenarioSelectOptions, setScenarioSelectOptions] = useState<Array<{label: string}>>([]);

    useEffect(() => {
        if(mdxGroup){
            const separatedJobs = {"Fixed total": new Array<MDXJob>(), "FX impact": new Array<MDXJob>()};
            mdxGroup.jobs.forEach(job => separatedJobs[job.query_type].push(job));
            // set options for fixed columns
            setYearSelectOptions(constructDistinctDropdownLabels(separatedJobs["Fixed total"].map(job => job.year)));
            setScenarioSelectOptions(constructDistinctDropdownLabels(separatedJobs["Fixed total"].map(job => job.scenario)));
            // set options for fx columns
            setFXYearSelectOptions(constructDistinctDropdownLabels(separatedJobs["FX impact"].map(job => job.year)));
            setFXScenarioSelectOptions(constructDistinctDropdownLabels(separatedJobs["FX impact"].map(job => job.scenario)));
        }
    }, [mdxGroup])

    const resetErrorMessage = () => {
        errorText && setStepErrors((stepErrors) => {return {...stepErrors, 3: ""}});
    }

    function constructFXInputField(fxColIndex: number, fieldLabel: string, columnField: ColumnInputField, inputProps: object = {}) {
        return (
            <FormField label={fieldLabel}>
                <Input
                    value = {fxColIndex !== -1 ? columns[fxColIndex][columnField].toString() : ""}
                    onChange={({detail}) => {
                        resetErrorMessage();
                        const updatedColumns = [...columns];
                        if (columnField === "column_position") {
                            updatedColumns[fxColIndex].column_position = getColumnPosition(detail.value, updatedColumns[fxColIndex].column_position);
                        } else {
                            updatedColumns[fxColIndex].column_name = detail.value;
                        }
                        setColumns(updatedColumns);
                    }}
                    {...inputProps}
                />
            </FormField>
        );
    }

    function constructFXImpactSelectField(index: number, fieldLabel: string, columnField: BasicColumnDropdownField, selectProps: object = {}) {
        // parse fx scenario from given variance column
        const fxScenario = columns[index]["fx_impact"] || {year: "", period: [], scenario: ""};
        const fxImpactColIndex = findFxColumIndex(index, columns);
        return(
            <FormField label={fieldLabel}>
               <Select
                   selectedOption={{label: fxScenario[columnField]}}
                   onChange={({detail}) => {
                       resetErrorMessage();
                       const updatedColumns = [...columns];
                       fxScenario[columnField] = detail.selectedOption.label || "";
                       updatedColumns[index]["fx_impact"] = {...fxScenario};
                       if(fxImpactColIndex !== -1){  // If this variance column has fx impact column enabled, update its column field
                           updatedColumns[fxImpactColIndex][columnField] = detail.selectedOption.label;
                       }
                       setColumns(updatedColumns)
                   }}
                   {...selectProps}
               />
            </FormField>
        );
    }

    function constructFXImpactMultiselectField(index: number, fieldLabel: string, columnField: PeriodColumnDropdownField, selectProps: object = {}) {

        const fxScenario = columns[index]["fx_impact"] || {year: "", period: [], scenario: ""};
        const fxImpactColIndex = findFxColumIndex(index, columns);
        return(
            <FormField label={fieldLabel}>
                <Multiselect
                    selectedOptions={fxScenario[columnField] as MultiselectProps.Options}
                    onChange={({detail}) => {
                        resetErrorMessage();
                        const updatedColumns = [...columns];
                        fxScenario[columnField] = sortPeriod(detail.selectedOptions) ;
                        updatedColumns[index]["fx_impact"] = {...fxScenario};
                        if(fxImpactColIndex !== -1){  // If this variance column has fx impact column enabled, update its column field
                            updatedColumns[fxImpactColIndex][columnField] = detail.selectedOptions;
                        }
                        setColumns(updatedColumns)
                    }}
                    options={disablePeriodColumns(fxScenario[columnField] as MultiselectProps.Options)}
                    {...selectProps}
                    placeholder="Choose Periods"
                />
            </FormField>
        );
    }

    function constructSelectField(index: number, fieldLabel: string, columnField: ColumnDropdownField, selectProps: object = {}) {
        return(
           <FormField label={fieldLabel}>
               <Select
                   selectedOption={{label: columns[index][columnField]?.toString()}}
                   onChange={({detail}) => {
                       resetErrorMessage();
                       const updatedColumns = [...columns];
                       updatedColumns[index][columnField] = detail.selectedOption.label;
                       setColumns(updatedColumns);
                   }}
                   {...selectProps}
               />
           </FormField>
       );
    }

    function constructMultiselectField(index: number, fieldLabel: string, columnField: PeriodAndBaselineDropdownField, selectProps: object = {}) {
        return(
            <FormField label={fieldLabel}>
                <Multiselect
                    selectedOptions={columns[index][columnField] as MultiselectProps.Options}
                    onChange={({detail}) => {
                        resetErrorMessage();
                        const updatedColumns = [...columns];
                        updatedColumns[index][columnField] =  sortPeriod(detail.selectedOptions as MultiselectProps.Options)
                        setColumns(updatedColumns);
                    }}
                    options={disablePeriodColumns(columns[index][columnField] as MultiselectProps.Options)}
                    {...selectProps}
                    placeholder="Choose Periods"
                />
            </FormField>
        );
    }

    function constructInputField(index: number, fieldLabel: string, columnField: ColumnInputField, inputProps: object = {}) {
        const onChangeHandler = (value: string) => {
            resetErrorMessage();
            const updatedColumns = [...columns];
            if (columnField === 'column_position'){
                const newPosition = getColumnPosition(value, updatedColumns[index].column_position);
                if(updatedColumns[index].column_type === ColumnType.Variance) {
                    const fxImpactIndex = findFxColumIndex(index, columns);
                    if(fxImpactIndex !== -1) {
                        updatedColumns[fxImpactIndex].parent_column_position = newPosition;
                    }
                }
                updatedColumns[index][columnField] = newPosition;
            } else {
               updatedColumns[index][columnField] = value;
            }
            setColumns(updatedColumns);
        }

        return (
            <FormField label={fieldLabel}>
                <Input
                    value = {columns[index][columnField].toString()}
                    onChange={({detail}) => onChangeHandler(detail.value)}
                    {...inputProps}
                />
            </FormField>
        );
    }

    function constructRemoveButton(index: number) {
        return(
            <Button
                variant="normal"
                onClick= { () => {
                    resetErrorMessage();
                    let updatedColumns = [...columns];
                    if (columns[index].column_type === ColumnType.Variance) {
                        updatedColumns = updatedColumns.filter(col => col.parent_column_position !== columns[index].column_position);
                    }
                    updatedColumns.splice(index, 1);
                    setColumns(updatedColumns);
                }}
                disabled={columns.length === 1}
            >
                Remove
            </Button>
        );
    }

    function constructVarianceColumn(index: number) : ReactNode {
        const hasFxImpactScenario = !!(columns[index].fx_impact);
        const onFXImpactToggleChange = (checked: boolean) => {
            let updatedColumns = [...columns];
            if(checked) {
                updatedColumns[index]["fx_impact"] = {year: "", period: [], scenario: ""};
            } else {
                delete updatedColumns[index].fx_impact;
                updatedColumns = updatedColumns.filter(col => col.parent_column_position !== columns[index].column_position);
            }
            setColumns(updatedColumns)
        }

        return (
            <VarianceColumnBox key={index}>
                <SpaceBetween direction='vertical' size='s'>
                    <FormField label='Column type'>Variance</FormField>
                    {constructInputField(index, 'Column position', 'column_position', {type: 'number'})}
                    {constructInputField(index, 'Column name', 'column_name', {type: 'text'})}
                    <VarianceConfigurationBox>
                        {constructSelectField(index, 'Year', 'year', {options: yearSelectOptions})}
                        {constructSelectField(index, 'Baseline year', 'baseline_year', {options: yearSelectOptions})}
                        {constructSelectField(index, 'Scenario', 'scenario', {options: scenarioSelectOptions ,filteringType: 'auto'})}
                        {constructSelectField(index, 'Baseline scenario', 'baseline_scenario', {options: scenarioSelectOptions, filteringType: 'auto'})}
                        {constructMultiselectField(index, 'Period', 'period' )}
                        {constructMultiselectField(index, 'Baseline period', 'baseline_period')}
                        </VarianceConfigurationBox>
                    <HorizontalLine/>
                    <Toggle checked={hasFxImpactScenario} onChange={({detail}) => {onFXImpactToggleChange(detail.checked)}}>
                        FX Impact
                    </Toggle>
                    {hasFxImpactScenario &&
                        <SpaceBetween direction='vertical' size='s'>
                            {constructFXImpactSelectField(index, 'Year', 'year', {options: fxYearSelectOptions})}
                            {constructFXImpactSelectField(index, 'Scenario', 'scenario', {options: fxScenarioSelectOptions, filteringType: 'auto'})}
                            {constructFXImpactMultiselectField(index, 'Period', 'period')}
                        </SpaceBetween>
                    }
                    <Box float='right'>{constructRemoveButton(index)}</Box>
                </SpaceBetween>
            </VarianceColumnBox>
        );
    }

    function constructColumn(index: number) : ReactNode {
        const isColumnBreak = columns[index].column_type === ColumnType.ColumnBreak;
        return (
            <FixedColumnBox key={index}>
                <SpaceBetween direction='vertical' size='s'>
                    <FormField label='Column type'>{isColumnBreak ? "Column break" : "Fixed total"}</FormField>
                    {constructInputField(index, 'Column position', 'column_position', {type: 'number'})}
                    {constructInputField(index, 'Column name', 'column_name', {type: 'text', disabled: isColumnBreak})}
                    {constructSelectField(index, 'Year', 'year',  {options: yearSelectOptions, disabled: isColumnBreak})}
                    {constructSelectField(index, 'Scenario', 'scenario', {options: scenarioSelectOptions, filteringType: 'auto', disabled: isColumnBreak})}
                    {constructMultiselectField(index, 'Period', 'period', {disabled: isColumnBreak})}
                    <Box float='right'>{constructRemoveButton(index)}</Box>
                </SpaceBetween>
            </FixedColumnBox>
        );
    }

    function constructFXColumn(varianceColumnIndex: number) {
        const parentsColPos = columns[varianceColumnIndex].column_position;
        const fxColumnIndex = findFxColumIndex(varianceColumnIndex, columns);
        const hasFxImpactColumn = fxColumnIndex !== -1;
        const varianceFXScenario = columns[varianceColumnIndex]["fx_impact"] || {year: "", scenario: "", period: []};
        // report view toggle handler
        const onToggleReportView = (checked: boolean) => {
            let updatedColumns = [...columns];
            if(checked){
                const nextColPos = getNextColumnPosition(updatedColumns);
                const newColumn = {
                    ...createColumn(nextColPos, ColumnType.FXImpact, true, parentsColPos),
                    ...varianceFXScenario
                };
                updatedColumns.splice(nextColPos - 1, 0, newColumn);
            } else {
                updatedColumns = columns.filter(col => col.parent_column_position !== parentsColPos);
            }
            setColumns(updatedColumns);

        }

        return (
            <FixedColumnBox key={`${varianceColumnIndex}_fx`}>
                <SpaceBetween direction='vertical' size='s'>
                    <SpaceBetween direction='horizontal' size='s'>
                        <FormField label='Column type'>FX Impact</FormField>
                        <Toggle checked={hasFxImpactColumn} onChange={({detail}) => onToggleReportView(detail.checked)}>
                            Report view
                        </Toggle>
                    </SpaceBetween>
                    <TextContent>{`This corresponds with position ${columns[varianceColumnIndex].column_position}.`}</TextContent>
                    {constructFXInputField(fxColumnIndex, "Column position", "column_position", {type: "number", disabled: !hasFxImpactColumn})}
                    {constructFXInputField(fxColumnIndex, "Column name", "column_name", {type: "text", disabled: !hasFxImpactColumn})}
                    <TextContent>The following fields can only be modified in corresponding variance column.</TextContent>
                    <FormField label='Year'><Input disabled={true} value={varianceFXScenario["year"]}/></FormField>
                    <FormField label='Scenario'><Input disabled={true} value={varianceFXScenario["scenario"]}/></FormField>
                    <FormField label='Period'><Multiselect selectedOptions={varianceFXScenario["period"]!} disabled={true}/></FormField>
                </SpaceBetween>
            </FixedColumnBox>
        );
    }

    function constructColumns() {
        const columnsReactNodes: ReactNode[] = [];
        columns.forEach(({column_type, fx_impact}, index) => {
            if (column_type === ColumnType.Variance){
                columnsReactNodes.push(constructVarianceColumn(index));
                if (fx_impact) {
                    columnsReactNodes.push(<VerticalLine key={`${index}_fx_impact`} />);
                    columnsReactNodes.push(constructFXColumn(index));
                }
            } else if (column_type === ColumnType.ColumnBreak || column_type === ColumnType.FixedTotal){
                columnsReactNodes.push(constructColumn(index));
            }
            columnsReactNodes.push(<VerticalLine key={`${index}_vertical_line`} />);
        })
        columnsReactNodes.pop();
        return columnsReactNodes;
    }

    const headerDescription = "Please add columns, select column type and column data filters to populate values in the"
        + "report page. There can be 20 data columns and a maximum of 10 column breaks. Columns can be rearranged by " +
        "changing the number in the 'Column position' field"

    return (
        <div style={{minWidth: "60vw", maxWidth: "60vw"}}>
            <SpaceBetween size="s" direction='vertical'>
                {errorText.length > 0 && <Alert type='error'>{errorText}</Alert>}
                <Container
                    header={
                    <Header
                        variant="h2"
                        actions={
                            <ButtonDropdown
                                items={addColumnButtonOptions}
                                variant="primary"
                                onItemClick = {({detail}) => {
                                    resetErrorMessage();
                                    const dataColumnCount = columns.reduce((count, column) => {
                                        return column.column_type !== ColumnType.ColumnBreak? count + 1 : count
                                    }, 0)
                                    const columnType = detail.id as ColumnType;
                                    if (columnType != ColumnType.ColumnBreak && dataColumnCount === 20) {
                                        return;
                                    }
                                    if (columnType === ColumnType.ColumnBreak && columns.length - dataColumnCount === 10) {
                                        return;
                                    }
                                    const nextColumnPosition = getNextColumnPosition(columns);
                                    const column = createColumn(nextColumnPosition, columnType);
                                    const updatedColumns = [...columns];
                                    updatedColumns.splice(nextColumnPosition - 1, 0, column);
                                    setColumns(updatedColumns);
                                }}
                            >
                                Add Column
                            </ButtonDropdown>
                        }
                    >
                        {`${reportWorkflow} report: ${reportName} (${columns.length}/23)`}
                    </Header>}
                >
                    <SpaceBetween direction='vertical' size='m'>
                        <TextContent>{headerDescription}</TextContent>
                        <ConfigureColumnBox>{mdxGroup && constructColumns()}</ConfigureColumnBox>
                        <Button onClick={() => {
                            resetErrorMessage();
                            if (columnsHaveValidPositions(columns)) {
                                const sortedColumns = [...columns].sort((a, b) => {
                                    return a.column_position - b.column_position;
                                });
                                setColumns(sortedColumns);
                            }
                        }}>
                            Reorder columns
                        </Button>
                    </SpaceBetween>
                </Container>
            </SpaceBetween>
        </div>
    );
}

export default ConfigureColumns;


/************************************ Helpers only applicable for ConfigureColumns **********************************8*/
export function createColumn(columnPosition: number, columnType: ColumnType, enabled = true,
                      parent_column_position?: number) {
    // default column values for must have column attributes
    let column : Column = {
        enabled,
        column_name: "",
        column_position: columnPosition,
        column_type: columnType,
        year: "",
        period: [],
        scenario: "",
    }
    if(columnType === ColumnType.FXImpact) {
        column = {...column, parent_column_position};
    }
    if (columnType === ColumnType.Variance) {
        column = {...column, baseline_year: "", baseline_period: [], baseline_scenario: ""}
    }
    return column;
}

export function getNextColumnPosition(updatedColumns: Column[]) : number {
    const nextIdx = updatedColumns.length + 1;
    for (let i = 0; i < updatedColumns.length - 1; i++) {
        if (i == 0 && updatedColumns[i].column_position != 1) {
            return 1;
        }
        if (updatedColumns[i].column_position + 1 != updatedColumns[i + 1].column_position) {
             return updatedColumns[i].column_position + 1;
        }
    }
    return nextIdx;
}

export function columnsHaveValidPositions(columns: Column[]) : boolean {
    const seenColumnPositions = new Set<number>();
    for (let i = 0; i < columns.length; i++) {
        if (seenColumnPositions.has(columns[i].column_position)) {
            return false;
        }
        seenColumnPositions.add(columns[i].column_position);
    }
    return true;
}

export function constructDistinctDropdownLabels(labels: string[]) {
    const uniqueLabels = new Set<string>(labels)
    // return sorted list in descending order(filter out manual input cubes scenario/year options)
    const dropdownLabels = Array.from(uniqueLabels).sort().reverse().filter(label => label !== 'all');
    return dropdownLabels.map((label: string) => {
        return {label};
    });
}

export function findFxColumIndex(varianceColumnIndex: number, columns: Column[]) {
    const parentCol = columns[varianceColumnIndex]
    return columns.findIndex(({column_type, parent_column_position}) => {
        return column_type === ColumnType.FXImpact && parent_column_position === parentCol.column_position;
    });
}

export function getColumnPosition(value: string, currentPosition: number) {
    const position = Number(value);
    return (position > 0 && position <= columnCountLimit) ? position : currentPosition;
}

export function disablePeriodColumns(values: MultiselectProps.Options) {

    const quarterAndYear = ["Q1", "Q2", "Q3", "Q4", "FY"]
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep",
        "Oct", "Nov", "Dec"]

    const periods = [
        { label: "Q1", value:"Q1", disabled:false},
        { label: "Q2", value:"Q2", disabled:false },
        { label: "Q3", value:"Q3", disabled:false },
        { label: "Q4", value:"Q4", disabled:false },
        { label: "FY", value:"FY", disabled:false },
        { label: "Jan", value:"Jan", disabled:false },
        { label: "Feb", value:"Feb", disabled:false },
        { label: "Mar", value:"Mar", disabled:false },
        { label: "Apr", value:"Apr", disabled:false },
        { label: "May", value:"May", disabled:false },
        { label: "Jun", value:"Jun", disabled:false },
        { label: "Jul", value:"Jul", disabled:false },
        { label: "Aug", value:"Aug", disabled:false },
        { label: "Sep", value:"Sep", disabled:false },
        { label: "Oct", value:"Oct", disabled:false },
        { label: "Nov", value:"Nov", disabled:false },
        { label: "Dec", value:"Dec", disabled:false}
    ]

    if (values.length == 0){
        // If Array is empty User not selected any drop down or User unselected all drop down options
        //Enable all options of Period.
        const updatedPeriods = [...periods]
        updatedPeriods.forEach(period => {
            period.disabled = false;
        });
        return updatedPeriods
    } else if (values.length == 1){
        // If a user selects single period either quarter/year or month
        const updatedPeriods = [...periods]
        const firstValue = values[0].label as string;
        if (quarterAndYear.indexOf(firstValue) >= 0){
            // if quarter is selected. Disable all except selected quarter/year
            const periodIdx = updatedPeriods.findIndex(period => period.label == firstValue);
            updatedPeriods.forEach((period, index) => {
                if(index !== periodIdx){
                    period.disabled = true;
                }
            });
            return updatedPeriods
        }else{
            // if month is selected. Disable all quarter/year
            const updatedPeriods = [...periods]
            updatedPeriods.forEach((period, index) => {
                if(index in [0, 1, 2, 3, 4]){
                    period.disabled = true;
                }
            });
            return updatedPeriods
        }
    } else{
        // This Else block is to handle when editing report.
        // User might have selected multiple months.
        // disable all quarter/year
        const updatedPeriods = [...periods]
        const valuesSelected = values.map(value => value.label as string);

        if(valuesSelected.some(value => months.indexOf(value) >= 0)){
            updatedPeriods.forEach((period, index) => {
                if(index in [0, 1, 2, 3, 4]){
                    period.disabled = true;
                }
            });
            return updatedPeriods
        }
    }
}
