import {createSelector} from "reselect";
import {
    ADD_HISTORY_FOR_BRIDGE,
    ADD_SUB_ITEMS_FOR_BRIDGE,
    BridgingCommentaryActions,
    BridgingPageStatus, REMOVE_LATEST_FOR_BRIDGE,
    RESET_BRIDGE_BEING_VIEWED_CURRENTLY,
    RESET_BRIDGING_PAGE_STATUS,
    RESET_FILLING_BRIDGES,
    RESET_REPORT_BRIDGES,
    RESET_SUB_ITEMS_FOR_BRIDGE,
    SET_BRIDGE_BEING_VIEWED_CURRENTLY,
    SET_BRIDGE_ITEMS,
    SET_BRIDGE_NAME,
    SET_BRIDGE_OWNER_GROUPS,
    SET_BRIDGE_STATUS,
    SET_BRIDGE_SUB_ITEMS,
    SET_BRIDGES_BEING_VIEWED_CURRENTLY,
    SET_BRIDGING_PAGE_STATUS,
    SET_COMMENTATORS,
    SET_ENABLE_LINKS_TO_BRIDGES,
    SET_FILLING_BRIDGES, SET_LATEST_FOR_BRIDGE,
    SET_PAGINATION_CONFIG,
    SET_REPORT_BRIDGES,
    SET_SELECTED_BRIDGE_ID,
    SET_SELECTED_ROW, SET_SUB_ITEMS_FOR_BRIDGE_TO_HISTORY,
    SET_VIEW_ALL_BRIDGES_MODAL,
    SET_VIEW_BRIDGE_MODAL,
    SET_WIZARD_BRIDGE_STATUS,
} from "src/actions/bridgingCommentary.actions";
import {BlankBridgeItem} from "src/components/BridgingCommentary/constants";
import {BridgeItem, ModalBridgeItem} from "src/components/BridgingCommentary/interfaces";
import {
    defaultRow, historyItemToBridgeItem,
    mostRecentCommenter
} from "src/components/FillOutBridgePage/CommentVersioning/BridgeComparisonHelpers";
import {BridgeHistory, BridgeHistoryItem, BridgeSubItem} from "src/components/FillOutBridgePage/interfaces";
import {AppState} from "src/reducers/AppState";
import {PaginationConfig} from "src/reducers/mdxLibrary.reducer";

export interface BridgingCommentaryState {
    paginationConfig: PaginationConfig
    status: BridgingPageStatus
    ownerGroups: { [p: string]: string }
    varianceDetails: {
        initiatedBridgeName: string
        leftVarianceHeader: string
        rightVarianceHeader: string
    },
    bridgeItems: Array<BridgeItem>
    fillingBridges: {
        bridgeItems: Array<BridgeItem>
        subItemsForBridges: { [bridge_id: string]: BridgeSubItem[] }
        historyForBridges: {[bridge_id: string]: BridgeHistory}
    }
    updatingBridges: {
        bridges: Array<BridgeItem>,
        bridgeOwnerGroup: { [p: string]: string }
    },
    enableLinksToBridges: boolean,
    bridgeBeingViewedCurrently: ModalBridgeItem,
    bridgesBeingViewedCurrently: { [p: string]: BridgeSubItem[] },
    selectedRow: BridgeSubItem,
    selectedBridgeId: string,
    viewBridgeModalVisible: boolean,
    viewAllBridgesModalVisible: boolean
}

const initialBridgingState: BridgingCommentaryState = {
    paginationConfig: {},
    status: {
        bridges: {},
        bridge: {},
        bridgeOwnerGroups: {},
        wizardBridge:{},
    },
    ownerGroups: {},
    varianceDetails: {
        initiatedBridgeName: "",
        leftVarianceHeader: "",
        rightVarianceHeader: "",
    },
    bridgeItems: [],
    fillingBridges: {
        bridgeItems: [],
        subItemsForBridges: {},
        historyForBridges: {}
},
    // Remove below object after integrating with bridges api.
    updatingBridges: {
        bridges: [],
        bridgeOwnerGroup: {},
    },
    enableLinksToBridges: true,
    bridgeBeingViewedCurrently: {
        bridge_item: BlankBridgeItem,
        bridge_sub_items: [],
        commentators: []
    },
    bridgesBeingViewedCurrently: {},
    selectedRow: {
        row_id: "",
        name: "",
        leftValue: 0,
        rightValue: 0,
        varianceValue: 0,
        commentary: "",
        commentator: "",
        treeLevel: 0,
        touched: false,
        follow_up: {
            comment: "",
            timestamp: "",
            login: ""
        }
    },
    selectedBridgeId: "",
    viewBridgeModalVisible: false,
    viewAllBridgesModalVisible: false
}

export const bridgingCommentaryReducer = (
    bridgingState = initialBridgingState, {type, payload}: BridgingCommentaryActions
): BridgingCommentaryState => {
    if (!payload) return bridgingState;
    const {
        varianceDetails = {initiatedBridgeName: "", leftVarianceHeader: "", rightVarianceHeader: ""},
        bridgeItems = [],
        ownerGroups = {},
        status = initialBridgingState.status,
        bridges = [],
        bridgeOwnerGroup = {},
        enableLinksToBridges = true,
        viewBridgeModalVisible = false,
        viewAllBridgesModalVisible = false,
        bridgeBeingViewedCurrently = {bridge_item: BlankBridgeItem, bridge_sub_items: [], commentators: []},
        bridgesBeingViewedCurrently = {},
        selectedRow = {
            row_id: "",
            name: "",
            leftValue: 0,
            rightValue: 0,
            varianceValue: 0,
            commentary: "",
            commentator: "",
            treeLevel: 0,
            touched: false,
            follow_up: {
                comment: '',
                timestamp: '',
                login: ''
            }
        },
        selectedBridgeId = "",
        bridgeId = "",
        bridgeStatus = {},
        bridgeSubItems = [],
        bridgeHistoryItem = {},
        historyId = ""
    } = payload;
    switch (type) {
        case SET_PAGINATION_CONFIG:
            const paginationConfig = {
                paginationKey: payload.paginationKey,
                hasMore: payload.hasMore
            }
            return {...bridgingState, paginationConfig}
        case SET_BRIDGING_PAGE_STATUS:
            return {...bridgingState, status}
        case RESET_BRIDGING_PAGE_STATUS:
            return {...bridgingState, status: initialBridgingState.status}
        case SET_BRIDGE_NAME:
            return {...bridgingState, varianceDetails: varianceDetails}
        case SET_BRIDGE_STATUS:
            return {
                ...bridgingState,
                status: {
                    ...bridgingState.status,
                    bridge: {
                        ...bridgingState.status.bridge,
                        [bridgeId]: bridgeStatus
                    }
                }
            }
        case SET_WIZARD_BRIDGE_STATUS:
            return {
                ...bridgingState,
                status: {
                    ...bridgingState.status,
                    wizardBridge: {
                        ...bridgingState.status.wizardBridge,
                        [bridgeId]: bridgeStatus
                    }
                }
            }
        case SET_BRIDGE_ITEMS:
            return {...bridgingState, bridgeItems: bridgeItems}
        case SET_FILLING_BRIDGES:
            return {...bridgingState, fillingBridges: {...bridgingState.fillingBridges, bridgeItems}}
        case RESET_FILLING_BRIDGES:
            return {...bridgingState, fillingBridges: initialBridgingState.fillingBridges}
        case SET_BRIDGE_OWNER_GROUPS:
            return {...bridgingState, ownerGroups: {...ownerGroups}}
        case SET_REPORT_BRIDGES:
            return {...bridgingState, updatingBridges: {...bridgingState.updatingBridges, bridges, bridgeOwnerGroup}}
        case RESET_REPORT_BRIDGES:
            return {...bridgingState, updatingBridges: initialBridgingState.updatingBridges}
        case SET_ENABLE_LINKS_TO_BRIDGES:
            return {...bridgingState, enableLinksToBridges: enableLinksToBridges}
        case SET_VIEW_BRIDGE_MODAL:
            return {...bridgingState, viewBridgeModalVisible: viewBridgeModalVisible}
        case SET_VIEW_ALL_BRIDGES_MODAL:
            return {...bridgingState, viewAllBridgesModalVisible: viewAllBridgesModalVisible}
        case SET_BRIDGE_BEING_VIEWED_CURRENTLY:
            return {
                ...bridgingState,
                bridgeBeingViewedCurrently: {
                    ...bridgingState.bridgeBeingViewedCurrently,
                    bridge_item: bridgeBeingViewedCurrently.bridge_item
                }
            }
        case RESET_BRIDGE_BEING_VIEWED_CURRENTLY:
            return {
                ...bridgingState,
                bridgeBeingViewedCurrently: {bridge_item: BlankBridgeItem, bridge_sub_items: [], commentators: []},
            }
        case SET_BRIDGES_BEING_VIEWED_CURRENTLY:
            return {...bridgingState, bridgesBeingViewedCurrently: bridgesBeingViewedCurrently}
        case SET_SELECTED_ROW:
            return {...bridgingState, selectedRow: selectedRow}
        case SET_SELECTED_BRIDGE_ID:
            return {...bridgingState, selectedBridgeId: selectedBridgeId}
        case SET_BRIDGE_SUB_ITEMS:
            return {
                ...bridgingState,
                bridgeBeingViewedCurrently: {
                    ...bridgingState.bridgeBeingViewedCurrently,
                    bridge_sub_items: bridgeBeingViewedCurrently.bridge_sub_items
                }
            }
        case SET_COMMENTATORS:
            return {
                ...bridgingState,
                bridgeBeingViewedCurrently: {
                    ...bridgingState.bridgeBeingViewedCurrently,
                    commentators: bridgeBeingViewedCurrently.commentators
                }
            }
        case ADD_SUB_ITEMS_FOR_BRIDGE:
            return {
                ...bridgingState,
                fillingBridges: {
                    ...bridgingState.fillingBridges,
                    subItemsForBridges: {
                        ...bridgingState.fillingBridges.subItemsForBridges,
                        [bridgeId]: bridgeSubItems
                    }
                }
            }
        case SET_SUB_ITEMS_FOR_BRIDGE_TO_HISTORY:
            return {
                ...bridgingState,
                fillingBridges: {
                    ...bridgingState.fillingBridges,
                    subItemsForBridges: {
                        ...bridgingState.fillingBridges.subItemsForBridges,
                        [bridgeId]: JSON.parse(JSON.stringify(historyItemToBridgeItem(bridgingState.fillingBridges.historyForBridges[bridgeId][historyId])))
                    }
                }
            }
        case ADD_HISTORY_FOR_BRIDGE:
            return {
                ...bridgingState,
                fillingBridges: {
                    ...bridgingState.fillingBridges,
                    historyForBridges: {
                        ...bridgingState.fillingBridges.historyForBridges,
                        [bridgeId]: {
                            ...bridgingState.fillingBridges.historyForBridges[bridgeId],
                            [historyId]: bridgeHistoryItem
                        }
                    }
                }
            }
        case SET_LATEST_FOR_BRIDGE:
            return {
                ...bridgingState,
                fillingBridges: {
                    ...bridgingState.fillingBridges,
                    historyForBridges: {
                        ...bridgingState.fillingBridges.historyForBridges,
                        [bridgeId]: {
                            ...bridgingState.fillingBridges.historyForBridges[bridgeId],
                            latest: JSON.parse(JSON.stringify(bridgeSubItems.reduce(
                                (historyItem: BridgeHistoryItem, row) => {
                                    historyItem[row.row_id] = row;
                                    return historyItem
                                }, {})))
                        }
                    }
                }
            }
        case REMOVE_LATEST_FOR_BRIDGE:
            const {latest, ...history} = bridgingState.fillingBridges.historyForBridges[bridgeId]
            console.log(`Removed history: ${latest}`);
            return {
            ...bridgingState,
            fillingBridges: {
                ...bridgingState.fillingBridges,
                historyForBridges: {
                    ...bridgingState.fillingBridges.historyForBridges,
                    [bridgeId]: {
                        ...history
                    }
                }
            }
        }
        case RESET_SUB_ITEMS_FOR_BRIDGE:
            return {...bridgingState, fillingBridges: {...bridgingState.fillingBridges, subItemsForBridges: {}}};
        default:
            return bridgingState
    }
}

export const getVarianceHeaderDetails = (state: AppState) => state.bridgingCommentary.varianceDetails;
export const getBridgeItems = (state: AppState) => state.bridgingCommentary.bridgeItems;
export const getSubItemsForBridges = (state: AppState) => state.bridgingCommentary.fillingBridges.subItemsForBridges;
export const getFillingBridgeItems = (state: AppState) => state.bridgingCommentary.fillingBridges.bridgeItems;
export const getBridgeHistory = (state: AppState) => state.bridgingCommentary.fillingBridges.historyForBridges;
export const forBridgeHistoryIds = createSelector(
    [
        getBridgeHistory,
        (state: AppState, bridgeId: string) => (bridgeId)
    ],
    (bridgeHistory, bridgeId) => {
        const ids = bridgeHistory[bridgeId] ?
            Object.keys(bridgeHistory[bridgeId]).filter(id => id !== 'latest') : []
        ids.sort();
        return ids
    }
)
export const forBridgeHistory = createSelector(
    [
        getBridgeHistory,
        (state: AppState, bridgeId: string) => (bridgeId)
    ],
    (bridgeHistory, bridgeId) => {
        return bridgeHistory[bridgeId] || {}
    }
)
export const forBridgeRowHistory = createSelector(
    [
        getBridgeHistory,
        (state: AppState, bridgeId: string, historyId: string, rowId: string) => ({bridgeId, historyId, rowId})
    ],
    (bridgeHistory, params): BridgeSubItem => {
        const {bridgeId, historyId, rowId} = params
        return bridgeHistory[bridgeId][historyId][rowId] || defaultRow
    }
)
export const forMostRecentCommenterInHistory = createSelector(
    [
        getBridgeHistory,
        (state: AppState, bridgeId: string, historyId: string) => ({bridgeId, historyId})
    ],
    (bridgeHistory, params): {timestamp: number, commenter: string} => {
        const {bridgeId, historyId} = params
        const bridgeHistoryObject = bridgeHistory[bridgeId] || {}
        const historyItem = bridgeHistoryObject[historyId]
        return mostRecentCommenter(historyItem)
    }
)
export const forRowIdsInHistory = createSelector(
    [
        getBridgeHistory,
        (state: AppState, bridgeId: string, historyId: string) => ({bridgeId, historyId})
    ],
    (bridgeHistory, params): string[] => {
        const {bridgeId, historyId} = params
        const bridgeHistoryObject = bridgeHistory[bridgeId] || {}
        const historyItem = bridgeHistoryObject[historyId] || {}
        return Object.keys(historyItem)
    }
)
export const forPreviousHistoryId = createSelector(
    [
        getBridgeHistory,
        (state: AppState, bridgeId: string, historyIdIndex: number) => ({bridgeId, historyIdIndex})
    ],
    (bridgeHistory, params): string => {
        const {bridgeId, historyIdIndex} = params
        const bridgeHistoryObject = bridgeHistory[bridgeId] || {}
        const historyIds = Object.keys(bridgeHistoryObject)
        historyIds.sort();
        if (historyIds.length < 1) return ''

        return historyIdIndex < 1 ? historyIds[historyIds.length-1] : historyIds[historyIdIndex - 1]
    }
)
export const getBridgeOwnerGroups = (state: AppState) => state.bridgingCommentary.ownerGroups;
export const getPaginationConfig = (state: AppState) => state.bridgingCommentary.paginationConfig;
export const getReportBridgeItems = (state: AppState) => state.bridgingCommentary.updatingBridges;
export const getBridgingPageStatus = (state: AppState) => state.bridgingCommentary.status;
export const getEnableLinksToBridges = (state: AppState) => state.bridgingCommentary.enableLinksToBridges;
export const getBridgeBeingViewedCurrently = (state: AppState) => state.bridgingCommentary.bridgeBeingViewedCurrently;
export const getBridgesBeingViewedCurrently = (state: AppState) => state.bridgingCommentary.bridgesBeingViewedCurrently;
export const getCommentators = (state: AppState) => state.bridgingCommentary.bridgeBeingViewedCurrently.commentators;
export const getViewBridgeModalVisible = (state: AppState) => state.bridgingCommentary.viewBridgeModalVisible;
export const getViewAllBridgesModalVisible = (state: AppState) => state.bridgingCommentary.viewAllBridgesModalVisible;
export const forInViewBridgeSubItem = (state: AppState) => state.bridgingCommentary.selectedRow;
export const forInViewBridgeId = (state: AppState) => state.bridgingCommentary.selectedBridgeId;


const listenPublishedBridges = (state: AppState): { [key: string]: BridgeSubItem[] } => {
    return state.bridgingCommentary.bridgesBeingViewedCurrently
};

const listenMarkers = (state: AppState): Array<string> | undefined => {
    return state.reportLibrary.viewingReport.markers;
}

const listenViewingReportRows = (state: AppState): {[p: string]: any}[] => {
    return state.reportLibrary.viewingReport.reportRows;
}

export const forCommentaryOfOneBridgeItem = createSelector(
    [
        listenPublishedBridges,
        (state: AppState, bridgeItemId: string, bridgeRowId: string) => ({bridgeItemId, bridgeRowId})
    ],
    (bridges, {bridgeItemId, bridgeRowId}) => {
        const bridgeRows: BridgeSubItem[] = bridges[bridgeItemId] || [];
        const dummy = {commentary: null}
        const oneRow = bridgeRows.find((item: BridgeSubItem) => item.row_id === bridgeRowId) || dummy;
        return oneRow.commentary
    }
);

export const forBridgeNaming = createSelector([
        listenPublishedBridges,
        (state: AppState, bridgeItemId: string, bridgeRowId: string) => ({bridgeItemId, bridgeRowId})
    ],
    (bridges, {bridgeItemId, bridgeRowId}) => {
        const namesInLevels: Record<"name" | "id", string>[] = [];
        const rows = bridges[bridgeItemId] || [];
        if (rows.length == 0) return namesInLevels;
        namesInLevels.push({name: rows[0].name, id: rows[0].row_id});
        const subRows = rows.filter(row => row.treeLevel > 1); /*don't repeat name of top-level item*/
        const oneRow = subRows.find((item: BridgeSubItem) => item.row_id === bridgeRowId)
        if (oneRow) {
            namesInLevels.push({name: oneRow.name, id: oneRow.row_id})
        }
        return namesInLevels
    }
);

export const forBridgeMetadata = createSelector([
        listenPublishedBridges,
        (state: AppState, bridgeItemId: string) => ({bridgeItemId})
    ],
    (bridges, {bridgeItemId}) => {
        const rows = bridges[bridgeItemId] || [];
        const allCommentators = rows.map((r) => r.commentator);
        const commentators = [...new Set(allCommentators)]
        return {commentators}
    }
);

export const forRowToBridgeRelation = createSelector([
        listenPublishedBridges
    ],
    (bridges) => {
        // this is reverse indexing of the Objects to enable finding bridge or sub item given a Row Id
        // this very helpful in connecting a table row click to a bridge
        const rowIdToBridgeId: Record<string, string> = {}
        const rowIdToSubItem: Record<string, BridgeSubItem> = {}
        Object.entries(bridges).forEach(([bridgeId, subItems]: [string, BridgeSubItem[]]) => {
            subItems.forEach((subItem) => {
                rowIdToBridgeId[subItem.row_id] = bridgeId;
                rowIdToSubItem[subItem.row_id] = subItem;
            })
        })
        return {rowIdToBridgeId, rowIdToSubItem}
    }
);

export const forPublishedBridgeItems = createSelector([
        listenPublishedBridges,
        listenMarkers,
        listenViewingReportRows
    ],
    (bridges, markers, reportRows) => {
        const orderIdToBridgeSubItems: Map<number, Array<BridgeSubItem>> = new Map([]);
        const arr: BridgeSubItem[] = []
        let sortedOrderIdToBridgeSubItems: Map<number, Array<BridgeSubItem>>;

        if (Object.keys(bridges).length !== 0) {
            markers?.forEach(function (publishedBridgeId) {
                if (bridges.hasOwnProperty(publishedBridgeId)) {
                    // Extract published bridge sub items array
                    const publishedBridgeSubItems = bridges[publishedBridgeId];
                    // Find main row with tree level 1 from array of bridge sub items
                    const publishedBridgeSubItem = publishedBridgeSubItems.find((row) => row.treeLevel === 1);
                    // Find viewing report row with matching name to get order id of sub item
                    const matchingReportRow = reportRows.find((row) => row.columns_name === publishedBridgeSubItem?.name);
                    orderIdToBridgeSubItems.set(matchingReportRow?.order_id, bridges[publishedBridgeId]);
                }
            });
        }
        if (orderIdToBridgeSubItems.size !== 0) {
            // Sort order ids in ascending order to maintain same order as report table
            sortedOrderIdToBridgeSubItems = new Map([...orderIdToBridgeSubItems].sort((a,b) =>  a[0]-b[0]));
            sortedOrderIdToBridgeSubItems.forEach((bridgeSubItems) => {
                bridgeSubItems.forEach((val) =>{
                    arr.push(val);
                })
            })
        }

        return {arr};
    }
);