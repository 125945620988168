import {Icon, IconProps, Popover, StatusIndicator, StatusIndicatorProps} from "@amzn/awsui-components-react";
import React from "react";
import {BridgeStatusType} from "src/components/BridgingCommentary/interfaces";
import {humanizeEpoch} from "src/utils/timeHelpers";

import { CustomBridgeItemStatus } from "../styling";

export const BridgeItemStatus = (props: {status: BridgeStatusType, lastModified: number, lastModifiedBy: string, rowTouched?: boolean}) => {
    const {status, lastModified, lastModifiedBy, rowTouched} = props;
    const humanizedTime = humanizeEpoch({epoch: lastModified, asDate: false});
    const statusText = status !== "In-progress" ? `${status} by ${lastModifiedBy} on ${humanizedTime}`
        : `Bridge is in-progress. Last activity on the bridge was on ${humanizedTime} by ${lastModifiedBy}`

    const iconName: {[key: string]: StatusIndicatorProps.Type} = {
        "Requested": "pending",
        "In-progress": "in-progress",
        "Drafting": "in-progress",
        "Submitted": "success",
        "Approved": "success",
        "Published": "success",
        "Unpublished": "stopped"
    }

    const customBridgeStatusIndicator = (iconName: IconProps.Name, status: BridgeStatusType) => {
        return (
            <CustomBridgeItemStatus>
                <Icon name={iconName} />
                {status}
            </CustomBridgeItemStatus>
        )
    }

    return(
        <Popover
            dismissAriaLabel="Close"
            size="small"
            content={statusText}
        >
            {
                status === 'Proposed' ?
                    customBridgeStatusIndicator("file-open", status) :
                    <StatusIndicator type={iconName[rowTouched ? 'Drafting' : status]}>
                        {rowTouched ? 'Drafting' : status}
                    </StatusIndicator>

            }
        </Popover>
    );
};