import {Button, ButtonDropdown, Link, StatusIndicator, TableProps} from "@amzn/awsui-components-react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Table from "@amzn/awsui-components-react/polaris/table";
import React, {ReactNode, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {deleteTheme} from "src/actions/themes.actions";
import {EmptyTableState} from "src/components/MDXLibraryPage/MDXLibraryTable";
import Paths from "src/components/PageConfig/Paths";
import {GroupedThemes, Theme, themeStateToIcon} from "src/components/Themes/interfaces";
import {getThemesApiLoading} from "src/reducers/apiLoading.reducer";
import {epochToAgo} from "src/utils/timeHelpers";
import styled from "styled-components";

enum ReportThemesTableColumns {
  LINE_ITEM ='Line item',
  STATUS = 'Status',
  LAST_EDITED = 'Last edited',
  ACTIONS = 'Actions'
}

interface ReportThemesTableItem {
  id: string,
  lineItem: ReactNode,
  status: ReactNode,
  lastEdited: ReactNode,
  actions: ReactNode
}

const IndentedRow = styled.span`
    padding-left: 28px;
  `

const buildThemesTableRow = (theme: Theme): ReportThemesTableItem => {
  const dispatch = useDispatch()
  const actions = <ButtonDropdown
      items={[{ text: "Delete", id: "delete"}]}
      variant="icon"
      onItemClick={() => dispatch(deleteTheme(theme.id))}
      expandToViewport
    />
  const lineItem = <IndentedRow>
    <Link href={`#${Paths.THEMES}?themeId=${theme.id}`}>
      {theme.name}
    </Link>
  </IndentedRow>

  return {
    id: theme.id,
    lineItem,
    status: <StatusIndicator type={themeStateToIcon[theme.state]}>{theme.state}</StatusIndicator>,
    lastEdited: epochToAgo({epoch: theme.lastModified}),
    actions
  }
}

const buildThemesTableRows = (groupedThemes: GroupedThemes, expandedGroups: { [p: string]: boolean }, setExpandedGroups:  (value: (((prevState: {[p: string]: boolean}) => {[p: string]: boolean}) | {[p: string]: boolean})) => void) => {
  const tableRows: ReportThemesTableItem[] = [];
  Object.keys(groupedThemes).forEach(scenario => {
    const isExpanded = expandedGroups[scenario]
    const iconName = isExpanded ? "treeview-collapse" : "treeview-expand";
    const lineItem = <>
      <Button
        iconName={iconName} variant="icon"
        onClick={() => setExpandedGroups({...expandedGroups, [scenario]: !expandedGroups[scenario]})}
      />
      {scenario}
    </>

    tableRows.push({
      id: scenario,
      lineItem,
      status: null,
      lastEdited: null,
      actions: null,
    })
    // Add sub rows of group
    if(isExpanded) {
      groupedThemes[scenario].forEach(theme => tableRows.push(buildThemesTableRow(theme)));
    }
  });
  return tableRows;
}

interface ReportThemesProps {
  groupedThemes: GroupedThemes
}

export const ReportThemes = ({groupedThemes}: ReportThemesProps) => {
  const [expandedGroups, setExpandedGroups] = useState<{ [p: string]: boolean }>({});
  const items = buildThemesTableRows(groupedThemes, expandedGroups, setExpandedGroups)
  const isLoading = useSelector(getThemesApiLoading)


  const columnDefinitions: TableProps.ColumnDefinition<ReportThemesTableItem>[] = [
    {
      id: ReportThemesTableColumns.LINE_ITEM,
      header: ReportThemesTableColumns.LINE_ITEM,
      cell: (e) => e.lineItem
    },
    {
      id: ReportThemesTableColumns.STATUS,
      header: ReportThemesTableColumns.STATUS,
      cell: (e) => e.status
    },
    {
      id: ReportThemesTableColumns.LAST_EDITED,
      header: ReportThemesTableColumns.LAST_EDITED,
      cell: (e) => e.lastEdited
    },
    {
      id: ReportThemesTableColumns.ACTIONS,
      header: ReportThemesTableColumns.ACTIONS,
      width: 40,
      cell: (e) => e.actions
    },
  ]

  return <Container
    header={<Header variant={'h1'}>Themes</Header>}
  >
    <Table
      wrapLines={false}
      trackBy="id"
      variant="borderless"
      columnDefinitions={columnDefinitions}
      items={items}
      loadingText="Loading resources"
      loading={isLoading}
      stickyHeader={true}
      empty={<EmptyTableState />}
    />
  </Container>
}