import {Roles} from "src/common/roles";
import {BridgeStatusType} from "src/components/BridgingCommentary/interfaces";
import {nonDollarValueRowItems} from "src/components/FillOutBridgePage/InputComponents";
import {BridgeSubItem} from "src/components/FillOutBridgePage/interfaces";
import {BackgroundAction} from "src/components/FillOutBridgePage/TableSubComponents";
import {getEpoch} from "src/utils/timeHelpers";
import {v4 as uuid} from "uuid";

export const isReadOnly = (status: BridgeStatusType, role: Roles, latest: boolean): boolean => {
    const isPublished = status === BridgeStatusType.Published
    const isOwnerAndApprovedOrSubmitted = role === Roles.BridgeOwner &&
        (status === BridgeStatusType.Approved || status === BridgeStatusType.Submitted)
    return isPublished || isOwnerAndApprovedOrSubmitted || !latest;
}

export const createSubItem = (
    name = "bridge item",
    leftValue = 0.00,
    rightValue = 0.00,
    varianceValue = 0.00,
    treeLevel = 1,
    touched = false,
    commentator = ""
): BridgeSubItem => {
    return {
        row_id: uuid(),
        name,
        leftValue,
        rightValue,
        varianceValue,
        treeLevel,
        touched,
        commentary: "",
        commentator,
        follow_up: {
            comment: "",
            timestamp: "",
            login: ""
        }
    };
}

// Round function
export const customRound = (value: number) => Math.round((value + Number.EPSILON) * 10) / 10;

export const getUnexplainedRow = (firstSubItem: BridgeSubItem, subItems: BridgeSubItem[], tableLayout: number): BridgeSubItem | null => {
    const {leftValue, rightValue, varianceValue} = firstSubItem;
    // Calculate values for unexplained cells
    const {unexplainedLeft, unexplainedRight, unexplainedVariance} = subItems.slice(1).reduce(
        (previousValue, subItem) => {
            return {
                unexplainedLeft: previousValue.unexplainedLeft - subItem.leftValue,
                unexplainedRight: previousValue.unexplainedRight - subItem.rightValue,
                unexplainedVariance: previousValue.unexplainedVariance - subItem.varianceValue
            }
        },
        {unexplainedLeft: Number(leftValue), unexplainedRight: Number(rightValue), unexplainedVariance: Number(varianceValue)}
    );

    // If variance has been explained skip the unexplained row
    if ((tableLayout === 1 && !unexplainedLeft && !unexplainedRight && !unexplainedVariance)
        || (tableLayout === 2 && !customRound(unexplainedVariance))) {

        return null;
    }

    return {
        row_id: "Unexplained variance",
        name: "Unexplained variance",
        leftValue: customRound(unexplainedLeft),
        rightValue: customRound(unexplainedRight),
        varianceValue: customRound(unexplainedVariance),
        treeLevel: 2,
        touched: false,
        commentary: "",
        commentator: "",
        follow_up: {
            comment: "",
            timestamp: "",
            login: ""
        }
    };
}

export const getFormattedValue = (value: string | number, rowItem: string) => {
    if (!nonDollarValueRowItems.has(rowItem)) {
        const valueFormatter = Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        });
        return valueFormatter.format(Number(value));
    } else {
        const valueFormatter = Intl.NumberFormat('en-US');
        return valueFormatter.format(parseInt(String(value)));
    }
}

export const getCountedText = (text: string, list: Array<any>) => `${text}${list.length > 1 ? "s" : ""}`;

export function combineBridgeSubItems(subItems: BridgeSubItem[], newSubItems: BridgeSubItem[], deletedRowIds: Set<string>, backgroundAction: BackgroundAction) {
    const rowIdToRow: { [p: string]: BridgeSubItem } = {}
    const seenRowIds = new Set<string>();
    const combinedSubItems: BridgeSubItem[] = [];

    subItems.forEach(item => {
        if (!deletedRowIds.has(item.row_id)) {
            rowIdToRow[item.row_id] = item
            seenRowIds.add(item.row_id);
        }
    });

    // Don't override active users edits when auto-refreshing
    backgroundAction === BackgroundAction.refreshingComments && newSubItems.forEach(item => {
        const followUpTouched = rowIdToRow[item.row_id] && (item.follow_up.timestamp !== rowIdToRow[item.row_id].follow_up.timestamp)
        if (item.touched || followUpTouched) {
            rowIdToRow[item.row_id] = item
        }
    });

    Object.entries(rowIdToRow).forEach(([, subItem]) => {
        combinedSubItems.push(subItem);
    });

    return combinedSubItems;
}

export function getRecentCommentators(subItems: BridgeSubItem[], lastNSeconds = 300) {
    const recentCommentators = new Set<string>();
    const epochNMilliSecondsAgo = getEpoch() - (lastNSeconds * 1000);
    subItems.forEach(item => {
        if (item.last_edited_timestamp && item.commentator && item.last_edited_timestamp >= epochNMilliSecondsAgo) {
            recentCommentators.add(item.commentator);
        }
    });
    return Array.from(recentCommentators);
}
