import {Button} from "@amzn/awsui-components-react";
import React, {useState} from "react";
import {groupByVariance} from "src/components/BridgingCommentary/helpers";
import {
    BridgingTableItem,
    TableRowsProps
} from "src/components/BridgingCommentary/interfaces";
import {constructBridgingTableRow} from "src/components/BridgingCommentary/tableComponents/constructBridgingTableRow";
import {ReportLink} from "src/components/BridgingCommentary/tableComponents/ReportLink";


const AdminTableRows = (props : TableRowsProps) => {
    const [expandedGroups, setExpandedGroups] = useState<{[p:string]: boolean}>({});


    const {bridgeItems, fullTable} = props;

    const groupedItems = groupByVariance(bridgeItems);

    const tableRows: BridgingTableItem[] = [];
    //Now group Items by Variance headers ?

    Object.keys(groupedItems).forEach(variance_header => {
        const isReportExpanded = !(expandedGroups[variance_header] || fullTable)
        const iconName = isReportExpanded ? "treeview-collapse" : "treeview-expand";
        const reportName = groupedItems[variance_header][0].report_name;
        const report_id = groupedItems[variance_header][0].report_id
        tableRows.push({
            id: variance_header,
            report_id: variance_header,
            name: reportName,
            displayName: (<>
                <Button
                    //TODO remove data test Id FOR NOW
                    // data-testid={ INTEGRATION_REPORT_IDS.has(report_id) ? 'expand-button' : ''}
                    iconName={iconName} variant="icon"
                    onClick={() =>
                        setExpandedGroups({...expandedGroups, [variance_header]: !expandedGroups[variance_header]})
                    }
                />
                <ReportLink reportName={variance_header} reportId={report_id} role={null}/>
            </>),
            actions: "",
            varianceName: "", requester: "", owners: <></>, status: "", editDate: 0, dueDate: <></>,
        })
        // Add sub rows of group
        if(isReportExpanded) {
            groupedItems[variance_header].forEach(bridgeItem => tableRows.push(constructBridgingTableRow(bridgeItem, props)));
        }
    });
    return tableRows;
}
export default AdminTableRows;
