import {Middleware} from "redux";
import {FILE_TRANSFER, setFileTransferStatus} from "src/actions/fileTransfer.actions";
import {AppState as State} from "src/reducers/AppState";

const fileTransferMiddleware: Middleware<{}, State> = ({dispatch}) => next => async action => {
    next(action);
    if (action.payload) {
        const {data = {}, errorData = {}, params = {}, method = ''} = action.payload;
        const setUploadProgress = action.meta?.setUploadProgress;
        switch (action.type) {
            case `${FILE_TRANSFER} API_SUCCESS`:
                if (method === 'GET') {
                    if (params.urlType === 'upload') {
                        const updateUploadProgress = (progressEvent: ProgressEvent) => {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress && setUploadProgress(percentCompleted);
                        };
                        await _uploadFileToS3(data.url, data.fields, params.file, updateUploadProgress);
                    } else if (params.urlType === 'download') {
                        _directDownloadOnClick(data.url);
                    }
                    dispatch(setFileTransferStatus(params.urlType, params.fileEntity, "success", params.urlType === 'upload' ? data.fields.key : ""));
                }
                break;
            case `${FILE_TRANSFER} API_ERROR`:
                dispatch(setFileTransferStatus(
                    params.urlType,
                    params.fileEntity,
                    `Failed: ${errorData.reason || errorData.parameter || 'Empty error message'}`
                ));
                break;
        }
    }
}

export function _directDownloadOnClick (downloadUrl: string) {
    window.open(downloadUrl, '_self')
}

function _uploadFileToS3(url: any, data: any, file: any, updateProgress = defaultUpdateProgress): Promise<void> {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value as string);
        });

        // Actual file has to be appended last.
        formData.append("file", file);

        const xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);
        xhr.upload.onprogress = updateProgress;
        xhr.withCredentials = false;
        xhr.send(formData);
        xhr.onload = function () {
            this.status === 204 ? resolve() : reject(this.responseText);
        };
    });
}

const defaultUpdateProgress = (event: ProgressEvent) => console.log((event.loaded / event.total) * 100);

export default fileTransferMiddleware;