import {Button, Input, TokenGroup} from "@amzn/awsui-components-react";
import React, {useState} from "react";
import {Roles} from "src/common/roles";
import {OwnerWidgetProps} from "src/components/BridgingCommentary/modalComponents/interfaces";
import {CustomGrid} from "src/components/BridgingCommentaryPage/BridgeDetailsModal";
import { B } from "src/components/FillOutBridgePage/TableSubComponents";

export const OwnersWidget = (props : OwnerWidgetProps) => {
    const {userRole, bridgeDetails, setBridgeDetails} = props;
    const [newOwners, setNewOwners] = useState("");

    const onAddNewOwner = () => {
        const logins = newOwners.split(",").map(login => login.trim()).filter(login => !!login);
        const updatedOwners = Array.from(new Set([...bridgeDetails.bridge_owners, ...logins])).sort();
        setBridgeDetails({...bridgeDetails, bridge_owners: updatedOwners});
        setNewOwners("");
    }
    return(
        userRole === Roles.Admin ?
            <li className={"spaced-line"}>
            <p style={{margin: "0", padding: "0"}}>
                <B fontWeight={"bold"}>Bridge owners: </B>
            </p>
            <TokenGroup
                items={bridgeDetails.bridge_owners.map(owner => (
                    {label: owner, dismissLabel: `Remove ${owner}`}
                ))}
                onDismiss={({detail: {itemIndex}}) => {
                    setBridgeDetails({
                        ...bridgeDetails,
                        bridge_owners: [
                            ...bridgeDetails.bridge_owners.slice(0, itemIndex),
                            ...bridgeDetails.bridge_owners.slice(itemIndex + 1)
                        ]
                    });
                }}
            />
            <>{ bridgeDetails.bridge_owners.length <= 20 ?
                <small>{`You can add up to ${20 - bridgeDetails.bridge_owners.length} users`}</small>
                : <small style={{color: "red"}}>{`You can only add up to 20 users`}</small>
            }</>
            <CustomGrid>
                <Input
                    value={newOwners}
                    onChange={({detail}) => setNewOwners(detail.value)}
                    placeholder="Enter comma separated logins"
                />
                <Button onClick={onAddNewOwner} disabled={!newOwners}>Add</Button>
            </CustomGrid>
        </li> : <li>Bridge Owners: <B>{bridgeDetails.bridge_owners.join(", ")}</B></li>
    )
}