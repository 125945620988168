import React from "react";
import {
    BridgeInfoProps
} from "src/components/BridgingCommentary/modalComponents/interfaces";
import {ReportLink} from "src/components/BridgingCommentary/tableComponents/ReportLink";
import {B} from "src/components/FillOutBridgePage/TableSubComponents";

export const BridgeInfo = (props : BridgeInfoProps) => {
    const {bridgeDetails, userRole} = props;
    return (
        <><li>
            {<B>Variance:</B>} {bridgeDetails.variance_headers.variance_header}{` from `}
            <ReportLink reportName={bridgeDetails.report_name} reportId={bridgeDetails.report_id} role={userRole} />
        </li>
        <li>{<B>Bridge Item: </B>}{bridgeDetails.account_rollup}</li>
        <li>{<B> Bridge Owner Group: </B>} {bridgeDetails.bridge_owner_group}</li>
        </>
    )
}