import {Alert} from "@amzn/awsui-components-react";
import React, {ReactNode, useEffect, useState} from "react";


interface TimedAlertProps {
    timeout: number,
    children?: ReactNode
}

export const TimedAlert = ({timeout, children}: TimedAlertProps) => {
    const [visible, setVisible] = useState(true)

    useEffect(() => {
        const timeId = setTimeout(() => {
            setVisible(false)
        }, timeout)

        return () => {
            clearTimeout(timeId)
        }
    }, []);

    return visible ? <Alert type={'info'}>{children}</Alert> : null
}