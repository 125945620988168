import {Button} from "@amzn/awsui-components-react";
import React, {useState} from "react";
import {groupBridgesForOwners} from "src/components/BridgingCommentary/helpers";
import {
    BridgingTableItem,
    TableRowsProps
} from "src/components/BridgingCommentary/interfaces";
import {VarianceIndent} from "src/components/BridgingCommentary/styling";
import {constructBridgingTableRow} from "src/components/BridgingCommentary/tableComponents/constructBridgingTableRow";

const OwnerTableRows = (props : TableRowsProps) => {
    const [expandedGroups, setExpandedGroups] = useState<{[p:string]: boolean}>({});

    const {bridgeItems, fullTable} = props;

    const tableRows: BridgingTableItem[] = [];
    const groupedItems = groupBridgesForOwners(bridgeItems);

    Object.keys(groupedItems).forEach(report_id => {
        const isReportExpanded = !(expandedGroups[report_id] || fullTable)
        const iconName = isReportExpanded ? "treeview-collapse" : "treeview-expand";
        const header = Object.keys(groupedItems[report_id])[0];
        const reportName = groupedItems[report_id][header][0].report_name;
        tableRows.push({
            id: report_id,
            report_id: report_id,
            name: reportName,
            displayName: (<>
                <Button
                    iconName={iconName} variant="icon"
                    onClick={() => setExpandedGroups({...expandedGroups, [report_id] : !expandedGroups[report_id]})}
                />
                {reportName}
            </>), actions: "", varianceName: "", requester: "", owners: <></>, status: "", editDate: 0, dueDate: <></>,
        })
        if(isReportExpanded) {
            Object.keys(groupedItems[report_id]).forEach(variance_header => {
                const variance_name = report_id + "." + variance_header
                const isVarianceExpanded = !(expandedGroups[variance_name] || fullTable)
                const groupIcon = (isVarianceExpanded) ? "treeview-collapse" : "treeview-expand";
                tableRows.push({
                    id: report_id + variance_header,
                    report_id: report_id,
                    name: variance_header,
                    displayName: (<VarianceIndent>
                        <Button
                            iconName={groupIcon} variant="icon"
                            onClick={() =>
                                setExpandedGroups({...expandedGroups, [variance_name] : !expandedGroups[variance_name]})
                            }
                        />
                        {variance_header}
                    </VarianceIndent>),
                    actions: "", varianceName: "", requester: "", owners: <></>, status: "", editDate: 0, dueDate: <></>,
                })
                if(isVarianceExpanded) groupedItems[report_id][variance_header].forEach(bridgeItem => tableRows.push(constructBridgingTableRow(bridgeItem, props)));
            })

        }
    });
    return tableRows;
}
export default OwnerTableRows;
