import {Link, SideNavigation, SideNavigationProps} from '@amzn/awsui-components-react';
import React, { useMemo} from 'react';
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {externalLinkProps} from 'src/common/labels';
import {Roles} from "src/common/roles";
import Paths from "src/components/PageConfig/Paths";
import {
    ACTIVE_REPORTS_TITLE,
    ARCHIVE_REPORTS_TITLE,
    PUBLISHED_REPORTS_TITLE
} from "src/components/ReportLibraryPage/constants";
import {getAssumedRole} from "src/reducers/user.reducer";

import {LinkItemProps} from "./interfaces";


// Constant links
const requestAccessLink = "https://t.corp.amazon.com/create/templates/aab7ed03-f3b3-4978-9644-87f23a17a933";
const wikiLink = "https://w.amazon.com/bin/view/AWS_FinBI_Apps/FinPress/";
const faqLink = "https://w.amazon.com/bin/view/AWS_FinBI_Apps/FinPress/";
const fileABugLink = "https://t.corp.amazon.com/create/templates/d14d9ce7-1a21-428b-aeb6-b6faeb0adb80";


/* a special case of external link, to be used within a link group, where all of them are external
 * and we do not repeat the icon
 */
export const ExternalLinkItem = ({ href, text }: LinkItemProps) => (
    <Link href={href} ariaLabel={`${text} ${externalLinkProps.externalIconAriaLabel}`} target="_blank">
        {text}
    </Link>
);


export function Navigation() {
    const assumedRole = useSelector(getAssumedRole);
    const location = useLocation();
    const generateReportLinks = (role: Roles) => {
        const activeReportLink = {type: "link", text: ACTIVE_REPORTS_TITLE, href: `#${Paths.ACTIVEREPORTS}`}
        const referenceReportLink = {type: "link", text: PUBLISHED_REPORTS_TITLE, href: `#${Paths.REFERENCEREPORTS}`}
        const archiveReportLink = {type: "link", text: ARCHIVE_REPORTS_TITLE, href: `#${Paths.ARCHIVEREPORTS}`}
        const buildReportLink = {type: "link", text: "Build Your Own Report", href: `#${Paths.BUILDREPORT}`}
        const buildReportWorkflowLink = {type: "link", text: "Build Your Own Report", href: `#${Paths.REPORT_BUILDER}`}
        const roleReportLinks: {[r in Roles]?: any[]} = {
            [Roles.Admin]: [activeReportLink, referenceReportLink, archiveReportLink],
            [Roles.FinanceLeader]: [referenceReportLink, archiveReportLink],
            [Roles.BusinessLeader]: [referenceReportLink],
            [Roles.FGBSLeader]: [referenceReportLink],
            [Roles.SFPA_ReportAuthor] : [referenceReportLink, buildReportLink],
            [Roles.SFPA_ReportViewer] : [referenceReportLink],
            [Roles.DCFPA_ReportAuthor] : [referenceReportLink, buildReportWorkflowLink],
            [Roles.DCFPA_ReportViewer] : [referenceReportLink]
        }

        return {
            type: "expandable-link-group", text: "Report libraries", href: "javascript:void(0)",
            items: roleReportLinks[role] || []
        }
    }

    const generateLinkItems = () => {
        const bridgingCommentaryLink = {type: "link", text: "Bridging & Commentary", href: `#${Paths.BRIDGINGCOMMENTARY}`};
        const mdxLibraryLink = {type: "link", text: "MDX Query Library", href: `#${Paths.MDXLIBRARY}`}
        const roleLinks: {[r in Roles]: any[]} = {
            [Roles.Admin]: [generateReportLinks(Roles.Admin), mdxLibraryLink],
            [Roles.BridgeOwner]: [bridgingCommentaryLink],
            [Roles.FinanceLeader]: [generateReportLinks(Roles.FinanceLeader)],
            [Roles.BusinessLeader]: [generateReportLinks(Roles.BusinessLeader)],
            [Roles.FGBSLeader]: [generateReportLinks(Roles.FGBSLeader)],
            [Roles.UNKNOWN]: [],
            [Roles.SFPA_ReportAuthor] : [generateReportLinks(Roles.SFPA_ReportAuthor)],
            [Roles.SFPA_ReportViewer] : [generateReportLinks(Roles.SFPA_ReportViewer)],
            [Roles.DCFPA_ReportAuthor] : [generateReportLinks(Roles.DCFPA_ReportAuthor)],
            [Roles.DCFPA_ReportViewer] : [generateReportLinks(Roles.DCFPA_ReportViewer)]
        }

        const linkItems: SideNavigationProps.Item[] = assumedRole ? roleLinks[assumedRole] : [];

        linkItems.push(
            {type: "link", text: "Request access", href: requestAccessLink, external: true},
            {type: "link", text: "Wiki?", href: wikiLink, external: true},
            {type: "link", text: "FAQ", href: faqLink, external: true},
            {type: "link", text: "File a bug", href: fileABugLink, external: true}
        );
        return linkItems;
    }

    // This value is always constant, so Memoize it
    const navigationConfig: {
        items: SideNavigationProps.Item[],
        header: SideNavigationProps.Header
    } = useMemo(() => {
            return {
                items: generateLinkItems(),
                header: {text: 'Home', href: `#${Paths.HOME}`}
            }
        }, [assumedRole]);

    return (
        <SideNavigation
            items={navigationConfig.items}
            header={navigationConfig.header}
            activeHref={`#${location.pathname}`}
        />
    );
}
