import { ExecuteFacetRequest } from "../interfaces/facetParams";

export function isSequential(arr: string[]): boolean {
    if (arr.length < 2) {
      return true; // An array with 0 or 1 element is considered sequential
    }
  
    const numbers = arr.map(Number);
  
    for (let i = 1; i < numbers.length; i++) {
      if (numbers[i] !== numbers[i - 1] + 1) {
        return false;
      }
    }
  
    return true;
  }
  
  export function validateFacetParams(facetRequest : ExecuteFacetRequest){

      if ( validateMultiSelect(facetRequest.facet_params['ner']['scenario1'].Periods) == false) {
        return false
      }
      if ( validateMultiSelect(facetRequest.facet_params['ner']['scenario2'].Periods) == false) {
        return false
      }
      return true

  }

  export function validateMultiSelect(arr : string[]) : boolean {

    const sorted = arr.sort()

    return isSequential(sorted)

  }