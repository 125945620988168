import {ReduxAction} from "src/common/interfaces";
import {ExternalReportState, ReportDefinition, ReportState, ReviewFolderState} from "src/common/report";
import {Roles} from "src/common/roles";
import {API_PATH} from "src/constants/api";
import {PaginationConfig} from "src/reducers/reportLibrary.reducer";

// Common constants
export const REPORT  = '[REPORT]';
export const WORKFLOW = '[WORKFLOW]'
export const REPORT_EXPORT = '[REPORT EXPORT]';
export const REPORT_DEFINITIONS = '[REPORT DEFINITION]';
export const BRIDGE_SYNC = '[BRIDGE SYNC]';
export const REVIEW_FOLDERS = '[REVIEW FOLDERS]';
export const REPORT_STATUS = '[REPORT STATUS]';
export const REPORT_LIBRARY = '[REPORT LIBRARY]';
export const REPORT_PUBLISHER = '[REPORT PUBLISHER]';
export const SPAN_TITLES = '[SPAN TITLES]';
export const SYNCHRONIZATION = '[SYNCHRONIZATION]';

// Constant types for reducer
export const SET_REPORT_LIBRARY = `${REPORT_LIBRARY} SET LIBRARY`;
export const SET_PAGINATION_CONFIG = `${REPORT_LIBRARY} SET PAGINATION_CONFIG`;
export const SET_REPORT_LIBRARY_STATUS = `${REPORT_LIBRARY} SET STATUS`;
export const RESET_REPORT_LIBRARY_STATUS = `${REPORT_LIBRARY} RESET STATUS`;
export const SET_UPDATING_REPORT_DEF = `${REPORT_LIBRARY} SET UPDATING RD`
export const SET_UPDATING_EXTERNAL_REPORT_DEF = `${REPORT_LIBRARY} SET UPDATING EXTERNAL RD`;
export const SET_UPDATING_REVIEW_FOLDER_DEF = `${REPORT_LIBRARY} SET UPDATING REVIEW FD`;
export const RESET_UPDATING_REPORT_DEF = `${REPORT_LIBRARY} RESET UPDATING RD`
export const RESET_VIEWING_REPORT = `${REPORT_LIBRARY} RESET VIEWING REPORT`;
export const SET_VIEWING_REPORT_ROWS = `${REPORT_LIBRARY} SET VIEWING ROWS`;
export const SET_VIEWING_REPORT_DEFINITION = `${REPORT_LIBRARY} SET VIEWING RD`;
export const SET_VIEWING_REPORT_SYNCHRONIZATION =  `${REPORT_LIBRARY} SET VIEWING SYNC`;

// Constants types for api call
export const POST_REPORT = `${REPORT} POST`;
export const GET_REPORT = `${REPORT} GET`;
export const POST_REPORT_EXPORT = `${REPORT_EXPORT} POST`;
export const GET_REPORT_STATUS= `${REPORT_STATUS} GET`;
export const POST_REPORT_DEFINITIONS = `${REPORT_DEFINITIONS} POST`;
export const POST_BRIDGE_SYNC = `${BRIDGE_SYNC} POST`;
export const POST_REVIEW_FOLDERS = `${REVIEW_FOLDERS} POST`
export const GET_REPORT_DEFINITIONS = `${REPORT_DEFINITIONS} GET`;
export const DELETE_REPORT_DEFINITIONS = `${REPORT_DEFINITIONS} DELETE`;
export const GET_REPORT_LIBRARY = `${REPORT_LIBRARY} GET`;
export const POST_REPORT_PUBLISHER = `${REPORT_PUBLISHER} POST`;
export const POST_SPAN_TITLES = `${SPAN_TITLES} POST`;
export const GET_SYNCHRONIZATION = '[SYNCHRONIZATION] GET';
export const DELETE_WORKFLOW = `${WORKFLOW} DELETE`;

// Interface definition for Report Library Status
export interface ReportLibraryStatus {
    fetchedReportStatus?: boolean,
    savedReportDefinitions?: boolean,
    deletedReportDefinitions?: boolean,
    updatedReviewFolders?: boolean,
    deletedReviewFolder?: boolean,
    startedReportComputation?: boolean,
    startedBridgeSync?: boolean,
    savedSpanTitles?: boolean,
    fetchedReportSynchronization?: boolean,
    fetchedReportRows?: boolean,
    exportedReport?: boolean,
    publishedReport?: boolean,
    errorMessage?: string,
    errorType?: string,
}
// Interface definition for Report Library action
export interface ReportLibraryActions extends ReduxAction {
    payload: {
        paginationKey?: any;
        hasMore?: boolean | undefined,
        reports?: ReportDefinition[],
        status?: ReportLibraryStatus,
        reportDefinitions?: ReportState,
        externalReportDefinitions?: ExternalReportState,
        reviewFolderDefinitions?: ReviewFolderState,
        canSyncObject?: {report_sync: boolean, bridge_sync: boolean},
        reportRows?: {[p: string]: any}[],
        markers?: Array<string>
    }
}

/************************************ REDUCER ACTIONS *****************************************/
// Action to set the report library
export const setReportLibrary = (reports: ReportDefinition[]) => ({
    type: SET_REPORT_LIBRARY,
    payload: {reports: reports}
});

// Action to set the pagination config for report library
export const setReportLibraryPaginationConfig = (payload: PaginationConfig) => ({
    type: SET_PAGINATION_CONFIG,
    payload: payload,
});

// Action to set status of create report
export const setReportLibraryStatus = (status: ReportLibraryStatus): ReportLibraryActions => ({
    type: SET_REPORT_LIBRARY_STATUS,
    payload: {status},
});

// Action to reset the status of create report
export const resetReportLibraryStatus= (): ReportLibraryActions => ({
    type: RESET_REPORT_LIBRARY_STATUS,
    payload: {},
});

export const setViewingReportRows = (reportRows: {[p: string]: any}[]) => ({
    type: SET_VIEWING_REPORT_ROWS,
    payload: {reportRows}
});

export const setViewingReportDefinition = (reportDefinitions: ReportState) => ({
    type: SET_VIEWING_REPORT_DEFINITION,
    payload: {reportDefinitions}
})

export const setViewingReportSynchronization = (canSyncObject: {report_sync: boolean, bridge_sync: boolean}) => ({
    type: SET_VIEWING_REPORT_SYNCHRONIZATION,
    payload: {canSyncObject}
})

export const setUpdatingReportDefinitions = (reportDefinitions: ReportState): ReportLibraryActions => ({
    type: SET_UPDATING_REPORT_DEF,
    payload: {reportDefinitions}
})

export const setUpdatingExternalReportDefinitions = (externalReportDefinitions: ExternalReportState): ReportLibraryActions => ({
    type: SET_UPDATING_EXTERNAL_REPORT_DEF,
    payload: {externalReportDefinitions}
})

export const setUpdatingReviewFolderDefinitions = (reviewFolderDefinitions: ReviewFolderState): ReportLibraryActions => ({
    type: SET_UPDATING_REVIEW_FOLDER_DEF,
    payload: {reviewFolderDefinitions}
})

/************************************ API CALL ACTIONS *****************************************/
// Action to get reports
export const getReportLibrary = (params: {reportLibrary: string, limit?: number, paginationKey?: any}) => ({
    type: GET_REPORT_LIBRARY,
    payload: {...params},
    meta: {
        apiCall: {method: 'GET', url: API_PATH.REPORT_LIBRARY, entity: REPORT_LIBRARY}
    }
});

export const postReportPublisher = (report_id: string, target_role: Roles, target_library: string) => ({
    type: POST_REPORT_PUBLISHER,
    payload: {
        report_id: report_id,
        target_role: target_role,
        target_library: target_library
    },
    meta: {
        apiCall: {method: 'POST', url: API_PATH.REPORT_PUBLISHER, entity: REPORT_PUBLISHER}
    }
});

export const deleteReportDefinition = (report_id: string) => ({
    type: DELETE_REPORT_DEFINITIONS,
    payload: {report_id},
    meta: {
        apiCall: {method: 'DELETE', url: API_PATH.REPORT_DEFINITIONS, entity: REPORT_DEFINITIONS}
    }
});

// Action to create/update report definition
export const postReportDefinition = (reportDefinitions: ReportState | ExternalReportState) => ({
    type: POST_REPORT_DEFINITIONS,
    payload: reportDefinitions,
    meta: {
        apiCall: {method: 'POST', url: API_PATH.REPORT_DEFINITIONS, entity: REPORT_DEFINITIONS}
    }
});

// Action to sync bridges connected to a report
export const postBridgeSync = (report_id: string, role: string) => ({
    type: POST_BRIDGE_SYNC,
    payload: {report_id, role},
    meta: {
        apiCall: {method: 'POST', url: API_PATH.REPORT_BRIDGES_SYNC(report_id), entity: BRIDGE_SYNC}
    }
});

// Action to create/update review folders
export const postReviewFolder = (reviewFolders: ReviewFolderState[]) => ({
    type: POST_REVIEW_FOLDERS,
    payload: {review_folders: reviewFolders},
    meta: {
        apiCall: {method: 'POST', url: API_PATH.REVIEW_FOLDERS, entity: REVIEW_FOLDERS}
    }
});

// Action to create/update review folders
export const deleteReviewFolder = (reviewFolder: ReviewFolderState) => ({
    type: POST_REVIEW_FOLDERS,
    payload: reviewFolder,
    meta: {
        apiCall: {method: 'DELETE', url: API_PATH.REVIEW_FOLDERS, entity: REVIEW_FOLDERS}
    }
});

// Action to get report definition
export const getReportDefinition = (report_id: string) => ({
    type: GET_REPORT_DEFINITIONS,
    payload: {report_id},
    meta: {
        apiCall: {method: 'GET', url: API_PATH.REPORT_DEFINITIONS, entity: REPORT_DEFINITIONS}
    }
});

// Action to get report status
export const getReportStatus= (report_id: string) => ({
    type: GET_REPORT_STATUS,
    payload: {report_id},
    meta: {
        apiCall: {method: 'GET', url: API_PATH.REPORT_STATUS, entity: REPORT_STATUS}
    }
});

// Action to start the report computation workflow
export const startReportComputation = (report_id: string) => ({
    type: POST_REPORT,
    payload: {report_id},
    meta: {
        apiCall: {method: 'POST', url: API_PATH.REPORT, entity: REPORT}
    }
});

export const getReport = (report_id: string) => ({
    type: GET_REPORT,
    payload: {report_id},
    meta: {
        apiCall: {method: 'GET', url: API_PATH.REPORT, entity: REPORT}
    }
});

// Action to save span title for a given report
export const postSpanTitles = (report_id: string, spanTitles: string[]) => ({
    type: POST_SPAN_TITLES,
    payload: {
        report_id: report_id,
        span_titles: spanTitles
    },
    meta: {
        apiCall: {method: 'POST', url: API_PATH.SPAN_TITLES, entity: SPAN_TITLES}
    }
});


// Action to do GET call to report synchronization api
export const getReportSynchronizationState = (report_id: string) => ({
    type: GET_SYNCHRONIZATION,
    payload: {report_id},
    meta: {
        apiCall: {method: 'GET', url: API_PATH.SYNCHRONIZATION, entity: SYNCHRONIZATION}
    }
});


// Action to save span title for a given report
export const postReportExport = (report_id: string, varianceValueType: string) => ({
    type: POST_REPORT_EXPORT,
    payload: {report_id, varianceValueType},
    meta: {
        apiCall: {method: 'POST', url: API_PATH.REPORT_EXPORT, entity: REPORT_EXPORT}
    }
});


//action to delete a workflow Report 
export const deleteWorkflow = (workflow_id : string, workflow_version : string, execution_id : string) => ({
    type: DELETE_WORKFLOW,
    payload: {workflow_id, workflow_version, execution_id},
    meta: {
        apiCall: {method: 'DELETE', url: API_PATH.DELETE_WORKFLOW(workflow_id, workflow_version, execution_id), entity: WORKFLOW}
    }
})