import {Link} from "@amzn/awsui-components-react";
import React from "react";
import {isPastDue} from "src/components/BridgingCommentary/helpers";
import {BridgeItem} from "src/components/BridgingCommentary/interfaces";
import {PastDueTag} from "src/components/BridgingCommentary/styling";
import {AdminDetailsProps} from "src/components/ReportBridgingPage/interfaces";
import {humanizeEpoch} from "src/utils/timeHelpers";


export const dueDateWidget = (props : AdminDetailsProps) => {
    const {setFocusedBridge, setFocusedModalVisible} = props;
    return function dueDate(item : BridgeItem) {
        return(<><Link
            data-testid = "due-date-link"
            href="#" onFollow={(event) => {
            event.preventDefault()
            setFocusedBridge({...item});
            setFocusedModalVisible(true);
        }}>
            {humanizeEpoch({epoch: item.due_timestamp})}
        </Link>
        <PastDueTag>{ isPastDue(item) && "Past Due"}</PastDueTag></>
        )}
}