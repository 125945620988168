import styled from "styled-components";

export const AlignedText = styled.div<{align: 'left' | 'right'}>`
    width: 100%;
    text-align: ${props => props.align};
    padding-right: 8px;
`

export const AlignedItalic = styled.i<{align: 'left' | 'right'}>`
    width: 100%;
    text-align: ${props => props.align};
    padding-right: 8px;
    display: inline-block;
`

export const AlignedBold = styled.b<{align: 'left' | 'right'}>`
    width: 100%;
    text-align: ${props => props.align};
    padding-right: 8px;
`