import {ContentLayout, SpaceBetween} from "@amzn/awsui-components-react";
import Header from "@amzn/awsui-components-react/polaris/header";
import React from "react";
import {useSelector} from "react-redux";
import BridgingCommentarySummary from "src/components/BridgingCommentary/BridgingCommentarySummary";
import BridgingAndCommentary from "src/components/BridgingCommentaryPage/BridgingAndCommentary"
import {getBridgeItems} from "src/reducers/bridgingCommentary.reducer";
import {getAssumedRole} from "src/reducers/user.reducer";

const BridgingCommentaryPage = () => {
	// Redux selectors
	const assumedRole = useSelector(getAssumedRole);
	const bridgeItemsForUser = useSelector(getBridgeItems);
	return (
				<ContentLayout
					header={
						<SpaceBetween size="m">
							<Header
								variant="h1"
								description="View and complete requested commentary for bridges"
							>
								Bridging and Commentary
							</Header>
						</SpaceBetween>
					}>
					<SpaceBetween size={"m"}>
						<BridgingCommentarySummary
							bridges={bridgeItemsForUser}
							role={assumedRole}/>
						<BridgingAndCommentary
							bridgeItems={bridgeItemsForUser}
							role ={assumedRole}
						/>
					</SpaceBetween>
				</ContentLayout>
	)
}

export default BridgingCommentaryPage;
