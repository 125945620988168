import styled from "styled-components";

export const Border = styled.div`
  border-top: thin-solid;
`

export const AlignedText = styled.div<{align: 'left' | 'right'}>`
    width: 100%;
    text-align: ${props => props.align};
    padding-right: 8px;
    `

export const BYORReportStyle = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: 2em;
  height : 85vh
`

export const LeftAlignedHeader = styled.div`
    text-align: left;
`