import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import {TreeTable} from "src/components/Common/TreeTable";
import {AppState} from "src/reducers/AppState";
import {
    forPublishedBridgeItems,
    getBridgeBeingViewedCurrently
} from "src/reducers/bridgingCommentary.reducer";
import styled from "styled-components";

const Div = styled.div`
  overflow: hidden;
`;

const BridgeTreeTable = ()=>{
    const bridgeBeingViewedCurrently = useSelector(getBridgeBeingViewedCurrently, shallowEqual);
    const headers = bridgeBeingViewedCurrently.bridge_item.variance_headers;

    const {arr} = useSelector((s: AppState) => forPublishedBridgeItems(s))

    return <Div>
        <TreeTable data={arr} headerData={headers}/>
    </Div>

}

export default BridgeTreeTable;

