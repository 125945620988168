import {SpaceBetween} from "@amzn/awsui-components-react";
import React from 'react'
import {nonDollarValueRowItems} from "src/components/FillOutBridgePage/InputComponents";
import {BridgeSubItem, defaultBridgeSubItem} from "src/components/FillOutBridgePage/interfaces";
import {ViewBridgeTableProps} from "src/components/ViewReportPage/SingleBridgeView/interfaces";
import {Table, TBody, TBodyRow, TDCell, THCell, THead} from "src/components/ViewReportPage/SingleBridgeView/Styled";
import {TableStatus} from "src/components/ViewReportPage/SingleBridgeView/TableSubComponents";
import {formatValue} from "src/utils/bridgingHelpers";

export const deltaSymbolUnicode = '\u0394'; // Unicode for greek letter delta

const ViewBridgeTable = (props: ViewBridgeTableProps) => {
    const {bridgeItem, subItems=[], loading=false, expandedView, setExpandedView} = props
    // constants
    const isNotHeadCountBridge = !nonDollarValueRowItems.has(bridgeItem.account_rollup);
    // This should always be present since it is initialized with bridge
    // Adding default to prevent errors in the case that APIs are loading
    const firstSubItem = subItems[0] || defaultBridgeSubItem
    // Helpers
    const generateTableHeadRow = (notHeadCountBridge: boolean) => {
        return (
            <tr key={0}>
                <THCell className='name-column'>Report / Line Item Bridge</THCell>
                { notHeadCountBridge && <THCell className='symbol-column'/>}
                <THCell className='number-column'>{notHeadCountBridge ? '$ ': ''}{deltaSymbolUnicode}</THCell>
                <THCell>Commentary</THCell>
            </tr>
        );
    };

    const generateBridgeSubRows = (notHeadCountBridge: boolean) => {
        return (<>
            <TBodyRow key={3} className="emphasized-row bolded">
                <TDCell className="name-cell">{bridgeItem.variance_headers.right_header}</TDCell>
                {notHeadCountBridge && <TDCell className="symbol-cell">$</TDCell>}
                <TDCell className="number-cell">
                    {formatValue(firstSubItem.rightValue, isNotHeadCountBridge)}
                </TDCell>
                <TDCell/>
            </TBodyRow>
            {subItems.slice(1).map((item: BridgeSubItem, index) => {
                return (
                    <TBodyRow key={index + 4} className={subItems.length === index + 2 ? "" : "bottom-border"}>
                        <TDCell className="name-cell left-padded">{item.name}</TDCell>
                        {notHeadCountBridge && <TDCell className="symbol-cell">$</TDCell>}
                        <TDCell className="number-cell">
                            {formatValue(item.varianceValue, isNotHeadCountBridge)}
                        </TDCell>
                        <TDCell className={'commentary-cell'}>{item.commentary}</TDCell>
                    </TBodyRow>
                );
            })}
            <TBodyRow key={subItems.length + 1} className={"emphasized-row bolded"}>
                <TDCell className="name-cell">{bridgeItem.variance_headers.left_header}</TDCell>
                {notHeadCountBridge && <TDCell className="symbol-cell">$</TDCell>}
                <TDCell className="number-cell" >
                    {formatValue(firstSubItem.leftValue, isNotHeadCountBridge)}
                </TDCell>
                <TDCell/>
            </TBodyRow>
        </>);
    }

    const generateTableBodyRows = (notHeadCountBridge: boolean) => {
        return (<>
            <TBodyRow key={1} className="emphasized-row">
                <TDCell className="name-cell">{bridgeItem.account_rollup}</TDCell>
                {notHeadCountBridge && <TDCell className="symbol-cell">$</TDCell>}
                <TDCell className="number-cell">
                    {formatValue(bridgeItem.variance_data.variance, isNotHeadCountBridge)}
                </TDCell>
                <TDCell className={'commentary-cell'}>{subItems[0].commentary}</TDCell>
            </TBodyRow>
            <TBodyRow>
                <TDCell className="button-cell" onClick={() => setExpandedView(!expandedView)}>
                    {`${expandedView ? "- Collapse" : "+ Expand"} variance drivers`}
                </TDCell>
            </TBodyRow>
            {expandedView && generateBridgeSubRows(notHeadCountBridge)}
        </>)
    };

    return (
        <SpaceBetween direction={"vertical"} size={"xs"}>
            <Table>
                <THead>{generateTableHeadRow(isNotHeadCountBridge)}</THead>
                <TBody>{subItems.length > 0 && generateTableBodyRows(isNotHeadCountBridge)}</TBody>
            </Table>
            <TableStatus loading={loading}/>
        </SpaceBetween>
    );
}

export default ViewBridgeTable;