import styled from "styled-components";

interface CommentVersionContainerProps {
    marginLeft?: string,
    marginRight?: string,
    dropdown?: boolean,
}

export const CommentVersionContainer = styled.div<CommentVersionContainerProps>`
  // Sizing
  margin-left: ${props => props.marginLeft || '3%'};
  margin-right: ${props => props.marginRight || '2%'};
  ${props => !props.dropdown && 'width: 100%'};
  
  // Text
  font-family: "Amazon Ember";
  
  // Positioning
  position: relative;
  overflow: clip;
  
  // Border
  border-radius: ${props => props.dropdown ? '0 0': '1rem 1rem'} 1rem 1rem;
  
  //Shadows
  ${props => props.dropdown && 'clip-path: inset(0px -10px -10px -10px);'}
  box-shadow:0 0 .06em .06em #e9ebed, 0 .06em .5em .12em rgba(0, 7, 22, 0.12);
`;

// Content Containers
const contentAlignItems = 'center';
const contentDisplay = 'grid';

export const LineItemHistoryContent = styled.div`
  align-items: ${contentAlignItems};
  display: ${contentDisplay};

  // Grid
  grid-template-columns: 2fr 1fr 10fr auto;
  grid-column-gap: .1em;
`

export const FollowUpContent = styled.div`
  padding: 0.5em 0em 0.5em 1em;
  align-items: ${contentAlignItems};
  display: ${contentDisplay};
  
  // Grid
  grid-template-columns: 1fr auto;
  grid-column-gap: 2em;

  border-left: 3px solid #0972d3;
`

export const VersionTreeContent = styled.div`
  padding: 1em 1.5em 1em 1.5em;
  align-items: ${contentAlignItems};
  display: ${contentDisplay};
  
  // Grid
  grid-template-columns: auto 11fr auto;
  grid-column-gap: .6em;
  
  i {
    grid-area: 2 / 1 / 2 / 3;
    color: #808080;
    padding-top: .5em;
    font-size: 80%;
  }
`

export const Metadata = styled.div`
  // Grid
  display: ${contentDisplay};
  grid-template-rows : 50% 50%;
  padding: 1em;
  
  // Text
  color: grey;
  place-items: center;
  font-size: 12px;
  font-weight: 350;
`

export const CommentaryHistory = styled.span`
    padding: .5em 1em;
`

export const ValueHistory = styled.span`
  display: flex;
  place-items: center;
  justify-content: center;

  height: 100%;
  padding: .5em;
  
  box-shadow: rgb(233, 235, 237) 0 0 .06em .06em, rgba(0,7,22,0.12) 0 .06em .5em .12em;
`;

export const SubItemHistory = styled.b`
  display: flex;
  place-items: center;
  overflow: hidden;
  
  height: 100%;
  padding: .5em 1em;
  
  text-overflow: ellipsis;
  background: #f2f8fd;
`