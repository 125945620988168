import {Icon} from "@amzn/awsui-components-react";
import React from "react";
import {useSelector} from "react-redux";
import {
    defaultRow,
    getCurrentBridgeItem, mergeRowIds,
    mostRecentCommenter
} from "src/components/FillOutBridgePage/CommentVersioning/BridgeComparisonHelpers";
import {
    buildFirstVersionRow, buildLastVersionRow,
    buildMiddleVersionRow,
    firstVersionFooter,
    firstVersionHeader, lastVersionFooter, lastVersionHeader,
    middleVersionFooter,
    middleVersionHeader
} from "src/components/FillOutBridgePage/CommentVersioning/BridgeComparisonsHelpers";
import {
    AllSubBridgeItems,
    BridgeComparisonsFieldSet,
    ContentDiv,
} from "src/components/FillOutBridgePage/CommentVersioning/BridgeComparisonStyles";
import {FooterInstructions} from "src/components/FillOutBridgePage/CommentVersioning/FooterInstructions";
import {IconWrapper} from "src/components/FillOutBridgePage/CommentVersioning/ItemHistoryDeepDive";
import {BridgeHistory, BridgeSubItem} from "src/components/FillOutBridgePage/interfaces";
import {AppState} from "src/reducers/AppState";
import {
    forMostRecentCommenterInHistory, forPreviousHistoryId,
    forRowIdsInHistory
} from "src/reducers/bridgingCommentary.reducer";
import styled from "styled-components";

interface BridgeComparisonsProps {
    dismiss: () => void,
    unselect: () => void,
    selectedHistoryId: string,
    bridgeId: string,
    setSelectedRow: Function,
    subItems: BridgeSubItem[],
    index: number,
    historyIds: string[],
    history: BridgeHistory
}

const InfoDiv = styled.div`
  padding-top: 1em;
  padding-left: 1em;
`

const BridgeComparisons = ({selectedHistoryId, index, bridgeId, subItems, dismiss, unselect, setSelectedRow, historyIds, history}: BridgeComparisonsProps) => {
    const previousHistoryId = useSelector((state: AppState) => forPreviousHistoryId(state, bridgeId, index));
    const previousRowIds = useSelector((state: AppState) => forRowIdsInHistory(state, bridgeId, previousHistoryId))
    const selectedRowIds = useSelector((state: AppState) => forRowIdsInHistory(state, bridgeId, selectedHistoryId))
    const mergedRowIds =  mergeRowIds(previousRowIds, selectedRowIds);
    const previousMostRecentCommenter = useSelector((state: AppState) => forMostRecentCommenterInHistory(state, bridgeId, previousHistoryId))
    const selectedMostRecentCommenter = useSelector((state: AppState) => forMostRecentCommenterInHistory(state, bridgeId, selectedHistoryId))

    const currentBridgeItem = getCurrentBridgeItem(subItems)

    const draftVersionStarted = 'latest' in history;
    const isLatestVersion = (selectedHistoryId === 'latest' || !selectedHistoryId)
    const isFirstVersion = index === 0;
    if(!historyIds.length) return null;

    const legendMapping: any = {
        default: `Changes in Version ${index < 0 ? historyIds.length : index + 1 }`,
        fistVersion: `Version 1`,
        latest: `Things to note for Version ${historyIds.length + 1}`
    }

    return <BridgeComparisonsFieldSet>
        <legend>{legendMapping[isFirstVersion ? 'fistVersion' : isLatestVersion && draftVersionStarted ? 'latest' : 'default']}</legend>
        <ContentDiv>
            <InfoDiv>
                <IconWrapper><Icon size={'normal'} name={'status-info'}/></IconWrapper>
                <span>
                    The change log below shows each line item from the bridge in the current version and previous version. Next
                    to each line item is previous follow up and the values from the current and previous version. Click on the
                    magnifying glass to do a deep dive into the line item.
                </span>
            </InfoDiv>
            {/******* Commenting out below for later use ************/}
            {/*<SummaryDetails open={true}>*/}
            {/*    <summary>Help</summary>*/}
            {/*    <p>*/}
            {/*    </p>*/}
            {/*</SummaryDetails>*/}
            {isFirstVersion ?
                <AllSubBridgeItems>
                    {firstVersionHeader(index)}
                    {selectedRowIds.map((rowId, idx) => buildFirstVersionRow(
                        rowId,
                        history[historyIds[0]][rowId],
                        idx,
                        setSelectedRow, unselect
                    ))}
                    {firstVersionFooter(selectedMostRecentCommenter, selectedRowIds.length)}
                </AllSubBridgeItems>
                :
                index < historyIds.length - 1 || (draftVersionStarted && index < historyIds.length)?
                    <AllSubBridgeItems>
                        {middleVersionHeader(index)}
                        {mergedRowIds.map((rowId, idx) => buildMiddleVersionRow(
                            rowId,
                            history[selectedHistoryId][rowId] || defaultRow,
                            history[previousHistoryId][rowId] || defaultRow,
                            idx,
                            setSelectedRow, unselect
                        ))}
                        {middleVersionFooter(previousMostRecentCommenter, selectedMostRecentCommenter, mergedRowIds.length)}
                    </AllSubBridgeItems>
                    :
                    <AllSubBridgeItems>
                        {lastVersionHeader(draftVersionStarted, index)}
                        {mergedRowIds.map((rowId, idx) => buildLastVersionRow(
                            draftVersionStarted,
                            rowId,
                            currentBridgeItem[rowId] || defaultRow,
                            history[previousHistoryId][rowId] || defaultRow,
                            idx,
                            setSelectedRow, unselect
                        ))}
                        {lastVersionFooter(previousMostRecentCommenter, mostRecentCommenter(currentBridgeItem), mergedRowIds.length)}
                    </AllSubBridgeItems>
        }
        </ContentDiv>
        <FooterInstructions dismiss={dismiss} state={'bridgeComparison'}/>
    </BridgeComparisonsFieldSet>
};

export default BridgeComparisons;