import {Roles} from "src/common/roles";
import {BridgeItem, BridgeStatusType} from "src/components/BridgingCommentary/interfaces";


export interface StatusBoxProps {
    bridges : BridgeItem[],
    bridgeStatus : AdminTabStatus | OwnerTabStatus,
    bridgeTabs : bridgeTabType
}

export interface bridgeTabType {
    [tabStatus : string] :{
        pastDue : boolean, bridgeStatusType : BridgeStatusType[]
    }
}

export interface SummaryProps {
    bridges : BridgeItem[],
    role : Roles | null
}
export enum AdminTabStatus{
    Requested  = "Requested",
    InProgress = "In-Progress",
    Submitted = "Submitted",
    Approved = "Approved",
    Published = "Published",
    PastDue = "Past Due",
    All = "All"
}

export enum OwnerTabStatus{
    Requested  = "Requested",
    Submitted = "Submitted",
    Approved = "Approved",
    PastDue = "Past Due",
    All = "All"
}
