const switchApiGw = (host: string): string => {
    console.log(host)
    return `https://${host}/api`
}

const currentApi = switchApiGw(window.location.host);

const EXECUTIVE_PORTAL_API = currentApi;
const JOB = (group: string, job_id: string) => `${EXECUTIVE_PORTAL_API}/job_group/${group}/job/${job_id}`
const JOB_GROUP = (group: string) => `${EXECUTIVE_PORTAL_API}/job_group/${group}`
const JOB_GROUPS = `${EXECUTIVE_PORTAL_API}/job_groups`
const SCENARIOS_METADATA = `${EXECUTIVE_PORTAL_API}/scenarios_metadata`
const USER_ROLES = `${EXECUTIVE_PORTAL_API}/user_roles`
const REPORT = `${EXECUTIVE_PORTAL_API}/report`
const REPORT_DEFINITIONS = `${EXECUTIVE_PORTAL_API}/report/definitions`
const REVIEW_FOLDERS = `${EXECUTIVE_PORTAL_API}/review_folders`
const REPORT_PUBLISHER = `${EXECUTIVE_PORTAL_API}/report/publisher`
const FILE_TRANSFER = `${EXECUTIVE_PORTAL_API}/file_transfer`
const REPORT_LIBRARY = `${EXECUTIVE_PORTAL_API}/report/library`
const SPAN_TITLES = `${EXECUTIVE_PORTAL_API}/report/span_titles`
const SYNCHRONIZATION = `${EXECUTIVE_PORTAL_API}/report/synchronization`
const REPORT_EXPORT = `${EXECUTIVE_PORTAL_API}/report/export`
const REPORT_STATUS = `${EXECUTIVE_PORTAL_API}/report/status`
const BRIDGE_OWNER_GROUPS = `${EXECUTIVE_PORTAL_API}/bridge_owner_groups`
const BRIDGE = (bridge_id: string) => `${EXECUTIVE_PORTAL_API}/v2/bridge/${bridge_id}`
const BRIDGES = `${EXECUTIVE_PORTAL_API}/v2/bridges`
const REPORT_BRIDGES = (report_id: string) => `${EXECUTIVE_PORTAL_API}/v2/bridges/report/${report_id}`
const REPORT_BRIDGES_SYNC = (report_id: string) => `${REPORT_BRIDGES(report_id)}/sync`
const CHANGE_BRIDGE_STATE = (bridge_id: string) => `${EXECUTIVE_PORTAL_API}/v2/bridge/${bridge_id}/change_state`
const COMMENTS = (bridge_id: string) => `${EXECUTIVE_PORTAL_API}/v2/bridge/${bridge_id}/comments`
const COMMENTS_HISTORY = (bridge_id: string) => `${EXECUTIVE_PORTAL_API}/v2/bridge/${bridge_id}/comments/history`
const MARKERS = `${EXECUTIVE_PORTAL_API}/v2/markers`
const EMPLOYEE_INFORMATION = `${EXECUTIVE_PORTAL_API}/employee_information`
const THEMES = `${EXECUTIVE_PORTAL_API}/themes`
const THEME = (theme_id: string) => `${THEMES}/${theme_id}`
const THEME_ITEMS = (theme_id: string) => `${THEME(theme_id)}/items`
const THEME_EXPORT = (theme_id: string) => `${THEME(theme_id)}/export`

const DELETE_WORKFLOW = (workflow_id : string, workflow_version : string, execution_id : string) => `${EXECUTIVE_PORTAL_API}/boatswain/workflows/${workflow_id}/version/${workflow_version}/executions/${execution_id}`
export const API_PATH = {
    EMPLOYEE_INFORMATION,
    JOB,
    JOB_GROUP,
    JOB_GROUPS,
    SCENARIOS_METADATA,
    USER_ROLES,
    REPORT,
    REPORT_STATUS,
    REPORT_EXPORT,
    REPORT_DEFINITIONS,
    REPORT_BRIDGES_SYNC,
    REVIEW_FOLDERS,
    REPORT_PUBLISHER,
    REPORT_LIBRARY,
    SPAN_TITLES,
    SYNCHRONIZATION,
    FILE_TRANSFER,
    BRIDGE,
    BRIDGES,
    REPORT_BRIDGES,
    BRIDGE_OWNER_GROUPS,
    CHANGE_BRIDGE_STATE,
    COMMENTS,
    COMMENTS_HISTORY,
    MARKERS,
    THEMES,
    THEME,
    THEME_ITEMS,
    THEME_EXPORT,
    DELETE_WORKFLOW
}