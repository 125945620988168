import {Link} from "@amzn/awsui-components-react";
import React from "react";
import {Roles} from "src/common/roles";
import Paths from "src/components/PageConfig/Paths";

export const ReportLink = (props: {reportName: string, reportId: string, role: Roles | null}) => {
    return ( props.role === Roles.Admin ?
            <Link href={`#${Paths.VIEWREPORT}?report_id=${props.reportId}`} target="_blank" external={true}>
                {props.reportName}
            </Link>
            : <>{props.reportName}</>
    )
}