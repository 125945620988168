import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import styled from 'styled-components'

export const Table = styled.table`
  border-collapse: collapse;
  color: ${awsui.colorTextBodyDefault};
  font-family: ${awsui.fontFamilyBase};
  table-layout: fixed; // non-responsive after load
  white-space: nowrap; // not wrapping as a global rule
  --table-header-bg-color :#005eb9;
  --outline-color : #1388f0;
`;

export const THead = styled.thead`
  height: 4em; 
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const THCell = styled.th`
  border-bottom: 1px solid gray;
  padding: 0 1em;
  background: var(--table-header-bg-color);
  color: white;
  white-space: nowrap; // wrap as this tends to be long
  width: auto;  // Use auto for commentary column to use remaining width available
  &.name-column {
    width: 25%;
  }
  &.symbol-column {
    width:10px;
    padding: 0;
  }
  &.number-column {
    width: 75px;
    text-align: right;
    font-size: 16px;
    padding: 0 0.2em;
  }
`;

export const TBody = styled.tbody`
  transition: background 0.2s ease-in;
`;

export const TBodyRow = styled.tr`
  border: none;   
  &.emphasized-row {
    background: whitesmoke;
  }
  &.bolded {
    font-weight: bold;
  }
  &.empty-row {
    height: 1em;
  }
  &.bottom-border td {
    border-bottom: solid 1px lightgrey;
  }
`;

export const TDCell = styled.td`
  padding: 2px 1em;
  border-top: thin solid transparent;
  border-bottom: thin solid transparent;
  border-right: thin solid transparent;
  vertical-align: baseline; // Always start the cell content from first row
  
  &.name-cell {
    white-space: normal;
  } 
  &.symbol-cell {
    padding: 2px 0;
  }
  &.number-cell {
    text-align: right;
    padding: 2px 0.2em;
  }
  &.commentary-cell {
    white-space: normal;
  }
  &.button-cell {
    font-style: italic;
    cursor: pointer;
    &:hover {
      background: aliceblue;
    }
    &:active{
      background: lightgrey;
    }
  }
  &.left-padded {
    padding: 2px 2em;
  }
`;

export const TableHeader = styled.div`
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between; /* stay at both ends*/
  border-bottom: solid 2px #00000014; /* same as footer line*/
  padding-bottom: 0.1em;
  margin-bottom: 1em;
  align-items: end; /* sit on the ground or start from the ground*/
`;

export const LegendList = styled.ul<{textAlign?: string}>`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: ${props => props.textAlign || "right"};

  li {
    padding: 2px;
    font-weight: normal;
    font-style: italic;
    line-height: 12px;
  }
`;

export const BridgeName = styled.div` 
  display: flex;
  column-gap: 0.4em;
  flex-wrap: wrap;
`;

export const ModalContentDiv = styled.div`
  height: 70vh;
  border: solid 1px lightgray;
  border-radius: 10px;
  overflow: auto;
`;