import {Button, Header, Modal} from "@amzn/awsui-components-react";
import Link from "@amzn/awsui-components-react/polaris/link";
import React from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {setViewAllBridgesModalVisible} from "src/actions/bridgingCommentary.actions";
import LargeLoadingOverlay from "src/components/Common/LargeLoadingOverlay";
import Paths from "src/components/PageConfig/Paths";
import BridgeTreeTable from "src/components/ViewReportPage/BridgeModals/BridgeTreeTable";
import {ContributorMetaData} from "src/components/ViewReportPage/BridgeModals/ContributorMetaData";
import HeaderWithCrumbs from "src/components/ViewReportPage/BridgeModals/HeaderWithCrumbs";
import ScrollableCommentaryPanel from "src/components/ViewReportPage/BridgeModals/ScrollableCommentaryPanel";
import {LoadingProps, ViewAllBridgesModalProps} from "src/components/ViewReportPage/interfaces";
import {
    getBridgesBeingViewedCurrentlyLoading,
    getCommentsForVarianceColumnLoading
} from "src/reducers/apiLoading.reducer";
import {getBridgeBeingViewedCurrently} from "src/reducers/bridgingCommentary.reducer";
import {forFinancialCycleAndYearOfReport} from "src/reducers/reportLibrary.reducer";
import {humanizeEpoch} from "src/utils/timeHelpers";
import styled, {css} from "styled-components";

export const TwoPanelLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  column-gap: 15px;
`;

const LeftSidePanel = styled.div`
  border: solid 1px lightgray;
  border-top-left-radius: 1rem; // neat contours
  border-bottom-left-radius: 1rem; // neat contours
  border-right: none;
  overflow: scroll;
  height: 64vh; // this is determined as its parent had something similar hard-coded.
`;

const RightSidePanel = styled.div`
  display: grid;
  grid-template-rows: auto 1fr 2em; // three sections. header, commentary paragraph, metadata
  border: solid 1px lightgray;
  border-top-right-radius: 1rem; // neat contours
  border-bottom-right-radius: 1rem; // neat contours
  border-left: none;
  padding: 0 0 0.2em 0;
  overflow: hidden;
`;

/* Now let's build the Modal Header.
*  this has titles and legends
* */

const SubHeaderDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between; /* stay at both ends*/
  border-bottom: solid 2px #00000014; /* same as footer line*/
  padding-bottom: 0.1em;
  margin-bottom: 1em;
  align-items: end; /* sit on the ground or start from the ground*/
`;

const LegendList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: right;

  li {
    padding: 2px;
    font-weight: normal;
    font-style: italic;
    line-height: 12px;
  }
`;

interface headerProps {
    report_id: string,
    report_name: string,
    variance_header: string,
}

const ThisModalHeader = (props: headerProps) => {
    const {report_id, report_name, variance_header} = props;
    return (
        <Header>
            {` All bridges of ${variance_header} from `}
            <Link href={`#${Paths.VIEWREPORT}?report_id=${report_id}`}
                  target="_blank"
                  fontSize={"heading-l"}>
                {report_name}
            </Link>
        </Header>)
}

interface footerProps {
    onModalDismiss: () => void
}

const FooterDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    >span{ // only direct child and does not affect Close button
      font-size: smaller;
      font-weight: 400;
    }
`

const ThisModalFooter = (props: footerProps) => {
    const {onModalDismiss} = props;
    return <FooterDiv>
        <span>Navigate table with Arrow Up/Down and hit Enter/Space to view commentary</span>
        <Button onClick={onModalDismiss}>Close</Button>
    </FooterDiv>
}

interface subHeaderProps {
    finance_cycle: string,
    year: number,
    last_modified_by: string,
    last_modified_timestamp: number
}

const SubHeaderText = (props: subHeaderProps) => {
    const {finance_cycle, year, last_modified_by, last_modified_timestamp} = props;
    /* This sits below Polaris Header. And has custom css.*/
    const cycleAndCurrencyMessage = `${finance_cycle} ${year}`;
    const subHeaderText = `Report published at ${humanizeEpoch({
        epoch: last_modified_timestamp, asDate: true
    })} by ${last_modified_by}@`;
    return (<SubHeaderDiv>
        {/* these two lines appear beneath header in small font */}
        <span>{subHeaderText}</span>
        <LegendList>
            <li>{cycleAndCurrencyMessage}</li>
            <li>All values in USD MM</li>
        </LegendList>
    </SubHeaderDiv>)
}
/* end of Modal Header */

/* Modal Content starts
* this content will take a lot of space on the UI and remain at fixed height*/

const ModalContentDiv = styled.div<LoadingProps>`
  height: 70vh;
  display: grid;
  // while loading this complex modal, place an overlay.
  // this is necessary only if overlay animations are needed with opacity or frosted or filler.
  .loading-overlay, .modal-container {
    grid-area: 1/1;
  }

  ${props => props.loading ? css`
    .modal-container {
      display: none;
    }
  ` : css`
    .loading-overlay {
      display: none;
    }
  `
  };
`;
/* end of */


const ModalContent = (props: any) => {
        // loading state trackers while our slow APIs run
        const commentsForVarianceColumnLoading = useSelector(getCommentsForVarianceColumnLoading, shallowEqual);
        const bridgesBeingViewedCurrentlyLoading = useSelector(getBridgesBeingViewedCurrentlyLoading, shallowEqual);
        const _loadingModal = commentsForVarianceColumnLoading || bridgesBeingViewedCurrentlyLoading;

        const loadingStyleProps = {loading: _loadingModal};
        return <ModalContentDiv id={"modal-bridge-content"} {...loadingStyleProps}>
            <LargeLoadingOverlay/>
            <div className={"modal-container"}>
                <SubHeaderText {...props} />
                <TwoPanelLayout id={"two-panel-modal"}>
                    <LeftSidePanel id={"left-side-panel"}>
                        <BridgeTreeTable/>
                    </LeftSidePanel>
                    <RightSidePanel id={"right-side-panel"}>
                        <HeaderWithCrumbs/>
                        <ScrollableCommentaryPanel/>
                        <ContributorMetaData/>
                    </RightSidePanel>
                </TwoPanelLayout>
            </div>
        </ModalContentDiv>
    }
;


const ViewAllBridgesModal = (props: ViewAllBridgesModalProps) => {
        const {visible} = props;
        const dispatch = useDispatch();

        const onModalDismiss = () => {
            dispatch(setViewAllBridgesModalVisible(false));
        };

        const {finance_cycle, year} = useSelector(forFinancialCycleAndYearOfReport, shallowEqual);
        const bridgeBeingViewedCurrently = useSelector(getBridgeBeingViewedCurrently, shallowEqual);
        const {variance_header} = bridgeBeingViewedCurrently.bridge_item.variance_headers;
        const {report_id, report_name, last_modified_timestamp, last_modified_by} = bridgeBeingViewedCurrently.bridge_item
        const modalHeaderProps = {
            report_id,
            report_name,
            variance_header,
            finance_cycle,
            year,
            last_modified_by,
            last_modified_timestamp
        };

        return (
            <Modal
                onDismiss={onModalDismiss}
                visible={visible}
                size="max"
                closeAriaLabel="Close modal"
                header={<ThisModalHeader {...modalHeaderProps}/>}
                footer={<ThisModalFooter onModalDismiss={onModalDismiss}/>}
            >
                <ModalContent {...modalHeaderProps}/>
            </Modal>
        );
    }
;

export default ViewAllBridgesModal;

