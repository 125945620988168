import { Container, Header} from "@amzn/awsui-components-react";
import React from "react";
import {Roles} from "src/common/roles";
import {SummaryProps} from "src/components/BridgingCommentary/summaryComponents/interfaces";
import {AdminView, BridgeOwnerView} from "src/components/BridgingCommentary/summaryComponents/SummaryLayout";

const BridgingCommentarySummary = (props : SummaryProps) => {
    const {bridges, role} = props;
    return (
        <Container
            data-testid={'bridging-commentary-summary'}
            header={
                <Header
                    variant="h1"
                >
                    Summary
                </Header>
            }
        >
            {(role== Roles.Admin) ? AdminView(bridges) : BridgeOwnerView(bridges)}
        </Container>);
}

export default BridgingCommentarySummary;
