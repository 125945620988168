import {
  Cell,
  Column,
  ColumnDef,
  InlineEditingDef,
  InlineEditingType,
  InlineEditSelectDetail,
  InlineEditTextStyle,
  Row,
  TableEvent
} from "@amzn/aws-fintech-fluid-table";
import {Dictionary} from "@reduxjs/toolkit";
import React from "react";
import {ThemeColumn, ThemeItem, ThemeItemType, ThemeStates} from "src/components/Themes/interfaces";
import {DisplayedThemeItem} from "src/components/Themes/table/interfaces";
import {parseLocation} from "src/components/Themes/table/ordering";
import {AlignedBold, AlignedItalic, AlignedText} from "src/components/Themes/table/styles";
import {formatValue} from "src/utils/bridgingHelpers";

const configureColumnSize = (column: string) => {
  const minColumnSize = 130
  const defaultColumnSize = 130
  const defaultMaxColumnSize = 300
  const columnSizeMapping: Dictionary<{ default: number, max: number }> = {
    'commentary': {default: 600, max: 1000},
  }

  return {
    width: columnSizeMapping[column]?.default || defaultColumnSize,
    maxWidth: columnSizeMapping[column]?.max || defaultMaxColumnSize,
    minWidth: minColumnSize,
    resizable: true
  }
}

const cellRendererFn = (isTextColumn: boolean, cell: Cell<ThemeItem>, state: ThemeStates) => {
  const value = typeof cell.value === 'number' ? formatValue(cell.value, false, false): cell.value
  const alignement = isTextColumn ? 'left' : 'right'
  switch(cell.row.data.type){
    case ThemeItemType.BRIDGE_SUB_ITEM:
      const isTopLevel = parseLocation(cell.row.rowId).length === 1
      return isTopLevel && state === ThemeStates.Published ?
        <AlignedText align={alignement}>{value}</AlignedText> :
        <AlignedItalic align={alignement}>{value}</AlignedItalic>
    case ThemeItemType.VARIANCE:
      return <AlignedItalic align={'right'}>{value}</AlignedItalic>
    case ThemeItemType.GROUPING:
      return <AlignedBold align={alignement}>{value}</AlignedBold>
    case ThemeItemType.BRIDGE:
      return <AlignedBold align={alignement}>{value}</AlignedBold>
    default:
      return <AlignedText align={'right'}>{value}</AlignedText>
  }
}

const createColumnDefs = (
  columns: ThemeColumn[],
  columnMapping: Dictionary<string>,
  state: ThemeStates,
  onInlineEdit?: (event: TableEvent<InlineEditSelectDetail<ThemeItem>>) => void,
): ColumnDef<ThemeItem>[] => {
  const textColumns: {[k: string]: keyof DisplayedThemeItem} = {'commentary': 'displayedCommentary'}
  const valueFormatterFn = (value: any) => value ? value : ""
  const editableCellTypes = new Set([ThemeItemType.BRIDGE_SUB_ITEM, ThemeItemType.GROUPING])

  return columns.map(c => {
    const column = c.name as keyof ThemeItem
    const isTextColumn = column in textColumns
    const valueAccessorFn = isTextColumn ?
      (row: Row<DisplayedThemeItem>) => row.data[textColumns[column]] || row.data[column]:
      (row: Row<ThemeItem>) => row.data.values[column]

    const inlineEditing: InlineEditingDef<ThemeItem> | undefined = isTextColumn && onInlineEdit ? {
      inlineEditingType: InlineEditingType.TEXT,
      onSelect: (event) => onInlineEdit(event),
      inlineEditableFn: (cell) => editableCellTypes.has(cell.row.data.type),
      textStyling: (cell) => cell.row.data.type === ThemeItemType.BRIDGE_SUB_ITEM ? InlineEditTextStyle.ITALIC : InlineEditTextStyle.BOLD,
  } : undefined

    return {
      columnId: c.id,
      valueAccessorFn,
      headerRendererFn: (column: Column) => isTextColumn ? "" : columnMapping[column.columnId],
      valueFormatterFn,
      cellRendererFn: (cell) => cellRendererFn(isTextColumn, cell, state),
      columnSizing: configureColumnSize(column),
      inlineEditing,
    }
  })
}

export default createColumnDefs