import {ColumnLayout} from "@amzn/awsui-components-react";
import React from "react";
import {adminBridgeTabStates, ownerBridgeTabStates} from "src/components/BridgingCommentary/constants";
import {BridgeItem} from "src/components/BridgingCommentary/interfaces";
import {AdminTabStatus, OwnerTabStatus} from "src/components/BridgingCommentary/summaryComponents/interfaces";
import {
    buildApprovedStatusBox, buildPublishedStatusBox,
    buildStatusIndicatorBox,
    buildSubmittedStatusBox
} from "src/components/BridgingCommentary/summaryComponents/StatusIndicatorBox";

const adminStatusCount = 6;
const ownerStatusCount = 4;
const columnWidth = 92;

export const BridgeOwnerView = (bridges : BridgeItem[]) => {
    return (<ColumnLayout columns={ownerStatusCount} variant="text-grid" minColumnWidth={columnWidth}>
        {buildStatusIndicatorBox({bridges : bridges,  bridgeStatus : OwnerTabStatus.Requested, bridgeTabs : ownerBridgeTabStates})}
        {buildSubmittedStatusBox({bridges : bridges,  bridgeStatus : OwnerTabStatus.Submitted, bridgeTabs : ownerBridgeTabStates})}
        {buildApprovedStatusBox({bridges : bridges,  bridgeStatus : OwnerTabStatus.Approved, bridgeTabs : ownerBridgeTabStates})}
        {buildStatusIndicatorBox({bridges : bridges,  bridgeStatus : OwnerTabStatus.PastDue, bridgeTabs : ownerBridgeTabStates})}
    </ColumnLayout>)
}
export const AdminView = (bridges : BridgeItem[]) =>   { return (<ColumnLayout columns={adminStatusCount} variant="text-grid" minColumnWidth={columnWidth}>
    {buildStatusIndicatorBox({bridges : bridges,  bridgeStatus : AdminTabStatus.Requested, bridgeTabs : adminBridgeTabStates})}
    {buildStatusIndicatorBox({bridges : bridges,  bridgeStatus : AdminTabStatus.InProgress, bridgeTabs : adminBridgeTabStates})}
    {buildSubmittedStatusBox({bridges : bridges,  bridgeStatus : AdminTabStatus.Submitted, bridgeTabs : adminBridgeTabStates})}
    {buildStatusIndicatorBox({bridges : bridges,  bridgeStatus : AdminTabStatus.Approved, bridgeTabs : adminBridgeTabStates})}
    {buildPublishedStatusBox({bridges : bridges,  bridgeStatus : AdminTabStatus.Published, bridgeTabs : adminBridgeTabStates})}
    {buildStatusIndicatorBox({bridges : bridges,  bridgeStatus : AdminTabStatus.PastDue, bridgeTabs : adminBridgeTabStates})}
</ColumnLayout>)}
