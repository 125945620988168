import {DatePicker, TimeInput} from "@amzn/awsui-components-react";
import React, {useState} from "react";
import {getDateMinusOne} from "src/components/Bridging/AssignBridgeItems";
import {TimeWidgetProps} from "src/components/BridgingCommentary/modalComponents/interfaces";
import {CustomGrid} from "src/components/BridgingCommentaryPage/BridgeDetailsModal";
import {epochToDate, epochToHhMm, getDateTime, validateDueDate} from "src/utils/timeHelpers";

export const CustomDatePicker = (props :TimeWidgetProps) => {
    const {bridgeDetails, setBridgeDetails} = props;

    const [dueDate, setDueDate] = useState(epochToDate(bridgeDetails.due_timestamp));
    const [dueTime, setDueTime] = useState(epochToHhMm(bridgeDetails.due_timestamp));
    const validDueDate = validateDueDate(dueDate, dueTime);

    return (<CustomGrid columnDistribution="50% 30% auto">
        <DatePicker
            value={dueDate}
            onChange={({detail}) => {
                setDueDate(detail.value)
                if (validateDueDate(detail.value, dueTime)) {
                    const updatedDueDate = getDateTime(detail.value, dueTime)
                    setBridgeDetails({...bridgeDetails, due_timestamp: updatedDueDate});
                }
            }}
            isDateEnabled={date =>
                // Past dates and week ends are not allowed
                date.getTime() >= getDateMinusOne().getTime()
            }
            openCalendarAriaLabel={selectedDate =>
                "Choose certificate expiry date" +
                (selectedDate
                    ? `, selected date is ${selectedDate}`
                    : "")
            }
            nextMonthAriaLabel="Next month"
            placeholder="yyyy/mm/dd"
            previousMonthAriaLabel="Previous month"
            todayAriaLabel="Today"
            invalid={!validDueDate}
        />
        <TimeInput
            value={dueTime}
            format="hh:mm"
            onChange={({detail}) => {
                setDueTime(detail.value)
                if (validateDueDate(dueDate, detail.value)) setBridgeDetails({...bridgeDetails, due_timestamp: getDateTime(dueDate, detail.value)});
            }}
            placeholder="hh:mm"
            invalid={!validDueDate}
        />
    </CustomGrid>)
}