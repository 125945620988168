import {ContentLayout, Header, SpaceBetween} from "@amzn/awsui-components-react";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
	getBridge,
	getComments,
	RESET_FILLING_BRIDGES,
	setBridgingPageStatus
} from "src/actions/bridgingCommentary.actions";
import {Roles} from "src/common/roles";
import UserAccessException from "src/components/Common/UserAccessException";
import FillOutBridgeTable from "src/components/FillOutBridgePage/FillOutBridgeTable";
import {getCountedText} from "src/components/FillOutBridgePage/FillOutBridgeTableHelpers";
import {
	getBridgingPageStatus,
	getFillingBridgeItems,
	getSubItemsForBridges
} from "src/reducers/bridgingCommentary.reducer";
import {getAssumedRole, getUser} from "src/reducers/user.reducer";

export const defaultBridgeStatus = {
	fetched: false,
	fetching: false,
	fetchedComments: false,
	fetchingComments: false,
	refreshingComments: false,
	fetchedVersions: true,
	saved: false,
	deleted: false,
	error: ""
}

const FillOutBridgePage= () => {
	const dispatch = useDispatch();
	// Selectors
	const bridges = useSelector(getFillingBridgeItems);
	const status = useSelector(getBridgingPageStatus);
	const bridgeSubItems = useSelector(getSubItemsForBridges);
	const role = useSelector(getAssumedRole)!;
	const user = useSelector(getUser);
	// Use effects
    useEffect(function cleanupFillOutBridgePageRedux() {
		return () => {
			dispatch({type: RESET_FILLING_BRIDGES, payload: {}});
		}
    }, [])

	useEffect(function fetchBridgeSubItems() {
		if(role) {
			// URL parser will not work properly if #/ anchor is present in the url
			const url = new URL(window.location.href.replace('#/', ""));
			const bridge_id = url.searchParams.get('bridge_id');
			// If bridge is opened via its own link
			if(bridge_id){
				dispatch(getBridge(bridge_id, role));
				dispatch(getComments(bridge_id, role))
				dispatch(setBridgingPageStatus({
					...status,
					bridge: {...status.bridge, [bridge_id]: {fetchingComments: true}}
				}));
			} else { // If user is coming from bridging lading page
				const fetchingStatus: { [p: string]: any } = {};
				bridges.forEach(bridge => {
					fetchingStatus[bridge.bridge_id] = {fetchingComments: true};
					dispatch(getComments(bridge.bridge_id, role))
				});
				dispatch(setBridgingPageStatus({
					...status,
					bridge: fetchingStatus
				}));
			}
		}
	}, [role])

	if(!bridges) return <></>;

	const isUserAllowed = Object.keys(status.bridge || {}).reduce((isAllowed, bridgeId) => {
		const error =  status.bridge[bridgeId].error
		return isAllowed && !(error && error.includes('UserNotAllowedException'))
	}, true)

	return (isUserAllowed ?
		<ContentLayout
			header={
				<Header variant="h1">
					{ role !== Roles.Admin ?
						getCountedText("Fill Out Bridge", bridges)
						: getCountedText("Review Bridge", bridges)
					}
				</Header>
			}
		>
			<SpaceBetween direction="vertical" size="xxl">
				{ bridges.map((bridge, index) =>
					<FillOutBridgeTable
						key={index}
						bridgeItem={bridge}
						subBridgeItems={bridgeSubItems[bridge.bridge_id] || []}
						user={user}
						role={role}
						bridgeStatus={status.bridge[bridge.bridge_id] || defaultBridgeStatus}
					/>
				)}
			</SpaceBetween>
		</ContentLayout>
		: <UserAccessException allowedRoles={[Roles.Admin, Roles.BridgeOwner]} user={user} assumedRole={role}/>
	)
}

export default FillOutBridgePage;
