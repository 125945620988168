import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import React from 'react';
import styled from "styled-components";

const Div = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  justify-content: center;
`;

const LargeLoadingOverlay = () => {
    return <Div className={"loading-overlay"}>
        <Spinner size="large"/>
    </Div>;
}

export default LargeLoadingOverlay