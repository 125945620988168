import {SpaceBetween} from "@amzn/awsui-components-react";
import React from "react";
import {BridgeStatusType} from "src/components/BridgingCommentary/interfaces";
import {
    CommentaryHistory,
    CommentVersionContainer,
    FollowUpContent,
    LineItemHistoryContent,
    Metadata,
    SubItemHistory,
    ValueHistory
} from "src/components/FillOutBridgePage/CommentVersioning/CommentVersionContainers";
import {VersionBadge} from "src/components/FillOutBridgePage/CommentVersioning/VersionNumberComponent";
import {FollowUpInput, InputProps} from "src/components/FillOutBridgePage/InputComponents";
import {BridgeHistory, BridgeSubItem, FollowUpItem} from "src/components/FillOutBridgePage/interfaces";
import {getFormattedValue} from "src/components/ViewReportPage/ViewAllBridgesTable";
import {getRelativeTime} from "src/utils/timeHelpers";
import styled from "styled-components";

interface LineItemHistoryProps {
    rowId: string,
    bridgeStatus: BridgeStatusType,
    followUpProps: any,
    history: BridgeHistory,
    historyIds: string[],
    latestSelected: boolean
}


//Main comment version history
export const LineItemHistory = ({rowId, bridgeStatus, followUpProps, historyIds, history, latestSelected}: LineItemHistoryProps) => {
    if (historyIds.length < 1 || Object.keys(history).length < 1) return null;

    const rowIdx = followUpProps.subItems.findIndex((item: any) => item.row_id === rowId)
    const versionComponents = historyIds.map((id, idx) => {
        if(rowId in history[id]){
            const subItem: BridgeSubItem = history[id][rowId]
            return(
                <div key={idx}>
                    <CommentVersion
                        version={idx+1}
                        subItem={subItem}
                    />
                    {'follow_up' in subItem &&
                        bridgeStatus === BridgeStatusType.Submitted && idx >= historyIds.length - 1 && latestSelected ?
                        <InputFollowUp
                            inputProps={{...followUpProps, index: rowIdx}}
                        /> :
                        <StaticFollowUp
                            followUpItem={subItem.follow_up}
                        />
                    }
                </div>
            )
        }
        return null;
    }).reverse()

    return(
        <>
            <SpaceBetween size={'l'} direction={'vertical'}>
                {versionComponents}
            </SpaceBetween>
        </>
    )
}

const CommentVersionNumberDiv = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1em;
  place-items: center;
`


//Sub components
const CommentVersion = ({version, subItem}: { version: number, subItem: BridgeSubItem}) => {
    const {name, varianceValue, commentary, commentator, last_edited_timestamp} = subItem

    return(
        <CommentVersionNumberDiv>
            <VersionBadge>{version}</VersionBadge>
            <CommentVersionContainer className={'comment-version-container'} marginLeft={'0%'} marginRight={'0%'}>
                <LineItemHistoryContent className={'line-item-history-content'}>
                    <SubItemHistory className={'sub-item-history'}>{name}</SubItemHistory>
                    <ValueHistory className={'value-history'}>{getFormattedValue(varianceValue)}</ValueHistory>
                    <CommentaryHistory className={'commentary-history'}>{commentary}</CommentaryHistory>
                    <Metadata className={'meta-data'}>
                        <span>{commentator}</span>
                        <span>{last_edited_timestamp && getRelativeTime(last_edited_timestamp.toString())}</span>
                    </Metadata>
                </LineItemHistoryContent>
            </CommentVersionContainer>
        </CommentVersionNumberDiv>
    )
}

const StaticFollowUp = ({followUpItem}: { followUpItem: FollowUpItem }) => {
    const {comment, timestamp, login} = followUpItem;

    if (comment) {
        return(
            <CommentVersionContainer className={'comment-version-container'} marginLeft={'6%'} marginRight={'2%'} dropdown={true}>
                <FollowUpContent className={'follow-up-content'}>
                    <span>{comment}</span>
                    <Metadata className={'meta-data'}>
                        <span>{login}</span>
                        <span>{getRelativeTime(timestamp)}</span>
                    </Metadata>
                </FollowUpContent>
            </CommentVersionContainer>
        )
    }
    return null
}

const InputFollowUp = ({inputProps}: { inputProps: InputProps }) => {
    const {subItems, readOnly, index} = inputProps
    if (index >= 0 && (!readOnly || subItems[index].follow_up.comment)) {
        return(
            <CommentVersionContainer className={'comment-version-container'} marginLeft={'6%'} marginRight={'4%'} dropdown={true}>
                <FollowUpContent className={'follow-up-content'}>
                    <FollowUpInput {...inputProps}/>
                </FollowUpContent>
            </CommentVersionContainer>
        )
    }

    return null
}