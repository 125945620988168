import {Select, SelectProps} from "@amzn/awsui-components-react/polaris";
import React from 'react';
import {
    AttributeOption,
    JobAttributeControlProps,
    JobAttributeType,
    JobState,
    ScenarioOptions
} from "src/components/MDXLibraryPage/interfaces";


const JobAttributeControl = (props: JobAttributeControlProps) => {
    const {attributeState, index, jobAttribute, setJobs, scenarioOptions, otherProps={}, disabled} = props;
    // values specific to job attributes
    const attributeSpecificValues = {
        ...otherProps,
        empty: "No option's",
        placeholder: `Select ${jobAttribute}`,
    };

    // Handler for when user focuses on job attribute dropdown
    const onFocusJobAttribute = () => {
        setJobs(jobs => {
            const currJobState = {...jobs[index]};
            const newJobs = [...jobs];
            newJobs[index] = {
                ...currJobState,
                [jobAttribute]: {
                    ...currJobState[jobAttribute],
                    options: getDropdowns(scenarioOptions, jobAttribute, currJobState)
                }
            };
            return newJobs;
        });
    };

    // Handler for when user selects options from job attribute dropdown
    const onChangeHandler = (detail: SelectProps.ChangeDetail) => {
        setJobs(jobs => {
            const currJobState = jobs[index];
            const newJobs = [...jobs];
            newJobs[index] = updateJobAttribute(jobAttribute, currJobState, detail.selectedOption.label);
            return newJobs;
        });
    }

    return(
        <Select selectedOption={attributeState["selectedOption"]}
                options={attributeState["options"]}
                onFocus={onFocusJobAttribute}
                onChange={e => onChangeHandler(e.detail)}
                disabled={disabled}
                {...attributeSpecificValues}
        />
    );
}

export default JobAttributeControl;


const attributes: JobAttributeType[] = ["instance", "cube", "year", "scenario"];
const attributeIndexMap: Record<JobAttributeType, number> = {"instance": 0, "cube": 1, "year": 2, "scenario": 3};

// Helper function to get dropdowns for given job attribute
export function getDropdowns(jobDropdownOptions: ScenarioOptions, jobAttribute: JobAttributeType, currJobState: JobState) {
    const dropDownOptions: AttributeOption[] = [];
    let i = 0;
    let accumulatedResult: any = {...jobDropdownOptions};
    let selectedValue: string;
    while(i < attributes.length && attributes[i] !== jobAttribute){
        selectedValue = currJobState[attributes[i]]["selectedOption"]["label"];
        if (selectedValue === "-"){
            return dropDownOptions;
        }
        accumulatedResult = accumulatedResult[selectedValue as keyof typeof jobDropdownOptions];
        i++;
    }
    if (accumulatedResult instanceof Array){
        accumulatedResult.forEach((item: string) => dropDownOptions.push({label: item}));
    } else {
        Object.keys(accumulatedResult).forEach((item: string) => dropDownOptions.push({label: item}));
    }
    return dropDownOptions;
}


// Helper function to update current job attribute with selected option
export function updateJobAttribute(jobAttribute: JobAttributeType, currJobState: JobState,
                            newValue: string | undefined): JobState {
    // handle the case for manual input cube selection
    if (jobAttribute === 'cube' && newValue === 'Manual Input'){
        return {
            ...currJobState,
            cube: {...currJobState.cube, selectedOption: {label: newValue}},
            year: {...currJobState.year, selectedOption: {label: "all"}},
            scenario: {...currJobState.scenario, selectedOption: {label: "all"}},
        }
    }

    const oldSelectedValue = currJobState[jobAttribute]["selectedOption"]["label"];
    if (oldSelectedValue !== newValue) {
        let i: number = attributeIndexMap[jobAttribute] + 1;
        while (i < attributes.length) {
            currJobState[attributes[i]]["selectedOption"]["label"] = "-";
            i++;
        }
    }

    return {
        ...currJobState,
        [jobAttribute]: {...currJobState[jobAttribute], selectedOption: {label: newValue}}
    };
}
