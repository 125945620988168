import {Popover} from "@amzn/awsui-components-react";
import React from "react";
import {humanizeEpoch} from "src/utils/timeHelpers";
import styled from "styled-components";

const CirclePlusDiv = styled.div`
  cursor: pointer;
  
  .icon {
    display: inline-flex;
    align-self: center;
  }

  .icon svg, .icon img {
    height: 1em;
    width: 1em;
    fill: currentColor;
  }

  .icon.baseline svg, .icon img {
    top: .125em;
    position: relative;
  }
`

export const CirclePlus = <CirclePlusDiv>
    <div className={'icon baseline'}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
            <path d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z M13,7 L13,11 L17,11 L17,13 L13,13 L13,17 L11,17 L11,13 L7,13 L7,11 L11,11 L11,7 L13,7 Z"
                  id="icon" fill="#414d5c"></path>
        </svg>
    </div>
</CirclePlusDiv>


const TimestampList = styled.ul`
  list-style: none;
  padding: 0;
`

const ReportSyncTimeExpander = ({timestamps}: {timestamps: number[]}) => {
    if(timestamps.length < 2) return null;

    return(
        <Popover
            /* Enabling renderWithPortal property will allow the popover to be rendered in the root stack
            context using React Portals, so that it will have highest z-index always. Fixes issue where popover
            appears hidden some browsers */
            renderWithPortal={true}
            header="Previous Syncs" dismissAriaLabel="Close" size="medium" triggerType="custom" position="bottom"
            content={
                <TimestampList>
                {timestamps.slice(-6,-1).reverse().map((timestamp, i) => {
                        return <li key={`timestamp${i}`}>
                            {humanizeEpoch({epoch: timestamp, asDate: false})}
                        </li>
                    })
                }
                </TimestampList>
            }
        >
            {CirclePlus}
        </Popover>
    )
}

export default ReportSyncTimeExpander;