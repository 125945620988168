import {Container} from "@amzn/awsui-components-react";
import Header from "@amzn/awsui-components-react/polaris/header";
import React, {useState} from "react";
import BridgingCommentaryTable from "src/components/BridgingCommentary/BridgingCommentaryTable";
import {BridgeItem, BridgingTableItem} from "src/components/BridgingCommentary/interfaces";
import {actionsWidget} from "src/components/ReportBridgingPage/components/actions";
import {detailsWidget} from "src/components/ReportBridgingPage/components/details";
import {dueDateWidget} from "src/components/ReportBridgingPage/components/dueDate";
import {headerActions} from "src/components/ReportBridgingPage/components/headerActions";
import {adminModals} from "src/components/ReportBridgingPage/components/modals";
import {ReportBridgingProps} from "src/components/ReportBridgingPage/interfaces";
import {FastTheme} from "src/components/Themes/reportBridgingIntegration/fastThemeCreation";

const ReportBridging = (props : ReportBridgingProps) => {
    // States for delete bridge modal
    const {bridgeItems, role, reportId} = props;
    const [selectedItems, setSelectedItems] = useState<BridgingTableItem[]>([]);
    const [focusedModalVisible, setFocusedModalVisible] = useState(false);
    const [focusedBridge, setFocusedBridge] = useState<BridgeItem | null>(null);
    const [deletingBridge, setDeletingBridge] = useState<BridgeItem | null>(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [updatingBridges, setUpdatingBridges] = useState<BridgeItem[] | null>(null);
    const [updateDatesModalVisible, setUpdateDatesModalVisible] = useState(false);
    const [createThemeModalVisible, setCreateThemeModalVisible] = useState(false);
    const [fastTheme, setFastTheme] = useState<FastTheme | undefined>(undefined);

    return ( <Container>
        {adminModals({
            reportId,
            focusedBridge,
            selectedItems,
            focusedModalVisible,
            setFocusedModalVisible,
            setFocusedBridge,
            assumedRole : role,
            deletingBridge,
            setDeletingBridge,
            setDeleteModalVisible,
            deleteModalVisible,
            setUpdatingBridges,
            updatingBridges,
            updateDatesModalVisible,
            setUpdateDatesModalVisible,
            createThemeModalVisible,
            setCreateThemeModalVisible,
            fastTheme
        })}
        <Header counter={`(${bridgeItems.length})`} variant="h1"
            actions={headerActions({bridgeItems, selectedItems, setUpdatingBridges, setUpdateDatesModalVisible,
                setFocusedBridge, setCreateThemeModalVisible, setFastTheme})}
        >
            Bridging and Commentary
        </Header>
        <BridgingCommentaryTable
            bridgeItems={bridgeItems}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            ownerWidget={detailsWidget({setFocusedBridge: setFocusedBridge, setFocusedModalVisible : setFocusedModalVisible})}
            dueDateWidget={dueDateWidget({setFocusedBridge : setFocusedBridge, setFocusedModalVisible :setFocusedModalVisible})}
            actionWidget={actionsWidget({setDeletingBridge : setDeletingBridge, setDeleteModalVisible: setDeleteModalVisible})}
        />
    </Container>)
}

export default ReportBridging;
