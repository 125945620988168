import React from "react";
import {BridgingPageStatus} from "src/actions/bridgingCommentary.actions";
import {BridgeTableRowItem} from "src/components/Bridging/BridgeTableConfig";

export enum BridgeTableColumns {
    LineItem = "Bridge Item",
    Variance = "Variance",
    TagOwnerGroup = "Tag Owner Group"
}

export interface BridgeInputError {
    [bridge_id: string]: {
        bridge_owner? : string,
        due_date? : string,
        due_time? : string,
        banner_alert?: string,
    }
}

export enum AssignBridgeTableColumns {
    LineItem = "Bridge Item",
    LeftHeader = "Left Header",
    RightHeader = "Right Header",
    VarianceHeader = "Variance Header",
    BridgeOwner = "Bridge Owner",
    NoteToBridgeOwner = "Note to bridge owner",
    DueBy = "Due By"
}

export enum RequestBridgeInputsTableColumns {
    BridgeOwners = "Bridge Owners",
    AssignedLineItem = "Assigned P&L Item"
}

export interface BridgeItemsTableProps {
    tableItems: BridgeTableRowItem[],
    selectedItems: BridgeTableRowItem[],
    setSelectedItems: React.Dispatch<React.SetStateAction<BridgeTableRowItem[]>>,
    errorText: string,
    setStepErrors: React.Dispatch<React.SetStateAction<StepErrors>>,
    tableHeader: JSX.Element,
    tableIsLoading: boolean,
    existingBridgeItemNames: Set<string>,
}

export interface AssignBridgeProps {
    varianceName: string | undefined,
    leftVarianceHeader?: string | undefined,
    rightVarianceHeader?: string | undefined,
    bridgeItems: BridgeTableRowItem[],
    setBridgeItems: React.Dispatch<React.SetStateAction<BridgeTableRowItem[]>>,
    errors: BridgeInputError,
    setStepErrors: React.Dispatch<React.SetStateAction<StepErrors>>,
    tableHeader: JSX.Element,
    bridgingPageStatus:BridgingPageStatus,
}

export interface StepErrors {
    1: string,
    2: BridgeInputError,
    3: string,
    4: string,
}

export interface BridgeOwnersProps {
    id: string,
    bridge_id: string,
    bridgeItems: BridgeTableRowItem[],
    setBridgeItems: React.Dispatch<React.SetStateAction<BridgeTableRowItem[]>>,
    errorText: string,
    errors: BridgeInputError,
    setStepErrors: React.Dispatch<React.SetStateAction<StepErrors>>,
    bridgingPageStatus:BridgingPageStatus,
}

export interface BridgeItemOwnerGroupSelectionProps {
    bridgeOwnerGroup:  {[p: string]: string},
    setBridgeToOwnerGroup:  React.Dispatch<React.SetStateAction<{[p: string]: string}>>,
    accountRollup: string,
    ownerGroups: {[p: string]: string},
}
