import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import {AppState} from "src/reducers/AppState";
import {
    forCommentaryOfOneBridgeItem,
    forInViewBridgeId,
    forInViewBridgeSubItem
} from "src/reducers/bridgingCommentary.reducer";
import styled from "styled-components";

/* This component displays text when a table row is clicked. this is scrollable vertically. */
const ScrollableCommentaryPanel = () => {
    const bridgeId = useSelector(forInViewBridgeId, shallowEqual);
    const selectedRow = useSelector(forInViewBridgeSubItem, shallowEqual);
    const bridgeRowId= selectedRow && selectedRow.row_id;
    const commentary = useSelector((s: AppState) => forCommentaryOfOneBridgeItem(s, bridgeId, bridgeRowId));
    return <ScrollableDiv id={"scrollable-commentary-panel"}>
        {commentary}
    </ScrollableDiv>
}

export default ScrollableCommentaryPanel;


const ScrollableDiv = styled.div`
    max-height: 50vh; // this tightly couples to 70vh of ModalContentDiv.
    margin: 0.5em 0 0.25em 0; // this prevent blue track line touching corners
    padding: 0 0.75em 0.5em 1em; // leave text some room
    overflow-x: hidden;
    overflow-y: scroll;
    text-overflow: ellipsis;
    border-left: solid 2px dodgerblue; // blue track line
`;