import {useCollection} from "@amzn/awsui-collection-hooks";
import {
    Alert,
    CollectionPreferences,
    CollectionPreferencesProps,
    Pagination,
    SpaceBetween,
    TextFilter
} from "@amzn/awsui-components-react";
import Header from "@amzn/awsui-components-react/polaris/header";
import Table from "@amzn/awsui-components-react/polaris/table";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getReportLibrary} from "src/actions/reportLibrary.actions";
import {ItemType, ReportDefinition, ReviewFolderState} from "src/common/report";
import {EmptyState} from "src/components/CreateReportPage/MDXGroupSelection";
import {ExpandedFolder, ReportSelectionProps} from "src/components/CreateReviewPage/interfaces";
import {EmptyTableState} from "src/components/MDXLibraryPage/MDXLibraryTable";
import {ReportTableColumns} from "src/components/ReportLibraryPage/interfaces";
import {constructTableRow} from "src/components/ReportLibraryPage/ReportLibraryTable";
import {
    getFilterCounterText,
    getReportTableColumnDefinitions,
    PAGE_SIZE_OPTIONS,
    ReportTableRowItem
} from "src/components/ReportLibraryPage/ReportTableConfig";
import {getReportLibraryApiLoading} from "src/reducers/apiLoading.reducer";
import {getPaginationConfig, getReports} from "src/reducers/reportLibrary.reducer";


const ReportsSelection = (props: ReportSelectionProps) => {
    const {
        folderWorkflow,
        folderName,
        reviewFolderDefinitions,
        errorText,
        setStepErrors,
        selectedItems,
        setSelectedItems
    } = props;
    const dispatch = useDispatch();
    const pageSizePref = 25;
    const {paginationKey, hasMore} = useSelector(getPaginationConfig);
    const tableLoading = useSelector(getReportLibraryApiLoading);
    const [expandedGroups, setExpandedGroups] = useState<ExpandedFolder>({});
    const reportStates = useSelector(getReports)

    // Use effect to fetch initial data in redux store
    useEffect(function initializeReportSelection() {
        dispatch(getReportLibrary({
            reportLibrary: reviewFolderDefinitions.report_library || "Active",
            limit: pageSizePref + 1
        }));
    }, []);

    const columnDefinitions = useMemo(() => {
        const excludedColumns = new Set([ReportTableColumns.Actions, ReportTableColumns.Status, ReportTableColumns.ReportAccess]);
        return getReportTableColumnDefinitions(excludedColumns);
    }, [])

    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: pageSizePref,
        visibleContent: columnDefinitions.map((obj) => obj.id)
    });

    const groupTreeViewControl = (item_id: string) => {
        const groupIsExpanded = expandedGroups[item_id];
        setExpandedGroups({...expandedGroups, [item_id]: !groupIsExpanded});
    };

    function constructTableRows(items: ReportDefinition[]): ReportTableRowItem[] {
        const tableRows: ReportTableRowItem[] = [];
        const processedItems = new Set<string>(); // keep track of processed report ids
        const reportMap: {[p:string]: ReportDefinition} = {};
        const folderItems: ReviewFolderState[] = [];

        // Separate folders and reports
        items.forEach(item => {
            if (item.item_type === ItemType.ReviewFolder){
                folderItems.push({...item});
            } else {
                reportMap[item.report_id] = {...item}
            }
        }) ;
        // Build table rows for folders first
        folderItems.forEach(folder => {
            const iconName = expandedGroups[folder.report_id] ? "treeview-collapse" : "treeview-expand";
            tableRows.push(constructTableRow(
                folder,
                "",
                {iconName, variant: "icon", onClick: () => groupTreeViewControl(folder.report_id)},
                ""
            ));
            processedItems.add(folder.report_id);
            // Add sub rows as needed
            folder.report_ids?.forEach(report_id => {
                processedItems.add(report_id);
                if (expandedGroups[folder.report_id]) {
                    tableRows.push(constructTableRow(
                        reportMap[report_id],
                        folder.report_id,
                        {},
                        "",
                        false
                    ));
                }
            });
        });
        // Add the remaining rows
        items.forEach(item => {
            if(!processedItems.has(item.report_id)){
                tableRows.push(constructTableRow(item, "", {}, "", false));
            }
        });
        return tableRows
    }


    const { items, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        constructTableRows(reportStates), {
            filtering: {
                empty: <EmptyState title="No instances" subtitle="No instances to display."/>,
                noMatch: <EmptyState title="No matches" subtitle="We can’t find a match."/>
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {},
        }
    );

    return(
        <SpaceBetween size="s" direction="vertical">
            {errorText.length > 0 &&
                <Alert type="error">{errorText}</Alert>
            }
            <Table
                {...collectionProps}
                onSelectionChange={({ detail }) => {
                    errorText && setStepErrors((stepErrors) => {return {...stepErrors, 2: ""}});
                    setSelectedItems(detail.selectedItems);
                }}
                selectedItems={selectedItems}
                columnDefinitions={columnDefinitions}
                items={items}
                loading={tableLoading}
                loadingText="Loading resources"
                selectionType="multi"
                trackBy="id"
                empty={<EmptyTableState/>}
                isItemDisabled={item => (item.itemType === ItemType.ReviewFolder) ||
                    (selectedItems.length >= 15 && (selectedItems.find(row => row.id === item.id) === undefined))
                }
                header={
                    <Header description="Please select the reports you would like in this folder">
                        {`${folderWorkflow} folder: ${folderName}`}
                    </Header>
                }
                filter={
                    <TextFilter
                        {...filterProps}
                        filteringAriaLabel="Filter instances"
                        filteringPlaceholder="Search"
                        countText={getFilterCounterText(filteredItemsCount || 0)}
                    />
                }
                visibleColumns={preferences.visibleContent}
                preferences={
                    <CollectionPreferences
                        onConfirm={({ detail }) => setPreferences(detail)}
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        preferences={preferences}
                        pageSizePreference={{
                            title: "Select page size",
                            options: PAGE_SIZE_OPTIONS
                        }}
                        visibleContentPreference={{
                            title: "Select visible content",
                            options: [
                                {
                                    label: "Report selection",
                                    options: columnDefinitions
                                }
                            ]
                        }}
                    />
                }
                pagination={
                    <Pagination
                        {...paginationProps}
                        onChange={(event) => {
                            if (event.detail.currentPageIndex === paginationProps.pagesCount && hasMore) {
                                dispatch(getReportLibrary({
                                    reportLibrary: "Active",
                                    limit: pageSizePref + 1,
                                    paginationKey
                                }));
                            }
                            paginationProps.onChange(event);
                        }}
                    />
                }
            />
        </SpaceBetween>
    )
}

export default ReportsSelection;
