import {FormField, Input, Select, SpaceBetween } from "@amzn/awsui-components-react"
import React, { useState } from "react"

import { CreateWorkflowRequest,ExecuteFacetRequest } from "../interfaces/facetParams"
import { Workflow } from "../workflowSlice"


export interface FacetData {
  facetRequest : ExecuteFacetRequest
  setFacetRequest : React.Dispatch<React.SetStateAction<ExecuteFacetRequest>>,
}

export interface WorkflowData {
  workflow : CreateWorkflowRequest
  setWorkflow : React.Dispatch<React.SetStateAction<CreateWorkflowRequest>>
}

export const configureFacet = (facetData : FacetData, workflowData: WorkflowData) => {
    return {
      title: "Select Report",
      description : "Choose the report you would like to build",
      content  : <Facet {...{ facetData : facetData , workflowData : workflowData}} ></Facet>
    }
  }
export const Facet = ({ facetData, workflowData} : { facetData: FacetData, workflowData: WorkflowData}) => {
    const {facetRequest, setFacetRequest} = facetData
    const {workflow, setWorkflow} = workflowData
    const [selectedOption, setSelectedOption] = useState<{label? : string | undefined, value?: string | undefined}>(
      { label: "Server Capex Low-Level Foundational Services Bridge", value : "LowLevelFoundational"},
    )

    return <SpaceBetween size={"m"}>
    <FormField
      description=""
      label="Report Name"
    >
    <Input onChange={event => {
      setWorkflow({...workflow, workflow_name : event.detail.value})
    }
    }
      value={workflow.workflow_name}
      placeholder="enter description for the report"></Input>
    </FormField>    <Select
    selectedOption={selectedOption}
      onChange={({ detail }) =>{
        setSelectedOption(detail.selectedOption)
        setFacetRequest({...facetRequest, facet_name : detail.selectedOption.value!})
        // setWorkflow({...workflow, facet_id : detail.selectedOption.value!})
      }}
      options={[
        { label: "Server Capex Low-Level Foundational Services Bridge", value : "LowLevelFoundational"},
        { label: "Server Capex Low-Level Dependent Services Bridge", value: "LowLevelDependent" },
        { label: "Server Capex High level Bridge", value: "HighLevel" },
        { label: "Server Capex Deep-Dive External Usage Bridge", value: "DeepDiveExternalDemand" },
        { label: "Server Capex Deep-Dive SDO Usage Bridge", value: "DeepDiveSDODemand" },
        { label: "Server Capex Deep-Dive Efficiency Bridge", value: "DeepDiveEfficiency" },
      ]}
    />
      </SpaceBetween>
}