
export enum Year {
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep",
        "Oct", "Nov", "Dec"
}


export interface FacetData  {
    name : string
    scenarios : {label: string, value: string}[]
}

export const DATA_SOURCES  :FacetData[]=  [
    {
    name: "NER",
    scenarios : [
        {label : "Actuals", value: "Actuals"} ,{label :"2024 OP2 TR", value : "2024 OP2 TR" },
        {label :"2025 OP1", value : "2025 OP1" }]
},{
    name: "Usage Revenue Summary",
    scenarios : [
        {label : "Actuals", value: "Actuals"} ,{label : "OP2 - 2024- TR & Q1 Stitch", value :  "OP2 - 2024 - TR & Q1 Stitch" }, {label: "OP2 - 2024 - 03.05.24", value: "OP2 - 2024 - 03.05.24"},
        {label: "OP1 - 2025 - 07.31.24", value: "OP1 - 2025 - 07.31.24"}]
    },{
    name: "Product Segment Details",
    scenarios : [{label : "Actuals", value: "Actuals"} ,{label :"OP2 - 2024 - V7", value : "OP2 - 2024 - V7" },
        {label :"R&O - 2024 - Q3.Final", value : "R&O - 2024 - Q3.Final" }]

},{
    name: "Racks by Period",
    scenarios : [
        {label : "Actuals", value: "Actuals"} ,{label: "OP2 - 2024 - TR FINAL & Q1 Stitch", value: "OP2 - 2024 - TR FINAL & Q1 Stitch"}, {label: "OP2 - 2024 - TR FINAL",
            value: "OP2 - 2024 - TR FINAL"}, {label: "OP2 - 2024 - TR & Q1 Stitch - Revised", value: "OP2 - 2024 - TR & Q1 Stitch - Revised"},
        {label : "OP2 - 2024 - 03.05.24 - Revised", value :  "OP2 - 2024 - 03.05.24 - Revised" },
        {label : "OP1 - 2025 - 7.25 & Q3 R&O Charlie", value :  "OP1 - 2025 - 7.25 & Q3 R&O Charlie" }]
    },{
    name: "Segment Assignment Usage Drivers - Reporting",
    scenarios : [
        {label : "Actuals", value: "Actuals"} ,{label: "OP2 - 2024 - V7", value: "OP2 - 2024 - V7"},{label : "Planning", value :  "Planning" },
        {label : "OP1 - 2025 - 07.31.24", value :  "OP1 - 2025 - 07.31.24" }]
    }
]

export const SCENARIOS :{ [name : string] : any}= {
    "NER" : [
        {label : "Actuals", value: "Actuals"} ,{label :"2024 OP2 TR", value : "2024 OP2 TR" },
        {label : "2025 OP1", value : "2025 OP1" }],

    "Usage Revenue Summary" :[
        {label : "Actuals", value: "Actuals"} ,{label : "OP2 - 2024- TR & Q1 Stitch", value :  "OP2 - 2024 - TR & Q1 Stitch" }, {label: "OP2 - 2024 - 03.05.24", value: "OP2 - 2024 - 03.05.24"},
        {label: "OP1 - 2025 - 07.31.24", value: "OP1 - 2025 - 07.31.24"}],

    "Product Segment Details":  [{label : "Actuals", value: "Actuals"} ,{label :"OP2 - 2024 - V7", value : "OP2 - 2024 - V7" },
        {label :"R&O - 2024 - Q3.Final", value : "R&O - 2024 - Q3.Final" }],


    "Racks by Period" :[
        {label : "Actuals", value: "Actuals"} ,{label: "OP2 - 2024 - TR FINAL & Q1 Stitch", value: "OP2 - 2024 - TR FINAL & Q1 Stitch"}, {label: "OP2 - 2024 - TR FINAL",
            value: "OP2 - 2024 - TR FINAL"}, {label: "OP2 - 2024 - TR & Q1 Stitch - Revised", value: "OP2 - 2024 - TR & Q1 Stitch - Revised"},
        {label : "OP2 - 2024 - 03.05.24 - Revised", value :  "OP2 - 2024 - 03.05.24 - Revised" },
        {label : "OP1 - 2025 - 7.25 & Q3 R&O Charlie", value :  "OP1 - 2025 - 7.25 & Q3 R&O Charlie" }],

    "Segment Assignment Usage Drivers - Reporting" : [
                {label : "Actuals", value: "Actuals"} ,{label: "OP2 - 2024 - V7", value: "OP2 - 2024 - V7"},{label : "Planning", value :  "Planning" },
        {label : "OP1 - 2025 - 07.31.24", value :  "OP1 - 2025 - 07.31.24" }]
}


export const SCENARIO_IDS : { [name : string] : any}= {
    "NER" : "ner",

    "Usage Revenue Summary" : "usage",

    "Product Segment Details": "product_segment_detail",

    "Racks by Period" : "capacity",

    "Segment Assignment Usage Drivers - Reporting" : "segment_assignment"
}
