import {
    Alert,
    Box,
    Button,
    Header,
    Modal,
    SpaceBetween,
} from "@amzn/awsui-components-react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {resetBridgingPageStatus, saveBridge} from "src/actions/bridgingCommentary.actions";
import {Roles} from "src/common/roles";
import {BridgeInfo} from "src/components/BridgingCommentary/modalComponents/BridgeInfo";
import {
    BridgeOwnersModalProps
} from "src/components/BridgingCommentary/modalComponents/interfaces";
import {OwnersWidget} from "src/components/BridgingCommentary/modalComponents/OwnersWidget";
import {TimeWidget} from "src/components/BridgingCommentary/modalComponents/TimeWidget";
import {InfoList} from "src/components/FillOutBridgePage/InputComponents";
import {B} from "src/components/FillOutBridgePage/TableSubComponents";
import {updateBridge} from "src/features/reportBridges/reportBridgeSlice";
import {getBridgeApiLoading} from "src/reducers/apiLoading.reducer";
import {getBridgingPageStatus} from "src/reducers/bridgingCommentary.reducer";
import {
    addDaysToTimeStamp,
    epochToDate,
    epochToHhMm,
    humanizeEpoch, validateDueDate
} from "src/utils/timeHelpers";

interface TimeProps {
    timeOption : string,
    setTimeOption : React.Dispatch<React.SetStateAction<string>>,
    inputDays : string,
    setInputDays : React.Dispatch<React.SetStateAction<string>>
}
const ReportBridgeDetailsModal = (props: BridgeOwnersModalProps) => {
    const dispatch = useDispatch();
    const {visible, setVisible, bridgeDetails, setBridgeDetails} = props;

    const bridgeApiLoading = useSelector(getBridgeApiLoading);
    // Get api call status for current bridge
    const bridgeStatus = useSelector(getBridgingPageStatus).bridge[bridgeDetails.bridge_id];
    const {saved, error} = bridgeStatus || {saved: false, error: ""};

    //default added time is 7 days, default action is change by amount of days
    const [timeOption, setTimeOption] = useState("first");
    const [inputDays, setInputDays] =useState("7");
    const timeProps : TimeProps= {
        timeOption : timeOption,
        setTimeOption : setTimeOption,
        inputDays : inputDays,
        setInputDays : setInputDays
    }

    useEffect(() => {
        if(!bridgeApiLoading && saved){onModalDismiss()}
    }, [bridgeApiLoading, saved]);

    // Event handler when 'X' button is clicked
    const onModalDismiss = () => {
        setBridgeDetails(null);
        setVisible(false);
        dispatch(resetBridgingPageStatus());
    }
    return (
        <Modal
            onDismiss={() => onModalDismiss()}
            visible={visible}
            closeAriaLabel="Close modal"
            footer={
                    <ModalFooter{...{ownerProps :props, timeProps : timeProps}}/>
            }
            header={<Header variant="h1"> Bridge Details </Header>}
        >
            <SpaceBetween direction="vertical" size="m">
                {!!error && <Alert type='error'>{error}</Alert>}
                <ModalInfoList{...{ownerProps :props, timeProps : timeProps}}/>
            </SpaceBetween>
        </Modal>
    )
}

const ModalInfoList = ({ownerProps, timeProps} : {ownerProps: BridgeOwnersModalProps, timeProps : TimeProps}) =>{
    const {bridgeDetails, setBridgeDetails} = ownerProps;
    const {timeOption, setTimeOption, inputDays, setInputDays} = timeProps;
    return (
        <InfoList fontWeight="350" margin="0">
            <BridgeInfo {...ownerProps}/>
            <li className="spaced-line">
                <p style={{margin: "0", padding: "0"}}>
                    <B fontWeight={"bold"}>Due by date </B>
                </p>
                { humanizeEpoch({epoch : bridgeDetails.due_timestamp, asDate : true})
                }
            </li>
            <li>
                <TimeWidget bridgeDetails={bridgeDetails} timeOption={timeOption}
                setTimeOption={setTimeOption} inputDays={inputDays} setInputDays={setInputDays} setBridgeDetails={setBridgeDetails}/>
            </li>
            <OwnersWidget{...ownerProps}/>
        </InfoList>
    )
}

const ModalFooter = ({ownerProps, timeProps} : {ownerProps: BridgeOwnersModalProps, timeProps : TimeProps}) =>{
    // Constants
    const {bridgeDetails} = ownerProps;
    const {timeOption, inputDays} = timeProps;

    const newTimestamp = (timeOption == "first")? addDaysToTimeStamp(bridgeDetails.due_timestamp, Number(inputDays))
        : bridgeDetails.due_timestamp;
    const dueDate = epochToDate(newTimestamp);
    const dueTime = epochToHhMm(newTimestamp);
    const bridgeApiLoading = useSelector(getBridgeApiLoading);
    const report_id = bridgeDetails.report_id

    const validDueDate = validateDueDate(dueDate, dueTime);

    const dispatch = useDispatch();
    return (<Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary"
                onClick={() => {
                    bridgeDetails.due_timestamp = newTimestamp;
                    dispatch(updateBridge({report_id: report_id, bridgeItem : bridgeDetails}))
                    dispatch(saveBridge(bridgeDetails, Roles.Admin));
                }}
                disabled={ bridgeDetails.bridge_owners.length === 0
                    || bridgeDetails.bridge_owners.length > 20
                    || !validDueDate
                }
                loading={bridgeApiLoading}
            >
                Submit
            </Button>
        </SpaceBetween>
    </Box>)
}
export default ReportBridgeDetailsModal;



