import {Link} from "@amzn/awsui-components-react";
import React from "react";
import {
    BridgeItem,
    BridgeStatusType,
    BridgingTableItem,
    TableRowsProps
} from "src/components/BridgingCommentary/interfaces";
import {ProposedTag} from "src/components/BridgingCommentary/styling";
import {BridgeItemStatus} from "src/components/BridgingCommentary/tableComponents/BridgeItemStatus";
import Paths from "src/components/PageConfig/Paths";

import {INTEGRATION_BRIDGE_IDS} from "../../../../cypress/support/constants";

export function constructBridgingTableRow(item: BridgeItem, props : TableRowsProps): BridgingTableItem {
    const { dueDate, owners, actions} = props;
    return {
        id: item.bridge_id,
        report_id: item.report_id,
        displayName: (
            <span style={{paddingLeft: "28px"}}>
					<Link href={`#${Paths.FILLOUTBRIDGE}?bridge_id=${item.bridge_id}`}
                          data-testid={INTEGRATION_BRIDGE_IDS.has(item.bridge_id) ? 'bridge-link' : ''}>
						{item.account_rollup}
					</Link>
                {item.status === BridgeStatusType.Proposed && <ProposedTag> Proposed </ProposedTag>}
				</span>
        ),
        varianceName: item.variance_headers.variance_header,
        requester: item.requester,
        owners: owners? owners(item) : "",
        status: (
            <BridgeItemStatus
                status={item.status}
                lastModified={item.last_modified_timestamp}
                lastModifiedBy={item.last_modified_by}
            />
        ),
        editDate: item.last_modified_timestamp,
        dueDate: dueDate? dueDate(item) : "",
        name: item.report_name,
        actions: actions? actions(item) : ""
    }
}