import React from "react";
import styled from "styled-components";

const EverArrowSVG = styled.svg`
  fill: #737373;
`

const EverArrowDiv = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const EverArrow = () => {
    return(
        <EverArrowDiv>
            <EverArrowSVG className={'ever-arrow-svg'} width="12" height="12" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.5858 10H0V8H14.5858L8.29289 1.70711L9.70711 0.292892L18.4142 9L9.70711 17.7071L8.29289 16.2929L14.5858 10Z" fill="black"/>
            </EverArrowSVG>
        </EverArrowDiv>
    )
};

const EverExchangeDiv = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`


export const EverExchange = ({onClick}:any) => {
    return(
        <EverExchangeDiv onClick={onClick}>
            <EverArrowSVG xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M9.61752431,11.0317379 C8.73148308,11.642436 7.65749832,12 6.5,12 C3.46243388,12 1,9.53756612 1,6.5 C1,3.46243388 3.46243388,1 6.5,1 C9.53756612,1 12,3.46243388 12,6.5 C12,7.65749832 11.642436,8.73148308 11.0317379,9.61752431 L14.7071068,13.2928932 L13.2928932,14.7071068 L9.61752431,11.0317379 Z M6.5,10 C8.43299662,10 10,8.43299662 10,6.5 C10,4.56700338 8.43299662,3 6.5,3 C4.56700338,3 3,4.56700338 3,6.5 C3,8.43299662 4.56700338,10 6.5,10 Z"/>
            </EverArrowSVG>
        </EverExchangeDiv>
    )
};
