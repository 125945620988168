import {BridgingPageStatus} from "src/actions/bridgingCommentary.actions";
import {BridgeTableRowItem} from "src/components/Bridging/BridgeTableConfig";
import {BRIDGE_NOT_EDITED_ALERT} from "src/components/Bridging/constants";
import {StepErrors} from "src/components/Bridging/interfaces";
import {BridgeItem} from "src/components/BridgingCommentary/interfaces";

export const findBridgeItemIdx = (id: string, items: BridgeTableRowItem[]) => {
    return items.findIndex(item => item.id === id)
}

export const bridgeOwnerToBridgeItem = (bridgeItems: BridgeItem[]) => {
    /*
      Creates a map grouping BridgeOwner to Bridge AccountRollUp, This Map will be used by
      Step 4 to populate Bridge Owners -> Assinged P&L Item table.
    */
    const briOwnAccountMap: Map<string, Array<{ bridge_id: string, account_rollup: string }>> = new Map<string, Array<{ bridge_id: string, account_rollup: string }>>();
    bridgeItems.forEach(bridgeItem => {
        bridgeItem.bridge_owners.forEach(owner => {
            if (briOwnAccountMap.has(owner)) {
                briOwnAccountMap.get(owner)?.push({
                    bridge_id: bridgeItem.bridge_id,
                    account_rollup: bridgeItem.account_rollup
                })
            } else {
                briOwnAccountMap.set(owner, new Array({
                    bridge_id: bridgeItem.bridge_id,
                    account_rollup: bridgeItem.account_rollup
                }))
            }
        })
    })
    return Array.from(briOwnAccountMap)
}

export const fetchOwnerGroup = (accountRollUp: string, ownerGroups: { [p: string]: string }): {label: string, value: string} => {
    if (ownerGroups.hasOwnProperty(accountRollUp)){
        return {label: ownerGroups[accountRollUp], value: ownerGroups[accountRollUp]}
    }else{
        return {label: ownerGroups["default"], value: ownerGroups["default"]}
    }
}

export const checkBridgeItemSubmittedOnce = (bridge_id: string,  bridgingPageStatus: BridgingPageStatus) => {
    if(bridgingPageStatus){
        return bridgingPageStatus.wizardBridge[bridge_id]
    }
}

export const stepErrorUpdate = (errors: StepErrors, bridgeId="") => {
    const updatedErrors = {...errors[2]}
    if(bridgeId){
        updatedErrors[bridgeId]["bridge_owner"] && delete (updatedErrors[bridgeId]["bridge_owner"])
        updatedErrors[bridgeId] && Object.keys(updatedErrors[bridgeId]).length === 0 && delete (updatedErrors[bridgeId])
    }else{
        updatedErrors[BRIDGE_NOT_EDITED_ALERT] && delete (updatedErrors[BRIDGE_NOT_EDITED_ALERT])
    }

    return {
        ...errors,
        2: updatedErrors,
    }
}

export const updatedWizardSendRequestStatus = (bridgingPageStatus: BridgingPageStatus, bridgeId: string) => {
    //Shallow Copy
    const updatedBridgingPageStatus = {...bridgingPageStatus}
    const focusedBridgePageStatus = {...updatedBridgingPageStatus.wizardBridge[bridgeId]}

    //Update requestInputs.requestInput to true as we are making API call for send request
    focusedBridgePageStatus.requestInputs = {saved: false, requestInput: true}
    updatedBridgingPageStatus.wizardBridge[bridgeId] = {...focusedBridgePageStatus}

    return updatedBridgingPageStatus
}
