import {Box, Button, Link, Modal, Popover} from "@amzn/awsui-components-react";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import React from "react";
import {useDispatch} from "react-redux";
import {
    RESET_FILLING_BRIDGES,
    RESET_REPORT_BRIDGES,
    resetBridgingPageStatus
} from "src/actions/bridgingCommentary.actions";
import Paths from "src/components/PageConfig/Paths";
import {getEpoch, humanizeEpoch} from "src/utils/timeHelpers";

const BridgesCreatedModal = (
    bridgeOwnerToItemMap: [string, Array<{ bridge_id: string, account_rollup: string }>][],
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
) => {
     // Modal will be displayed after all BridgeItems change_state api are successful

    const dispatch = useDispatch();
    const userList = () => {
        const ownerList = <ul>
            {bridgeOwnerToItemMap.map((ownerToItem, index) => <li key={index}>{ownerToItem[0] + "@"}</li>)}
        </ul>

        return (
            <Popover
                header="" dismissAriaLabel="Close" size="medium" triggerType="custom" position="bottom"
                content={ownerList}
            >
                <Link>{bridgeOwnerToItemMap.length}</Link>
            </Popover>
        )
    }

    const onModalDismiss = () => {
        setVisible(false);
        // RESET
        dispatch(resetBridgingPageStatus());
        dispatch({type: RESET_FILLING_BRIDGES, payload: {}});
        dispatch({type: RESET_REPORT_BRIDGES, payload: {}})
        window.location.replace(`#${Paths.REFERENCEREPORTS}`)
    }

    return (
        <Modal
            onDismiss={() => onModalDismiss()}
            visible={visible}
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    <Button variant="primary" onClick={() => onModalDismiss()}>
                        Ok
                    </Button>
                </Box>
            }
            header="Bridge Request Notification"
        >
            <TextContent>
                <p>
                    {userList()} {` Bridge owners were sent emails about the assignment on `}
                    <strong>{humanizeEpoch({epoch: getEpoch(), asDate: false})}</strong>
                </p>
            </TextContent>
        </Modal>
    );
}

export default BridgesCreatedModal