export const INTEGRATION_REPORT_IDS = new Set([
    'report_id.d7d8338f-1a41-4b03-8eaa-1b23b7bc2d6e', // Alpha
    'report_id.78172b60-7e47-4cab-ab68-65e77dc295c8', // Beta
    'report_id.b5c005c1-e842-49cd-bb2f-f079ce23d1f8', // Gamma
    'report_id.99cc431d-7394-443a-a13e-c9328315a23b', // Prod
])
export const INTEGRATION_BRIDGE_IDS = new Set([
    'report_id.d7d8338f-1a41-4b03-8eaa-1b23b7bc2d6e.VG90YWwgUmV2ZW51ZQ==.1.ZA==.4', // Alpha
    'report_id.78172b60-7e47-4cab-ab68-65e77dc295c8.VG90YWwgUmV2ZW51ZQ==.1.ZA==.4', // Beta
    'report_id.b5c005c1-e842-49cd-bb2f-f079ce23d1f8.VG90YWwgUmV2ZW51ZQ==.1.ZA==.4', // Gamma
    'report_id.99cc431d-7394-443a-a13e-c9328315a23b.VG90YWwgUmV2ZW51ZQ==.1.ZA==.4', // Prod
])
