import {Roles} from "src/common/roles";

export const DUMMY_FACET_REQUEST= (role: Roles | null) => {
    return {
        "facet_name": "LowLevelFoundational",
        "created_by": "",
        "facet_params": {
            "usage": {
                "scenario2": {
                    "scenarioName": "Actuals",
                    "Year": "",
                    "Periods": []
                },
                "scenario1": {
                    "scenarioName": "OP2 - 2024 - TR & Q1 Stitch",
                    "Year": "",
                    "Periods": []
                }
            },
            "capacity": {
                "scenario2": {
                    "scenarioName": "Actuals",
                    "Year": "",
                    "Periods": []
                },
                "scenario1": {
                    "scenarioName": "OP2 - 2024 - TR FINAL & Q1 Stitch",
                    "Year": "",
                    "Periods": []
                }
            },
            "ner": {
                "scenario2": {
                    "scenarioName": "Actuals",
                    "Year": "",
                    "Periods": []
                },
                "scenario1": {
                    "scenarioName": "2024 OP2 TR",
                    "Year": "",
                    "Periods": []
                }
            },
            "segment_assignment": {
                "scenario2": {
                    "scenarioName": "Actuals",
                    "Year": "",
                    "Periods": []
                },
                "scenario1": {
                    "scenarioName": "OP2 - 2024 - V7",
                    "Year": "",
                    "Periods": []
                }
            },
            "product_segment_detail": {
                "scenario2": {
                    "scenarioName": "Actuals",
                    "Year": "",
                    "Periods": []
                },
                "scenario1": {
                    "scenarioName": "OP2 - 2024 - V7",
                    "Year": "",
                    "Periods": []
                }
            }
        },
        role: role
    }
}
