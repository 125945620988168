import {MECCalendar} from "@amzn/aws-fintech-workday-calendar";
import {Box, Button, Container, ContentLayout, Header, IconProps, SpaceBetween} from "@amzn/awsui-components-react";
import React from 'react';
import {useSelector} from "react-redux";
import {Roles} from "src/common/roles";
import howItWorks from "src/components/HomePage/HowItWorks";
import RecentReportsTable from "src/components/HomePage/RecentReportsTable";
import {ActionCardsDiv, ColorDiv, Div, SingleActionCardDiv} from "src/components/HomePage/styling";
import Paths from "src/components/PageConfig/Paths";
import {getAssumedRole} from "src/reducers/user.reducer";

interface HomePageActionType{
    name: string,
    description : string,
    buttonText : string,
    iconName? : IconProps.Name |  undefined,
    redirect : string,
}

const viewPublishedReports : HomePageActionType = {
    name: "View Published Reports",
    description: "Browse all published reports to which you have access. " +
        "Click into embedded bridges and drill-down to see line-item variance commentary." +
        " Trace the source of reported data and see any formulae used to create calculated report columns.",
    buttonText: "View Reports",
    redirect: `#${Paths.REFERENCEREPORTS}`
}

const reviewBridgesCommentary : HomePageActionType = {
    name: "Review Bridges & Commentary",
    description: "Select a report and view embedded bridges calculating variance. Collect, follow-up on, " +
        "and review commentary as stepping stones – from grain-level business drivers to report line items.",
    buttonText: "Review Bridges",
    redirect: `#${Paths.BRIDGINGCOMMENTARY}`
}

const createNewReport : HomePageActionType = {
    name: "Create New Report",
    description: "Select data, format your report, and define bridges and build bridge calculations across " +
        "columns. Once created, you can request commentary from partners to complete your report before you " +
        "publish.",
    buttonText: "New Report",
    iconName: "add-plus",
    redirect: `#${Paths.CREATEREPORT}`
}
const createNewCustomReport = (role: Roles.DCFPA_ReportAuthor | Roles.SFPA_ReportAuthor): HomePageActionType => ({
    name: "Create New Report",
    description: "Select data, format your report, and define bridges and build bridge calculations across " +
        "columns. Once created, you can request commentary from partners to complete your report before you " +
        "publish.",
    buttonText: "New Report",
    iconName: "add-plus",
    // TODO return just report builder path once SFPA workspace is supported by report builder
    redirect: role === Roles.DCFPA_ReportAuthor ? `#${Paths.REPORT_BUILDER}` : `#${Paths.BUILDREPORT}`
});

const SingleActionCard = (item : HomePageActionType) => {
    return (
        <Container>
            <SingleActionCardDiv
            >
                <Header
                    variant={"h2"}
                >
                    {item.name}
                </Header>
                <Box variant="p"
                     padding={{ top: "s" , bottom: "m"}}>
                    <ColorDiv>
                        {item.description}
                    </ColorDiv>
                </Box>

                <Button
                    iconName={item.iconName}
                    disabled={false}
                    href={item.redirect}
                >
                    {item.buttonText}
                </Button>
            </SingleActionCardDiv>
        </Container>
    );
}

const filterActions = (role : Roles| null) => {
    switch (role) {
        case Roles.Admin:
            return [viewPublishedReports, createNewReport]
        case Roles.SFPA_ReportAuthor:
        case Roles.DCFPA_ReportAuthor:
            return [viewPublishedReports, createNewCustomReport(role)]
        case Roles.BridgeOwner:
            return [reviewBridgesCommentary]
        case Roles.FinanceLeader:
        case Roles.BusinessLeader:
        case Roles.FGBSLeader:
        case Roles.SFPA_ReportViewer:
        case Roles.DCFPA_ReportViewer:
            return [viewPublishedReports]
        default:
            return []
    }
}

const HomePage = () => {
    const currentAssumedRole = useSelector(getAssumedRole);
    const validRoles = new Set<Roles>([Roles.Admin, Roles.FinanceLeader, Roles.BusinessLeader, Roles.FGBSLeader, Roles.SFPA_ReportAuthor, Roles.SFPA_ReportViewer, Roles.DCFPA_ReportViewer, Roles.DCFPA_ReportAuthor]);
    const recentReportsTable = <Div>
        <Container>
            <SpaceBetween size={"m"}>
            <Header variant={"h2"}>
                Recent Published Reports
            </Header>
            <RecentReportsTable reportLibrary={"Reference"} validRoles={validRoles}/>
            </SpaceBetween>
        </Container>
    </Div>

    const homepageActions = filterActions(currentAssumedRole)
    const actionCards = homepageActions.map(action => SingleActionCard(action))
    return (
        <ContentLayout
            header={
                <Div>
                    <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" >
                        FinPress
                    </Box>
                    <Box variant="p">
                        <span>
                            The bridging and commentary tool enabling traceable financial report generation, collaboration, review and distribution.
                        </span>
                    </Box>
                </Div>
            }
        >
            <SpaceBetween size={"xxl"}>
                <Div>
                    <Box>
                        <ActionCardsDiv>
                            {actionCards}
                            <MECCalendar/>
                        </ActionCardsDiv>
                    </Box>
                </Div>
                {currentAssumedRole && validRoles.has(currentAssumedRole) && recentReportsTable}
                {howItWorks}
            </SpaceBetween>
        </ContentLayout>
    );
}

export default HomePage;
