import {createEntityAdapter, createSlice} from '@reduxjs/toolkit'


export type Workflow = {
    workflow_id : string,
    execution_id : string,
    created_by : string,
    workflow_name : string
    facet_id : string,
    facet_output_location : string
}
const workflowAdapter = createEntityAdapter({
    selectId: (workflow : Workflow) => workflow.workflow_id,
    sortComparer: (a, b) => a.execution_id.localeCompare(b.execution_id)
})

const workflowSlice = createSlice({
    name: 'workflow',
    initialState: workflowAdapter.getInitialState(),
    reducers: {
        addWorkflow: workflowAdapter.addOne,
        removeWorkflow: workflowAdapter.removeOne,
        updateWorkflow: workflowAdapter.updateOne
    }
})


export const {addWorkflow, removeWorkflow, updateWorkflow} = workflowSlice.actions

export const workflowReducer =  workflowSlice.reducer

export const {
    selectAll : selectWorkflows,
    selectById : selectWorkflowById,
    selectEntities : selectWorkflowEntities
} = workflowAdapter.getSelectors((state : any) => state.workflow)