import {FormField, Grid, Input, Select, SelectProps} from "@amzn/awsui-components-react";
import Container from "@amzn/awsui-components-react/polaris/container";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import Header from "@amzn/awsui-components-react/polaris/header";
import React from "react";
import {FINANCE_PERIODS, getYears, PERIODS} from "src/components/CreateReportPage/constants";

import {FolderDescriptionProperty, FolderDescriptionStepProps} from "./interfaces";

const FolderDescriptionForm = (props : FolderDescriptionStepProps) => {
    const {folderWorkflow, folderDescription, setFolderDescription, errorTexts, setStepErrors} = props
    const resetErrorMessage = () => {
        const errorOccurred = Object.keys(errorTexts).length > 0;
        errorOccurred && setStepErrors((stepErrors) => {return {...stepErrors, 1: {}}});
    };

    function constructSelectField(property: FolderDescriptionProperty, selectedOption: string,
                                  options: SelectProps.Options, otherProps: object = {}){
        return(
            <Select
                selectedOption={{label: selectedOption}}
                options={options}
                selectedAriaLabel='Selected'
                onChange={ ({detail}) => {
                    resetErrorMessage();
                    setFolderDescription({
                        ...folderDescription,
                        [property]: detail.selectedOption.label ?? ""
                    })
                }}
                {...otherProps}
            />
        )
    }

    // title: "Folder name",
    return(
        <Container>
            <Header variant="h2"
                    description="Please select the report type and fill out the report name for what you would like
                    shown throughout the report creation journey."
            >
                {`${folderWorkflow} folder`}
            </Header>
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 2 }, { colspan: 2 }, { colspan: 2}]}>
                <FormField label="Review Name" stretch = {true} errorText={errorTexts['folderName']}>
                    <Input
                        value={folderDescription.folderName}
                        onChange={event => {
                            resetErrorMessage()
                            if (event.detail.value.length <= 60) {
                                setFolderDescription({...folderDescription, folderName: event.detail.value});
                            }
                        }}
                        placeholder={"Name the review"}
                    />
                </FormField>
                <FormField label="Period" errorText={errorTexts['period']}>
                    {constructSelectField('period', folderDescription.period, PERIODS)}
                </FormField>
                <FormField label="Year" errorText={errorTexts["year"]}>
                    {constructSelectField('year', folderDescription.year, getYears())}
                </FormField>
                <FormField label="Finance Cycle" stretch = {true} errorText={errorTexts["financeCycle"]}>
                    {constructSelectField('financeCycle', folderDescription.financeCycle, FINANCE_PERIODS, {placeholder: "Make a selection"})}
                </FormField>
            </Grid>
            {/* todo - looks like DatePicker max-width is not being over ridden see https://sage.amazon.com/posts/1472748 */}
            <Grid gridDefinition={[{ colspan: 6 }]}>
                <FormField label="Review date" stretch = {true} errorText={errorTexts['reviewDate']}>
                    <DatePicker
                        onChange={({ detail }) => {
                            resetErrorMessage();
                            setFolderDescription({...folderDescription, reviewDate: detail.value});
                        }}
                        value={folderDescription.reviewDate}
                        openCalendarAriaLabel={selectedDate =>
                            "Choose Date" +
                            (selectedDate
                                ? `, selected date is ${selectedDate}`
                                : "")
                        }
                        nextMonthAriaLabel="Next month"
                        placeholder="YYYY/MM/DD"
                        previousMonthAriaLabel="Previous month"
                        todayAriaLabel="Today"
                    />
                </FormField>
            </Grid>
            <Grid gridDefinition={[{ colspan: 6 }]}>
                <FormField label="Review attendees"
                           stretch = {true}
                           description="Enter comma separated list of user ids"
                           errorText={errorTexts['attendees']}
                >
                    <Input
                        value={folderDescription.attendees}
                        onChange={event => {
                            resetErrorMessage()
                            if (event.detail.value.length <= 60) {
                                setFolderDescription({...folderDescription, attendees: event.detail.value});
                            }
                        }}
                        placeholder={"Enter Amazon alias"}
                    />
                </FormField>
            </Grid>
        </Container>
    );
}

export default FolderDescriptionForm;
