import React from "react";

export interface ReportLibraryTableProps {
    headerTitle: string,
    description: string,
    headerActions: React.ReactNode,
    reportActions: Function,
    confirmationModal: JSX.Element,
    reportLibrary: string,
    showBridgeStatus : boolean
}

export enum ReportTableColumns {
    ReportType = "Report type",
    ReportName = "Review / Report name",
    FinanceCycle = "Finance cycle",
    FinanceYear = "Finance year",
    BridgingAndCommentary = "Bridging & Commentary",
    LastEdited = "Date of last edit",
    Status = "Status",
    ReportAccess = "Report access",
    Actions = "Actions"
}