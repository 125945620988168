const LOADER = '[API_LOADER]';
export const SET_LOADING = `${LOADER} SET`;
export const RESET_LOADING = `${LOADER} RESET`;


export interface APILoadingAction {
    type: string,
    payload: {
        entity: string,
        loading: boolean
    }
}


// Action to set api loading status
export const setAPILoading = (entity: string): APILoadingAction => ({
    type: SET_LOADING,
    payload: {entity, loading: true}
});


// Action to reset the api loading status
export const resetAPILoading = (entity: string): APILoadingAction => ({
    type: RESET_LOADING,
    payload: {entity, loading: false}
});
