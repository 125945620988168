import {Alert, SpaceBetween} from "@amzn/awsui-components-react";
import Table from "@amzn/awsui-components-react/polaris/table";
import React from "react";
import {BridgeTableRowItem} from "src/components/Bridging/BridgeTableConfig";
import {DISABLED_BRIDGE_ITEMS} from "src/components/Bridging/constants";
import {
    BridgeItemsTableProps,
    BridgeTableColumns
} from "src/components/Bridging/interfaces";
import {Cell} from "src/components/FillOutBridgePage/InputComponents";

import {EmptyTableState} from "../MDXLibraryPage/MDXLibraryTable";


const SELECT_BRIDGE_ITEMS_COLUMN_DEFINITIONS = [
    {
        id: BridgeTableColumns.LineItem,
        header: BridgeTableColumns.LineItem,
        cell: (e: BridgeTableRowItem) => e.bridge_item_name,
        label: BridgeTableColumns.LineItem,
    },
    {
        id: BridgeTableColumns.Variance,
        header: BridgeTableColumns.Variance,
        cell: (e: BridgeTableRowItem) => <Cell marginLeft="1px" valueCell={true}>{e.variance_data.variance}</Cell>,
        label: BridgeTableColumns.Variance,
    },
    {
        id: BridgeTableColumns.TagOwnerGroup,
        header: BridgeTableColumns.TagOwnerGroup,
        cell: (e: BridgeTableRowItem) => e.bridge_owner_group,
        label: BridgeTableColumns.TagOwnerGroup,
        minWidth: 400,
    },
];

const BridgeItemsTable = (props: BridgeItemsTableProps) => {

    const {
        tableItems,
        selectedItems,
        setSelectedItems,
        errorText,
        setStepErrors,
        tableHeader,
        tableIsLoading,
        existingBridgeItemNames
    } = props

    return (
        <SpaceBetween size="s" direction="vertical">
            {errorText.length > 0 &&
                <Alert type="error">{errorText}</Alert>
            }
            <Table
                onSelectionChange={({detail}) => {
                    errorText && setStepErrors((stepErrors) => {
                        return {...stepErrors, 1: ""}
                    })
                    setSelectedItems(detail.selectedItems);
                }
                }
                selectedItems={selectedItems}
                ariaLabels={{
                    selectionGroupLabel: "Items selection",
                    allItemsSelectionLabel: ({selectedItems}) =>
                        `${selectedItems.length} ${
                            selectedItems.length === 1 ? "item" : "items"
                        } selected`,
                    itemSelectionLabel: ({selectedItems}, item) => {
                        const isItemSelected = selectedItems.filter(
                            i => i.id === item.id
                        ).length;
                        return `${item.id} is ${
                            isItemSelected ? "" : "not"
                        } selected`;
                    }
                }}
                columnDefinitions={SELECT_BRIDGE_ITEMS_COLUMN_DEFINITIONS}
                items={tableItems}
                loadingText="Loading resources"
                loading={tableIsLoading}
                empty={<EmptyTableState/>}
                isItemDisabled={item => DISABLED_BRIDGE_ITEMS.includes(item.account_rollup)
                    || existingBridgeItemNames.has(item.account_rollup)}
                selectionType="multi"
                trackBy="id"
                header={
                    tableHeader
                }

            />
        </SpaceBetween>
    )
}

export default BridgeItemsTable;
