import {Button, ButtonDropdown, SpaceBetween} from "@amzn/awsui-components-react";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteReportDefinition,
    deleteReviewFolder,
    postReportPublisher,
    postReviewFolder,
    RESET_UPDATING_REPORT_DEF,
    setUpdatingExternalReportDefinitions,
    setUpdatingReportDefinitions,
    setUpdatingReviewFolderDefinitions
} from "src/actions/reportLibrary.actions";
import {ItemType, Libraries, ReportDefinition, ReportTriggerEvent, ReviewFolderState} from "src/common/report";
import {Roles} from "src/common/roles";
import Paths from "src/components/PageConfig/Paths";
import ConfirmationModal from "src/components/ReportLibraryPage/ConfirmationModal";
import {
    ACTIVE_REPORTS_TITLE,
    DELETE,
    DELETE_REVIEW,
    DUPLICATE,
    DUPLICATE_REVIEW,
    EDIT_EXTERNAL,
    EDIT_INTERNAL,
    EDIT_REVIEW,
    MOVE_OUT_OF_REVIEW,
    MOVE_TO_ARCHIVE,
    MOVE_TO_PUBLSIHED,
    WARNING_DELETE,
    WARNING_MOVE_TO_ARCHIVE,
    WARNING_MOVE_TO_REFERENCE,
    WARNING_REMOVE_FROM_FOLDER
} from "src/components/ReportLibraryPage/constants";
import {createDuplicateReportDefinition, getAvailableActions} from "src/components/ReportLibraryPage/helpers";
import ReportLibrary from "src/components/ReportLibraryPage/ReportLibrary";
import {
    getReportDefinitionsApiLoading,
    getReportPublisherApiLoading,
    getReviewFolderApiLoading
} from "src/reducers/apiLoading.reducer";
import {getReportLibraryStatus, getReports} from "src/reducers/reportLibrary.reducer";
import {getAssumedRole} from "src/reducers/user.reducer";
import {filterExternalReportState, filterReportState} from "src/utils/reportHelpers";
import {v4 as uuid} from "uuid";


const ActiveReportLibraryPage = () => {
    const dispatch = useDispatch();
    const tableData = useSelector(getReports)

    const headerActions =
        <SpaceBetween direction='horizontal' size='xs'>
            <Button
                href={`#${Paths.CREATEREVIEW}`}
                variant="normal"
                onClick={() => dispatch(setUpdatingReviewFolderDefinitions({report_id: ""}))}
            >
                Create review
            </Button>
            <Button
                href={`#${Paths.EXTERNALREPORT}`}
                variant="normal"
                onClick={() => dispatch(setUpdatingExternalReportDefinitions({report_id: ""}))}
            >
                External report
            </Button>
            <Button
                href={`#${Paths.CREATEREPORT}`}
                variant="primary"
                onClick={() => dispatch({type: RESET_UPDATING_REPORT_DEF})}
            >
                Create report
            </Button>
        </SpaceBetween>

    // Confirmation modal applicable states
    const [isConfirmationModalReferencesVisible, setIsConfirmationModalReferencesVisible] = useState<boolean>(false);
    const [confirmationText, setConfirmationText] = useState<string>("");
    const [onClickFunction, setOnClickFunction] = useState(() => () => {console.log("Default handler")});
    const [redirectUrl, setRedirectUrl] = useState<string>(window.location.href);

    const assumedRole = useSelector(getAssumedRole);
    const {publishedReport, deletedReportDefinitions, updatedReviewFolders, deletedReviewFolder, errorType, errorMessage} = useSelector(getReportLibraryStatus);
    const reportDefinitionsApiIsLoading = useSelector(getReportDefinitionsApiLoading);
    const reportPublisherApiIsLoading = useSelector(getReportPublisherApiLoading);
    const reviewFolderApiIsLoading = useSelector(getReviewFolderApiLoading);

    const reportActions = (report: ReportDefinition, reviewFolderId: string) => <ButtonDropdown
        data-testid={'active-report-library-actions'}
        expandToViewport = {true}
        items={getAvailableActions(assumedRole, "Active", report.item_type, reviewFolderId !== "")}
        ariaLabel="Control instance"
        variant="icon"
        onItemClick={event => {
            let updatedReportState: ReportDefinition;
            switch (event.detail.id) {
                case EDIT_REVIEW:
                    updatedReportState= {
                        ...report,
                        report_status: "Updating"
                    };
                    dispatch(setUpdatingReviewFolderDefinitions(updatedReportState));
                    window.location.replace(`#${Paths.CREATEREVIEW}`);
                    break;
                case DUPLICATE_REVIEW:
                    updatedReportState = {
                        ...report,
                        report_id: `review_folder_id.${uuid()}`,
                        report_status: "Creating",
                        name_and_type: {
                            ...report.name_and_type,
                            report_name: `${report.name_and_type?.report_name}-DUPLICATE`
                        }
                    };
                    dispatch(setUpdatingReviewFolderDefinitions(updatedReportState));
                    window.location.replace(`#${Paths.CREATEREVIEW}`);
                    break;
                case DELETE_REVIEW:
                    const deleteReviewHandler= () => () => {
                        dispatch(deleteReviewFolder(report));
                    }
                    setOnClickFunction(deleteReviewHandler);
                    setIsConfirmationModalReferencesVisible(true);
                    setConfirmationText(WARNING_DELETE);
                    break;
                case EDIT_EXTERNAL:
                    if(reviewFolderId !== "") { // in folder external report
                        updatedReportState= {
                            ...filterExternalReportState(report),
                            report_status: "Updating"
                        };
                        dispatch(setUpdatingExternalReportDefinitions(updatedReportState));
                        window.location.replace(`#${Paths.EXTERNALREPORT}`);
                    } else { // outside of folder external report
                        updatedReportState= {
                            ...filterExternalReportState(report),
                            report_status: "Updating"
                        };
                        dispatch(setUpdatingExternalReportDefinitions(updatedReportState));
                        window.location.replace(`#${Paths.EXTERNALREPORT}`);
                    }
                    break;
                case EDIT_INTERNAL:
                    if(reviewFolderId !== "") { // in folder internal report
                        updatedReportState= {
                            ...filterReportState(report),
                            report_status: "Updating",
                            triggered_event: ReportTriggerEvent.EDIT
                        };
                        dispatch(setUpdatingReportDefinitions(updatedReportState));
                        window.location.replace(`#${Paths.CREATEREPORT}`);
                    } else { // outside of folder internal report
                        updatedReportState = {
                            ...filterReportState(report),
                            report_status: "Updating",
                            triggered_event: ReportTriggerEvent.EDIT
                        };
                        dispatch(setUpdatingReportDefinitions(updatedReportState));
                        window.location.replace(`#${Paths.CREATEREPORT}`);
                    }
                    break;
                case MOVE_OUT_OF_REVIEW:
                    const folder = tableData.find(i => i.report_id == reviewFolderId);
                    const updatedReportIds = (folder as ReviewFolderState)?.report_ids?.filter(report_id => report_id !== report.report_id);
                    const removeFromFolder = () => () => {
                        if (folder) {
                            dispatch(postReviewFolder(
                                [{
                                    ...folder,
                                    report_ids: updatedReportIds
                                }],
                            ));
                        }
                    }
                    setOnClickFunction(removeFromFolder);
                    setIsConfirmationModalReferencesVisible(true);
                    setConfirmationText(WARNING_REMOVE_FROM_FOLDER);
                    break;
                case MOVE_TO_PUBLSIHED:
                    const libraryPublisherToReferenceLibrary = () => () => {
                        dispatch(postReportPublisher(
                            report.report_id,
                            Roles.FinanceLeader,
                            Libraries.Reference
                        ));
                        setRedirectUrl(`#${Paths.REFERENCEREPORTS}`);
                    }
                    setOnClickFunction(libraryPublisherToReferenceLibrary);
                    setIsConfirmationModalReferencesVisible(true);
                    setConfirmationText(WARNING_MOVE_TO_REFERENCE);
                    break;
                case MOVE_TO_ARCHIVE:
                    const libraryPublisherToArchiveLibrary = () => () => {
                        dispatch(postReportPublisher(
                            report.report_id,
                            Roles.FinanceLeader,
                            Libraries.Archive));
                        setRedirectUrl(`#${Paths.ARCHIVEREPORTS}`);
                    }
                    setOnClickFunction(libraryPublisherToArchiveLibrary);
                    setIsConfirmationModalReferencesVisible(true);
                    setConfirmationText(WARNING_MOVE_TO_ARCHIVE);
                    break;
                case DUPLICATE:
                    if(report.item_type === ItemType.External) {
                        updatedReportState = filterExternalReportState(report);
                        dispatch(setUpdatingExternalReportDefinitions({
                            ...updatedReportState,
                            report_id: `report_id.${uuid()}`,
                            report_status: 'Creating',
                            name_and_type: {
                                ...updatedReportState.name_and_type,
                                report_name: "name_duplicate"
                            }
                        }));
                        window.location.replace(`#${Paths.EXTERNALREPORT}`);
                    } else {
                        const updatedInternalReportState = createDuplicateReportDefinition(report)
                        dispatch(setUpdatingReportDefinitions(updatedInternalReportState));
                        window.location.replace(`#${Paths.CREATEREPORT}`);
                    }
                    break;
                case DELETE:
                    const deleteReportHandler = () => () => {
                        dispatch(deleteReportDefinition(report.report_id));
                    }
                    setOnClickFunction(deleteReportHandler);
                    setIsConfirmationModalReferencesVisible(true);
                    setConfirmationText(WARNING_DELETE);
                    break;
            }
        }}
    />

    const confirmationModal = ConfirmationModal(
        isConfirmationModalReferencesVisible,
        setIsConfirmationModalReferencesVisible,
        redirectUrl,
        confirmationText,
        onClickFunction,
        "Active",
        reportDefinitionsApiIsLoading || reportPublisherApiIsLoading || reviewFolderApiIsLoading,
        !!(publishedReport || deletedReportDefinitions || updatedReviewFolders || deletedReviewFolder),
        errorType ? `${errorType}: ${errorMessage}` : "",
    );

    return ReportLibrary(
        ACTIVE_REPORTS_TITLE,
        "This is a collection of all the active reports",
        `#${Paths.ACTIVEREPORTS}`,
        headerActions,
        reportActions,
        confirmationModal,
        "Active",
        false
    );
}

export default ActiveReportLibraryPage;