import styled from "styled-components";


export type VersionNumberVariety = 'default' | 'latest' | 'sync';

const whiteBlue = '#f2f8fd'
const paleBlue = '#d3e7f9'
const lightBlue = '#0972d3'
const mediumBlue = '#0452a2'
const darkBlue = '#033160'
const whiteMagenta = '#fceff6'
const paleMagenta = '#f5d0e5'
const lightMagenta = '#dc63a8'
const mediumMagenta = '#ab4d83'
const darkMagenta = '#924270'
const white = '#ffffff'

// Used to map CSS colors to different button varieties
const colorMapping: any = {
    'latest': {
        border:lightBlue,
        selectedBorder: white,
        color: lightBlue,
        selectedColor: white,
        selectedBackground: lightBlue,
        background: white,
        hoverBorder: mediumBlue,
        hoverColor: mediumBlue,
        selectedHoverColor: white,
        hoverBackground: whiteBlue,
        selectedHoverBackground: mediumBlue,
        activeBorder: darkBlue,
        activeColor: darkBlue,
        selectedActiveColor: white,
        activeBackground: paleBlue,
        selectedActiveBackground: darkBlue
},
    'default': {
        border: lightBlue,
        selectedBorder: lightBlue,
        color: lightBlue,
        selectedColor: white,
        selectedBackground: lightBlue,
        background: white,
        hoverBorder: mediumBlue,
        hoverColor: mediumBlue,
        selectedHoverColor: white,
        hoverBackground: whiteBlue,
        selectedHoverBackground: mediumBlue,
        activeBorder: darkBlue,
        activeColor: darkBlue,
        selectedActiveColor: white,
        activeBackground: paleBlue,
        selectedActiveBackground: darkBlue
    },
    'sync': {
        border: lightMagenta,
        selectedBorder: lightMagenta,
        color: lightMagenta,
        selectedColor: white,
        selectedBackground: lightMagenta,
        background: white,
        hoverBorder: mediumMagenta,
        hoverColor: mediumMagenta,
        hoverColorSelected: white,
        hoverBackground: whiteMagenta,
        selectedHoverBackground: mediumMagenta,
        activeBorder: darkMagenta,
        activeColor: darkMagenta,
        selectedActiveColor: white,
        activeBackground: paleMagenta,
        selectedActiveBackground: darkMagenta
    }
}

export const VersionButton = styled.button<{variety: VersionNumberVariety, selected?: boolean}>`
    border-radius: .5rem;
    border: .1em ${props => props.variety === 'latest' ? 'dashed' : 'solid'} ${props => colorMapping[props.variety][props.selected ? 'selectedBorder': 'border']};

    color: ${props => colorMapping[props.variety][props.selected ? 'selectedColor' :'color']};
    background: ${props => colorMapping[props.variety][props.selected  ? 'selectedBackground' : 'background']}; 
    
    padding: 0.2em 0.8em 0.2em 0.8em;
  
    cursor: pointer;
    appearance: none;

    -webkit-appearance: none;
    -moz-appearance: none;
    
    &:hover {
        border-color: ${props => colorMapping[props.variety]['hoverBorder']};
        color: ${props => colorMapping[props.variety][props.selected ? 'selectedHoverColor' : 'hoverColor']};
        background: ${props => colorMapping[props.variety][props.selected ? 'selectedHoverBackground' : 'hoverBackground']};
    }
    
    &:active {
      border-color: ${props => colorMapping[props.variety]['activeBorder']};
      color: ${props => colorMapping[props.variety][props.selected ? 'selectedActiveColor' : 'activeColor']};
      background: ${props => colorMapping[props.variety][props.selected ? 'selectedActiveBackground' : 'activeBackground']};
    }
`

export const VersionBadge = styled.div<{}>`
    padding: 0.2em 0.5em 0.2em 0.5em;
    border-radius: .5rem;
    border: .1em solid ${lightBlue};
    background: ${white};
    color: ${lightBlue};
    display: inline-block;
`
