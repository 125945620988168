import {Dictionary} from "@reduxjs/toolkit";
import {BridgeItem, BridgeStatusType} from "src/components/BridgingCommentary/interfaces";


export enum FastTheme {
  operatingIncome = 'Operating Income',
  capex = 'Capex',
  fcf = 'Free Cash Flow',
  headcount = 'Headcount'

}

const fastThemes: Dictionary<Set<string>> = {
  [FastTheme.operatingIncome]: new Set([
    'Total Revenue', 'Infrastructure', 'Sales', 'Marketing', 'Global Services', 'Hosted Software',
    'Bad Debt', 'Cost of Payments', 'Service Variable Costs', 'Finance Judgement', 'Variable Allocations',
    'Direct Fixed Costs', 'Fixed Allocations', 'COGS'
  ]),
  [FastTheme.capex]: new Set(['AWS Server Capex', 'AWS Data Center Capex', 'AWS Network Capex', 'AWS Non-Infra Capex', 'SDO Capex']),
  [FastTheme.fcf]: new Set([
    'Operating Income', 'Depreciation add back', 'SBC add back', 'OOEI/Other Impact', 'Tax Impact',
    'Capex', 'Change In Deferred Revenue', 'Change In Accounts Receivable', 'Change In Accounts Payable',
    'Change In Inventory', 'Corporate Allocations'
  ]),
  [FastTheme.headcount]: new Set(['AWS Fixed HC', 'Infra Fixed HC', 'AWS Variable HC', 'Infra Variable HC'])
}

export const getFastTheme = (bridges: BridgeItem[]): FastTheme | undefined => {
  if (!bridges) return undefined

  const bridgeFastThemes = bridges.map(bridge =>
    Object.entries(fastThemes).reduce((prevFastTheme: FastTheme | undefined, themeMapping) => {
      if (prevFastTheme) return prevFastTheme
      const fastTheme: FastTheme = themeMapping[0] as FastTheme
      const bridgeNames = themeMapping[1]
      return bridgeNames?.has(bridge.account_rollup) ? fastTheme : undefined
    }, undefined))

  return bridgeFastThemes.every(ft => ft === bridgeFastThemes[0]) ? bridgeFastThemes[0] : undefined
}

export const filterFastThemeBridges = (fastTheme: FastTheme, bridges: BridgeItem[], scenario: string) => {
  const bridgeNames = fastThemes[fastTheme]
  return bridges.filter(bridge =>
    bridgeNames?.has(bridge.account_rollup) &&
    bridge.status === BridgeStatusType.Published &&
    bridge.variance_headers.variance_header === scenario
  )
}

export const validateBridgesInSameScenario = (selectedBridges: BridgeItem[]) => {
  if (!selectedBridges) return false;
  const scenario = selectedBridges[0].variance_headers.variance_header
  return selectedBridges.reduce((isSame, bridge) => isSame && bridge.variance_headers.variance_header === scenario, true)
}