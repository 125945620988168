import {Icon} from "@amzn/awsui-components-react";
import React from "react";
import styled from "styled-components";

const InstructionFieldSet = styled.fieldset`
  font-family: Amazon Ember, Arial, sans-serif;
  font-weight: 400;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: .4em;
  place-items: center;
  border-radius: 1rem;
  border-width: 2px;
  border-color: dodgerblue;

  legend {
    font-size: larger;
    font-weight: normal;
    padding: 0 1rem;
    color: #0972d3;
  }
  
  p {
    display: inline-block;
    width: 100%;
  }
`;

const InstructionMode = () => {
    return <InstructionFieldSet>
        <legend>Follow Up and Audit History</legend>
        <Icon name={'status-info'}/>
        <p>
            Click on any version above to deep dive into the history of bridge items. The version with dashed border
            is a draft and not yet submitted
        </p>
    </InstructionFieldSet>
}

export default InstructionMode;