import {Box, Button} from "@amzn/awsui-components-react";
import Link from "@amzn/awsui-components-react/polaris/link";
import React from 'react'
import {Roles} from "src/common/roles";
import {ReportLink} from "src/components/BridgingCommentary/tableComponents/ReportLink" ;
import {CustomBox, nonDollarValueRowItems} from "src/components/FillOutBridgePage/InputComponents";
import Paths from "src/components/PageConfig/Paths";
import {
    ViewBridgeModalFooterProps,
    ViewBridgeModalHeaderProps
} from "src/components/ViewReportPage/SingleBridgeView/interfaces";
import {BridgeName} from "src/components/ViewReportPage/SingleBridgeView/Styled";
import {deltaSymbolUnicode} from "src/components/ViewReportPage/SingleBridgeView/ViewBridgeTable";
import {formatValue} from "src/utils/bridgingHelpers";

export const ViewBridgeModalHeader = ({bridgeItem, firstSubItem}: ViewBridgeModalHeaderProps) => {

    // Constants
    const formattedVarianceValue = formatValue(
        firstSubItem.varianceValue,
        !nonDollarValueRowItems.has(bridgeItem.account_rollup),
        true
    )
    const headerLine = `${deltaSymbolUnicode} ${bridgeItem.account_rollup}: ${formattedVarianceValue}`;

    return(<>
        <BridgeName>
            <span>{headerLine}</span>
            <span>
                <Link external href={`#${Paths.FILLOUTBRIDGE}?bridge_id=${bridgeItem.bridge_id}`}>
                    See Versions
                </Link>
            </span>
        </BridgeName>
        <Box textAlign="left" fontSize="body-m" color="text-body-secondary" fontWeight="light">
            {`${bridgeItem.variance_headers.variance_header} from `}
            <ReportLink
                reportName={bridgeItem.report_name}
                reportId={bridgeItem.report_id}
                role={Roles.Admin}
            />
        </Box>
    </>);
}

export const ViewBridgeModalFooter = ({bridgeItem, viewAllBridgesHandler, dismissModalHandler}: ViewBridgeModalFooterProps) => {

    return(
        <CustomBox>
            <Link onFollow={viewAllBridgesHandler} variant="primary">
                {`Show more from ${bridgeItem.variance_headers.variance_header}`}
            </Link>
            <Box float="right">
                <Button onClick={() => dismissModalHandler()}>
                    Close
                </Button>
            </Box>
        </CustomBox>
    );
}