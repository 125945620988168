import {
    Box,
    Button,
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TableProps,
    TextFilter
} from "@amzn/awsui-components-react";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getJobGroup, getJobGroups, postJob, resetLibraryStatus} from "src/actions/mdxLibrary.actions";
import {paginationAriaLabels} from "src/common/labels";
import {Roles} from "src/common/roles";
import {ExpandedGroup, MDXJob, MDXTableRow} from "src/components/MDXLibraryPage/interfaces";
import JobDeleteModal from "src/components/MDXLibraryPage/JobDeleteModal";
import Status from "src/components/MDXLibraryPage/JobStatus";
import MDXGroupModal from "src/components/MDXLibraryPage/MDXGroupModal";
import {GROUPS_PER_PAGE, GROUPS_REFRESH_INTERVAL_MIN,} from "src/constants/mdxLibraryConstatnts";
import {getJobGroupsApiLoading} from "src/reducers/apiLoading.reducer";
import {getMdxLibraryStatus, getPaginationConfig, LibraryGroup} from "src/reducers/mdxLibrary.reducer";
import {getAvailableRoles} from "src/reducers/user.reducer";
import {
    aggregateJobStatus,
    collectInProgressGroups,
    findJobGroups,
    getGroupsForPage,
    getTotalPages,
    sortTableData
} from "src/utils/mdxLibraryHelpers";
import {humanizeEpoch} from "src/utils/timeHelpers";


const MDXLibraryTable = ({jobGroups}: {jobGroups: {[p:string]: LibraryGroup}}) => {
    const dispatch = useDispatch();
    // Redux selectors
    const {fetchedGroups} = useSelector(getMdxLibraryStatus);
    const jobGroupsApiLoading = useSelector(getJobGroupsApiLoading);
    const isAdmin = useSelector(getAvailableRoles).includes(Roles.Admin);
    const {paginationKey, hasMore} = useSelector(getPaginationConfig);
    // States for mdx group create/update modal
    const [enableCreateModal, setEnableCreateModal] = useState(false);
    const [editGroup, setEditGroup] = useState({});
    // States for job delete modal
    const [enableConfirmDeleteModal, setEnableConfirmDeleteModal] = useState(false);
    const [deleteJob, setDeleteJob] = useState<MDXJob | undefined>();
    // States for UI table
    const [pageIndex, setPageIndex] = useState(1);
    const [groupsForPage, setGroupsForPage] = useState<{[p:string]: LibraryGroup}>({});
    const [descendingSorting, setDescendingSorting] = useState(false);
    const [sortingColumn, setSortingColumn] = useState<TableProps.SortingColumn<MDXTableRow>>();
    const [groupSearchText, setGroupSearchText] = useState("");
    const [expandedGroups, setExpandedGroups] = useState<ExpandedGroup>({});

    // Constants
    const totalPages = groupSearchText.length > 0 ? 1 : getTotalPages(jobGroups);
    const tableLoading = jobGroupsApiLoading && !Object.keys(groupsForPage).length;

    // Column definition for MDX query library table
    const columnDefinition = useMemo(() => {
        const columns = [
            {
                id: "group",
                header: "Group name/TM1 instance",
                cell: (e: MDXTableRow) => e.instance || e.group,  // Try to use instance name first, fallback to group name
                sortingField: "group"
            },
            {
                id: "cube",
                header: "TM1 cube",
                cell: (e: MDXTableRow) => e.cube,
            },
            {
                id: "year",
                header: "Year",
                cell: (e: MDXTableRow) => e.year
            },
            {
                id: "scenario",
                header: "Scenario",
                cell: (e: MDXTableRow) => e.scenario
            },
            {
                id: "query_type",
                header: "Query type",
                cell: (e: MDXTableRow) => e.query_type,
            },
            {
                id: "last_refreshed",
                header: "Last refreshed",
                cell: (e: MDXTableRow) => e.last_refreshed,
                sortingField: "last_refreshed"
            },
            {
                id: "status",
                header: "Status",
                cell: (e: MDXTableRow) => e.status
            },
        ];
        // add action column definition only if user is admin
        isAdmin && columns.push({id: "action", header: "Action", cell: (e: MDXTableRow) => e.action,});

        return columns;
    }, [isAdmin]);

    // setup timer to refresh the in-progress or pending groups from current redux store
    useEffect(function setupTimerToRefreshGroups(){
        const timer = setInterval(() => {
            const groupNameSet = collectInProgressGroups(jobGroups);
            groupNameSet.forEach(groupName => {
                dispatch(getJobGroup(groupName));
            });
        }, GROUPS_REFRESH_INTERVAL_MIN * 60 * 1000);

        return function stopTimer() {
            clearInterval(timer)
        };

    }, [jobGroups]);


    useEffect(() => {
        const pageNumber = (fetchedGroups && pageIndex > totalPages && !tableLoading) ? totalPages : pageIndex;
        setPageIndex(pageNumber)
        setGroupsForPage(getGroupsForPage(jobGroups, pageNumber));
        // Reset library status if it was group refresh call
        fetchedGroups && dispatch(resetLibraryStatus());
    }, [fetchedGroups, jobGroups]);

    // Handler to set edit group
    const onClickEditGroup = (groupName: string) => {
        setEditGroup({[groupName]: {...jobGroups[groupName]}});
        setEnableCreateModal(true);
    }

    // Handler to control the tree view of current group in the table
    const groupTreeViewControl = (groupName: string) => {
        const groupIsExpanded = expandedGroups[groupName];
        setExpandedGroups({...expandedGroups, [groupName]: !groupIsExpanded});
    };

    // Handler to refresh/cancel/delete the given job in the group
    const onClickJobRowButton = (job: MDXJob, action = "refresh") => {
        if (action === 'refresh' || action === 'cancel') {
            // dispatch to update detail of given job
            dispatch(postJob(job.group, job.job_id, action));
        }
        else if (action === 'delete') {
            // prepare delete modal
            setDeleteJob(job);
            setEnableConfirmDeleteModal(true);
        }
    };

    // Handler to change sorting order/column of table rows
    const onSortingChange = ({isDescending, sortingColumn}: TableProps.SortingState<MDXTableRow>) => {
        const descending = isDescending === undefined ? true : isDescending;
        const sortKey = sortingColumn.sortingField || "last_refreshed"; // by default use last_refreshed key to sort
        setDescendingSorting(descending);
        setSortingColumn(sortingColumn);
        setGroupsForPage(sortTableData(groupsForPage, sortKey, !descending));
    };

    // Handler when group search is initiated
    const onGroupSearch = (searchText: string) => {
        // paginate matching groups
        const matchingGroups = searchText.length > 0 ? findJobGroups(jobGroups, searchText)
            : getGroupsForPage(jobGroups, 1);
        setGroupsForPage(matchingGroups);
        setGroupSearchText(searchText);
        setPageIndex(1);
    }

    // handler to set the groups for new page number
    const onPageChange = (newPageNumber:number, requestedPageAvailable: boolean) => {
        if (!requestedPageAvailable){
            dispatch(getJobGroups({paginationKey, limit: GROUPS_PER_PAGE}))
        }
        setPageIndex(newPageNumber);
        setGroupsForPage(getGroupsForPage(jobGroups, newPageNumber));
    };

    // Helper function to construct jobs row of given group
    function constructJobRow(row: MDXJob): MDXTableRow {
        const jobStatus = aggregateJobStatus(row.status);
        const enableRefreshDelete = (jobStatus === 'Success' || jobStatus === 'Error' || jobStatus === 'Cancelled');
        const enableCancel = (jobStatus === 'In-progress' || jobStatus === 'Pending');

        return {
            ...row,
            group: <>{row.group}</>,
            instance: <span style={{paddingLeft: "28px"}}>{row.instance}</span>,
            status: <Status status={row.status} lastRefreshed={row.last_refreshed}/>,
            last_refreshed: humanizeEpoch({epoch: row.last_refreshed, asDate: false}),
            action: (
                <SpaceBetween direction="horizontal" size="xxxs">
                    {enableRefreshDelete &&
                        <Button iconName="refresh" variant="icon" onClick={() => onClickJobRowButton(row, 'refresh')}/>
                    }
                    {enableCancel &&
                        <Button iconName="close" variant="icon" onClick={() => onClickJobRowButton(row, 'cancel')}/>
                    }
                    {enableRefreshDelete &&
                        <Button iconName="status-negative" variant="icon" onClick={() => onClickJobRowButton(row, 'delete')}/>
                    }
                </SpaceBetween>
            )
        };
    }

    // General helper function to construct rows of mdx query table
    function constructTableRows (tableData: {[p:string]: LibraryGroup}): MDXTableRow[] {
        const rows: MDXTableRow[] = [];

        Object.keys(tableData).forEach(groupName => {
            // determine the group expansion state icon name
            const iconName = expandedGroups[groupName] ? "treeview-collapse" : "treeview-expand";

            // add the group name first
            rows.push({
                job_id: groupName,
                group: (
                    <>
                        <Button iconName={iconName} variant="icon" onClick={() => groupTreeViewControl(groupName)}/>
                        {groupName}
                    </>
                ),
                action: <Button iconName="edit" variant="icon" onClick={() => onClickEditGroup(groupName)}/>

            });
            // add the sub rows of the group if it is in expanded state
            if (expandedGroups[groupName]){
                tableData[groupName].jobs.forEach((row: MDXJob) => rows.push(constructJobRow(row)));
            }
        });
        return rows;
    }
    return (
        <>
            { deleteJob &&
                <JobDeleteModal job={deleteJob}
                                visible={enableConfirmDeleteModal}
                                setVisible={setEnableConfirmDeleteModal}
                />
            }
            <MDXGroupModal initialGroup={editGroup}
                           resetInitialGroup={() => setEditGroup({})}
                           visible={enableCreateModal}
                           setVisible={setEnableCreateModal}
            />
            <Table
                variant="full-page"
                columnDefinitions={columnDefinition}
                items={constructTableRows(groupsForPage)}
                sortingColumn={sortingColumn}
                sortingDescending={descendingSorting}
                onSortingChange={event => onSortingChange(event.detail)}
                loadingText="Loading resources"
                loading={tableLoading}
                stickyHeader={true}
                header={
                    <Header counter={`(${Object.keys(jobGroups).length})`}
                            variant="h1"
                            actions={ isAdmin &&
                                <SpaceBetween size='s' direction='horizontal'>
                                    <Button onClick={() => {
                                        const updatedExpandedGroups: ExpandedGroup = {};
                                        Object.keys(jobGroups).forEach(groupName =>
                                            updatedExpandedGroups[groupName] = false
                                        );
                                        setExpandedGroups(updatedExpandedGroups);
                                    }}>
                                        Collapse all
                                    </Button>
                                    <Button variant="primary" onClick={() => setEnableCreateModal(true)}>
                                        Create MDX group
                                    </Button>
                                </SpaceBetween>
                            }
                    >
                        MDX Query Library
                    </Header>
                }
                empty={<EmptyTableState/>}
                filter={
                    <TextFilter filteringPlaceholder={"enter group name"}
                                filteringText={groupSearchText}
                                onChange={({detail}) => onGroupSearch(detail.filteringText)}
                                countText={`${Object.keys(groupsForPage).length} matches`}
                    />
                }
                pagination={
                    <Pagination ariaLabels={paginationAriaLabels}
                                currentPageIndex={pageIndex}
                                onChange={({detail}) =>
                                    onPageChange(detail.currentPageIndex, true)
                                }
                                onNextPageClick={({detail}) =>
                                    onPageChange(detail.requestedPageIndex, detail.requestedPageAvailable)
                                }
                                openEnd={hasMore && groupSearchText.length === 0}
                                pagesCount={totalPages}
                    />
                }
            />
        </>
    );
}

export default MDXLibraryTable;

export const EmptyTableState = () => {
    return(
        <Box textAlign="center" color="inherit">
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                No resources to display.
            </Box>
        </Box>
    );
}
