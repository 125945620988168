import '@amzn/aws-fintech-fluid-table/dist/style.css';

import {
    ColumnReorderDetail,
    InlineEditSelectDetail,
    Row,
    RowAction,
    RowActionDetail, RowNumberingDef,
    RowNumberingType,
    RowReorderDetail,
    Table,
    TableEvent,
} from '@amzn/aws-fintech-fluid-table';
import {Dictionary} from "@reduxjs/toolkit";
import React, {useCallback} from 'react'
import {ThemesAction} from "src/actions/themes.actions";
import {DriverCommentarySelect} from "src/components/Themes/header/interfaces";
import {ThemeColumn, ThemeItem, ThemeItemType, ThemeStates, ThemeTableEvent,} from "src/components/Themes/interfaces";
import createColumnDefs from "src/components/Themes/table/columns";
import {deleteRow} from "src/components/Themes/table/deleteRow";
import {DisplayedThemeItem} from "src/components/Themes/table/interfaces";
import {getLevelItems, parseLocation} from "src/components/Themes/table/ordering";
import {reorderRow} from "src/components/Themes/table/reorderRow";
import {CommentarySettings} from "src/components/Themes/ThemesPage";

const transformCommentary = (items: ThemeItem[], commentarySettings: CommentarySettings): ThemeItem[] => items.map(item => {
    const subRows: {subRows: ThemeItem[] | undefined} = item.subRows ? {subRows: transformCommentary(item.subRows, commentarySettings)} : {subRows: undefined}
    if(item.type !== ThemeItemType.BRIDGE_SUB_ITEM) return {...item, ...subRows}
    const commentMapping: {[k in DriverCommentarySelect]: string } = {
        [DriverCommentarySelect.driver]: item.name,
        [DriverCommentarySelect.commentary]: item.commentary,
        [DriverCommentarySelect.both]: `${item.name}: ${item.commentary}`,
    }

    const isCommentaryEdited = item.commentaryAlias && (item.commentaryAlias !== commentMapping[DriverCommentarySelect.both])

    const displayedCommentary =  !commentarySettings.sourceCommentary && isCommentaryEdited ?
      item.commentaryAlias || '':
      commentMapping[commentarySettings.driverCommentarySelect || DriverCommentarySelect.both]

    return {...item, displayedCommentary, ...subRows}
})


const getCurrentParentId = (location: string, items: ThemeItem[]): string => {
    const rowLocation = parseLocation(location)
    if (rowLocation.length < 2) return ''
    return getLevelItems(rowLocation.slice(0, -2).join('.'), items)[rowLocation[rowLocation.length-2]].id
}

interface ThemeTableProps {
    state: ThemeStates,
    items: ThemeItem[],
    setItems: (themeItems: ThemeItem[]) => ThemesAction,
    columns: ThemeColumn[],
    setColumns: (columns: ThemeColumn[]) => void,
    addEvent: (event: ThemeTableEvent) => void,
    updateItem: (location: string, itemUpdates: object) => void,
    updateSourceCommentary: (sourceCommentary: boolean) => void,
    commentarySettings: CommentarySettings,
    rowNumbering: RowNumberingType
}

const ThemeTable = ({ state, items, setItems, columns, setColumns, addEvent, updateItem, updateSourceCommentary, commentarySettings, rowNumbering}: ThemeTableProps) => {
    const columnMapping: Dictionary<string> = columns.reduce((mapping: Dictionary<string>, col) => ({...mapping, [col.id]: col.name}), {})
    const onInlineEdit = useCallback((event: TableEvent<InlineEditSelectDetail<ThemeItem>>) => {
        const row = event.detail.cell.row
        const newValue = event.detail.value
        const currentParent = row.data.type === ThemeItemType.BRIDGE_SUB_ITEM ? {currentParent: getCurrentParentId(row.rowId, items)} : {}
        const itemUpdates = row.data.type === ThemeItemType.BRIDGE_SUB_ITEM ? {commentaryAlias: newValue} : {commentary: newValue}
        updateItem(row.rowId, itemUpdates)
        addEvent({
            [row.data.id]: {...currentParent, commentary: newValue, itemType: row.data.type}
        })

        row.data.type === ThemeItemType.BRIDGE_SUB_ITEM && updateSourceCommentary(false)
    }, [items])

    const onRowReorder = (event: TableEvent<RowReorderDetail<ThemeItem>>) => {
        const { droppedRow, targetParentRow, index} = event.detail;
        setItems(reorderRow(droppedRow, targetParentRow, index, items, addEvent));
    };

    const onColumnReorder = (event: TableEvent<ColumnReorderDetail<ThemeItem>>) => {
        const { columns: newColumnDefs } = event.detail;
        const newColumns = newColumnDefs.map(def => 'columnId' in def ? def.columnId : def.columnGroupId)
        setColumns(newColumns.map(id => ({id, name: columnMapping[id] || ''})));
    };

    const onDeleteRow = (event: TableEvent<RowActionDetail<ThemeItem>>) => {
        const {row} = event.detail;
        setItems(deleteRow(items, row, addEvent))
    }

    const isPublished = state === ThemeStates.Published
    const notPublishedProps = !isPublished ? {onColumnReorder, onRowReorder} : {}
    const columnDefs = createColumnDefs(columns, columnMapping, state,isPublished ? undefined : onInlineEdit)
    const rowActions: RowAction<ThemeItem>[] = [
        {name: 'Delete', actionFn: onDeleteRow, rowActionableFn: (row: Row<ThemeItem>) => row.data.type === ThemeItemType.GROUPING }
    ]

    const rowNumberingDef: RowNumberingDef<DisplayedThemeItem> = {
        type: rowNumbering,
        rowActions
    }

    return <Table<DisplayedThemeItem>
      columns={columnDefs}
      data={transformCommentary(items, commentarySettings)}
      rowExpansion={{columnId: columns[0]?.id}}
      stickyRows={{top: 1, bottom: 2}}
      stickyColumns={{left: 1}}
      columnLettering
      rowNumbering={rowNumberingDef}
      {...notPublishedProps}
    />
}

export default React.memo(ThemeTable);
