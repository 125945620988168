import {Button, Modal} from "@amzn/awsui-components-react";
import * as React from "react";
import {Theme} from "src/components/Themes/interfaces";
import styled from "styled-components";


interface ConfirmDeleteModalProps {
  onConfirm: () => void;
  onClose: () => void;
  visible: boolean;
  theme: Theme
}

const FooterButtons = styled.div`
    display: flex;
    gap: 1em;
    justify-content: right;
`

export const ConfirmDeleteModal = ({onConfirm, onClose, visible, theme}: ConfirmDeleteModalProps) => {
  const confirmHandler = () => {
    onConfirm()
    onClose()
  }

  return (
    <Modal
      data-testid="confirm-delete-modal"
      visible={visible}
      onDismiss={() => onClose()}
      closeAriaLabel={"Close modal"}
      header={`Delete ${theme.name}`}
      footer={
        <FooterButtons>
          <Button formAction={"none"} onClick={onClose}>
            Cancel
          </Button>
          <Button variant={"primary"} formAction={"none"} onClick={confirmHandler} data-testid="confirm-delete-button">
            Confirm
          </Button>
        </FooterButtons>
      }
    >
      Are you sure you want to delete the {theme.name} Theme Bridge?
    </Modal>
  );
}