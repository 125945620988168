import { Container, Link} from "@amzn/awsui-components-react";
import Header from "@amzn/awsui-components-react/polaris/header";
import React, {useState} from "react";
import BridgingCommentaryTable from "src/components/BridgingCommentary/BridgingCommentaryTable";
import {BridgeItem, BridgeStatusType, BridgingTableItem} from "src/components/BridgingCommentary/interfaces";
import {DueSoonTag, PastDueTag} from "src/components/BridgingCommentary/styling";
import {BridgingCommentaryProps} from "src/components/BridgingCommentaryPage/interfaces";
import {headerActions} from "src/components/BridgingCommentaryPage/tableAbstractions/headerComponent";
import {bridgeOwnerDetails} from "src/components/BridgingCommentaryPage/tableAbstractions/ownerComponent";
import {dueInTwoDays, getEpoch, humanizeEpoch} from "src/utils/timeHelpers";

const BridgingAndCommentary = (props : BridgingCommentaryProps) => {

    // States for delete bridge modal
    const {bridgeItems, role} = props;
    const [selectedItems, setSelectedItems] = useState<BridgingTableItem[]>([]);
    const [ownersModalVisible, setOwnersModalVisible] = useState(false);
    const [focusedBridge, setFocusedBridge] = useState<BridgeItem | null>(null);
    const ownerWidget = (item : BridgeItem) =>{
        return ( <Link href="#" onFollow={(event)=> {
            event.preventDefault()
            setFocusedBridge({...item});
            setOwnersModalVisible(true);
        }}>
            {`${item.bridge_owners.length} owners`}
        </Link>)
    }

    const dueDateWidget = (item : BridgeItem) =>{
        return (<>
            {humanizeEpoch({epoch: item.due_timestamp})}
            {DueTimeTag(item)}
        </>)
    }

    return ( <Container data-testid={'bridging-and-commentary'}>
        {bridgeOwnerDetails(
            {focusedBridge: focusedBridge,
                selectedItems : selectedItems,
                ownersModalVisible : ownersModalVisible,
                setOwnersModalVisible : setOwnersModalVisible,
                setFocusedBridge : setFocusedBridge,
                assumedRole : role})}
        <Header
            counter={`(${bridgeItems.length})`}
            variant="h1"
            actions={headerActions({bridgeItems, selectedItems})}
        >
            {"Bridges"}
        </Header>
        <BridgingCommentaryTable
            bridgeItems={bridgeItems}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            ownerWidget={ownerWidget}
            dueDateWidget={dueDateWidget}
        />
    </Container>)
}

export const DueTimeTag = (item : BridgeItem) => {
    const pastDue = item.due_timestamp <=getEpoch();
    if(pastDue){
        return <PastDueTag> Past Due</PastDueTag>
    }

    if(dueInTwoDays(getEpoch(), item.due_timestamp) && (item.status == BridgeStatusType.Requested || item.status == BridgeStatusType.Proposed)){
        return <DueSoonTag> Due Soon</DueSoonTag>
    }
}

export default BridgingAndCommentary;
