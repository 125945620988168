import {PropertyFilterProperty} from "@amzn/awsui-collection-hooks";
import {ReactNode} from "react";
import {ItemType} from "src/common/report";
import {ReportTableColumns} from "src/components/ReportLibraryPage/interfaces";

export interface ReportTableRowItem {
	// Keys used for showing the table
	id: string
	displayName: string | ReactNode
	lastModified: string | number
	reportStatus: string
	reportRole: string
	actions: ReactNode
	reportType: string
	parentFolderId: string | undefined
	bridgeAndCommentary ? : ReactNode | undefined
	itemType: ItemType
	// Keys used for filtering table
	reportName: string
	financeCycle: string
	year: string
	period: string
}


const COLUMN_DEFINITIONS = [
	{
		id: ReportTableColumns.ReportName,
		header: ReportTableColumns.ReportName,
		cell: (e : ReportTableRowItem) => e.displayName,
		label: ReportTableColumns.ReportName
	},
	{
		id: ReportTableColumns.BridgingAndCommentary,
		header: ReportTableColumns.BridgingAndCommentary,
		cell: (e : ReportTableRowItem) => e.bridgeAndCommentary,
		label: ReportTableColumns.BridgingAndCommentary,
	},
	{
		id: ReportTableColumns.ReportType,
		header: ReportTableColumns.ReportType,
		cell: (e : ReportTableRowItem) => e.reportType,
		label: ReportTableColumns.ReportType,
	},
	{
		id: ReportTableColumns.FinanceCycle,
		header: ReportTableColumns.FinanceCycle,
		cell: (e : ReportTableRowItem) => e.financeCycle,
		label: ReportTableColumns.FinanceCycle,
	},
	{
		id: ReportTableColumns.FinanceYear,
		header: ReportTableColumns.FinanceYear,
		cell: (e : ReportTableRowItem) => e.year,
		label: ReportTableColumns.FinanceYear,
	},
	{
		id: ReportTableColumns.LastEdited,
		header: ReportTableColumns.LastEdited,
		cell: (e : ReportTableRowItem) => new Date(e.lastModified as number).toDateString(),
		label: ReportTableColumns.LastEdited,
	},
	{
		id: ReportTableColumns.Status,
		header: ReportTableColumns.Status,
		cell: (e : ReportTableRowItem) => e.reportStatus,
		label: ReportTableColumns.Status,
	},
	{
		id: ReportTableColumns.ReportAccess,
		header: ReportTableColumns.ReportAccess,
		cell: (e : ReportTableRowItem) => e.reportRole,
		label: ReportTableColumns.ReportAccess,
	},
	{
		id: ReportTableColumns.Actions,
		header: ReportTableColumns.Actions,
		cell: (e : ReportTableRowItem) => e.actions,
		label: ReportTableColumns.Actions,
	}
];


export const PROPERTY_FILTERING_I18N_CONSTANTS = {
	filteringAriaLabel: 'your choice',
	dismissAriaLabel: 'Dismiss',

	filteringPlaceholder: 'Search',
	groupValuesText: 'Values',
	groupPropertiesText: 'Properties',
	operatorsText: 'Operators',

	operationAndText: 'and',
	operationOrText: 'or',

	operatorLessText: 'Less than',
	operatorLessOrEqualText: 'Less than or equal',
	operatorGreaterText: 'Greater than',
	operatorGreaterOrEqualText: 'Greater than or equal',
	operatorContainsText: 'Contains',
	operatorDoesNotContainText: 'Does not contain',
	operatorEqualsText: 'Equals',
	operatorDoesNotEqualText: 'Does not equal',

	editTokenHeader: 'Edit filter',
	propertyText: 'Property',
	operatorText: 'Operator',
	valueText: 'Value',
	cancelActionText: 'Cancel',
	applyActionText: 'Apply',
	allPropertiesLabel: 'All properties',

	tokenLimitShowMore: 'Show more',
	tokenLimitShowFewer: 'Show fewer',
	clearFiltersText: 'Clear filters',
	removeTokenButtonAriaLabel: () => 'Remove token',
	enteredTextLabel: (text: string) => `Use: "${text}"`,
};


/**
* Define where the filter will get the data for filtering. The key used here should be from original data not
* table column
 */
export const FILTERING_PROPERTIES: PropertyFilterProperty[] = [
	{
		propertyLabel: 'Review / Report name',
		key: "reportName",
		groupValuesLabel: 'Review / Report names',
		operators: [':', '!:', '=', '!='],
	},
	{
		propertyLabel: 'Finance cycle',
		key: "financeCycle",
		groupValuesLabel: 'Finance cycle values',
		operators: [':', '!:', '=', '!='],
	},
	{
		propertyLabel: 'Finance year',
		key: "year",
		groupValuesLabel: 'Finance year values',
		operators: [':', '!:', '=', '!='],
	},
	{
		propertyLabel: 'Finance period',
		key: "period",
		groupValuesLabel: 'Finance year values',
		operators: [':', '!:', '=', '!='],
	},
];

export const PAGE_SIZE_OPTIONS = [
	{value: 10, label: "10 reports"},
	{value: 20, label: "20 reports"},
	{value: 30, label: "30 reports"},
	{value: 40, label: "40 reports"},
	{value: 50, label: "50 reports"}
];

export const DEFAULT_COLUMNS = [
	ReportTableColumns.ReportName,
	ReportTableColumns.FinanceCycle,
	ReportTableColumns.FinanceYear,
	ReportTableColumns.LastEdited,
	ReportTableColumns.Status,
	ReportTableColumns.ReportAccess,
	ReportTableColumns.Actions
];
export const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

export const getReportTableColumnDefinitions = (excludedColumns: Set<ReportTableColumns>) => {
	return COLUMN_DEFINITIONS.filter(definition => !excludedColumns.has(definition.id));
}


