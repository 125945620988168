import styled from "styled-components";

export const BridgeComparisonsFieldSet = styled.fieldset`
  font-family: Amazon Ember, Arial, sans-serif;
  font-weight: 400;
  display: grid;
  border-radius: 1rem;
  border-width: 2px;
  border-color: dodgerblue;

  legend {
    font-size: larger;
    font-weight: normal;
    padding: 0 1rem;
    color: #0972d3;
  }

  [open] summary {
    color: #0972d3;
  }
`;

export const ContentDiv = styled.div`
  padding: 0.1em;
  overflow: hidden;
`;

export const SummaryDetails = styled.details`
  border-bottom: thin solid lightgrey;
  padding: 0.5em 1em;
  font-weight: lighter;

  summary {
    font-size: 12px;
    width: 20em;
    color: black;
    padding: 0.1em;
    border: none;
    cursor: pointer;
  }

  p {
    padding: 0.125em;
    margin-top: 0.125rem;
  }
`;

export const AllSubBridgeItems = styled.div`
  display: grid;
  padding: 1em 0;
`;

export const EachFollowUpChange = styled.div`
  // Grid
  display: grid;
  margin: 0.25em;
  grid-auto-flow: row;
  grid-template-columns: 20% 1fr 8% 1% 8% 3%;
  grid-column-gap: .5em;

  &.dummy-row-for-metadata{
    .meta-data {
      padding: 0;
    }
  }

    &.dummy-row-for-version-arrow {
    justify-content: center;

    .sub-item-name {
      background: none;
    }

    .value-new {
      background: #f2f8fd;
    }

    .value-new, .value-old {
      border: none;
    }

    .follow-up-text-deep-dive {
      font-style: italic;
      font-size: 12px;
      justify-self: center;
    }
  }
`;

export const SubItemName = styled.div<{indented?: boolean}>`
  display: flex;
  place-items: center;
  overflow: hidden;
  padding: .5em ${props => props.indented ? "2em" : "1em"};
  text-overflow: ellipsis;
  background: #f2f8fd; // very light blue with a new fresh morning feel
  ${props => !props.indented && "font-weight: bold"}
`;

export const ColumnText = styled.div`
  display: flex;
  place-items: center;
  justify-content: left;
  padding: .5em;
`;

export const ValueOld = styled.div`
  display: flex;
  place-items: center;
  justify-content: center;
  background-color: #fffaf05e; // this is a low-opacity floral white for page from an old book look
  padding: .5em;
  border: thin solid rgba(211, 211, 211, 0.66);
  white-space: nowrap;
`;

export const ValueNew = styled.div`
  display: flex;
  place-items: center;
  justify-content: center;
  background: #f2f8fd;
  padding: .5em;
  border: thin solid #1e90ff3b; // this is a low-opacity dodgerblue
  border-radius: 0.325rem;
  white-space: nowrap;
`;
