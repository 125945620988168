import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {BridgeStatus} from "src/actions/bridgingCommentary.actions";
import {Roles} from "src/common/roles";
import {BridgeStatusType} from "src/components/BridgingCommentary/interfaces";
import BridgeComparisons from "src/components/FillOutBridgePage/CommentVersioning/BridgeComparisons";
import InstructionMode from "src/components/FillOutBridgePage/CommentVersioning/InstructionMode";
import {ItemHistoryDeepDive} from "src/components/FillOutBridgePage/CommentVersioning/ItemHistoryDeepDive";
import {BridgeHistory} from "src/components/FillOutBridgePage/interfaces";
import {AppState} from "src/reducers/AppState";
import {forBridgeHistory, forBridgeHistoryIds} from "src/reducers/bridgingCommentary.reducer";

interface ChangeLogProps {
    followUpProps: any,
    bridgeId: string,
    commentHistory: { current: string, latest: boolean, index: number },
    openChangeLog: boolean,
    setOpenChangeLog: Function,
    bridgeStatus: BridgeStatus,
    bridgeState: string,
    role: Roles
}
export const ChangeLog = ({bridgeId, bridgeStatus, bridgeState, role, commentHistory, openChangeLog, setOpenChangeLog, followUpProps}: ChangeLogProps) => {
    const historyIds: string[] = useSelector((state: AppState) => forBridgeHistoryIds(state, bridgeId))
    const history: BridgeHistory = useSelector((state: AppState) => forBridgeHistory(state, bridgeId))

    const [deepDive, setDeepDive] = useState<"bridge" | "bridge-item" | "instruction">("instruction")
    const [selectedRow, setSelectedRow] = useState({id: '', name: ''})

    const {current: historyId, latest, index} = commentHistory

    useEffect(() => {
        if (openChangeLog || (role === Roles.BridgeOwner && bridgeState === BridgeStatusType.Requested))
            setDeepDive('bridge')
        else
            setDeepDive('instruction')
    }, [openChangeLog, bridgeState])

    if (!bridgeStatus.fetchedVersions || !historyIds.length) {
        return null
    }

    const dismissFunction = () => {
        setDeepDive("instruction")
        setOpenChangeLog(false)
    }

    const states = {
        'bridge': <BridgeComparisons
            selectedHistoryId={historyId}
            index={index}
            bridgeId={bridgeId}
            history={history}
            historyIds={historyIds}
            subItems={followUpProps.subItems}
            setSelectedRow={setSelectedRow}
            unselect={() => setDeepDive("bridge-item")}
            dismiss={dismissFunction}
        />,
        'bridge-item': <ItemHistoryDeepDive
            history={history}
            historyIds={historyIds}
            bridgeStatus={bridgeState}
            row={selectedRow}
            latestSelected={latest}
            role={role}
            unselect={() => setDeepDive("bridge")}
            dismiss={dismissFunction}
            followUpProps={followUpProps}
        />,
        'instruction': <InstructionMode/>
    }

    return states[deepDive]
};

