import {ReduxAction} from "src/common/interfaces";
import {API_PATH} from "src/constants/api";


// common types
export const FILE_TRANSFER = '[FILE TRANSFER]';

// reducer constant types
export const SET_FILE_TRANSFER_STATUS = `${FILE_TRANSFER} SET STATUS`
export const RESET_FILE_TRANSFER = `${FILE_TRANSFER} GET`

// API call types
export const GET_FILE_TRANSFER = `${FILE_TRANSFER} GET`

// Interface definition for file transfer action payload
export interface FileTransfer {
    urlType?: string,
    fileKey?: string,
    url?: string,
    fields?: {[p: string]: any},
    file?: any
    setUploadProgress?: ((progress: number) => void) | undefined
    fileEntity?: string,
    transferStatus?: string
}

// Interface definition for file transfer redux action
export interface FileTransferActions extends ReduxAction {
    payload: FileTransfer
}

/************************************ REDUCER ACTIONS *****************************************/
// fileKey is optional as this action will be used both for upload and download
// Currently only for upload API will send fileKey as resp
export const setFileTransferStatus = (urlType: string, fileEntity: string, transferStatus: string, fileKey = "") => ({
    type: SET_FILE_TRANSFER_STATUS,
    payload: {urlType, fileEntity, transferStatus, fileKey}
});

/************************************ API CALL ACTIONS *****************************************/
export const getFileTransfer = (fileTransfer: FileTransfer) => {
    const { setUploadProgress, ...filteredPayload } = fileTransfer;
    return {
        type: GET_FILE_TRANSFER,
        payload: filteredPayload,
        meta: {
            apiCall: {method: 'GET', url: API_PATH.FILE_TRANSFER, entity: FILE_TRANSFER},
            setUploadProgress // Store it in meta for middleware use
        }
    };
};
