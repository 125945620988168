import {Box, Button, Link, Popover, SpaceBetween} from "@amzn/awsui-components-react";
import React, {useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getBridgeForViewing,
    getComments,
    setBridgingNames,
    setViewBridgeModalVisible
} from "src/actions/bridgingCommentary.actions";
import BackgroundSpinner from "src/components/Common/BackgroundSpinner";
import {Column, ColumnType} from "src/components/CreateReportPage/interfaces";
import Paths from "src/components/PageConfig/Paths";
import ChangeSpanTitleModal from "src/components/ViewReportPage/ChangeSpanTitleModal";
import {ReportTableProps} from "src/components/ViewReportPage/interfaces";
import ViewBridgeModal from "src/components/ViewReportPage/SingleBridgeView/ViewBridgeModal";
import ViewAllBridgesModal from "src/components/ViewReportPage/ViewAllBridgesModal";
import {
    getEnableLinksToBridges,
    getViewAllBridgesModalVisible,
    getViewBridgeModalVisible
} from "src/reducers/bridgingCommentary.reducer";
import {getMarkersFromState} from "src/reducers/reportLibrary.reducer";
import {constructBridgeId} from "src/utils/bridgingHelpers";
import {attrToColNameMap, generateAlphabet} from "src/utils/reportHelpers";
import styled from "styled-components";

function ReportTable(props: ReportTableProps) {
    const {
        isReference,
        isAdmin,
        varianceValueType,
        reportDefinitions,
        reportRows,
        spanTitles,
        setSpanTitles,
        modifyPermission,
        role
    } = props;
    const dispatch = useDispatch();
    const [isEditSpanTitleModalVisible, setIsEditSpanTitleModalVisible] = useState<boolean>(false);
    const [editingSpanTitleText, setEditingSpanTitleText] = useState<string>("");
    const [spanTitleIndex, setSpanTitleIndex] = useState<number>(0);

    const enableLinksToBridges = useSelector(getEnableLinksToBridges);
    const markers = new Set(useSelector(getMarkersFromState) || []);
    const viewBridgeModalVisible = useSelector(getViewBridgeModalVisible);
    const viewAllBridgesModalVisible = useSelector(getViewAllBridgesModalVisible);
    const attrToColMap = useMemo(() => {
        return attrToColNameMap(reportDefinitions.configure_columns)
    }, [reportDefinitions.configure_columns])

    const changeSpanTitleModal = (
        <ChangeSpanTitleModal
            report_id={reportDefinitions.report_id}
            visible={isEditSpanTitleModalVisible}
            setVisible={setIsEditSpanTitleModalVisible}
            editingSpanTitle={editingSpanTitleText}
            setEditingSpanTitle={setEditingSpanTitleText}
            spanTitleIndex={spanTitleIndex}
            setSpanTitles={setSpanTitles}
            spanTitles={spanTitles}
        />
    );

    const AlphabetCell = ({alphabet, column, attrToColMap, isReference, isAdmin}: {alphabet: string, column: Column,
        attrToColMap: {[p: string]: string}, isReference: boolean, isAdmin: boolean}) => {

        // Destructure current column properties
        const {column_type, parent_column_position, fx_impact, year, period, scenario, baseline_period, baseline_year,
            baseline_scenario, column_name} = column;

        let leftColumnName = ""
        let rightColumnName = ""

        // inline css style for button
        const btnInlineStyle = {border: "none", width: "100px", maxWidth: "100px", background: "inherit", padding: "0",
            margin: "0", cursor: "pointer"};

        function createDetailField(label: string, detail: string) {
            return (
                <div>
                    <Box variant="awsui-key-label">{label}</Box>
                    <Box variant="span">{detail}</Box>
                </div>
            )
        }

        function checkBridgingInitiate(){
            if (column_type === ColumnType.Variance){
                const key = scenario + "," + period?.map(p => p.label).join(",") + "," + year
                const baselineKey = baseline_scenario + "," + baseline_period?.map(p => p.label).join(",")
                    + "," + baseline_year
                leftColumnName = attrToColMap[key]
                rightColumnName = attrToColMap[baselineKey]
                return !!(isAdmin && isReference && attrToColMap[key] && attrToColMap[baselineKey])
            }
        }

        return (
            <th className="alphabet-cell">
                <Popover
                    /* Enabling renderWithPortal property will allow the popover to be rendered in the root stack
                    context using React Portals, so that it will have highest z-index always. Fixes issue where popover
                    appears hidden some browsers */
                    renderWithPortal={true}
                    header="Column details" dismissAriaLabel="Close" size="medium" triggerType="custom" position="bottom"
                    content={
                        <SpaceBetween size="s" direction="vertical">
                            {createDetailField("Column type", column_type)}
                            {createDetailField("Scenario", `${year} / ${scenario} / `
                                + period?.map(p => p.label).join(","))}
                            { column_type === ColumnType.FXImpact &&
                                <>{createDetailField("Parent column position", `${parent_column_position}`)}</>
                            }
                            { column_type === ColumnType.Variance &&
                                <>{createDetailField("Baseline scenario", `${baseline_year} / ${baseline_scenario} / `
                                    + baseline_period?.map(p => p.label).join(","))}</>
                            }
                            { column_type === ColumnType.Variance && fx_impact &&
                                <>{createDetailField("FX impact scenario", `${fx_impact.year} / ${fx_impact.scenario} / `
                                    + fx_impact.period?.map(p => p.label).join(","))}</>
                            }

                            {
                                checkBridgingInitiate() &&
                                <Button
                                    href={`#${Paths.BRIDGE}`}
                                    variant="link"
                                    onClick={() => {
                                            dispatch(setBridgingNames({
                                                initiatedBridgeName: column_name,
                                                leftVarianceHeader: leftColumnName,
                                                rightVarianceHeader: rightColumnName
                                            }))
                                        }
                                    }
                                >
                                    Configure Bridges
                                </Button>
                            }
                        </SpaceBetween>
                    }
                >
                    <button style={btnInlineStyle}>{alphabet}</button>
                </Popover>
            </th>
        );
    }

    const createColumnHeadersLetters = () => {
        // first two cells are empty for alphabet header row
        const alphabetRowCells: JSX.Element[] = [ <th className="empty-cell" colSpan={2}/>];
        let index = 0;
        // const attrToColMap = attrToColNameMap(reportDefinitions.configure_columns)
        reportDefinitions.configure_columns?.forEach(column => {
            const headerCell = column.column_type === ColumnType.ColumnBreak ? <th className="column-break-cell"/>
                : <AlphabetCell alphabet={generateAlphabet(index++)}
                                column={column}
                                attrToColMap={attrToColMap}
                                isReference={isReference}
                                isAdmin={isAdmin}
                />
            alphabetRowCells.push(headerCell);
        });
        return alphabetRowCells;
    }

    const FXDiv = styled.div`
      display: inline;
      color: #5f6b7a;
    `;

    const generateEditButton = (i: number, colSpan: number, isGroupWithFX: boolean) => {
        const fxInfo = varianceValueType.includes("WithFX") ? "incl. FX" : "excl. FX";
        const currSpanTitleText = spanTitles[i]
        const fxComponent = <FXDiv>{currSpanTitleText && ' - '} {fxInfo}</FXDiv>
        return (
            <th colSpan={colSpan}>
                { modifyPermission &&
                    <Button iconName="edit" variant={'inline-icon'}
                            onClick={() => {
                                setSpanTitleIndex(i);
                                setEditingSpanTitleText(spanTitles[i]);
                                setIsEditSpanTitleModalVisible(true);
                            }}
                    />
                }
                {currSpanTitleText} {isGroupWithFX && fxComponent}
            </th>
        );
    }

    const createSpanTitles = () => {
        // first two cells are empty for span title header row
        const spanTitleRowCells = [<th className="empty-cell" colSpan={2}/>];
        let isGroupWithFX = false;
        let [groupSize, spanTitleIndex] = [0, 0];
        reportDefinitions.configure_columns?.forEach(column => {
            if (column.column_type === ColumnType.ColumnBreak) {
                spanTitleRowCells.push(generateEditButton(spanTitleIndex++, groupSize, isGroupWithFX));  // merged cells for span-title
                spanTitleRowCells.push(<th className="column-break-cell"/>);  // empty cell for column break
                groupSize = 0;
                isGroupWithFX = false;
                return;
            }
            isGroupWithFX = isGroupWithFX || (column.column_type === ColumnType.Variance && !!column.fx_impact);
            groupSize++;
        });
        // Add the last span title merged cell
        spanTitleRowCells.push(generateEditButton(spanTitleIndex, groupSize, isGroupWithFX));

        return spanTitleRowCells;
    }

    const createColumnHeaders = () => {
        // first two cells are have title for text column
        const headerRowCells = [
            <th className={"text-column-title"} colSpan={2}>In USD M Format High/(Low)</th>
        ];
        reportDefinitions.configure_columns?.forEach(column => {
            const headerCell = column.column_type === ColumnType.ColumnBreak ? <th className="column-break-cell"/>
                : <th className={"column-title-cell"}>{ column.column_name }</th>
            headerRowCells.push(headerCell)
        });
        return headerRowCells;
    }

    const createRows = () => {
        if (!reportRows) return [];

        const tableRows: JSX.Element[] = [];

        for(let i = 0 ; i < reportRows.length; i++) {
            const currentRow = reportRows[i];
            const rowCells: JSX.Element[] = [];
            // if empty line insert empty table row and go to next iteration
            if (currentRow["columns_name"].includes("empty_line")) {
                tableRows.push(<tr className="empty-row"/>);
                continue;
            }
            rowCells.push(<td className={currentRow["td_first_style"]}>{ currentRow["_id"] }</td>)
            rowCells.push(<td className={currentRow["td_second_style"]}>{ currentRow["columns_name"] }</td>)
            // if underlined row add underlined row cells and go to next iteration
            if (currentRow["tr_style"] === "underlined_row") {
                tableRows.push(<tr className={currentRow["tr_style"]}>{ rowCells }</tr>);
                continue;
            }
            let column_number = 0;
            reportDefinitions.configure_columns?.forEach(({column_name, column_type})=> {
                column_number++;
                if (column_type === ColumnType.ColumnBreak) {
                    rowCells.push(<td className="column-break-cell"/>)
                }
                if (column_type === ColumnType.Variance) {
                    //For backwards compatibility if FXImpact value doesn't exist check variance value
                    //type and populate respective metric withFX.
                    const varianceValue = currentRow[column_name][varianceValueType] ||
                        currentRow[column_name][varianceValueType.includes('amount') ? 'amount' : 'percentage'];
                    const bridgeIdConstructed = constructBridgeId(reportDefinitions.report_id, currentRow.columns_name, currentRow._id, column_name, column_number);
                    if(enableLinksToBridges && markers.has(bridgeIdConstructed)){
                        rowCells.push(
                            <td align={"right"}>
                                <Link variant={"secondary"} onFollow={(e) => {
                                    e.preventDefault();
                                    // setEnableViewBridgeModal(true);
                                    dispatch(setViewBridgeModalVisible(true));
                                    dispatch(getBridgeForViewing({role, bridge_id: bridgeIdConstructed}));
                                    dispatch(getComments(bridgeIdConstructed, role));
                                }}>
                                    { varianceValue }
                                </Link>
                            </td>
                        )
                    } else {
                        rowCells.push(
                            <td align={"right"}>
                                { varianceValue }
                            </td>
                        )
                    }
                }
                if (column_type === ColumnType.FixedTotal || column_type === ColumnType.FXImpact) {
                    rowCells.push(<td align={"right"}>{ currentRow[column_name] }</td>)
                }
            })
            tableRows.push(<tr className={currentRow["tr_style"]}> {rowCells}</tr>)
        }

        return (
            <>
            <ViewBridgeModal visible={viewBridgeModalVisible} />
            <ViewAllBridgesModal visible={viewAllBridgesModalVisible} />
            <div className='table-wrapper'>
                <table className="custom-report-table">
                    <thead>
                    <tr className="span-title-row">{ createSpanTitles() }</tr>
                    <tr className="alphabets-row">{ createColumnHeadersLetters() }</tr>
                    <tr className="column-title-row">{ createColumnHeaders() }</tr>
                    </thead>
                    <tbody>
                    { tableRows }
                    </tbody>
                </table>
            </div>
            </>
        )
    };

    return (
        <>
            { reportRows.length === 0 ? <BackgroundSpinner text="Loading report" />
            : <div>
                { changeSpanTitleModal }
                { createRows() }
            </div>
        }</>
    );
}

export default ReportTable;

