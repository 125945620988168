import { RowNumberingType } from "@amzn/aws-fintech-fluid-table";
import {useDispatch} from "react-redux";
import {patchTheme, postThemeExport, setThemeItems} from "src/actions/themes.actions";
import {DriverCommentarySelect} from "src/components/Themes/header/interfaces";
import {
  ThemeActionHandlers,
  ThemeItem,
  ThemeItemType,
  ThemeTableEvent
} from "src/components/Themes/interfaces";
import {calculateRowPosition, repositionRowLevel} from "src/components/Themes/table/ordering";
import {CommentarySettings} from "src/components/Themes/ThemesPage";
import {v4 as uuid} from "uuid";

const handleAddRow = (themeItems: ThemeItem[], addEvent: (event: ThemeTableEvent) => void) => {
  const firstThemeItem = themeItems[0] // This should always be there
  const secondThemeItem = themeItems[1] // There will always be a second theme item, if no bridge items then this is the bottom line
  const newRowPosition = calculateRowPosition(firstThemeItem, secondThemeItem)
  const id = uuid()
  const themeItemsWithRow = [
    firstThemeItem,
    {
      id,
      position: typeof newRowPosition === 'number' ? newRowPosition : 0,
      name: "",
      commentary: "New theme",
      values: {},
      type: ThemeItemType.GROUPING,
      subRows: []
    } as ThemeItem,
    ...themeItems.slice(1, themeItems.length)
  ]

  addEvent({
    [id]: {
      commentary: "New Theme",
      position: typeof newRowPosition === 'number' ? newRowPosition : 0,
      itemType: ThemeItemType.GROUPING,
      currentParent: '',
      move: {new: ''}
    }
  })

  return typeof newRowPosition !== 'number' && newRowPosition.name === 'RowPositionClash' ? repositionRowLevel(
    '',
    themeItemsWithRow,
    (updatedItem) => !(new Set([ThemeItemType.VARIANCE, ThemeItemType.BRIDGE])).has(updatedItem.type) && addEvent({
      [updatedItem.id]: {position: updatedItem.position, itemType: updatedItem.type, currentParent: ''},
    })
  ) : themeItemsWithRow
}


export const themeActionHandlers = (
  themeId: string,
  themeItems: ThemeItem[],
  addEvent: (event: ThemeTableEvent) => void,
  setCommentarySettings: (settings: CommentarySettings) => void,
  setRowNumbering: (type: RowNumberingType) => void,
  showDeleteModal: () => void
): ThemeActionHandlers => {
  const dispatch = useDispatch();
  const onDownload = () => dispatch(postThemeExport(themeId))
  const onPublish = () => dispatch(patchTheme(themeId, 'Publish'))
  const onUnpublish = () => dispatch(patchTheme(themeId, 'Unpublish'))
  const setDriverCommentarySelect = (driverCommentarySelect: DriverCommentarySelect) => setCommentarySettings({driverCommentarySelect})
  const setSourceCommentary = (sourceCommentary: boolean) => setCommentarySettings({sourceCommentary})

  return {
    onAddRow: () => dispatch(setThemeItems(handleAddRow(themeItems, addEvent))),
    onDelete: showDeleteModal,
    onDownload,
    onPublish,
    onUnpublish,
    setDriverCommentarySelect,
    setSourceCommentary,
    setRowNumbering
  }
}
