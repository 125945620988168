import {ReactNode} from "react";

export type ModifiableValue = "leftValue" | "rightValue" | "varianceValue";
export type BridgeAction = "request_inputs" | "save" | "submit" | "approve" | "follow_up" | "publish" | "unpublish";

export interface FillOutTableItem {
	name: ReactNode
	leftValue: ReactNode
	rightValue: ReactNode
	varianceValue: ReactNode
	commentary?: ReactNode
	commentator?: ReactNode
	actions?: ReactNode
}

export interface FollowUpItem {
	comment: string,
	timestamp: string,
	login: string
}

export interface BridgeSubItem {
	row_id: string
	touched: boolean
	name: string
	leftValue: number
	rightValue: number
	treeLevel: number
	varianceValue: number
	commentary: string
	commentator: string
	last_edited_timestamp?: number,
	follow_up: FollowUpItem
}

export const defaultBridgeSubItem: BridgeSubItem = {
	row_id: "",
	touched: false,
	name: "",
	leftValue: 0,
	rightValue: 0,
	treeLevel: 0,
	varianceValue: 0,
	commentary: "",
	commentator: "",
	last_edited_timestamp: 0,
	follow_up: {
		comment: "",
		timestamp: "",
		login: ""
	}
}

export interface BridgeHistoryItem {
	[rowId: string]: BridgeSubItem
}

export interface BridgeHistory {
	[historyId: string]: BridgeHistoryItem
}

export enum FillOutTableColumns {
	// Common headers
	Name = "P&L line item bridge",
	LeftScenario = "Left",
	Commentary = "Commentary",
	Actions = "Actions",
	LastEditedBy = "Last edited by",
	// Layout 1 headers
	RightScenario = "Right",
	Variance = "Variance",
	// Layout 2 headers
	Value = "Value",
}
