import {Dictionary} from "@reduxjs/toolkit";
import {Dispatch} from "react";
import {AnyAction, Middleware} from "redux";
import {API_ERROR, API_SUCCESS} from "src/actions/api.actions";
import {
    getThemes,
    setTheme,
    setThemeItems,
    setThemes, setThemesAlert,
    THEME, THEME_EXPORT,
    THEME_ITEMS,
    THEMES,
    updateThemeItems
} from "src/actions/themes.actions";
import Paths from "src/components/PageConfig/Paths";
import {_directDownloadOnClick} from "src/middleware/app/fileTransfer.middleware";
import {AppState as State} from "src/reducers/AppState";


export const success = (entity: string) => `${entity} ${API_SUCCESS}`
export const error = (entity: string) => `${entity} ${API_ERROR}`

export const themesMiddleware: Middleware<{}, State> = ({dispatch, getState}) => next => action => {
    next(action);
    const {data = {}, errorData = {}, method = ''} = action.payload;
    if (action.payload) {
        const handlerMapping: Dictionary<() => void> = {
            [success(THEMES)]: () => themesApiHandler(method, data, dispatch),
            [error(THEMES)]: () => errorHandler(errorData, dispatch),
            [success(THEME)]: () => themeApiHandler(method, data, dispatch, getState),
            [error(THEME)]: () => errorHandler(errorData, dispatch),
            [success(THEME_EXPORT)]: () => themeExportApiHandler(method, data),
            [error(THEME_EXPORT)]: () => errorHandler(errorData, dispatch),
            [success(THEME_ITEMS)]: () => themeItemApiHandler(method, data, dispatch),
            [error(THEME_ITEMS)]: () => errorHandler(errorData, dispatch),
        }

        const handler = handlerMapping[action.type]
        handler && handler()
    }
}

const themesApiHandler = (method: string, data: any, dispatch: Dispatch<AnyAction>) => {
    if (method === 'GET')
        dispatch(setThemes(data.groupedThemes))
    else if (method === 'POST') {
        dispatch(setTheme(data.theme))
        window.location.replace(`#${Paths.THEMES}?themeId=${data.theme.id}`);
    }
}

const themeApiHandler = (method: string, data: any, dispatch: Dispatch<AnyAction>, getState: () => State) => {
    if (method === 'GET')
        dispatch(setTheme(data.theme))
    else if (method === "DELETE")
        if (window.location.hash.split('/')[1].includes('report-bridging')){
            // Delete was called from report bridging page
            const reportId = getState().reportBridge.ids[0]
            dispatch(getThemes(reportId as string))
        } else {
            // Delete was called from themes page
            window.location.replace(`#/`);
        }
    else if (method === "PATCH")
        dispatch(setTheme(data.theme))
}

const themeExportApiHandler = (method: string, data: any) => {
    if (method === 'POST')
        _directDownloadOnClick(data.url);
}

const themeItemApiHandler = (method: string, data: any, dispatch: Dispatch<AnyAction>) => {
    if (method === 'GET')
        dispatch(setThemeItems(data.themeItems))
    else if (method === "PATCH")
        dispatch(updateThemeItems(data.updatedThemeItems))
}

const errorHandler = (errorData: any, dispatch: Dispatch<AnyAction>) => {
    dispatch(setThemesAlert({type: 'error', message: errorData.message, reason: errorData.reason}))
}