
import {ReduxAction} from "src/common/interfaces";
import {Roles} from "src/common/roles";
import {BridgeItem, ModalBridgeItem} from "src/components/BridgingCommentary/interfaces";
import {
    BridgeAction,
    BridgeHistoryItem,
    BridgeSubItem,
} from "src/components/FillOutBridgePage/interfaces";
import {API_PATH} from "src/constants/api";
import {PaginationConfig} from "src/reducers/mdxLibrary.reducer";

// Common constants
export const BRIDGING_COMMENTARY = '[BRIDGING-COMMENTARY]';
export const BRIDGE = '[BRIDGE]';
export const WIZARD_BRIDGE = '[WIZARD BRIDGE]';
export const BRIDGES = '[BRIDGES]';
export const COMMENTS = '[COMMENTS]';
export const COMMENTS_HISTORY = '[COMMENTS_HISTORY]';
export const MARKERS = '[MARKERS]';
export const COMMENTATORS = '[COMMENTATORS]';
export const REPORT_BRIDGES = '[REPORT BRIDGES]';
export const BRIDGE_OWNER_GROUPS = '[BRIDGE_OWNER_GROUPS]';
export const CHANGE_BRIDGE_STATE = `[CHANGE_BRIDGE_STATE]`;
export const BRIDGE_BEING_VIEWED_CURRENTLY = '[BRIDGE_BEING_VIEWED_CURRENTLY]';
export const BRIDGES_BEING_VIEWED_CURRENTLY = '[BRIDGES_BEING_VIEWED_CURRENTLY]';
export const BRIDGE_SUB_ITEMS = '[BRIDGE_SUB_ITEMS]';
export const BRIDGE_ID_BEING_VIEWED = '[BRIDGE_ID_BEING_VIEWED]';
export const VIEW_BRIDGE_MODAL = '[VIEW_BRIDGE_MODAL]';
export const VIEW_ALL_BRIDGES_MODAL = '[VIEW_ALL_BRIDGES_MODAL]';
export const COMMENTS_FOR_VARIANCE_COLUMN = '[COMMENTS_FOR_VARIANCE_COLUMN]';
export const SELECTED_ROW = '[SELECTED_ROW]';
export const SELECTED_BRIDGE_ID = '[SELECTED_BRIDGE_ID]';

// Types for reducer
export const ADD_SUB_ITEMS_FOR_BRIDGE = `${BRIDGING_COMMENTARY} BRIDGE_SUB_ITEMS ADD`;
export const SET_SUB_ITEMS_FOR_BRIDGE_TO_HISTORY = `${BRIDGING_COMMENTARY} BRIDGE_SUB_ITEMS SET TO HISTORY`;
export const ADD_HISTORY_FOR_BRIDGE = `${BRIDGING_COMMENTARY} HISTORY ADD`;
export const SET_LATEST_FOR_BRIDGE= `${BRIDGING_COMMENTARY} HISTORY LATEST`;
export const REMOVE_LATEST_FOR_BRIDGE= `${BRIDGING_COMMENTARY} HISTORY REMOVE LATEST`;

export const RESET_SUB_ITEMS_FOR_BRIDGE = `${BRIDGING_COMMENTARY} BRIDGE_SUB_ITEMS RESET`;
export const SET_PAGINATION_CONFIG = `${BRIDGING_COMMENTARY} SET PAGE CONFIG`;
export const SET_BRIDGE_NAME = `${BRIDGE} SET NAME`;
export const SET_BRIDGE_ITEMS = `${BRIDGE} SET BRIDGE ITEMS`;
export const SET_FILLING_BRIDGES = `${BRIDGE} SET FILLING BRIDGES`;
export const RESET_FILLING_BRIDGES = `${BRIDGE} RESET FILLING BRIDGES`;
export const SET_BRIDGE_OWNER_GROUPS = `${BRIDGE_OWNER_GROUPS} SET`
export const SET_BRIDGING_PAGE_STATUS = `${BRIDGING_COMMENTARY} SET STATUS`;
export const SET_BRIDGE_STATUS = `${BRIDGE} SET STATUS`;
export const SET_WIZARD_BRIDGE_STATUS = `${WIZARD_BRIDGE} SET STATUS`;
export const RESET_BRIDGING_PAGE_STATUS = `${BRIDGING_COMMENTARY} RESET STATUS`;
export const SET_REPORT_BRIDGES = `${REPORT_BRIDGES} SET ITEMS`;
export const RESET_REPORT_BRIDGES = `${REPORT_BRIDGES} RESET ITEMS`;
export const SET_ENABLE_LINKS_TO_BRIDGES = `${BRIDGING_COMMENTARY} SET ENABLE LINKS TO BRIDGES`;
export const SET_BRIDGE_BEING_VIEWED_CURRENTLY = `${BRIDGE_BEING_VIEWED_CURRENTLY} SET`;
export const RESET_BRIDGE_BEING_VIEWED_CURRENTLY = `${BRIDGE_BEING_VIEWED_CURRENTLY} RESET`;
export const SET_BRIDGES_BEING_VIEWED_CURRENTLY = `${BRIDGES_BEING_VIEWED_CURRENTLY} SET`;
export const SET_BRIDGE_SUB_ITEMS = `${BRIDGE_SUB_ITEMS} SET`;
export const SET_BRIDGE_ID_BEING_VIEWED = `${BRIDGE_ID_BEING_VIEWED} SET`;
export const SET_COMMENTATORS = `${COMMENTATORS} SET`;
export const SET_VIEW_BRIDGE_MODAL = `${VIEW_BRIDGE_MODAL} SET`;
export const SET_VIEW_ALL_BRIDGES_MODAL = `${VIEW_ALL_BRIDGES_MODAL} SET`;
export const SET_SELECTED_ROW = `${SELECTED_ROW} SET`;
export const SET_SELECTED_BRIDGE_ID = `${SELECTED_BRIDGE_ID} SET`;

// Constants types for api call
export const GET_BRIDGE_OWNER_GROUPS = `${BRIDGE_OWNER_GROUPS} GET`;
export const GET_BRIDGES = `${BRIDGES} GET`;
export const GET_REPORT_BRIDGES = `${REPORT_BRIDGES} REPORT GET`;
export const POST_BRIDGE = `${BRIDGE} POST`;
export const DELETE_BRIDGE = `${BRIDGE} DELETE`;
export const GET_BRIDGE_BEING_VIEWED_CURRENTLY = `${BRIDGE_BEING_VIEWED_CURRENTLY} GET`;
export const GET_COMMENTS = `${COMMENTS} GET`;
export const POST_COMMENTS = `${COMMENTS} POST`;
export const GET_COMMENTS_HISTORY = `${COMMENTS_HISTORY} GET`
export const GET_MARKERS = `${MARKERS} GET`;
export const SET_MARKERS = `${MARKERS} SET`;
export const GET_BRIDGES_BEING_VIEWED_CURRENTLY = `${BRIDGES_BEING_VIEWED_CURRENTLY} GET`;
export const GET_COMMENTS_FOR_VARIANCE_COLUMN = `${COMMENTS_FOR_VARIANCE_COLUMN} GET`;
// Interface definition for status object in bridging and commentary page
export interface BridgeStatus {
    fetched?: boolean
    fetching?: boolean
    fetchedComments?: boolean
    fetchingComments?: boolean
    refreshingComments?: boolean,
    refreshingVersions?: boolean
    fetchingVersions?: number,
    fetchedVersions?: boolean,
    saved?: boolean,
    deleted?: boolean,
    error?: string,
    requestInputs?: {saved?: boolean, requestInput?: boolean, error?: string},
}

export interface BridgingPageStatus{
    bridges: {
        fetched?: boolean
        error?: string
    }
    bridge: {[bridge_id: string]: BridgeStatus}
    bridgeOwnerGroups: {
        fetched?: boolean
        error?: string
    }
    wizardBridge: {[bridge_id: string]: BridgeStatus}
}

export interface BridgingCommentaryActions extends ReduxAction {
    payload: {
        varianceDetails?: {
            initiatedBridgeName: string,
            leftVarianceHeader: string,
            rightVarianceHeader: string,
        },
        bridgeItems?: Array<BridgeItem>,
        ownerGroups?: Array<string>
        paginationKey?: {[p: string]: any},
        hasMore?: boolean | undefined,
        status?: BridgingPageStatus,
        bridgeStatus?: BridgeStatus,
        bridges?:Array<BridgeItem>,
        bridgeOwnerGroup?:{},
        enableLinksToBridges?: boolean,
        bridgeBeingViewedCurrently?: ModalBridgeItem
        bridgesBeingViewedCurrently?: {[p: string]: BridgeSubItem[]},
        selectedRow?: BridgeSubItem,
        selectedBridgeId?: string,
        bridgeId?: string,
        bridgeSubItems?: BridgeSubItem[],
        viewBridgeModalVisible?: boolean,
        viewAllBridgesModalVisible?: boolean,
        bridgeHistoryItem?: BridgeHistoryItem
        historyId?: number
    }
}

/************************************ REDUCER ACTIONS *****************************************/
// Action to set bridging page status
export const setBridgingPageStatus= (status: BridgingPageStatus): BridgingCommentaryActions=> ({
    type: SET_BRIDGING_PAGE_STATUS,
    payload: {status}
});

// Action to set bridging page status
export const setBridgeStatus= (bridgeId: string, bridgeStatus: BridgeStatus): BridgingCommentaryActions=> ({
    type: SET_BRIDGE_STATUS,
    payload: {bridgeId, bridgeStatus}
});

// Action to set wizard bridging page status
export const setWizardBridgeStatus= (bridgeId: string, bridgeStatus: BridgeStatus): BridgingCommentaryActions=> ({
    type: SET_WIZARD_BRIDGE_STATUS,
    payload: {bridgeId, bridgeStatus}
});

// Action to reset bridging page status
export const resetBridgingPageStatus= (): BridgingCommentaryActions=> ({
    type: RESET_BRIDGING_PAGE_STATUS,
    payload: {}
});

// Action to set the pagination config for bridging-commentary page
export const setBridgingPaginationConfig = (payload: PaginationConfig): BridgingCommentaryActions => ({
    type: SET_PAGINATION_CONFIG,
    payload: payload,
});

// Action to update initiated bridge name
export const setBridgingNames = (payload: {
    initiatedBridgeName: string,
    leftVarianceHeader: string,
    rightVarianceHeader: string,
}) => ({
    type: SET_BRIDGE_NAME,
    payload: {
        varianceDetails: payload
    }
});

// Action to update bridgeItems of a variance
export const setBridgeItems = (bridgeItems: Array<BridgeItem>) => ({
    type: SET_BRIDGE_ITEMS,
    payload: {
        bridgeItems: bridgeItems
    }
});

export const setFillingBridges = (bridgeItems: BridgeItem[]) => ({
    type: SET_FILLING_BRIDGES,
    payload: {bridgeItems}
});

export const setBridgeOwnerGroups = (ownerGroups: { [p: string]: string }) => ({
    type: SET_BRIDGE_OWNER_GROUPS,
    payload: {ownerGroups}
});

// Action to update bridgeItems of a Report
export const setReportBridgeItems = (bridges: Array<BridgeItem>, bridgeOwnerGroup: { [p: string]: string }) => ({
    type: SET_REPORT_BRIDGES,
    payload: {
        bridges: bridges,
        bridgeOwnerGroup: bridgeOwnerGroup
    }
});

// Action to enable or disable links to bridges on report cell clicks
export const setEnableLinksToBridges = (enableLinksToBridges: boolean) => ({
    type: SET_ENABLE_LINKS_TO_BRIDGES,
    payload: {enableLinksToBridges}
});

export const setBridgeBeingViewedCurrently = (bridgeBeingViewedCurrently: BridgeItem) => ({
    type: SET_BRIDGE_BEING_VIEWED_CURRENTLY,
    payload: {bridgeBeingViewedCurrently}
});

export const setBridgeSubItems = (bridgeBeingViewedCurrently: []) => ({
    type: SET_BRIDGE_SUB_ITEMS,
    payload: {bridgeBeingViewedCurrently}
});

export const addSubItemsForBridge = (bridgeId: string, bridgeSubItems: Array<BridgeSubItem>) => ({
    type: ADD_SUB_ITEMS_FOR_BRIDGE,
    payload: {bridgeId, bridgeSubItems}
});
export const setSubItemsForBridgeToHistory = (bridgeId: string, historyId: string) => ({
    type: SET_SUB_ITEMS_FOR_BRIDGE_TO_HISTORY,
    payload: {bridgeId, historyId}
});

export const addHistoryForBridge = (bridgeId: string, historyId: string, bridgeHistoryItem: BridgeHistoryItem) => ({
    type: ADD_HISTORY_FOR_BRIDGE,
    payload: {bridgeId, historyId, bridgeHistoryItem}
});

export const setLatestForBridge = (bridgeId: string, bridgeSubItems: BridgeSubItem[]) => ({
    type: SET_LATEST_FOR_BRIDGE,
    payload: {bridgeId, bridgeSubItems}
});

export const removeLatestForBridge = (bridgeId: string) => ({
    type: REMOVE_LATEST_FOR_BRIDGE,
    payload: {bridgeId}
});

export const setCommentators = (bridgeBeingViewedCurrently: []) => ({
   type: SET_COMMENTATORS,
   payload: {bridgeBeingViewedCurrently}
});

export const setMarkers = (markers: Array<string>) => ({
    type: SET_MARKERS,
    payload: {markers}
});

// Action to make all bridges modal appear or disappear
export const setViewAllBridgesModalVisible = (viewAllBridgesModalVisible: boolean) => ({
    type: SET_VIEW_ALL_BRIDGES_MODAL,
    payload: {viewAllBridgesModalVisible}
});

// Action to make single bridge modal appear or disappear
export const setViewBridgeModalVisible = (viewBridgeModalVisible: boolean) => ({
    type: SET_VIEW_BRIDGE_MODAL,
    payload: {viewBridgeModalVisible}
});

export const setBridgesBeingViewedCurrently = (bridgesBeingViewedCurrently: {[p: string]: BridgeSubItem[]}) => ({
    type: SET_BRIDGES_BEING_VIEWED_CURRENTLY,
    payload: {bridgesBeingViewedCurrently}
});

export const setSelectedRow = (selectedRow: BridgeSubItem) => ({
    type: SET_SELECTED_ROW,
    payload: {selectedRow}
});

export const setSelectedBridgeId = (selectedBridgeId: string) => ({
    type: SET_SELECTED_BRIDGE_ID,
    payload: {selectedBridgeId}
});

/************************************ API CALL ACTIONS *****************************************/
// API call to fetch multiple bridges for landing page
export const getBridges = (params: {role: Roles, limit?: number, paginationKey?: {[p: string]: any}}) => ({
    type: GET_BRIDGES,
    payload: params,
    meta: {
        apiCall: {method: 'GET', url: API_PATH.BRIDGES, entity: BRIDGES}
    }
});

// API call to fetch bridges for given report
export const getReportBridges = (params: {role: Roles, variance_header: string, report_id : string}) => ({
    type: GET_REPORT_BRIDGES,
    payload: params,
    meta: {
        apiCall: {method: 'GET', url: API_PATH.REPORT_BRIDGES(params.report_id), entity: REPORT_BRIDGES}
    }
});

// API call to fetch available bridge owner groups
export const getBridgeOwnerGroups = () => ({
    type: GET_BRIDGE_OWNER_GROUPS,
    payload: {},
    meta: {
        apiCall: {method: 'GET', url: API_PATH.BRIDGE_OWNER_GROUPS, entity: BRIDGE_OWNER_GROUPS}
    }
});

// API call to GET bridge api to get bridge item
export const getBridge = (bridge_id: string, role: Roles) => ({
    type: POST_BRIDGE,
    payload: {bridge_id, role},
    meta: {
        apiCall: {method: 'GET', url: API_PATH.BRIDGE(bridge_id), entity: BRIDGE}
    }
});

// API call to POST to bridge api to create/update bridge item
export const saveBridge = (bridge_item: BridgeItem, role: Roles, forWizard = false) => ({
    type: POST_BRIDGE,
    payload: {bridge_item, role},
    meta: {
        apiCall: {method: 'POST', url: API_PATH.BRIDGE(bridge_item.bridge_id), entity: forWizard ? WIZARD_BRIDGE : BRIDGE}
    }
});

// API call to DELETE bridge item
export const deleteBridge = (bridge_id: string, role: Roles) => ({
    type: DELETE_BRIDGE,
    payload: {role, bridge_id},
    meta: {
        apiCall: {method: 'DELETE', url: API_PATH.BRIDGE(bridge_id), entity: BRIDGE}
    }
});

// API call to change status of bridge item
export const changeBridgeState = (
    params: {bridge_id: string, role: Roles, action: BridgeAction, bridge_sub_items?: BridgeSubItem[], deleted_row_ids?: string[]}
) => ({
    type: CHANGE_BRIDGE_STATE,
    payload: params,
    meta: {
        apiCall: {method: 'POST', url: API_PATH.CHANGE_BRIDGE_STATE(params.bridge_id), entity: CHANGE_BRIDGE_STATE}
    }
});

// API call to fetch comments for a given bridge
export const getComments = (bridge_id: string, role: Roles | null, history_id?: string, event_history?: string) => ({
    type: GET_COMMENTS,
    payload: {bridge_id, role, history_id, event_history},
    meta: {
        apiCall: {method: 'GET', url: API_PATH.COMMENTS(bridge_id), entity: COMMENTS}
    }
});

// API call to fetch comments for a given bridge
export const getCommentsForVarianceColumn = (bridge_id: string, role: Roles | null) => ({
    type: GET_COMMENTS_FOR_VARIANCE_COLUMN,
    payload: {bridge_id, role},
    meta: {
        apiCall: {method: 'GET', url: API_PATH.COMMENTS(bridge_id), entity: COMMENTS_FOR_VARIANCE_COLUMN}
    }
});

// API call to get bridge item for a given bridge_id
export const getBridgeForViewing = (params: {role: Roles, bridge_id: string}) => ({
    type: GET_BRIDGE_BEING_VIEWED_CURRENTLY,
    payload: params,
    meta: {
        apiCall: {method: 'GET', url: API_PATH.BRIDGE(params.bridge_id), entity: BRIDGE_BEING_VIEWED_CURRENTLY}
    }
});

export const postComments = (
    params: {bridge_id: string, role: Roles, bridge_sub_items: BridgeSubItem[], deleted_row_ids?: string[]}
) => ({
    type: POST_COMMENTS,
    payload: params,
    meta: {
        apiCall: {method: 'POST', url: API_PATH.COMMENTS(params.bridge_id), entity: COMMENTS}
    }
});

export const getMarkers = (params: {report_id: string, role: Roles}) => ({
    type: GET_MARKERS,
    payload: params,
    meta: {
        apiCall: {method: 'GET', url: API_PATH.MARKERS, entity: MARKERS}
    }
});

// API call to fetch bridges for given report
export const getBridgesBeingViewed = (params: {role: Roles, variance_header: string}, report_id: string) => ({
    type: GET_BRIDGES_BEING_VIEWED_CURRENTLY,
    payload: params,
    meta: {
        apiCall: {method: 'GET', url: API_PATH.REPORT_BRIDGES(report_id), entity: BRIDGES_BEING_VIEWED_CURRENTLY}
    }
});


