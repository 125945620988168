import {
	Alert,
	Box,
	Button,
	DatePicker,
	Header,
	Input,
	Modal,
	SpaceBetween,
	TimeInput,
	TokenGroup
} from "@amzn/awsui-components-react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {resetBridgingPageStatus, saveBridge} from "src/actions/bridgingCommentary.actions";
import {Roles} from "src/common/roles";
import {getDateMinusOne} from "src/components/Bridging/AssignBridgeItems";
import {BridgeItem} from "src/components/BridgingCommentary/interfaces";
import {ReportLink} from "src/components/BridgingCommentary/tableComponents/ReportLink";
import {InfoList} from "src/components/FillOutBridgePage/InputComponents";
import {B} from "src/components/FillOutBridgePage/TableSubComponents";
import {getBridgeApiLoading} from "src/reducers/apiLoading.reducer";
import {getBridgingPageStatus} from "src/reducers/bridgingCommentary.reducer";
import {epochToDate, epochToHhMm, getEpoch, getTimeZone, humanizeEpoch} from "src/utils/timeHelpers";
import styled from "styled-components";

interface BridgeOwnersModalProps {
	visible: boolean
	setVisible: React.Dispatch<React.SetStateAction<boolean>>
	userRole: Roles | null
	bridgeDetails: BridgeItem
	setBridgeDetails: React.Dispatch<React.SetStateAction<BridgeItem | null>>
}

const BridgeDetailsModal = (props: BridgeOwnersModalProps) => {
	const dispatch = useDispatch();
	const {visible, setVisible, userRole, bridgeDetails, setBridgeDetails} = props;

	const bridgeApiLoading = useSelector(getBridgeApiLoading);
	// Get api call status for current bridge
	const bridgeStatus = useSelector(getBridgingPageStatus).bridge[bridgeDetails.bridge_id];
	const {saved, error} = bridgeStatus || {saved: false, error: ""};
	const [dueDate, setDueDate] = useState(epochToDate(bridgeDetails.due_timestamp));
	const [dueTime, setDueTime] = useState(epochToHhMm(bridgeDetails.due_timestamp));
	const [newOwners, setNewOwners] = useState("");

	// Constants
	const validDueDate = validateDueDate(dueDate, dueTime);

	useEffect(() => {
		if(!bridgeApiLoading && saved) onModalDismiss();
	}, [bridgeApiLoading, saved]);

	// Event handler when 'Add' button is clicked to add new user
	const onAddNewOwner = () => {
		const logins = newOwners.split(",").map(login => login.trim()).filter(login => !!login);
		const updatedOwners = Array.from(new Set([...bridgeDetails.bridge_owners, ...logins])).sort();
		setBridgeDetails({...bridgeDetails, bridge_owners: updatedOwners});
		setNewOwners("");
	}

	// Event handler when 'X' button is clicked
	const onModalDismiss = () => {
		setBridgeDetails(null);
		setVisible(false);
		dispatch(resetBridgingPageStatus());
	}

	return (
		<Modal
			onDismiss={() => onModalDismiss()}
			visible={visible}
			closeAriaLabel="Close modal"
			footer={
				<Box float="right">
					<SpaceBetween direction="horizontal" size="xs">
						{ userRole === Roles.Admin &&
                            <Button variant="primary"
									onClick={() => {
										if(userRole === Roles.Admin) dispatch(saveBridge(bridgeDetails, Roles.Admin));
                                    }}
                                    disabled={ bridgeDetails.bridge_owners.length === 0
										|| bridgeDetails.bridge_owners.length > 20
										|| validDueDate === null
									}
                                    loading={bridgeApiLoading}
                            >
								Submit
                            </Button>
						}
					</SpaceBetween>
				</Box>
			}
			header={<Header variant="h1"> Bridge Details </Header>}
		>
			<SpaceBetween direction="vertical" size="m">
				{!!error && <Alert type='error'>{error}</Alert>}
				<InfoList fontWeight="350" margin="0">
					<li>
						{'Variance: '} <B>{bridgeDetails.variance_headers.variance_header}</B>{` from `}
						<ReportLink reportName={bridgeDetails.report_name} reportId={bridgeDetails.report_id} role={userRole}/>
					</li>
					<li>{`Bridge Item: `}<B>{bridgeDetails.account_rollup}</B></li>
					<li>{`Bridge Owner Group: `}<B>{bridgeDetails.bridge_owner_group}</B></li>
					{ userRole === Roles.Admin ?
						<li className="spaced-line">
							<p style={{margin: "0", padding: "0"}}>
								<B fontWeight={"bold"}>Due by ({getTimeZone()}): </B>
							</p>
							{ bridgeDetails.due_timestamp >= getEpoch() ? <small>{`Change the due date`}</small>
								: <small style={{color: "red"}}>{`Due date has passed`}</small>
							}
							<CustomGrid columnDistribution="30% 15% auto">
								<DatePicker
									value={dueDate}
									onChange={({detail}) => {
										setDueDate(detail.value)
										const updatedDueDate = validateDueDate(detail.value, dueTime);
										if(updatedDueDate) setBridgeDetails({...bridgeDetails, due_timestamp: updatedDueDate});
									}}
									isDateEnabled={date =>
										// Past dates and week ends are not allowed
										date.getTime() >= getDateMinusOne().getTime()
									}
									openCalendarAriaLabel={selectedDate =>
										"Choose certificate expiry date" +
										(selectedDate
											? `, selected date is ${selectedDate}`
											: "")
									}
									nextMonthAriaLabel="Next month"
									placeholder="yyyy/mm/dd"
									previousMonthAriaLabel="Previous month"
									todayAriaLabel="Today"
									invalid={!validDueDate}
								/>
								<TimeInput
									value={dueTime}
									format="hh:mm"
									onChange={({detail}) => {
										setDueTime(detail.value)
										const updatedDueDate = validateDueDate(dueDate, detail.value);
										if(updatedDueDate) setBridgeDetails({...bridgeDetails, due_timestamp: updatedDueDate});
									}}
									placeholder="hh:mm"
									invalid={!validDueDate}
								/>
							</CustomGrid>
						</li>
						: <li>{`Due By: `}<B>{humanizeEpoch({epoch: bridgeDetails.due_timestamp})}</B></li>
					}
					{ userRole === Roles.Admin ?
						<li className={"spaced-line"}>
							<p style={{margin: "0", padding: "0"}}>
								<B fontWeight={"bold"}>Bridge owners: </B>
							</p>
							<TokenGroup
								items={bridgeDetails.bridge_owners.map(owner => (
									{label: owner, dismissLabel: `Remove ${owner}`}
								))}
								onDismiss={({detail: {itemIndex}}) => {
									setBridgeDetails({
										...bridgeDetails,
										bridge_owners: [
											...bridgeDetails.bridge_owners.slice(0, itemIndex),
											...bridgeDetails.bridge_owners.slice(itemIndex + 1)
										]
									});
								}}
							/>
							<>{ bridgeDetails.bridge_owners.length <= 20 ?
								<small>{`You can add up to ${20 - bridgeDetails.bridge_owners.length} users`}</small>
								: <small style={{color: "red"}}>{`You can only add up to 20 users`}</small>
							}</>
							<CustomGrid>
								<Input
									value={newOwners}
									onChange={({detail}) => setNewOwners(detail.value)}
									placeholder="Enter comma separated logins"
								/>
								<Button onClick={() => onAddNewOwner()} disabled={!newOwners}>Add</Button>
							</CustomGrid>
						</li>
						: <li>Bridge Owners: <B>{bridgeDetails.bridge_owners.join(", ")}</B></li>

					}
				</InfoList>
			</SpaceBetween>
		</Modal>
	)
}

export default BridgeDetailsModal;

export const CustomGrid = styled.div<{columnDistribution?: string}>`
  display: grid;
  grid-template-columns: ${props => props.columnDistribution || "60% 14% auto"};
  gap: 10px;
`;

export const validateDueDate = (date: string, time: string): number | null => {
	const timeCrumbs = time.split(":")
	const dateCrumbs = date.split("-");
	if(timeCrumbs.length !== 2 || dateCrumbs.length !== 3 || timeCrumbs[0].length !== 2 || timeCrumbs[1].length !== 2
		|| dateCrumbs[0].length !== 4 || dateCrumbs[1].length !== 2 || dateCrumbs[2].length !== 2
	) {
		return null;
	}
	const fullDate = new Date(`${date} ${time}`);
	// Past date and weekends are not allowed
	if (fullDate.getTime() < getEpoch()) return null;
	return fullDate.getTime();
}