
// This must match with what is in the server.
export enum Roles {
    Admin = "Admin",
    FinanceLeader = "Finance Leader",
    BusinessLeader = "Business Leader",
    BridgeOwner = "Bridge Owner",
    FGBSLeader = "FGBS Leader",
    SFPA_ReportAuthor = "SFPA_ReportAuthor",
    SFPA_ReportViewer = "SFPA_ReportViewer",
    DCFPA_ReportAuthor = "DC FPA_ReportAuthor",
    DCFPA_ReportViewer = "DC FPA_ReportViewer",
    UNKNOWN = "Unknown"
}

export const roleOrder: {[r in Roles]: number} = {
    [Roles.Admin]: 0,
    [Roles.FinanceLeader]: 1,
    [Roles.BusinessLeader]: 2,
    [Roles.FGBSLeader]: 3,
    [Roles.BridgeOwner]: 4,
    [Roles.SFPA_ReportAuthor] : 5,
    [Roles.SFPA_ReportViewer] : 6,
    [Roles.DCFPA_ReportAuthor] : 7,
    [Roles.DCFPA_ReportViewer] : 8,
    [Roles.UNKNOWN]: 9
}

export const finPressRoles = [Roles.Admin, Roles.FinanceLeader, Roles.BusinessLeader, Roles.BridgeOwner, Roles.FGBSLeader, Roles.SFPA_ReportAuthor, Roles.SFPA_ReportViewer, Roles.DCFPA_ReportAuthor, Roles.DCFPA_ReportViewer];
