import {Spinner} from "@amzn/awsui-components-react";
import * as React from 'react';
import styled from "styled-components";

export interface BackgroundProps {
	text?: string
	initialize?: () => void
}

const BackgroundSpinner = (props: BackgroundProps) => {
	const {text='Signing in'} = props;

	return (
		<BackgroundPage>
			<Text id={"auth-text"}>
				{text}
			</Text>
			<div>
				<Spinner size='large' variant='disabled'/>
			</div>
		</BackgroundPage>
	);
}

export default BackgroundSpinner;

const BackgroundPage = styled.div`
      margin: 0;
      padding: 0;
      display: grid;
      width: 100%;
      height: 100vh;
      overflow: hidden;
      pointer-events: none;
      place-content: center;
      background: #f2f3f3;
      align-items: center;
      grid-template-columns: 50% 50%;
      grid-column-gap: 1em;`;


const Text = styled.div`
    color: black;
    font-size: 24px;
    font-weight: 400;
    justify-self: right;`;


