import {Alert, Box, Button, ButtonDropdown, Header, SpaceBetween, Toggle} from "@amzn/awsui-components-react";
import Container from "@amzn/awsui-components-react/polaris/container";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setEnableLinksToBridges} from "src/actions/bridgingCommentary.actions";
import {
    postBridgeSync,
    postReportDefinition,
    postReportExport, ReportLibraryStatus,
    resetReportLibraryStatus,
    setUpdatingReportDefinitions
} from "src/actions/reportLibrary.actions";
import {Libraries, ReportStatus, ReportTriggerEvent} from "src/common/report";
import {Roles} from "src/common/roles";
import {TimedAlert} from "src/components/Common/TimedAlert";
import {getSpanTitles} from "src/components/CreateReportPage/CreateReportPage";
import Paths from "src/components/PageConfig/Paths";
import ConfirmReportSyncModal from "src/components/ViewReportPage/ConfirmReportSyncModal";
import {ReportTableProps} from "src/components/ViewReportPage/interfaces";
import ReportSyncTimeExpander from "src/components/ViewReportPage/ReportSyncTimeExpander";
import ReportTable from "src/components/ViewReportPage/ReportTable";
import {
    getBridgeSyncApiLoading,
    getReportApiLoading,
    getReportDefinitionsApiLoading,
    getReportExportApiLoading
} from "src/reducers/apiLoading.reducer";
import {getEnableLinksToBridges} from "src/reducers/bridgingCommentary.reducer";
import {getMarkersFromState, getReportLibraryStatus, getViewReport} from "src/reducers/reportLibrary.reducer";
import {getAssumedRole, getUser} from "src/reducers/user.reducer";
import {getEpoch, humanizeEpoch} from "src/utils/timeHelpers";

const ViewReportContainer = ({
    reportLibraryStatus: {startedReportComputation, startedBridgeSync, errorType, errorMessage}
}: {reportLibraryStatus: ReportLibraryStatus}) => {
    const dispatch = useDispatch();
    // Redux selectors
    const userName = useSelector(getUser);
    const role = useSelector(getAssumedRole)!
    const isAdmin = role === Roles.Admin;
    const {reportDefinitions, reportRows, canSyncObject} = useSelector(getViewReport);
    const enableLinksToBridges = useSelector(getEnableLinksToBridges);
    const isReference = reportDefinitions.report_library === Libraries.Reference;
    const reportDefinitionApiIsLoading = useSelector(getReportDefinitionsApiLoading);
    const reportApiIsLoading = useSelector(getReportApiLoading);
    const bridgeSyncAPILoading = useSelector(getBridgeSyncApiLoading)
    const reportExportIsLoading = useSelector(getReportExportApiLoading);
    const markers = useSelector(getMarkersFromState);
    // React states
    const [spanTitles, setSpanTitles] = useState<string[]>(reportDefinitions.span_titles ?? []);
    const [toggleSymbol, setToggleSymbol] = useState('$');
    const [linksToBridgesDisabled, setLinksToBridgesDisabled] = useState(true);
    const [popOverText, setPopOverText] = useState(`No published bridges on this report, hence disabled.`);
    const [fxToggle, setFXToggle] = useState(true);
    const [hasFXConfig, setHasFXConfig] = useState(false);
    // Constants
    const reportIsDisabled = disabledReports.has(reportDefinitions.report_id)
    const loadingSomeApi = reportDefinitionApiIsLoading || reportApiIsLoading || reportExportIsLoading;  // used to disable all button when something is loading
    const tableLastSynced = reportDefinitions.table_last_synced ?
        // Needed for backwards compatibility
        typeof reportDefinitions.table_last_synced !== 'object' ? [reportDefinitions.table_last_synced] : [...reportDefinitions.table_last_synced] :
        [0]
    const sortedSyncTimestamp = tableLastSynced.sort()
    const lastSyncedTime = humanizeEpoch({epoch: sortedSyncTimestamp.slice(-1), asDate: false});
    const reportTableProps: ReportTableProps = {
        isReference,
        isAdmin,
        varianceValueType:((hasFXConfig && fxToggle) ? ((toggleSymbol == '%') ? 'percentageWithFX' : 'amountWithFX') :
            ((toggleSymbol == '%') ? 'percentage' : 'amount')),
        reportDefinitions,
        reportRows,
        spanTitles,
        setSpanTitles,
        modifyPermission: isAdmin,
        role
    };
    const [modalDetails, setModalDetails] = useState<{visible: boolean, syncId: string}>({visible: false, syncId: ''});
    const [disableToggle, setDisableToggle] = useState(false)

    useEffect(function setInitialSpanTitle() {
        if (reportDefinitions.report_id !== "") {
            const {configure_columns = [], span_titles = []} = reportDefinitions;
            const fxConfig = configure_columns.reduce((prev, current) => prev || !!current.fx_impact, false);
            setSpanTitles(getSpanTitles(span_titles, configure_columns));
            setHasFXConfig(fxConfig);
        }
    }, [reportDefinitions]);

    useEffect(function reportSyncStartedSuccessfully() {
        if (startedReportComputation) {
            dispatch(resetReportLibraryStatus());
            window.location.replace(`#${Paths.REFERENCEREPORTS}`);
        }
    }, [startedReportComputation]);

    useEffect(() => {
        if(markers && markers?.length > 0) {
            setPopOverText(`${markers.length} Published Bridges`);
            setLinksToBridgesDisabled(false);
        } else {
            setPopOverText('No published bridges on this report, hence disabled');
            setLinksToBridgesDisabled(true);
        }
    }, [markers]);

    useEffect(() => {
        (!fxToggle && sortedSyncTimestamp[sortedSyncTimestamp.length - 1] < epochForFXImpactPercentage)
            ? setDisableToggle(true) : setDisableToggle(false)
    }, [fxToggle])

    // Helpers and handlers
    const onClickSync = (id: string) => {
        /*
          report_definition API SUCCESS MIDDLEWARE has dispatch action for report computation.
        */
        if(id === 'report') {
            const table_last_synced = [...tableLastSynced, getEpoch()]
            const updatedReportDefinitions = {
                ...reportDefinitions,
                configure_columns: reportDefinitions.configure_columns,
                table_last_synced,
                report_status: ReportStatus.UPDATING,
                last_modified: getEpoch(),
                last_modified_by: userName,
                span_titles: [...spanTitles],
                triggered_event: ReportTriggerEvent.EDIT
            };
            dispatch(postReportDefinition(updatedReportDefinitions));
        } else if (id === 'bridge') {
            dispatch(postBridgeSync(reportDefinitions.report_id, role))
        }
    };

    // When user selects % toggle and report last synced is less than epochForFXImpactPercentage
    // disable FX Impact i.e report will show % metrics which have FX Impact included
    const fxDisabled = !hasFXConfig ||
        (toggleSymbol === '%' && ( sortedSyncTimestamp[sortedSyncTimestamp.length - 1] < epochForFXImpactPercentage ))
    const dataTip = fxDisabled ? "Disabled as no variance columns have an FX scenario selected." : "";

    function constructActions() {
        return (
            <SpaceBetween direction='horizontal' size='s'>
                {isAdmin && <>
                    <ButtonDropdown
                        loading={reportApiIsLoading || reportDefinitionApiIsLoading || bridgeSyncAPILoading}
                        disabled={loadingSomeApi || (!canSyncObject.report_sync && (!canSyncObject.bridge_sync || startedBridgeSync))}
                        items={[
                            { text: "Report", id: "report", disabled: !canSyncObject.report_sync },
                            { text: "Bridges", id: "bridge", disabled: (!canSyncObject.bridge_sync || startedBridgeSync) },
                        ]}
                        onItemClick={(itemClickDetails) => setModalDetails({visible: true, syncId: itemClickDetails.detail.id})}
                    >
                        Sync
                    </ButtonDropdown>
                    <ConfirmReportSyncModal
                        visible={modalDetails.visible}
                        syncId={modalDetails.syncId}
                        onClose={() => setModalDetails({visible: false, syncId: ""})}
                        onConfirm={() => onClickSync(modalDetails.syncId)}
                    />
                    {!isReference &&
                    <Button iconName="edit"
                            disabled={loadingSomeApi || reportIsDisabled}
                            onClick={() => {
                                dispatch(setUpdatingReportDefinitions({
                                    ...reportDefinitions,
                                    report_status: ReportStatus.UPDATING,
                                    span_titles: spanTitles,
                                    triggered_event: ReportTriggerEvent.EDIT
                                }));
                                window.location.replace(`#${Paths.CREATEREPORT}`);
                            }}
                    >
                        Edit report
                    </Button>
                    }
                </>}
                <Button iconName="download"
                        disabled={loadingSomeApi}
                        loading={reportExportIsLoading}
                        onClick={() => dispatch(postReportExport(
                            reportDefinitions.report_id,
                            reportTableProps.varianceValueType
                        ))}
                >
                    Excel download
                </Button>
            </SpaceBetween>
        );
    }

    return (
        <>
            <Container
                header={
                    <SpaceBetween direction='vertical' size='s'>
                        {errorType && errorType.length > 0 &&
                        <Alert type='error'>{`${errorType}: ${errorMessage}`}</Alert>
                        }
                        {startedBridgeSync &&
                            <TimedAlert timeout={5000}>Bridge Sync Started</TimedAlert>
                        }
                        <Header variant="h2"
                                description={<>
                                    Last synced on {lastSyncedTime} <ReportSyncTimeExpander
                                    timestamps={sortedSyncTimestamp}/>
                                </>}
                                actions={constructActions()}
                        >
                            {reportDefinitions.name_and_type?.report_name}
                        </Header>
                    </SpaceBetween>
                }
            >
                <SpaceBetween direction="vertical" size="m">
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='s'>
                            <Toggle
                                data-tooltip-id={'global'}
                                data-tooltip-content={popOverText}
                                checked={enableLinksToBridges}
                                onChange={() => {
                                    dispatch(setEnableLinksToBridges(!enableLinksToBridges));
                                }}
                                disabled={linksToBridgesDisabled}
                            >
                                {'Bridges'}
                            </Toggle>

                            <Toggle checked={toggleSymbol === '$'}
                                    onChange={({detail}) => setToggleSymbol(detail.checked ? '$' : '%')}
                                    disabled={disableToggle}
                            >
                                {toggleSymbol}
                            </Toggle>
                            <Toggle data-tooltip-content={dataTip} data-tooltip-id={'global'} checked={fxToggle}
                                    onChange={({detail}) => setFXToggle(detail.checked)}
                                    disabled={fxDisabled}
                            >
                                Include FX
                            </Toggle>
                        </SpaceBetween>
                    </Box>
                    {ReportTable(reportTableProps)}
                </SpaceBetween>
            </Container>
        </>
    );
}

export default ViewReportContainer;


//ToDo(babburr@): Delete this after old reports are migrated.
const disabledReports = new Set<string>([
    'report_id.227c4abe-7f3e-4528-8ea7-b910c7c94a46',
    'report_id.26899c26-f1a6-4292-b8ac-eb9e2bb2a526',
    'report_id.e99b0ab5-ab18-4f9a-adb5-ab6deed9767a',
    'report_id.fcf6f650-8096-4550-b14b-8613b502e1b3',
    'report_id.97ce8cc0-d277-4dde-b6b9-f544cfd06f07',
    'report_id.19d88088-1644-4fb6-baed-3a5affb3dd77',
    'report_id.7b815ade-24b6-4ddf-8640-de81c4db5803',
    'report_id.3c48b530-d09c-4bde-978b-bba7d310b590',
    'report_id.d7810dab-2c1d-4820-ba78-8e8f5d708827',
    'report_id.336d03a1-3c38-4b6a-b808-37fa7cb3322e'
])

//For backward compatability of existing reports with recent FX Impact Percentage view implementation
// Why this epoch?, In production this is the latest report synced before deployment of new change
const epochForFXImpactPercentage = 1704996981841

