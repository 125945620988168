import {Row} from "@amzn/aws-fintech-fluid-table";
import {ThemeItem, ThemeItemType, ThemeTableEvent} from "src/components/Themes/interfaces";
import {getOriginalRow} from "src/components/Themes/table/reorderRow";

export const deleteRow = (items: ThemeItem[], row: Row<ThemeItem>, addEvent: (event: ThemeTableEvent) => void) => {
  if (row.data.type !== ThemeItemType.GROUPING) return items

  const newItems = [...items]

  const parent = { rowId: row.rowId.slice(0, row.rowId.length - 2) } as Row<ThemeItem>;
  const originalParent = getOriginalRow(parent, newItems)
  // Parent must have subRows because 'row' is under it
  const originalParentSubRows = originalParent ? originalParent.subRows as ThemeItem[] : newItems

  const insertIndex = originalParentSubRows.map(r => r.id).indexOf(row.data.id)
  const newSubRows = [...originalParentSubRows.slice(0, insertIndex), ...(row.data.subRows || []), ...originalParentSubRows.slice(insertIndex + 1)]
  addEvent({
    [row.data.id]: {
      itemType: row.data.type,
      deleted: true
    }
  })

  row.data.subRows?.forEach(r => addEvent({
    [r.id]: {
      itemType: r.type,
      move: {'new': originalParent?.id ?? '', old: row.data.id}
    }
  }))

  if (originalParent) originalParent.subRows = newSubRows
  else return newSubRows // This row was top level so newSubRows is just newItems

  return newItems
}