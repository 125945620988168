import { RowNumberingType } from "@amzn/aws-fintech-fluid-table";
import {Icon, Link, Toggle} from "@amzn/awsui-components-react";
import * as React from "react";
import Paths from "src/components/PageConfig/Paths";
import {DriverCommentarySelect} from "src/components/Themes/header/interfaces";
import {
  ActionBarDivider,
  ActionButton, ControlButtonWrapper,
  ThemeActionBarWrapper,
  ToggleWrapper
} from "src/components/Themes/header/styles";
import {ThemeActionHandlers, ThemeStates} from "src/components/Themes/interfaces";
import {CommentarySettings} from "src/components/Themes/ThemesPage";

interface ControlGroupProps {
  selectedId: string,
  onChange: (id: string) => void,
  options: {text: string, id: string}[]
}

const ControlGroup = ({selectedId, onChange, options}: ControlGroupProps) => <ControlButtonWrapper length={options.length}>
  {options.map((option) =>
    <ActionButton key={option.id} selected={selectedId === option.id} onClick={() => onChange(option.id)}>
      {option.text}
    </ActionButton>)}
</ControlButtonWrapper>

interface ThemeActionBarProps{
  leftScenario: string,
  rightScenario: string,
  state: ThemeStates,
  actionHandlers: ThemeActionHandlers,
  commentarySettings: CommentarySettings,
  reportId: string,
  rowNumbering: RowNumberingType
}


const ThemeActionBar = ({actionHandlers, commentarySettings, reportId, rowNumbering}: ThemeActionBarProps) => {
  return <ThemeActionBarWrapper>
        <ToggleWrapper key={'row-number-toggle-control'}>
          <Toggle
            onChange={({ detail }) => actionHandlers.setRowNumbering(detail.checked ? 'relative' : 'absolute')}
            checked={rowNumbering === 'relative'}
            data-testid={'numbering-toggle'}
          >
            Simple #
          </Toggle>
        </ToggleWrapper>
        <ActionBarDivider/>
        <ActionButton onClick={actionHandlers.onAddRow} key={'add-row-button'}>
          <Icon name="add-plus" /> New Theme
        </ActionButton>
        <ActionBarDivider/>
        <ControlGroup
          key={'segment-control'}
          selectedId={commentarySettings.driverCommentarySelect || DriverCommentarySelect.both}
          onChange={selectedId => actionHandlers.setDriverCommentarySelect(selectedId as DriverCommentarySelect)}
          options={[
            { text: 'Driver', id: DriverCommentarySelect.driver },
            {text: 'Both', id: DriverCommentarySelect.both},
            {text: 'Commentary', id: DriverCommentarySelect.commentary}
          ]}
        />
        <ActionBarDivider/>
        <ToggleWrapper key={'toggle-control'}>
          <Toggle
            onChange={({ detail }) => actionHandlers.setSourceCommentary(detail.checked)}
            checked={!!commentarySettings.sourceCommentary}
          >
            <Link href={`#${Paths.VIEWREPORT}?report_id=${reportId}`}>Source Text</Link>
          </Toggle>
        </ToggleWrapper>
    </ThemeActionBarWrapper>
}

export default ThemeActionBar;
