/* eslint-disable max-lines-per-function */
import { Button, Container, ContentLayout, Header, SpaceBetween,Spinner, StatusIndicator} from '@amzn/awsui-components-react';
import React, {useEffect, useState} from 'react';
import { Provider, useSelector } from "react-redux"
import {useParams } from 'react-router-dom';
import {Roles} from "src/common/roles";
import { _directDownloadOnClick } from 'src/middleware/app/fileTransfer.middleware';
import {getAssumedRole, getUser} from 'src/reducers/user.reducer';

import {useExecuteWorkflowMutation, useFetchFacetQuery, useFetchWorkflowQuery, useRefreshWorkflowMutation} from '../apiSlice';
import {
    ExcelStatus,
    ExecuteWorkflowRequest,
    FacetStatus,
    FetchFacetRequest,
    FetchWorkflowRequest
} from '../interfaces/facetParams';
import { isErrorWithMessage, isFetchBaseQueryError } from '../rtkQueryErrorHelpers';
import workflowStore from '../stores/workflowStore';
import {BYORTable} from './BYORTable'
import { downloads3 } from './downloadURL';
import { ExecutionNotFound } from './ExecutionNotFound';
import { BYORReportStyle } from './styling';

interface Report {
    columns : any,
    rows: any
  }
export const viewCustomReport = () => {
    const userName = useSelector(getUser)
    const role = useSelector(getAssumedRole)
    //query call to getWorkflow 
    return (
    <Provider store = {workflowStore}>
      <ContentLayout>
      <Container>
    <BYORReport {...{
        userName,
        role}
    } ></BYORReport>
    </Container>
    </ContentLayout>
    </Provider>
   )
}

export const BYORReport = ({userName, role} : {userName : any, role: Roles| null}) => {
    const [jsonValue, setJsonValue] = useState<Report>();

    const [stopPollingFacet, setStopPollingFacet] = useState<boolean>(false);

    const [stopPollingWorkflow, setStopPollingWorkflow] = useState<boolean>(false);

    const [downloadExcelError, setDownloadExcelError] = useState<boolean>(false);

    //For displaying workflow Error message
    const [workflowError, setWorkflowError] = useState<boolean>(false);
    const [workflowErrorMessage, setWorkflowErrorMessage] =  useState<string>("");

    //Case where FacetExecution is Completed
    const {workflow_id = "", execution_id = ""} = useParams()


    const executionRequest : ExecuteWorkflowRequest = {
        workflow_id : workflow_id,
        workflow_version : "version_1",
        execution_id : execution_id,
        role: role,

    }

    const fetchWorkflowRequest : FetchWorkflowRequest = {
        workflow_id : workflow_id,
        workflow_version : "version_1",
        execution_id : execution_id,
        role: role,

    }

    const {data : executionResponse, isLoading, error : fetchWorkflowError} = useFetchWorkflowQuery(fetchWorkflowRequest,{
      pollingInterval : 10 * 1000,
      skip: stopPollingWorkflow
    })

    const testRequest : FetchFacetRequest = {
        facet_id : executionResponse?.input_id || "",
        execution_id : executionResponse?.input_version || "",
        role: role,
      }

    const {data: facetResponse, error: fetchFacetError, isSuccess : isFacetAvailable} = useFetchFacetQuery(testRequest, {
        pollingInterval : 10 * 1000,
        skip: isLoading || stopPollingFacet
    })

    const [executeWorkflow, {error: executeWorkflowError} ] = useExecuteWorkflowMutation()

    const [refreshWorkflow, {isLoading: isLoadingExcel}] = useRefreshWorkflowMutation()


    const disableDownload = executionResponse?.excel_details.excel_status == ExcelStatus.INPROGRESS || executionResponse?.excel_details.excel_status == ExcelStatus.NOTSTARTED

    //cancel after 10 minutes 
    useEffect(() => {
      const timeout = setTimeout(() => {
        setStopPollingFacet(true); // Set stopPolling to true to stop polling
        setStopPollingWorkflow(true); // Set stopPolling to true to stop polling
        setWorkflowError(true)
        setWorkflowErrorMessage('TimeOutError')
      }, 10 * 60000); // Timeout after 10 minute (adjust as needed)
  
      return () => clearTimeout(timeout); // Clean up timeout on component unmount
    }, []);

    //only create workflowExecution when excel status is not complete.
        //already in ddb
      useEffect(() => {
        const downloadExcel = async () => {
          executeWorkflow(executionRequest).unwrap()

        };

        if(facetResponse?.status == FacetStatus.COMPLETE && executionResponse?.excel_details.excel_status == ExcelStatus.NOTSTARTED){
          downloadExcel()
      }
      if(executionResponse?.excel_details.excel_status == ExcelStatus.SUCCESS  || executionResponse?.excel_details.excel_status == ExcelStatus.FAILED){
        setStopPollingWorkflow(true)
      }
      }, [facetResponse, executionResponse]);

  //   //already in ddb
    useEffect(() => {
        const fetchData = async (url : string | undefined) => {
          if(url){
            const json = await downloads3(url)
            setJsonValue(json)
          }    
        };

        if(isFacetAvailable && facetResponse.status == FacetStatus.COMPLETE){
            fetchData(facetResponse.facet_output_s3_presigned_url); // Fetch data when the component mounts
        }
        if(isFacetAvailable && (facetResponse.status == FacetStatus.COMPLETE || facetResponse?.status == FacetStatus.ERROR) ){
          setStopPollingFacet(true) //stop polling workflow

          if(facetResponse?.status == FacetStatus.ERROR){
            setWorkflowError(true)
            setStopPollingWorkflow(true) //stop polling workflow
          }
        }
      }, [facetResponse]);

    //if error -> shows error 
    if(fetchWorkflowError || fetchFacetError || executeWorkflowError){
      setStopPollingFacet(true) //stop polling workflow
      setStopPollingWorkflow(true) //stop polling workflow

      if(isFetchBaseQueryError(fetchWorkflowError) && isErrorWithMessage(fetchWorkflowError.data)){
        setWorkflowErrorMessage(fetchWorkflowError.data.message)
        setWorkflowError(true)
      }
    }

    
   if(workflowError){
      if(workflowErrorMessage == 'MissingItemError'){
        return(<ExecutionNotFound></ExecutionNotFound>)
      }
      if(workflowErrorMessage == 'TimeOutError'){
        return(<StatusIndicator type="error"> Execution has timed out </StatusIndicator>)
      }
      return (<StatusIndicator type="error"> Execution has failed </StatusIndicator>)
   }
   
   if(!jsonValue){
    //if still polling
    return (<><Spinner></Spinner><h1>Executing Facet</h1></>)
   }

   const downloadExcelHandler = async () => {
      // if excel s3 presigned url failed or expires, raise some error
      try{
        //use mutation.then direct download on Click
        await refreshWorkflow(fetchWorkflowRequest).unwrap().then(res => _directDownloadOnClick(res?.excel_details.excel_s3_presigned_url!))
      }
      catch (error){
        //show an error status indicator
        setDownloadExcelError(true)
      }
   }

    //TODO force fetch when you click downloadexcel handler
    return (
      <BYORReportStyle className='byor-report'>
        <Header
        actions={
          <SpaceBetween direction="horizontal" size="xs">
          {downloadExcelError ? <StatusIndicator type="error">Download Excel has failed {executionResponse?.excel_details.message} </StatusIndicator> : ""}
          {<Button variant="primary" disabled={disableDownload} loading={isLoadingExcel} loadingText='Downloading Excel' onClick={downloadExcelHandler}>{disableDownload? "Generating Excel" : "Download Excel"}</Button>}
          </SpaceBetween>
        }
        > {executionResponse?.workflow_name || "workflow" } by {executionResponse?.created_by || userName}</Header>
        <><BYORTable {...jsonValue}></BYORTable></>
        </BYORReportStyle>
    )
}