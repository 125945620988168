import {ReduxAction} from "src/common/interfaces";
import {JobDropdownOptions, MDXJob} from "src/components/MDXLibraryPage/interfaces";
import {API_PATH} from "src/constants/api";
import {LibraryGroup, PaginationConfig} from "src/reducers/mdxLibrary.reducer";


// Common constants
const MDX_LIBRARY = '[MDX LIBRARY]';
export const JOB = '[JOB]';
export const JOB_GROUP = '[JOB GROUP]';
export const JOB_GROUPS = '[JOB GROUPS]';
export const SCENARIOS_METADATA = '[SCENARIOS METADATA]';

// Types for reducer
export const SET_PAGINATION_CONFIG = `${MDX_LIBRARY} SET PAGINATION_CONFIG`;
export const SET_JOB_OPTIONS = `${MDX_LIBRARY} SET JOB_OPTIONS`;
export const SET_GROUPS = `${MDX_LIBRARY} SET GROUPS`;
export const ADD_GROUP = `${MDX_LIBRARY} ADD GROUP`;
export const REMOVE_GROUP = `${MDX_LIBRARY} REMOVE GROUP`;
export const UPDATE_GROUP = `${MDX_LIBRARY} UPDATE GROUP`;
export const SET_STATUS = `${MDX_LIBRARY} SET STATUS`;
export const RESET_STATUS = `${MDX_LIBRARY} RESET STATUS`;

// API call types
export const POST_JOB = `${JOB} POST`;
export const DELETE_JOB = `${JOB} DELETE`;
export const GET_JOB_GROUPS = `${JOB_GROUPS} GET`;
export const GET_JOB_GROUP = `${JOB_GROUP} GET`;
export const POST_JOB_GROUP = `${JOB_GROUP} POST`;
export const GET_SCENARIOS_METADATA = `${SCENARIOS_METADATA} GET`;

// constrained actions on groups and jobs
type GroupActionType = 'create' | 'update';
type JobActionType = 'refresh' | 'cancel';

// Interface definition for status object in mdx library
export interface MDXLibraryStatus {
    fetchedGroups?: boolean,
    cancelledJob?: boolean,
    refreshedJob?: boolean,
    deletedJob?: boolean,
    createdGroup?: boolean,
    updatedGroup?: boolean,
    errorType?: string,
    errorMessage?: string,
}

// Interface definition of redux action for MDX library
export interface MDXLibraryAction extends ReduxAction{
   payload: {
       groupName?: string,
       job_id?: string,
       jobOptions?: JobDropdownOptions,
       groups?: {[group: string]: LibraryGroup}
       group?: {[group: string]: LibraryGroup}
       jobs?: MDXJob[],
       status?: MDXLibraryStatus,
       paginationKey?: {[p: string]: any},
       hasMore?: boolean | undefined,
   }
}


/************************************ REDUCER ACTIONS *****************************************/
// Action to set mdx library status
export const setLibraryStatus= (status: MDXLibraryStatus): MDXLibraryAction => ({
    type: SET_STATUS,
    payload: {status}
});


// Action to reset mdx library status
export const resetLibraryStatus= (): MDXLibraryAction => ({
    type: RESET_STATUS,
    payload: {}
});

// Action to set the pagination config for mdx library
export const setMDXPaginationConfig = (payload: PaginationConfig): MDXLibraryAction => ({
    type: SET_PAGINATION_CONFIG,
    payload: payload,
});


// Action to set job options for a mdx job in redux store
export const setJobDropdownOptions = (jobOptions: JobDropdownOptions): MDXLibraryAction => ({
    type: SET_JOB_OPTIONS,
    payload: {jobOptions},
});


// Action to set multiple groups in mdx library page
export const setGroups = (groups: {[group: string]: LibraryGroup}): MDXLibraryAction => ({
    type: SET_GROUPS,
    payload: {groups}
});


// Action to add new group to mdx library page
export const addGroup= (group: {[p: string]: LibraryGroup}): MDXLibraryAction => ({
    type: ADD_GROUP,
    payload: {group}
});


// Action to add new group to mdx library page
export const removeGroup= (groupName: string): MDXLibraryAction => ({
    type: REMOVE_GROUP,
    payload: {groupName}
});


// Action to update job group in mdx library
export const updateGroup = (group: {[p: string]: LibraryGroup}): MDXLibraryAction => ({
    type: UPDATE_GROUP,
    payload: {group}
});


/************************************ API CALL ACTIONS *****************************************/
// Action to fetch dropdown options for a job
export const getScenariosMetadata = () => ({
    type: GET_SCENARIOS_METADATA,
    payload: {},
    meta: {
        apiCall: {method: 'GET', url: API_PATH.SCENARIOS_METADATA, entity: SCENARIOS_METADATA}
    }
});

// Action to get multiple job groups
export const getJobGroups = (payload: {paginationKey?: {[p: string]: any}, limit?: number}) => ({
    type: GET_JOB_GROUPS,
    payload: {...payload},
    meta: {
        apiCall: {method: 'GET', url: API_PATH.JOB_GROUPS, entity: JOB_GROUPS}
    }
});


// Action to get the specific job group
export const getJobGroup = (group: string) => ({
    type: GET_JOB_GROUP,
    payload: {},
    meta: {
        apiCall: {method: 'GET', url: API_PATH.JOB_GROUP(group), entity: JOB_GROUP}
    }
});


// Action to create/update job group
export const postJobGroup = (group: string, job_details: MDXJob[], action: GroupActionType) => ({
    type: POST_JOB_GROUP,
    payload: {job_details, action},
    meta: {
        apiCall: {method: 'POST', url: API_PATH.JOB_GROUP(group), entity: JOB_GROUP}
    }
});


// Action to refresh/cancel job
export const postJob = (group: string, job_id: string, action: JobActionType) => ({
    type: POST_JOB,
    payload: {action},
    meta: {
        apiCall: {method: 'POST', url: API_PATH.JOB(group, job_id), entity: JOB}
    }
});


// Action to delete job
export const deleteJob = (group: string, job_id: string) => ({
    type: DELETE_JOB,
    payload: {},
    meta: {
        apiCall: {method: 'DELETE', url: API_PATH.JOB(group, job_id), entity: JOB}
    }
});
