export const sanitizeString = (inputString: string) => {
    try {
        // Only allow ASCII characters between 0 - 127
        return inputString.replace(/[^\x00-\x7F]/g, "");
    } catch (e) {
        console.log("Error while sanitizing input string ", e);
        return inputString;
    }
}

export const isLetter = (ascii: number) => (ascii >= 65 && ascii <= 90) || (ascii >= 97 && ascii <= 122);
export const isNumber = (ascii: number) => ascii >= 48 && ascii <= 57;
export const isUnderscore = (ascii: number) => ascii == 95;