import {SpaceBetween} from "@amzn/awsui-components-react";
import React from "react";
import {EverArrow, EverExchange} from "src/components/FillOutBridgePage/CommentVersioning/ArrowComponents";
import {
    ColumnText,
    EachFollowUpChange, SubItemName, ValueNew, ValueOld,
} from "src/components/FillOutBridgePage/CommentVersioning/BridgeComparisonStyles";
import {Metadata} from "src/components/FillOutBridgePage/CommentVersioning/CommentVersionContainers";
import {BridgeSubItem} from "src/components/FillOutBridgePage/interfaces";
import {getRelativeTime} from "src/utils/timeHelpers";

interface RecentCommentMetaData {timestamp: number, commenter: string}

export const buildFirstVersionRow = (rowId: string, rowData: BridgeSubItem, index: number, setSelectedRow: Function, unselect: Function) => {
    const {name, varianceValue} = rowData

    const onClick = () =>{
        setSelectedRow({id: rowId, name: name})
        unselect()
    }

    return <EachFollowUpChange key={index + 1}>
        <SubItemName className={'sub-item-name'} indented={index > 0}>{name}</SubItemName>
        <div/>
        <div/>
        <div/>
        <ValueNew className={'value-new'}>{varianceValue}</ValueNew>
        <EverExchange onClick={onClick}/>
    </EachFollowUpChange>
}


export const buildMiddleVersionRow = (rowId: string, selectedRowData: BridgeSubItem, previousRowData:BridgeSubItem, index: number, setSelectedRow: Function, unselect: Function) =>{
    const name = selectedRowData.name || previousRowData.name;

    const onClick = () => {
        setSelectedRow({id: rowId, name: name})
        unselect()
    }

    return (
        <EachFollowUpChange key={index + 1}>
            <SubItemName className={'sub-item-name'} indented={index > 0}>{name}</SubItemName>
            <ColumnText className={'follow-up-text-deep-dive'}>{previousRowData.follow_up.comment}</ColumnText>
            <ValueOld className={'value-old'}>{previousRowData.varianceValue || ""}</ValueOld>
            <SpaceBetween size={"s"}/>
            <ValueNew className={'value-new'}>{selectedRowData.varianceValue || ""}</ValueNew>
            <EverExchange onClick={onClick}/>
        </EachFollowUpChange>
    )
}

export const buildLastVersionRow = (draftVersionStarted: boolean, rowId: string, selectedRowData: BridgeSubItem,
                                    previousRowData:BridgeSubItem, index: number, setSelectedRow: Function, unselect: Function) =>{
    const name = selectedRowData.name || previousRowData.name;
    const followUp = draftVersionStarted ? selectedRowData.follow_up.comment : previousRowData.follow_up.comment

    const onClick = () => {
        setSelectedRow({id: rowId, name: name})
        unselect()
    }

    return (
        <EachFollowUpChange key={index + 1}>
            <SubItemName className={'sub-item-name'} indented={index > 0}>{name}</SubItemName>
            <ColumnText className={'follow-up-text-deep-dive'}>{followUp}</ColumnText>
            <ValueOld className={'value-old'}>{previousRowData.varianceValue || ""}</ValueOld>
            <SpaceBetween size={"s"}/>
            <ValueNew className={'value-new'}>{selectedRowData.varianceValue || ""}</ValueNew>
            <EverExchange onClick={onClick}/>
        </EachFollowUpChange>
    )
}

export const firstVersionHeader = (index: number) => {
    return <EachFollowUpChange className={"dummy-row-for-version-arrow"} key={0}>
        <SubItemName className={'sub-item-name'}/>
        <div/>
        <div/>
        <div/>
        <ValueNew className={'value-new'}>{`Version ${index + 1}`}</ValueNew>
        <ColumnText className={'follow-up-text-deep-dive'}>Actions</ColumnText>
    </EachFollowUpChange>
}

export const firstVersionFooter = (mostRecentCommenter: RecentCommentMetaData, index: number) => {
    return <EachFollowUpChange className={"dummy-row-for-metadata"} key={index + 1}>
        <span/>
        <span/>
        <span/>
        <span/>
        <Metadata className={'meta-data'}>
            <span>{mostRecentCommenter.commenter}</span>
            <span>{getRelativeTime(mostRecentCommenter.timestamp.toString())}</span>
        </Metadata>
    </EachFollowUpChange>
}

export const middleVersionHeader = (index: number) => {
    return <EachFollowUpChange className={"dummy-row-for-version-arrow"} key={0}>
        <SubItemName className={'sub-item-name'}/>
        <ColumnText className={'follow-up-text-deep-dive'}>Previous Follow-up</ColumnText>
        <ValueOld className={'value-old'}>Version {index}</ValueOld>
        <EverArrow/>
        <ValueNew className={'value-new'}>{`Version ${index + 1}`}</ValueNew>
        <ColumnText className={'follow-up-text-deep-dive'}>Actions</ColumnText>
    </EachFollowUpChange>
}
export const middleVersionFooter = (previousMostRecentCommenter: RecentCommentMetaData, selectedMostRecentCommenter: RecentCommentMetaData, index: number) => {
    return <EachFollowUpChange className={"dummy-row-for-metadata"} key={index + 1}>
        <span/>
        <span/>
        <Metadata className={'meta-data'}>
            <span>{previousMostRecentCommenter.commenter}</span>
            <span>{getRelativeTime(previousMostRecentCommenter.timestamp.toString())}</span>
        </Metadata>
        <span/>
        <Metadata className={'meta-data'}>
            <span>{selectedMostRecentCommenter.commenter}</span>
            <span>{getRelativeTime(selectedMostRecentCommenter.timestamp.toString())}</span>
        </Metadata>
    </EachFollowUpChange>
}


export const lastVersionHeader = (draftVersionStarted: boolean, index: number) => {
    return <EachFollowUpChange className={"dummy-row-for-version-arrow"} key={0}>
        <SubItemName className={'sub-item-name'}/>
        <ColumnText className={'follow-up-text-deep-dive'}>{!draftVersionStarted && 'Previous'} Follow-up</ColumnText>
        <ValueOld className={'value-old'}>Version {index}</ValueOld>
        <EverArrow/>
        <ValueNew className={'value-new'}>{draftVersionStarted ? 'Drafted Version' : `Version ${index+1}`}</ValueNew>
        <ColumnText className={'follow-up-text-deep-dive'}>Actions</ColumnText>
    </EachFollowUpChange>
}
export const lastVersionFooter = (previousMostRecentCommenter: RecentCommentMetaData, selectedMostRecentCommenter: RecentCommentMetaData, index: number) => {
    return <EachFollowUpChange className={"dummy-row-for-metadata"} key={index + 1}>
        <span/>
        <span/>
        <Metadata className={'meta-data'}>
            <span>{previousMostRecentCommenter.commenter}</span>
            <span>{getRelativeTime(previousMostRecentCommenter.timestamp.toString())}</span>
        </Metadata>
        <span/>
        <Metadata className={'meta-data'}>
            <span>{selectedMostRecentCommenter.commenter}</span>
            <span>{getRelativeTime(selectedMostRecentCommenter.timestamp.toString())}</span>
        </Metadata>
    </EachFollowUpChange>
}