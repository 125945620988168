import {Box, Button, Grid, SpaceBetween, Spinner} from "@amzn/awsui-components-react";
import React, {useEffect} from "react";
import {Roles} from "src/common/roles";
import {A, Img, ProfileDiv} from "src/components/Bridging/RequestBridgeInputs";
import {BridgeItem, BridgeStatusType} from "src/components/BridgingCommentary/interfaces";
import {ReportLink} from "src/components/BridgingCommentary/tableComponents/ReportLink";
import {getCountedText} from "src/components/FillOutBridgePage/FillOutBridgeTableHelpers";
import {CustomBox, InfoList} from "src/components/FillOutBridgePage/InputComponents";
import {BridgeAction} from "src/components/FillOutBridgePage/interfaces";
import {humanizeEpoch} from "src/utils/timeHelpers";
import styled from "styled-components";

export const BridgeInformation = ({bridgeItem, role}: { bridgeItem: BridgeItem, role: Roles }) => {
    const {report_id, report_name, variance_headers} = bridgeItem;
    const bridgeOwnerText = getCountedText("Bridge owner", bridgeItem.bridge_owners);
    const bridgeOwnersList = bridgeItem.bridge_owners.join(", ");
    return (
        <CustomBox>
            <InfoList fontWeight="350">
                <li>
                    {'Variance: '} <B>{variance_headers.variance_header}</B>{` from `}
                    <ReportLink reportName={report_name} reportId={report_id} role={role}/>
                </li>
                {bridgeItem.note_to_bridge_owner &&
                <li>{`Note to bridge owner: `}<B>{bridgeItem.note_to_bridge_owner}</B></li>
                }
            </InfoList>
            <InfoList fontWeight="350">
                <li>
                    {`Due by: `}<B>{humanizeEpoch({epoch: bridgeItem.due_timestamp})}</B>
                </li>
                <li>{`${bridgeOwnerText}: `}<B>{bridgeOwnersList}</B></li>
            </InfoList>
        </CustomBox>
    );
}


interface BridgeActionButtonsProps {
    bridgeItem: BridgeItem
    role: Roles,
    clickedButton: string,
    canSaveBridge: boolean
    canSubmitBridge: boolean
    followUpCount: number,
    disableButton: boolean
    loading: boolean
    isThisForProposedState: boolean
    onClickBridgeActionButton: Function
}

export const BridgeActionButtons = (props: BridgeActionButtonsProps) => {
    const {
        bridgeItem,
        role,
        clickedButton,
        canSaveBridge,
        canSubmitBridge,
        followUpCount,
        disableButton,
        loading,
        isThisForProposedState = false
    } = props;
    const {status} = bridgeItem;
    // Conditional props for buttons
    const submitButtonOtherProps = !canSubmitBridge ? {"data-tooltip-content": "Can not submit bridge because unexplained var. is not 0"} : {};
	const publishButtonOtherProps = !canSubmitBridge ? {"data-tooltip-content": "Can not publish bridge because unexplained var. is not 0"} : {};
    const saveButtonOtherProps = !canSaveBridge ? {"data-tooltip-content": "None of the row has been edited"} : {};
    const followUpButtonOtherProps = followUpCount > 0 ? {"data-tooltip-content": "This will trigger an email notification to all stakeholders"} :
        {"data-tooltip-content": "No follow up has been added."}

    if (status === BridgeStatusType.Proposed) {
        return (
            <SpaceBetween direction="horizontal" size="xs">
                <div {...saveButtonOtherProps} data-tooltip-id="global">
                            <Button
                                loading={loading && clickedButton === 'save'}
                                disabled={disableButton || !canSaveBridge}
                                onClick={() => props.onClickBridgeActionButton('save')}
                            >
                                Save
                            </Button>
                </div>
                { isThisForProposedState &&
                    <Button
                        loading={loading && clickedButton === 'request_inputs'}
                        onClick={() => props.onClickBridgeActionButton('request_inputs')}
                    >
                        Request Inputs
                    </Button>
                }
            </SpaceBetween>
        );
    } else if (status === BridgeStatusType.Requested || status === BridgeStatusType.InProgress) {
        return (
            <SpaceBetween direction="vertical" size="xxxs">
                {status === BridgeStatusType.InProgress &&
                    <small style={{color: "green", margin: "0"}} data-testid={'last-saved'}>
                        Last saved: {humanizeEpoch({epoch: bridgeItem.last_modified_timestamp})}
                    </small>
                }
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <div {...saveButtonOtherProps} data-tooltip-id="global">
                            <Button
                                data-testid={'save-button'}
                                loading={loading && clickedButton === 'save'}
                                disabled={disableButton || !canSaveBridge}
                                onClick={() => props.onClickBridgeActionButton('save')}
                            >
                                Save
                            </Button>
                        </div>
                        <div {...submitButtonOtherProps} data-tooltip-id="global">
                            <Button
                                variant={"primary"}
                                loading={loading && clickedButton === 'submit'}
                                disabled={disableButton || !canSubmitBridge}
                                onClick={() => props.onClickBridgeActionButton('submit')}
                            >
                                Submit
                            </Button>
                        </div>
                    </SpaceBetween>
                </Box>
            </SpaceBetween>
        );
    } else if ((status === BridgeStatusType.Submitted || status === BridgeStatusType.Approved) && role === Roles.Admin) {
        const [thirdButtonAction, thirdButtonName] = (status === BridgeStatusType.Submitted) ? ["approve", "Approve"]
            : ["publish", "Publish"];
        return (
            <SpaceBetween direction="horizontal" size="xs">
                <div {...saveButtonOtherProps} data-tooltip-id="global">
                    <Button
                        loading={loading && clickedButton === 'save'}
                        disabled={disableButton || !canSaveBridge}
                        onClick={() => props.onClickBridgeActionButton('save')}
                    >
                        Save
                    </Button>
                </div>
                {status === BridgeStatusType.Approved ?
                    <div
                        data-tooltip-id="global"
                        data-tooltip-content={"This will trigger an email notification to all stakeholders"}
                    >
                        <Button
                            loading={loading && clickedButton === 'revoke'}
                            disabled={disableButton}
                            onClick={() => props.onClickBridgeActionButton('revoke')}
                        >
                            Revoke
                        </Button>
                    </div> :
                    <div {...followUpButtonOtherProps} data-tooltip-id="global">
                        <Button
                            loading={loading && clickedButton === 'follow_up'}
                            disabled={disableButton || followUpCount < 1}
                            onClick={() => props.onClickBridgeActionButton('follow_up')}
                        >
                            {followUpCount > 0 ? followUpCount : ""} Follow-Up
                        </Button>
                    </div>
                }
                {thirdButtonName === "Publish" ?
                    <div {...publishButtonOtherProps} data-tooltip-id="global">
                        <Button
                            variant="primary"
                            loading={loading && clickedButton === thirdButtonAction}
                            disabled={disableButton || !canSubmitBridge}
                            onClick={() => props.onClickBridgeActionButton(thirdButtonAction as BridgeAction)}
                        >
                            {thirdButtonName}
                        </Button>
                    </div>
                    : <Button
                        variant="primary"
                        loading={loading && clickedButton === thirdButtonAction}
                        disabled={disableButton}
                        onClick={() => props.onClickBridgeActionButton(thirdButtonAction as BridgeAction)}
                    >
                        {thirdButtonName}
                    </Button>
                }
            </SpaceBetween>
        )
    } else if (status === BridgeStatusType.Published && role === Roles.Admin) {
        return (
            <SpaceBetween direction="horizontal" size="xs">
                <Button variant="primary"
                        loading={loading && clickedButton === 'unpublish'}
                        disabled={disableButton}
                        onClick={() => props.onClickBridgeActionButton('unpublish')}
                >
                    Unpublish
                </Button>
            </SpaceBetween>
        );
    } else {
        return (<></>);
    }
}

export enum BackgroundAction {
    none,
    refreshingComments,
    autoSaving,
    commentsSaved
}

interface TableFooterProps {
    recentCommentators: string[],
    backgroundAction: BackgroundAction,
    setBackgroundAction: Function
}

export const TableFooter = (props: TableFooterProps) => {
    const {recentCommentators, backgroundAction, setBackgroundAction} = props;

    useEffect(() => {
        if (backgroundAction === BackgroundAction.commentsSaved) {
            const timeId = setTimeout(() => setBackgroundAction(BackgroundAction.none), 3000)
            return () => {
                clearTimeout(timeId)
            }
        }
    }, [backgroundAction])

    const recentCommenters = recentCommentators.length > 0 ?
        <RecentCommentatorsBox>
            Recent commentators:
            <Grid disableGutters={true}>
                {recentCommentators.map(user =>
                    <ProfileDiv padding={"0 4px"}>
                        <A href={`https://phonetool.amazon.com/users/${user}`} target={"_blank"}>
                            <Img title={`${user}@`} alt={"pic"} imageHeight={"26px"}
                                 src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${user}`}
                            />
                        </A>
                    </ProfileDiv>
                )}
            </Grid>
        </RecentCommentatorsBox>
        : null

    const loadingComponents: any = {
        [BackgroundAction.none]: recentCommenters,
        [BackgroundAction.refreshingComments]: <FooterText float={"right"}><Spinner/> Refreshing bridge...</FooterText>,
        [BackgroundAction.autoSaving]: <FooterText float={"right"}><Spinner/> Saving comments...</FooterText>,
        [BackgroundAction.commentsSaved]: <FooterText float={"right"} color={'green'} data-testid={'comments-saved'}>Comments Saved</FooterText>,
    }

    return (
        <FooterBox>
            <>Unexplained variance will disappear when value reaches zero.</>
            {loadingComponents[backgroundAction]}
        </FooterBox>
    );
}

const FooterBox = styled.small`
  display: flex;
  height: 25px;
  place-items: center;
  justify-content: space-between;
  color: #687078;
  font-weight: 400
`;

export const FooterText = styled.small<{ float?: string, color?: string}>`
  display: flex;
  place-items: center;
  float: ${props => props.float || "none"};
  color: ${props => props.color === 'green' ? 'green' : '#687078'};
  font-weight: 400
`

const RecentCommentatorsBox = styled.div`
  display: flex;
  place-items: center;
  float: right;
`;

export const B = styled.b<{ fontWeight?: string }>`
  font-weight: ${props => props.fontWeight || "400"};
`;

export const BridgeTitle = styled.div`
  display: flex;
  place-items: center;
  margin: 4px 0;
`;
