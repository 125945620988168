import {Link} from "@amzn/awsui-components-react";
import Header from "@amzn/awsui-components-react/polaris/header";
import Wizard from "@amzn/awsui-components-react/polaris/wizard";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    BridgingPageStatus,
    changeBridgeState,
    getReportBridges,
    RESET_FILLING_BRIDGES,
    RESET_REPORT_BRIDGES,
    resetBridgingPageStatus,
    saveBridge,
    setBridgingPageStatus
} from "src/actions/bridgingCommentary.actions";
import {ReportState} from "src/common/report";
import {Roles} from "src/common/roles";
import AssignBridgeItems from "src/components/Bridging/AssignBridgeItems";
import BridgeItemOwnerGroupSelection from "src/components/Bridging/BridgeItemOwnerGroupSelection";
import BridgeItemsTable from "src/components/Bridging/BridgeItemsTable";
import BridgesCreatedModal from "src/components/Bridging/BridgesCreatedModal";
import {BridgeTableRowItem} from "src/components/Bridging/BridgeTableConfig";
import {BRIDGE_NOT_EDITED_ALERT, DISABLED_BRIDGE_ITEMS, DUMMY_BRIDGE_ITEM} from "src/components/Bridging/constants";
import DraftBridges from "src/components/Bridging/DraftBridges";
import {
    bridgeOwnerToBridgeItem,
    checkBridgeItemSubmittedOnce,
    fetchOwnerGroup,
    stepErrorUpdate,
    updatedWizardSendRequestStatus
} from "src/components/Bridging/helpers";
import {BridgeInputError, StepErrors} from "src/components/Bridging/interfaces";
import RequestBridgeInputs from "src/components/Bridging/RequestBridgeInputs";
import {BridgeItem, BridgeStatusType} from "src/components/BridgingCommentary/interfaces";
import {Column} from "src/components/CreateReportPage/interfaces";
import Paths from "src/components/PageConfig/Paths";
import {getReportBridgesApiLoading} from "src/reducers/apiLoading.reducer";
import {
    getBridgeOwnerGroups,
    getBridgingPageStatus,
    getFillingBridgeItems,
    getReportBridgeItems,
    getSubItemsForBridges,
    getVarianceHeaderDetails
} from "src/reducers/bridgingCommentary.reducer";
import {getViewReport} from "src/reducers/reportLibrary.reducer";
import {getUser} from "src/reducers/user.reducer";
import {constructBridgeId} from "src/utils/bridgingHelpers";
import {attrToColNameMap} from "src/utils/reportHelpers";
import {dateAndTimeToEpoch, epochToDate, epochToHhMm, getEpoch} from "src/utils/timeHelpers";
import styled from "styled-components";

const CreateBridge = () => {
    const selectedBridgeItems: BridgeTableRowItem[] = [];

    const dispatch = useDispatch();

    //Redux read
    const userName = useSelector(getUser);
    const varianceDetails = useSelector(getVarianceHeaderDetails);
    const {bridges, bridgeOwnerGroup} = useSelector(getReportBridgeItems);
    const tableIsLoading = useSelector(getReportBridgesApiLoading);
    const {reportDefinitions, reportRows} = useSelector(getViewReport);
    const bridgingPageStatus = useSelector(getBridgingPageStatus);
    const ownerGroups = useSelector(getBridgeOwnerGroups)
    /*
     This is populated in Step 3 DraftBridges component UseEffect by getComments dispatch
     fillingBridgeItems will have only BridgeItems that are in Proposed state.
    */
    const fillingBridgeItems = useSelector(getFillingBridgeItems);
    const bridgeSubItems = useSelector(getSubItemsForBridges);

    //Component State
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [stepErrors, setStepErrors] = useState<StepErrors>({1: "", 2: {}, 3: "", 4: ""});
    const [bridgeToOwnerGroup, setBridgeToOwnerGroup] = useState<{ [p: string]: string }>(bridgeOwnerGroup);
    const [bridgeItems, setBridgeItems] = useState<BridgeTableRowItem[]>([]);
    const [bridgeTableItems, setBridgeTableItems] = useState<BridgeTableRowItem[]>([]);
    const [bridgeOwnerToItemMap, setBridgeOwnerToItemMap] = useState<[string, Array<{ bridge_id: string, account_rollup: string }>][]>(Array(["", Array({
        bridge_id: "",
        account_rollup: ""
    })]));
    const [isBridgeCreateModalVisible, setIsBridgeCreateModalVisible] = useState(false);
    const [selectedItems, setSelectedItems] = useState<BridgeTableRowItem[]>(selectedBridgeItems);
    const [bridgeItemsSentForPost, setBridgeItemsSentForPost] = useState<string[]>([]);
    const [existingBridgeItemNames, setExistingBridgeItemNames] = useState<Set<string>>(new Set<string>([]));
    const [bridgePostAPIErrors, setBridgePostAPIErrors] = useState({});
    const [wizardStepLoading, setWizardStepLoading] = useState(false);
    const [startRequestInputsCheck, setStartRequestInputsCheck] = useState(false);

    const varianceHeaderColumnPosition = findColumnPosition(reportDefinitions.configure_columns!, varianceDetails.initiatedBridgeName)

    useEffect(() => {
        //Clean up Redux params used by CreateBridge component
        dispatch(resetBridgingPageStatus());
        dispatch({type: RESET_REPORT_BRIDGES, payload: {}})

        //Get existing bridge Items for particular variance
        dispatch(getReportBridges({
            role: Roles.Admin,
            variance_header: varianceDetails.initiatedBridgeName,
        report_id : reportDefinitions.report_id}))
    }, [])

    useEffect(() => {
        if (bridges.length > 0 && bridgeOwnerGroup) {
            const defaultBridgeTableRows = constructBridgingTableRows(varianceDetails.initiatedBridgeName, userName, reportDefinitions, reportRows)
            const selectedAndBridgeItems = updateExistingBridgeItemsWithDefault(bridges, defaultBridgeTableRows)
            setSavedStatusForExistingBridgeItems(bridges)
            setBridgeTableItems(selectedAndBridgeItems.updatedBridgeItems)
            setSelectedItems(selectedAndBridgeItems.selectedBridgeItems)
            const tempItemNameSet = new Set<string>()
            selectedAndBridgeItems.selectedBridgeItems.map(item => tempItemNameSet.add(item.account_rollup))
            setExistingBridgeItemNames(tempItemNameSet)
            setBridgeToOwnerGroup(bridgeOwnerGroup)
        } else {
            setBridgeTableItems(constructBridgingTableRows(varianceDetails.initiatedBridgeName, userName, reportDefinitions, reportRows))
        }
    }, [bridges, bridgeOwnerGroup])

    useEffect(() => {
        /*
            Check for BridgeItem status after saveBridge apis are triggered
        */
        if(Object.keys(bridgeItemsSentForPost).length > 0 && !startRequestInputsCheck){
            if(checkBridgeItemsSavedStatus(bridgeItemsSentForPost, bridgingPageStatus)){
                if( Object.keys(stepErrors[2]).length === 0){
                    setWizardStepLoading(false);
                    setActiveStepIndex(2);
                }
            }else if(checkBridgeIdStatusCheck(bridgingPageStatus)){
                setWizardStepLoading(false);
            }
        }

        /*
            Check for BridgeItem status after change_state apis with action request_inputs
            are triggered.
        */
        if(startRequestInputsCheck){
            if(checkBridgeItemsRequestInputsStatus(bridgeItemsSentForPost, bridgingPageStatus)){
                setIsBridgeCreateModalVisible(true);
                setWizardStepLoading(false);
            }
        }


    }, [bridgingPageStatus.wizardBridge, bridgeItemsSentForPost, startRequestInputsCheck])

    const setSavedStatusForExistingBridgeItems = (bridges: BridgeItem[]) => {
        /*
            Update Redux BridgingCommentaryState.status.wizardBridge for already created bridge items
            This is required to track if any changes are bring done to BridgeItem definition by user events
        */
        const existingBridgeIdStatus: { [bridge_id: string]: {} } = {}
        for (const bridge of bridges) {
            existingBridgeIdStatus[bridge.bridge_id] = {saved: true, fetching: false, requestInputs: false}
        }
        dispatch(setBridgingPageStatus({
            ...bridgingPageStatus,
            wizardBridge: {...bridgingPageStatus.wizardBridge, ...existingBridgeIdStatus}
        }));
    }

    const constructBridgeOwnerGroup = (accountRollup: string) => {
        if (!DISABLED_BRIDGE_ITEMS.includes(accountRollup)) {
            return <BridgeItemOwnerGroupSelection bridgeOwnerGroup={bridgeOwnerGroup}
                                                  setBridgeToOwnerGroup={setBridgeToOwnerGroup}
                                                  accountRollup={accountRollup}
                                                  ownerGroups={ownerGroups}/>
        } else {
            return <></>
        }
    }

    function constructBridgingTableRow(
        reportRow: Record<string, any>,
        userName: string,
        reportId: string,
        reportName: string,
        varianceName: string,
        attrToColMap: { [p: string]: string },
        baselineKey: string,
        currKey: string,
): BridgeTableRowItem {
        // Backend has this naming convention amount = diff_value - fxValue amountWithFX = diff_value
        //diff_value: scenario(RightColumn) - base_scenario(LeftColumn)
        // scenario/base_scenario : Already has have FX value in it.
        // IPAT finance team ask is to have Bridges include metric with FXImpact.
        // If report doesn't have FXImpact value then amount & amountWithFX will have same value.
        // It's bridge_owner task to add comment to variance to tell what is FXImpact.
        // To-Do: Check with IPAT if we can auto-populate this FX Impact comment if report has respective FXImpact
        // value.
        return {
            id: reportRow["_id"],
            bridge_id: constructBridgeId(reportId, reportRow["columns_name"],
                reportRow["_id"], varianceName, varianceHeaderColumnPosition),
            report_id: reportId,
            report_name: reportName,
            bridge_item_name: <BridgeItemsNameWrapper
                td_second_style={reportRow['td_second_style']}
                tr_style={reportRow['tr_style']}
            >{reportRow["columns_name"]}</BridgeItemsNameWrapper>,
            bridge_owner_group: constructBridgeOwnerGroup(reportRow["columns_name"]),
            bridge_owner_group_selected: fetchOwnerGroup(reportRow["columns_name"], ownerGroups).value,
            account_rollup: reportRow["columns_name"],
            varianceValue: reportRow[varianceName]['amountWithFX'].replace("$", ""),
            variance_headers: {
                left_header: attrToColMap[currKey],
                right_header: attrToColMap[baselineKey],
                variance_header: varianceName
            },
            variance_data: {
                left: reportRow[attrToColMap[currKey]].replace("$", ""),
                right: reportRow[attrToColMap[baselineKey]].replace("$", ""),
                variance: reportRow[varianceName]['amountWithFX'].replace("$", ""),
            },
            requester: userName,
            bridge_owners: [],
            note_to_bridge_owner: "",
            status: BridgeStatusType.Proposed,
            created_timestamp: getEpoch(),
            due_date: "",
            //Default time is 5PM PST
            due_time: "17:00",
            last_modified_timestamp: getEpoch(),
            last_modified_by: userName
        }
    }

    function constructBridgingTableRows(varianceName: string, userName: string,
                                        reportDefinitions: ReportState, reportRows: { [p: string]: any }[]) {
        const {attrToColMap, baselineKey, currKey} = getVarianceItems(varianceName, reportDefinitions)
        const filteredBridgeItems: BridgeTableRowItem[] = []
        const reportId = reportDefinitions.report_id
        const reportName = reportDefinitions.name_and_type!.report_name!

        reportRows.forEach((reportRow: Record<string, any>) => {
            if (reportRow[varianceName] === '' && !reportRow["columns_name"].includes("empty_line")) {
                filteredBridgeItems.push(createDummyBridgingTableRow(reportRow))
            } else if (reportRow[varianceName] !== '') {
                filteredBridgeItems.push(constructBridgingTableRow(reportRow,
                    userName,
                    reportId,
                    reportName,
                    varianceName,
                    attrToColMap,
                    baselineKey,
                    currKey,
                ))
            }
        })
        return filteredBridgeItems
    }

    const WorkFlowHeader = () => {
        return (
            <Header>
                {`You are configuring Bridge requests for ${varianceDetails?.initiatedBridgeName} from `}
                <Link
                    href={`#${Paths.VIEWREPORT}?report_id=${reportDefinitions.report_id}`}
                    target="_blank"
                    fontSize={"heading-l"}
                >
                    {reportDefinitions.name_and_type?.report_name}
                </Link>
            </Header>
        )
    }

    const bridgeSelection = {
        title: "Select Bridge Items",
        content: (
            <BridgeItemsTable
                tableItems={bridgeTableItems}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                errorText={stepErrors["1"]}
                setStepErrors={setStepErrors}
                tableHeader={<WorkFlowHeader/>}
                tableIsLoading={tableIsLoading}
                existingBridgeItemNames={existingBridgeItemNames}
            />
        )
    };

    const assignBridge = {
        title: "Assign a bridge",
        content: (
            <AssignBridgeItems
                varianceName={varianceDetails?.initiatedBridgeName}
                leftVarianceHeader={varianceDetails?.leftVarianceHeader}
                rightVarianceHeader={varianceDetails?.rightVarianceHeader}
                bridgeItems={bridgeItems}
                setBridgeItems={setBridgeItems}
                errors={stepErrors["2"]}
                setStepErrors={setStepErrors}
                tableHeader={<WorkFlowHeader/>}
                bridgingPageStatus={bridgingPageStatus}
            />
        )

    };

    const draftBridges = {
        title: "Proposed commentary",
        description: "This step is optional. You can add bridge items before requesting inputs from the contributor. " +
            "You can open this Proposed bridge later from the bridging & commentary page to add more items.",
        content: (
            <DraftBridges
                bridgeItems={bridgeItems}
                role={Roles.Admin}
                errorText={stepErrors["3"]}
                setStepErrors={setStepErrors}
            />
        ),
    }

    const requestBridgeInputs = {
        title: "Send request",
        description: "This step sends emails to contributor and moves the bridge to Requested state.\n" +
            "Do this only after your drafts are complete.  You can navigate to this bridge later from the\n" +
            "bridging & commentary page to send emails later.",
        content: (
            <RequestBridgeInputs
                bridgeOwnerToItemMap={bridgeOwnerToItemMap}
                tableHeader={<WorkFlowHeader/>}
                bridgingPageStatus={bridgingPageStatus}
                errorText={stepErrors["4"]}
            />
        ),
    }

    const dispatchSaveBridges = () => {
        /*
            Dispatch saveBridge action only to the BirdgeItems
            i) Newly created.
            ii) Existing BridgeItems which have been updated.Existing BridgeItems which are not modified
            will be discarded from savingBridge action
        */
        const dispatchedBridgeIds: string[] = []

        for (const bridgeItem of bridgeItems) {
            //Check if bridgeItem is already submitted successfully
            if (checkBridgeItemSubmittedOnce(bridgeItem.bridge_id, bridgingPageStatus)) {
                //Dispatch saveBridge api call only if user made any changes to bridgeItem
                if (!bridgingPageStatus.wizardBridge[bridgeItem.bridge_id].saved) {
                    dispatchedBridgeIds.push(bridgeItem.bridge_id)
                    dispatch(setBridgingPageStatus({
                        ...bridgingPageStatus,
                        wizardBridge: {...bridgingPageStatus.wizardBridge, [bridgeItem.bridge_id]: {saved: false, fetching: true}}
                    }));
                    dispatch(saveBridge(constructBridgeItemForSaving(bridgeItem), Roles.Admin, true))
                }
            } else {
                dispatchedBridgeIds.push(bridgeItem.bridge_id)
                dispatch(setBridgingPageStatus({
                    ...bridgingPageStatus,
                    wizardBridge: {...bridgingPageStatus.wizardBridge, [bridgeItem.bridge_id]: {saved: false, fetching: true}}
                }));
                dispatch(saveBridge(constructBridgeItemForSaving(bridgeItem), Roles.Admin, true))
            }
        }
        //Set this state so only targeted bridgeId's will be checked for status.
        setBridgeItemsSentForPost(dispatchedBridgeIds)
    }

    const saveBridges = (currentIndex: number, requestedIndex: number) => {
        /*
            Validate BridgeItems inputs
        */
        Object.keys(bridgePostAPIErrors).length > 0 && setBridgePostAPIErrors({})
        const {
            errors,
            navigationAllowed,
            saveRequired
        } = validateBridgeItemsUserInputs(bridgeItems, bridgingPageStatus, stepErrors);
        if(navigationAllowed){
            /*
               If bridge inputs are valid
                    i) dispatchSaveBridges
                    ii) Block user from going to next step till saveBridge API are successful by making next button
                    as loading.
               NotValid:
                    i)Update StepErrors to with errors.

            */
            saveRequired && setWizardStepLoading(true);
            saveRequired && dispatchSaveBridges();
            !saveRequired && setActiveStepIndex(requestedIndex);
        }else{
            setStepErrors({...stepErrors, 2: errors})
        }

    }

    const checkBridgeSubItemsSaved = () => {
        /*
            Check all the subItems of Bridges are saved by reading bridge status
            from redux BridgingCommentaryState.status.wizardBridge.refreshingComments
        */
        const errors:{ [bridgeId: string]: string } = {}
        setWizardStepLoading(true);

        for (const bridgeId of Object.keys(bridgingPageStatus.wizardBridge)){
            const { refreshingComments } = bridgingPageStatus.wizardBridge[bridgeId]
            if(refreshingComments){
                errors[bridgeId] = "Comments not saved"
            }
        }
        return {errors, navigationAllowed: Object.keys(errors).length === 0}
    }


    const validateAndNavigate = (currentIndex: number, requestedIndex: number) => {
        if (requestedIndex < currentIndex) {
            //Reset bridgeItem Post call error messages
            setActiveStepIndex(requestedIndex);
        } else if (currentIndex === 0) {
            if (selectedItems.length > 0) {
                //Updating bridgeItems with already existing bridgeDetails
                if (bridgeItems.length >= 1) {
                    const updatedBridgeItems = selectedItems.map(originalItem => {
                        const updatedItem = bridgeItems.find(bridgeItem => bridgeItem.id === originalItem.id)
                        return updatedItem ? {...originalItem, ...updatedItem} : originalItem
                    })
                    const bridgeItemsWithOwnerGroup = updateBridgeItemsWithOwnerGroupSelected(updatedBridgeItems, bridgeToOwnerGroup, stepErrors[2])
                    setBridgeItems(bridgeItemsWithOwnerGroup);
                } else {
                    const bridgeItemsWithOwnerGroup = updateBridgeItemsWithOwnerGroupSelected(selectedItems, bridgeToOwnerGroup, stepErrors[2])
                    setBridgeItems(bridgeItemsWithOwnerGroup);
                }
                setActiveStepIndex(requestedIndex);
            } else {
                setStepErrors({...stepErrors, 1: "At least one bridge item must be selected"})
            }
        } else if (currentIndex === 1) {
            saveBridges(currentIndex, requestedIndex);
        } else if (currentIndex === 2) {
            // Create Bridge Owner -> Bridge Account RollUps Map
            setBridgeOwnerToItemMap(bridgeOwnerToBridgeItem(fillingBridgeItems));
            // Check all bridgeSubItems are saved, Then allow to proceed to next step
            const {navigationAllowed} = checkBridgeSubItemsSaved();
            if(navigationAllowed){
                // Remove the loading of next button that was set in saveBridges method.
                setActiveStepIndex(requestedIndex);
            }else{
                setStepErrors((stepErrors) => {
                    return {...stepErrors, 3: "Bridge Comments are not saved, Please save them to move forward."}
                })
            }
            setWizardStepLoading(false);

        } else{
            /*
                Check if stepErrors["4"] has any error and make it empty
                if change_state api call for request_inputs fail, there is nothing a user can do other than reaching out
                to dev team so if user tries to navigate to other steps again and comeback to step 4 we are checking
                if any error exists and reset.
            */
            stepErrors["4"] && setStepErrors((stepErrors) => {
                return {...stepErrors, 4: ""}
            })
            setActiveStepIndex(requestedIndex);
        }
    }

    const updateBridgeItemsWithOwnerGroupSelected = (bridgeItems: BridgeTableRowItem[], bridgeOwnerGroup: { [p: string]: string },
                                                     errors: BridgeInputError) => {
        return bridgeItems.map(originalItem => {
            const ownerGroup = bridgeOwnerGroup[originalItem.account_rollup]
            const existingOwnerGroup = originalItem.bridge_owner_group_selected && originalItem.bridge_owner_group_selected
            const bridge_id = originalItem.bridge_id
            if (ownerGroup) {
                /*
                    If user changes bridgeOwnerGroup dropdown, Check and update stepErrors.
                    If user makes any changes to BridgeItem it's tracked in redux
                    BridgingCommentaryState.status.wizardBridge, So we can make decision to dispatch saveBridge
                    in dispatchSaveBridges method
                */
                if(existingOwnerGroup !== ownerGroup ){

                    errors[originalItem.bridge_id] && setStepErrors((stepErrors) => {
                        return stepErrorUpdate(stepErrors, bridge_id)
                    })

                    errors[BRIDGE_NOT_EDITED_ALERT] && setStepErrors((stepErrors) => {
                        return stepErrorUpdate(stepErrors)
                    })

                    dispatch(setBridgingPageStatus({
                        ...bridgingPageStatus,
                        wizardBridge: {...bridgingPageStatus.wizardBridge, [bridge_id]: {saved: false, fetching: false}}
                    }));
                }

                return {...originalItem, bridge_owner_group_selected: ownerGroup};
            }
            return originalItem;
        })
    }

    const bridgesCreatedModal = BridgesCreatedModal(
        bridgeOwnerToItemMap,
        isBridgeCreateModalVisible,
        setIsBridgeCreateModalVisible,
    )

    const sendRequest = () => {
        /*
            i)Dispatch change_state api call with action "request_inputs".
            ii) Show Request Input status by enabling loading on Submit button.
            iii) Update bridgeId's list in bridgeItemsSentForPost that needs to be tracked.
            iv) Before dispatch make BridgingCommentaryState.status.wizardBridge[bridgeId] as false.
            This is again done for tracking status of api calls.
        */
        setWizardStepLoading(true);
        setBridgeItemsSentForPost(fillingBridgeItems.map(bridgeItem => bridgeItem.bridge_id));

        fillingBridgeItems.map(bridge => {
            const updatedBridgingPageStatus = updatedWizardSendRequestStatus(bridgingPageStatus, bridge.bridge_id)
            // Dispatch redux params
            dispatch(setBridgingPageStatus(updatedBridgingPageStatus));
            // Dispatch change_state api with action request_inputs.
            dispatch(changeBridgeState({
                bridge_id: bridge.bridge_id,
                role: Roles.Admin,
                action: 'request_inputs',
                bridge_sub_items: bridgeSubItems[bridge.bridge_id],
            }));
        })
        setStartRequestInputsCheck(true);
    }

    const checkBridgeItemsSavedStatus = (bridgeIds: string[], bridgingPageStatus: BridgingPageStatus) => {
        /*
           Tracking the status of api call made by saveBridge action.
           i) IF block is to check if any bridgeItems are submitted for api call(using bridgeItemsSentForPost state),
              This is needed to avoid unnecessary execution of checkBridgeItemsSavedStatus is triggered by useEffect
              (base condition).
           ii) ELSE block will return false for any reason saveBridge action api fails.
           iii)Function will return true when all the bridgeIds exists bridgeItemsSentForPost have status as true in
           BridgingCommentaryState.status.wizardBridge[bridgeId].saved
        */
        const errors: { [bridge_id: string]: {} } = {}
        const bridgeErrors: BridgeInputError = {}
        if (Object.keys(bridgeIds).length === 0) {
            return false
        }else {
            for (const bridgeId of bridgeIds) {
                if (bridgingPageStatus.wizardBridge[bridgeId]) {
                    const {saved, error} = bridgingPageStatus.wizardBridge[bridgeId]
                    if (!saved) {
                        if (error) {
                            errors[bridgeId] = error
                            bridgeErrors[bridgeId] = {bridge_owner: error}
                            const updatedErrors = {...stepErrors[2], ...bridgeErrors}
                            setStepErrors({
                                ...stepErrors,
                                2: {...updatedErrors}
                            })
                        }
                        //Exit if any save api call failed
                        return false
                    }
                } else {
                    //Exit if still waiting for save api call status
                    return false
                }
            }
        }
        return true
    }

    const checkBridgeItemsRequestInputsStatus = (bridgeIds: string[], bridgingPageStatus: BridgingPageStatus) => {
        // This is to track status of change_state api calls for action "request_inputs"
        for (const bridgeId of bridgeIds) {
            if (bridgingPageStatus.wizardBridge[bridgeId]) {
                /*
                  i) By default bridgeItem requestInputs of status param is false.
                  ii) Before dispatch of api call in sendRequest method we make requestInputs as true.
                  iii) After change_state api success in CHANGE_BRIDGE_STATE middleware we make requestInputs as
                        false.
                  iv) As long as requestInputs is true, function will return false.
               */
                const {saved, requestInput, error} = bridgingPageStatus.wizardBridge[bridgeId]["requestInputs"]!
                if(!saved && requestInput){
                    error && setStepErrors((stepErrors) => {
                        return {...stepErrors, 4: "Requesting Inputs failed, Please read out to vac-dev@."}
                    })
                    return false
                }
            }
        }
        return true
    }

    return (
        <>
            {bridgesCreatedModal}
            <Wizard
                i18nStrings={{
                    stepNumberLabel: stepNumber =>
                        `Step ${stepNumber}`,
                    collapsedStepsLabel: (stepNumber, stepsCount) =>
                        `Step ${stepNumber} of ${stepsCount}`,
                    cancelButton: "Save & Exit Wizard",
                    previousButton: "Previous",
                    nextButton: "Next",
                    submitButton: "Send request",
                }}
                onNavigate={({detail}) => validateAndNavigate(activeStepIndex, detail.requestedStepIndex)}
                activeStepIndex={activeStepIndex}
                isLoadingNextStep={wizardStepLoading}
                steps={[
                    bridgeSelection,
                    assignBridge,
                    draftBridges,
                    requestBridgeInputs
                ]}
                onCancel={() => {
                    dispatch(resetBridgingPageStatus())
                    dispatch({type: RESET_REPORT_BRIDGES, payload: {}})
                    dispatch({type: RESET_FILLING_BRIDGES, payload: {}})
                    window.location.replace(`#${Paths.REFERENCEREPORTS}`)
                }}
                onSubmit={() => {
                    sendRequest();
                }}
            />
        </>
    );
}

export default CreateBridge

/***************************Functions doesn't change state**************************************************/

const findColumnPosition = (columns: Column[], varianceHeader: string) => {
    const desiredColumn = columns.find(col => col.column_name === varianceHeader)
    return desiredColumn ? desiredColumn.column_position : -1
}

export const validateBridgeItemsUserInputs = (bridgeItems: BridgeTableRowItem[], bridgingPageStatus: BridgingPageStatus, stepErrors: StepErrors) => {
    let navigationStatus = true
    const USERNAME_REGEX = /[a-zA-Z0-9._%+-@]+/
    const invalidErrorConst = "Bridge Owner have invalid username "
    const errors: BridgeInputError = {}

    for (let i = 0; i < bridgeItems.length; i++) {
        const bridgeItem = bridgeItems[i]
        if (bridgeItem.bridge_owners.length === 0) {
            errors[bridgeItem.bridge_id] = {
                bridge_owner: `Bridge Owners can't be empty`
            }
        } else {
            for (let j = 0; j < bridgeItem.bridge_owners.length; j++) {
                const failedOwners = bridgeItem.bridge_owners.filter(owner => !USERNAME_REGEX.test(owner))
                if (failedOwners.length !== 0) {
                    errors[bridgeItem.bridge_id] = {
                        bridge_owner: failedOwners.length === 1 ? invalidErrorConst + failedOwners.join(", ") :
                            invalidErrorConst + "s " + failedOwners.join(", ")
                    }
                }
            }
        }

        if (bridgeItem.due_date === "") {
            errors[bridgeItem.bridge_id] = {
                ...errors[bridgeItem.bridge_id],
                due_date: `Due date can't be empty`
            }
        }

        if (bridgeItem.due_time === "") {
            errors[bridgeItem.bridge_id] = {
                ...errors[bridgeItem.bridge_id],
                due_time: `Due time can't be empty`
            }
        }

    }

    Object.keys(stepErrors[2]).length === 0 ? navigationStatus = Object.keys(errors).length === 0 : navigationStatus = false

    return {errors, navigationAllowed: navigationStatus, saveRequired: checkIfBridgeItemsEdited(bridgeItems, bridgingPageStatus)}
}

const checkIfBridgeItemsEdited = (bridgeItems: BridgeTableRowItem[], bridgingPageStatus: BridgingPageStatus) => {
    for (const b of bridgeItems) {
        if (bridgingPageStatus.wizardBridge[b.bridge_id] && !bridgingPageStatus.wizardBridge[b.bridge_id].saved) {
            return true
        }
    }
    return false
}

const getVarianceItems = (varianceName: string, reportDefinitions: ReportState) => {
    const attrToColMap = attrToColNameMap(reportDefinitions.configure_columns)
    let baselineKey = ""
    let currKey = ""
    reportDefinitions.configure_columns?.forEach(item => {
        if (item.column_name === varianceName) {
            baselineKey = item.baseline_scenario + "," + item.baseline_period?.map(p => p.label).join(",")
                + "," + item.baseline_year
            currKey = item.scenario + "," + item.period?.map(p => p.label).join(",") + "," + item.year
        }
    })

    return {
        attrToColMap,
        baselineKey,
        currKey
    }
}

const BridgeItemsNameWrapper = styled.span<{ td_second_style?: string, tr_style?: string }>`
  padding-left: ${props => {
    if (props.td_second_style === "" || props.td_second_style == "firstLevel") {
        return "0"
    } else if (props.td_second_style === "secondLevel") {
        return "15px"
    } else {
        return "25px"
    }
  }};
  font-style: ${props => props.tr_style === "italic" ? "italic" : "normal"};
  font-weight: ${props => props.tr_style === "impor_row" || props.tr_style === "sub-summary-line" ? "bold" : "normal"};
  text-decoration: ${props => props.tr_style === "underlined_row" ? "underline" : "none"};
`;

const createDummyBridgingTableRow = (reportRow: Record<string, any>) => {

    return {
        id: reportRow["_id"],
        bridge_id: DUMMY_BRIDGE_ITEM,
        report_id: "",
        report_name: "",
        bridge_item_name: <BridgeItemsNameWrapper
            td_second_style={reportRow['td_second_style']}
            tr_style={reportRow['tr_style']}
        >{reportRow["columns_name"]}</BridgeItemsNameWrapper>,
        bridge_owner_group: <></>,
        bridge_owner_group_selected: "",
        account_rollup: DUMMY_BRIDGE_ITEM,
        varianceValue: DUMMY_BRIDGE_ITEM,
        variance_headers: {
            left_header: DUMMY_BRIDGE_ITEM,
            right_header: DUMMY_BRIDGE_ITEM,
            variance_header: DUMMY_BRIDGE_ITEM
        },
        variance_data: {
            left: DUMMY_BRIDGE_ITEM,
            right: DUMMY_BRIDGE_ITEM,
            variance: "",
        },
        requester: "",
        bridge_owners: [],
        note_to_bridge_owner: "",
        status: BridgeStatusType.Proposed,
        created_timestamp: getEpoch(),
        due_date: "",
        due_time: "",
        last_modified_timestamp: getEpoch(),
        last_modified_by: ""
    }
}

const updateExistingBridgeItemsWithDefault = (existingBridgeItems: BridgeItem[], defaultBridgeItems: BridgeTableRowItem[]) => {
    const selectedBridgeItems: BridgeTableRowItem[] = []

    if (defaultBridgeItems.length > 0) {
        defaultBridgeItems.map(originalBridgeItem => {
            const existingItem = existingBridgeItems.find(bridgeItem => bridgeItem.bridge_id === originalBridgeItem.bridge_id)
            if (existingItem) {
                originalBridgeItem['bridge_owners'] = existingItem['bridge_owners']
                originalBridgeItem['note_to_bridge_owner'] = existingItem['note_to_bridge_owner']
                originalBridgeItem['status'] = existingItem['status']
                originalBridgeItem['created_timestamp'] = existingItem['created_timestamp']
                originalBridgeItem['bridge_owner_group_selected'] = existingItem['bridge_owner_group']
                originalBridgeItem['due_date'] = epochToDate(existingItem['due_timestamp'])
                originalBridgeItem['due_time'] = epochToHhMm(existingItem['due_timestamp'])
                selectedBridgeItems.push(originalBridgeItem)
            }
            return originalBridgeItem
        })
    }
    return {
        updatedBridgeItems: defaultBridgeItems,
        selectedBridgeItems: selectedBridgeItems
    }

}

export const constructBridgeItemForSaving = (bridgeItem: BridgeTableRowItem) => {
    return {
        bridge_id: bridgeItem.bridge_id,
        analysis_id: bridgeItem.bridge_id,
        report_id: bridgeItem.report_id,
        report_name: bridgeItem.report_name,
        account_rollup: bridgeItem.account_rollup,
        variance_headers: {
            left_header: bridgeItem.variance_headers.left_header,
            right_header: bridgeItem.variance_headers.right_header,
            variance_header: bridgeItem.variance_headers.variance_header
        },
        variance_data: {
            left: +bridgeItem.variance_data.left.replace(/[$,]/g, ''),
            right: +bridgeItem.variance_data.right.replace(/[$,]/g, ''),
            variance: +bridgeItem.variance_data.variance.replace(/[$,]/g, ''),
        },
        requester: bridgeItem.requester,
        bridge_owner_group: bridgeItem.bridge_owner_group_selected,
        bridge_owners: bridgeItem.bridge_owners,
        note_to_bridge_owner: bridgeItem.note_to_bridge_owner,
        status: bridgeItem.status,
        created_timestamp: bridgeItem.created_timestamp,
        last_modified_timestamp: bridgeItem.last_modified_timestamp,
        due_timestamp: dateAndTimeToEpoch(bridgeItem.due_date, bridgeItem.due_time),
        last_modified_by: bridgeItem.last_modified_by,
    }
}

export const checkBridgeIdStatusCheck = (bridgingPageStatus: BridgingPageStatus) => {
    for(const bridgeId of Object.keys(bridgingPageStatus.wizardBridge)){
        const {saved, error} = bridgingPageStatus.wizardBridge[bridgeId]
        /*
            We are checking if api call is either success or fail
            saved will be false in two conditions.
                i) If api call is waiting for response.
                ii) If api call failed and error exists.
        */
        if(saved || (!saved && error)){
            continue
        }else{
            return false
        }
    }
    return true
}

