import {ButtonDropdown, ButtonDropdownProps} from "@amzn/awsui-components-react";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    deleteWorkflow,
    postReportPublisher,
    setUpdatingExternalReportDefinitions,
    setUpdatingReportDefinitions,
    setUpdatingReviewFolderDefinitions
} from "src/actions/reportLibrary.actions";
import {ItemType, Libraries, ReportDefinition} from "src/common/report";
import {Roles} from "src/common/roles";
import Paths from "src/components/PageConfig/Paths";
import ConfirmationModal from "src/components/ReportLibraryPage/ConfirmationModal";
import {
    DELETE_WORKFLOW,
    DUPLICATE,
    DUPLICATE_REVIEW, GO_TO_WORKFLOW,
    MOVE_TO_ARCHIVE,
    PUBLISH_TO_BUSINESS,
    PUBLISHED_REPORTS_TITLE,
    UNPUBLISH_FROM_BUSINESS,
    WARNING_DELETE,
    WARNING_MOVE_TO_ARCHIVE,
    WARNING_PUBLISH_TO_BUSINESS,
    WARNING_UNPUBLISH_FROM_BUSINESS,
} from "src/components/ReportLibraryPage/constants";
import {createDuplicateReportDefinition, getAvailableActions} from "src/components/ReportLibraryPage/helpers";
import ReportLibrary from "src/components/ReportLibraryPage/ReportLibrary";
import {getReportDefinitionsApiLoading, getReportPublisherApiLoading} from "src/reducers/apiLoading.reducer";
import {getReportLibraryStatus} from "src/reducers/reportLibrary.reducer";
import {getAssumedRole} from "src/reducers/user.reducer";
import {filterExternalReportState} from "src/utils/reportHelpers";
import {v4 as uuid} from "uuid";

const ReferenceReportLibraryPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {publishedReport, deletedReportDefinitions, errorType, errorMessage} = useSelector(getReportLibraryStatus);
    const reportDefinitionsApiIsLoading = useSelector(getReportDefinitionsApiLoading);
    const reportPublisherApiIsLoading = useSelector(getReportPublisherApiLoading);

    const [isConfirmationModalReferencesVisible, setIsConfirmationModalReferencesVisible] = useState<boolean>(false);
    const [confirmationText, setConfirmationText] = useState<string>("");
    const [onClickFunction, setOnClickFunction] = useState(() => () => {console.log("Default handler")});
    const [redirectUrl, setRedirectUrl] = useState<string>(window.location.href);

    const eventHandlerConfirmationModalForBusinessLevel = (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>, report: ReportDefinition) => {
        switch (event.detail.id) {
            case UNPUBLISH_FROM_BUSINESS:
                const libraryPublisherToFinanceRole = () => () => {
                    dispatch(postReportPublisher(
                        report.report_id,
                        Roles.FinanceLeader,
                        Libraries.Reference));
                }
                setOnClickFunction(libraryPublisherToFinanceRole);
                setIsConfirmationModalReferencesVisible(true);
                setConfirmationText(WARNING_UNPUBLISH_FROM_BUSINESS);
                break;
            case MOVE_TO_ARCHIVE:
                setIsConfirmationModalReferencesVisible(true);
                setConfirmationText(WARNING_MOVE_TO_ARCHIVE);
                const libraryPublisherToArchiveLibrary = () => () => {
                    dispatch(postReportPublisher(
                        report.report_id,
                        Roles.FinanceLeader,
                        Libraries.Archive));
                    setRedirectUrl(`#${Paths.ARCHIVEREPORTS}`);
                }
                setOnClickFunction(libraryPublisherToArchiveLibrary);
                break;
        }
    }

    const eventHandlerConfirmationModalForFinanceLevel = (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>, report: ReportDefinition) => {
        let updatedReportState: ReportDefinition;
        switch (event.detail.id) {
            case DUPLICATE_REVIEW:
                updatedReportState = {
                    ...report,
                    report_id: `review_folder_id.${uuid()}`,
                    report_status: "Creating",
                    name_and_type: {
                        ...report.name_and_type,
                        report_name: `${report.name_and_type?.report_name}-DUPLICATE`
                    }
                };
                dispatch(setUpdatingReviewFolderDefinitions(updatedReportState));
                window.location.replace(`#${Paths.CREATEREVIEW}`);
                break
            case DUPLICATE:
                if(report.item_type === ItemType.Internal){
                    const updatedInternalReportState = createDuplicateReportDefinition(report)
                    dispatch(setUpdatingReportDefinitions(updatedInternalReportState));
                    window.location.replace(`#${Paths.CREATEREPORT}`);
                }
                else {
                    updatedReportState= filterExternalReportState(report);
                    dispatch(setUpdatingExternalReportDefinitions({
                        ...updatedReportState,
                        report_id: `report_id.${uuid()}`,
                        report_status: 'Creating',
                        name_and_type: {
                            ...updatedReportState.name_and_type,
                            report_name: "name_duplicate"
                        }
                    }));
                    window.location.replace(`#${Paths.EXTERNALREPORT}`);
                }
                break
            case PUBLISH_TO_BUSINESS:
                setIsConfirmationModalReferencesVisible(true);
                setConfirmationText(WARNING_PUBLISH_TO_BUSINESS);
                const libraryPublisherToBusinessRole = () => () => {
                    dispatch(postReportPublisher(
                        report.report_id,
                        Roles.BusinessLeader,
                        Libraries.Reference));
                }
                setOnClickFunction(libraryPublisherToBusinessRole);
                break
            case MOVE_TO_ARCHIVE:
                setIsConfirmationModalReferencesVisible(true);
                setConfirmationText(WARNING_MOVE_TO_ARCHIVE);
                const libraryPublisherToArchiveLibrary = () => () => {
                    dispatch(postReportPublisher(
                        report.report_id,
                        Roles.FinanceLeader,
                        Libraries.Archive));
                    setRedirectUrl(`#${Paths.ARCHIVEREPORTS}`);
                }
                setOnClickFunction(libraryPublisherToArchiveLibrary);
                break
            case DELETE_WORKFLOW:
                setIsConfirmationModalReferencesVisible(true);
                setConfirmationText(WARNING_DELETE);
                const parts = report.report_id.split("_")
                const workflow_id = parts[0]
                const execution_id = parts[1]
                const deleteSFPAReport = () => () => {
                    dispatch(deleteWorkflow(
                        workflow_id,
                        "version_1",
                        execution_id));
                }
                setOnClickFunction(deleteSFPAReport);
                break
            case GO_TO_WORKFLOW:
                navigate(`/report-builder/${report.workflow_id}?versionId=${report.workflow_version}&executionId=${report.execution_id}`);
                break
        }
    }

    const assumedRole = useSelector(getAssumedRole);

    const reportActions = (report: ReportDefinition, reviewFolderId: string) => <ButtonDropdown
        expandToViewport = {true}
        items={report.report_role === Roles.BusinessLeader ?
            getAvailableActions(assumedRole, "ReferenceBusiness", report.item_type, reviewFolderId !== "")
            : getAvailableActions(assumedRole, "ReferenceFinance", report.item_type, reviewFolderId !== "")
        }
        ariaLabel="Control instance"
        variant="icon"
        onItemClick={event => {
            report.report_role === Roles.BusinessLeader ? eventHandlerConfirmationModalForBusinessLevel(event, report)
                : eventHandlerConfirmationModalForFinanceLevel(event, report);
        }}
    />

    const confirmationModal = ConfirmationModal(
        isConfirmationModalReferencesVisible,
        setIsConfirmationModalReferencesVisible,
        redirectUrl,
        confirmationText,
        onClickFunction,
        "Reference",
        reportPublisherApiIsLoading || reportDefinitionsApiIsLoading,
        !!(publishedReport || deletedReportDefinitions),
        errorType ? `${errorType}: ${errorMessage}` : ""
    );

    return ReportLibrary(
        PUBLISHED_REPORTS_TITLE,
        "",
        `#${Paths.REFERENCEREPORTS}`,
        null,
        reportActions,
        confirmationModal,
        "Reference",
        true
    );
}

export default ReferenceReportLibraryPage;