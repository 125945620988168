import {Middleware} from "redux";
import {setFileTransferStatus} from "src/actions/fileTransfer.actions";
import {
    BRIDGE_SYNC,
    REPORT,
    REPORT_DEFINITIONS,
    REPORT_EXPORT,
    REPORT_LIBRARY,
    REPORT_PUBLISHER,
    REPORT_STATUS,
    REVIEW_FOLDERS,
    setReportLibrary,
    setReportLibraryPaginationConfig,
    setReportLibraryStatus,
    setViewingReportDefinition,
    setViewingReportRows,
    setViewingReportSynchronization,
    SPAN_TITLES,
    startReportComputation,
    SYNCHRONIZATION,
    WORKFLOW
} from "src/actions/reportLibrary.actions";
import {ItemType, ReportDefinition, ReportState} from "src/common/report";
import {_directDownloadOnClick} from "src/middleware/app/fileTransfer.middleware";
import {AppState as State} from "src/reducers/AppState";
import {combineReportDefinitionLists} from "src/utils/reportHelpers";

const reportLibraryPageMiddleware: Middleware<{}, State> = ({dispatch, getState}) => next => async action => {
    next(action);
    if (action.payload) {
        const {data = {}, errorData = {}, params = {}, method = ''} = action.payload;
        const currentLibraryStatus = getState().reportLibrary.libraryStatus;
        switch (action.type) {
            /*********************************** Report Library API ***********************************/
            case `${REPORT_LIBRARY} API_SUCCESS`:
                if (method === 'GET') {
                    const {nextPaginationKey, hasMore} = data;
                    const currentReports = getState().reportLibrary.reports;
                    const updatedReports: ReportDefinition[] = params.paginationKey ?
                        combineReportDefinitionLists(data.report_library, currentReports) : data.report_library;
                    dispatch(setReportLibrary(updatedReports));
                    dispatch(setReportLibraryPaginationConfig({paginationKey: nextPaginationKey, hasMore: hasMore}));
                }
                break;


            /*********************************** Report Definition API ***********************************/
            case `${REPORT_DEFINITIONS} API_SUCCESS` :
                if (method === 'POST'){
                    dispatch(setReportLibraryStatus({savedReportDefinitions: true}));
                    // start the report computation
                    if (params.item_type == ItemType.Internal) {
                        dispatch(startReportComputation(params.report_id));
                    }
                } else if (method == 'DELETE') {
                    dispatch(setReportLibraryStatus({...currentLibraryStatus, deletedReportDefinitions: true}));
                }else if (method == 'GET') {
                    // deep copy
                    const updatedReportDefinition: ReportState = {...data.report_definitions}
                    dispatch(setViewingReportDefinition(updatedReportDefinition));
                }
                break;
            case `${REPORT_DEFINITIONS} API_ERROR` :
                dispatch(setReportLibraryStatus({
                    ...currentLibraryStatus,
                    errorType: errorData.message,
                    errorMessage: errorData.reason || errorData.parameter || 'Empty error message'
                }));
                break;


            /*********************************** Review Folder API ***********************************/
            case `${REVIEW_FOLDERS} API_SUCCESS`:
                if (method === 'POST'){
                    dispatch(setReportLibraryStatus({...currentLibraryStatus, updatedReviewFolders: true}));
                } else if (method == 'DELETE') {
                    dispatch(setReportLibraryStatus({...currentLibraryStatus, deletedReviewFolder: true}));
                }
                break;
            case `${REVIEW_FOLDERS} API_ERROR`:
                dispatch(setReportLibraryStatus({
                    ...currentLibraryStatus,
                    errorType: errorData.message,
                    errorMessage: errorData.reason || errorData.parameter || 'Empty error message'
                }));
                break;



            /*********************************** Report Publisher API ***********************************/
            case `${REPORT_PUBLISHER} API_SUCCESS` :
                if (method === 'POST'){
                    dispatch(setReportLibraryStatus({...currentLibraryStatus, publishedReport: true}));
                }
                break;
            case `${REPORT_PUBLISHER} API_ERROR` :
                dispatch(setReportLibraryStatus({
                    ...currentLibraryStatus,
                    errorType: errorData.message,
                    errorMessage: errorData.reason || errorData.parameter || 'Empty error message'
                }));
                break;


            /*********************************** Report API ***********************************/
            case `${REPORT} API_SUCCESS` :
                if (method === 'POST'){
                    dispatch(setReportLibraryStatus({...currentLibraryStatus, startedReportComputation: true}));
                }
                if (method === 'GET') {
                    dispatch(setViewingReportRows(data.report));
                    dispatch(setReportLibraryStatus({...currentLibraryStatus, fetchedReportRows: true}));
                }
                break;
            case `${REPORT} API_ERROR` :
                dispatch(setReportLibraryStatus({
                    ...currentLibraryStatus,
                    errorType: errorData.message,
                    errorMessage: errorData.reason || errorData.parameter || 'Empty error message'
                }))
                break;


            /*********************************** Bridge Sync API ***********************************/
            case `${BRIDGE_SYNC} API_SUCCESS` :
                if (method === 'POST'){
                    dispatch(setReportLibraryStatus({...currentLibraryStatus, startedBridgeSync: true}));
                }
                break;
            case `${BRIDGE_SYNC} API_ERROR` :
                dispatch(setReportLibraryStatus({
                    ...currentLibraryStatus,
                    errorType: errorData.message,
                    errorMessage: errorData.reason || errorData.parameter || 'Empty error message'
                }));
                break;


            /*********************************** Span Title API ***********************************/
            case `${SPAN_TITLES} API_SUCCESS`:
                if (method === 'POST'){
                    dispatch(setReportLibraryStatus({...currentLibraryStatus, savedSpanTitles: true}))
                }
                break;
            case `${SPAN_TITLES} API_ERROR`:
                dispatch(setReportLibraryStatus({
                    ...currentLibraryStatus,
                    errorType: errorData.message,
                    errorMessage: errorData.reason || errorData.parameter || 'Empty error message'
                }))
                break;


            /*********************************** Synchronization API ***********************************/
            case `${SYNCHRONIZATION} API_SUCCESS`:
                if (method === 'GET'){
                    dispatch(setViewingReportSynchronization(data));
                    dispatch(setReportLibraryStatus({...currentLibraryStatus, fetchedReportSynchronization: true}));
                }
                break;
            case `${SYNCHRONIZATION} API_ERROR`:
                dispatch(setReportLibraryStatus({
                    ...currentLibraryStatus,
                    errorType: errorData.message,
                    errorMessage: errorData.reason || errorData.parameter || 'Empty error message'
                }))
                break;


            /*********************************** Report Export API ***********************************/
            case `${REPORT_EXPORT} API_SUCCESS`:
                if (method === 'POST'){
                    dispatch(setReportLibraryStatus({...currentLibraryStatus, exportedReport: true}));
                    dispatch(setFileTransferStatus('download', '[ReportExport]', 'success'));
                    _directDownloadOnClick(data.url);
                }
                break;
            case `${REPORT_EXPORT} API_ERROR`:
                const errorMessage = errorData.reason || errorData.parameter || 'Empty error message';
                const errorType = errorData.message
                dispatch(setReportLibraryStatus({...currentLibraryStatus, errorType, errorMessage}));
                dispatch(setFileTransferStatus(
                    'download',
                    '[ReportExport]',
                    `Failed: ${errorType}: ${errorMessage}`
                ));
                break;


            /*********************************** Report Status API ***********************************/
            case `${REPORT_STATUS} API_SUCCESS`:
                if (method === 'GET'){
                    const reportId: string = params.report_id;
                    const updatedStatus: string = data.report_status;
                    const updatedReports = getState().reportLibrary.reports.map(report => {
                        return report.report_id === reportId ? {...report, report_status: updatedStatus} : report;
                    });
                    dispatch(setReportLibrary(updatedReports));
                    dispatch(setReportLibraryStatus({...currentLibraryStatus, fetchedReportStatus: true}));
                }
                break;
            case `${REPORT_STATUS} API_ERROR`:
                dispatch(setReportLibraryStatus({
                    ...currentLibraryStatus,
                    errorType: errorData.message,
                    errorMessage: errorData.reason || errorData.parameter || 'Empty error message'
                }))
                break;

             /*********************************** Workflow API ***********************************/
             case `${WORKFLOW} API_SUCCESS`:
               if (method == 'DELETE') {
                    dispatch(setReportLibraryStatus({...currentLibraryStatus, deletedReportDefinitions: true}));
                }
                break;
            case `${WORKFLOW} API_ERROR`:
                dispatch(setReportLibraryStatus({
                    ...currentLibraryStatus,
                    errorType: errorData.message,
                    errorMessage: errorData.reason || errorData.parameter || 'Empty error message'
                }));
                break;
        }
    }
}

export default reportLibraryPageMiddleware;