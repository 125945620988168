import {apiRequest} from "src/actions/api.actions";

//Filter Middleware
//This middleware will check if action has meta and apicall objects.


export const apiConfigureMiddleware = ({dispatch}) => next => action => {
    const value = action.payload && action.meta && action.meta.apiCall;
    if(value){
        const { method, url, timeout, entity, contentType, isLog } = action.meta.apiCall;
        const params = action.payload;
        const notify = action.meta.notify || false;
        dispatch(apiRequest({params, method, url, contentType, timeout, entity, notify, isLog}));
        next(action);
    }else{
        next(action);
    }

}