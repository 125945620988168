import React from "react";
import {VersionButton} from "src/components/FillOutBridgePage/CommentVersioning/VersionNumberComponent";
import styled from "styled-components";


const FooterInstructionsDiv = styled.div`
  border-top: thin solid lightgrey;
  font-style: italic;
  font-size: 12px;
  font-weight: lighter;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 0.75em;
`;

interface props { dismiss : ()=>void, state?: string}
export const FooterInstructions = ({dismiss, state}: props) => {
    const instructionMapping : any = {
        bridgeComparison: 'Click on the magnifying glass to see history of the bridge items.',
        itemHistory: 'Navigate back to see all bridge line-items and the change across the bridge-line items.'
    }

    return <FooterInstructionsDiv className={"title-with-line"}>
        <span>{state ? instructionMapping[state]: ''}</span>
        <VersionButton onClick={dismiss} variety={'default'}>
            Dismiss
        </VersionButton>
    </FooterInstructionsDiv>
};