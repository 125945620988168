import {SET_MARKERS} from "src/actions/bridgingCommentary.actions";
import {
    ReportLibraryActions,
    ReportLibraryStatus,
    RESET_REPORT_LIBRARY_STATUS,
    RESET_UPDATING_REPORT_DEF,
    RESET_VIEWING_REPORT,
    SET_PAGINATION_CONFIG,
    SET_REPORT_LIBRARY,
    SET_REPORT_LIBRARY_STATUS,
    SET_UPDATING_EXTERNAL_REPORT_DEF,
    SET_UPDATING_REPORT_DEF,
    SET_UPDATING_REVIEW_FOLDER_DEF,
    SET_VIEWING_REPORT_DEFINITION,
    SET_VIEWING_REPORT_ROWS,
    SET_VIEWING_REPORT_SYNCHRONIZATION
} from "src/actions/reportLibrary.actions";
import {
    ExternalReportState,
    ReportDefinition,
    ReportState,
    ReportTriggerEvent,
    ReviewFolderState
} from "src/common/report";
import {ColumnType} from "src/components/CreateReportPage/interfaces";
import {FormattedReport} from "src/components/ViewReportPage/interfaces";
import {AppState} from "src/reducers/AppState";

export interface PaginationConfig {
    paginationKey?: { [p: string]: any }
    hasMore?: boolean | undefined
}

export interface ReportLibraryState {
    libraryStatus: ReportLibraryStatus;
    reports: ReportDefinition[];
    paginationConfig: PaginationConfig;
    updatingReport: { reportDefinitions: ReportState };
    viewingReport: FormattedReport;
    updatingExternalReport: { reportDefinitions: ExternalReportState }
    updatingReviewFolder: { folderDefinitions: ReviewFolderState }
}

const initialReportLibraryState: ReportLibraryState = {
    libraryStatus: {},
    reports: [],
    paginationConfig: {},
    updatingReport: {
        reportDefinitions: {
            report_id: "",
            name_and_type: {
                report_name: "",
                report_type: "",
                finance_cycle: "",
                year: "",
                period: ""
            },
            configure_columns: [
                {
                    enabled: true,
                    column_name: "",
                    column_position: 1,
                    column_type: ColumnType.FixedTotal,
                    year: "",
                    period: [],
                    scenario: "",
                }
            ],
            triggered_event: ReportTriggerEvent.CREATE
        }
    },
    updatingExternalReport: {
        reportDefinitions: {
            report_id: "",
            name_and_type: {
                report_name: "",
                report_type: "",
                finance_cycle: "",
                year: "",
                period: ""
            },
            external_report_source: "",
            link: "",
        }
    },
    viewingReport: {
        canSyncObject: {report_sync: false, bridge_sync: false},
        reportRows: [],
        reportDefinitions: {report_id: ""},
        markers: []
    },
    updatingReviewFolder: {
        folderDefinitions: {
            report_id: ""
        }
    }
}

export const reportLibraryReducer = (
    reportLibraryState = initialReportLibraryState, {type, payload}: ReportLibraryActions
): ReportLibraryState => {
    if (payload) {
        const {
            reports = [],
            status = {},
            reportRows = [],
            reportDefinitions = {report_id: ""},
            externalReportDefinitions = {report_id: ""},
            reviewFolderDefinitions = {report_id: ""},
            canSyncObject = {report_sync: false, bridge_sync: false}
        } = payload;
        switch (type) {
            case SET_REPORT_LIBRARY:
                return {...reportLibraryState, reports: reports};
            case SET_PAGINATION_CONFIG:
                return {
                    ...reportLibraryState,
                    paginationConfig: {paginationKey: payload.paginationKey, hasMore: payload.hasMore}
                };
            case SET_REPORT_LIBRARY_STATUS:
                return {...reportLibraryState, libraryStatus: status};
            case RESET_REPORT_LIBRARY_STATUS:
                return {...reportLibraryState, libraryStatus: {}};
            case SET_UPDATING_REPORT_DEF:
                return {...reportLibraryState, updatingReport: {reportDefinitions}};
            case SET_UPDATING_EXTERNAL_REPORT_DEF:
                return {...reportLibraryState, updatingExternalReport: {reportDefinitions: externalReportDefinitions}};
            case SET_UPDATING_REVIEW_FOLDER_DEF:
                return {...reportLibraryState, updatingReviewFolder: {folderDefinitions: reviewFolderDefinitions}};
            case RESET_UPDATING_REPORT_DEF:
                return {...reportLibraryState, updatingReport: initialReportLibraryState.updatingReport};
            case RESET_VIEWING_REPORT:
                return {...reportLibraryState, viewingReport: initialReportLibraryState.viewingReport};
            case SET_VIEWING_REPORT_ROWS:
                return {...reportLibraryState, viewingReport: {...reportLibraryState.viewingReport, reportRows}};
            case SET_VIEWING_REPORT_DEFINITION:
                return {...reportLibraryState, viewingReport: {...reportLibraryState.viewingReport, reportDefinitions}};
            case SET_VIEWING_REPORT_SYNCHRONIZATION:
                return {...reportLibraryState, viewingReport: {...reportLibraryState.viewingReport, canSyncObject}};
            case SET_MARKERS:
                return {
                    ...reportLibraryState,
                    viewingReport: {...reportLibraryState.viewingReport, markers: payload.markers}
                };
            default:
                return reportLibraryState;
        }
    }
    return reportLibraryState;
};

export const getReports = (state: AppState) => state.reportLibrary.reports;
export const getPaginationConfig = (state: AppState) => state.reportLibrary.paginationConfig;
export const getReportLibraryStatus = (state: AppState) => state.reportLibrary.libraryStatus;
export const getViewReport = (state: AppState) => state.reportLibrary.viewingReport;
export const getUpdatingReport = (state: AppState) => state.reportLibrary.updatingReport.reportDefinitions;
export const getExternalUpdatingReport = (state: AppState) => state.reportLibrary.updatingExternalReport.reportDefinitions;
export const getUpdatingReviewFolder = (state: AppState) => state.reportLibrary.updatingReviewFolder.folderDefinitions;
export const getMarkersFromState = (state: AppState) => state.reportLibrary.viewingReport.markers;
export const forFinancialCycleAndYearOfReport = (state: AppState): { finance_cycle: string, year: number } => {
    const dummy = {finance_cycle: "MEC", year: 2023}
    const actual = state.reportLibrary.viewingReport.reportDefinitions.name_and_type;
    /*TODO I don't understand why "actual" is not mandatory state for a report being viewed
    * This is a bad pattern. As a team we should stop doing this.
    * It is not present initially is not a valid reason. Because nothing is.
    * Learn React Suspense.*/
    const nameType = {...actual, ...dummy};
    const {finance_cycle, year} = nameType;
    return {finance_cycle, year}
}

