import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

import {CreateWorkflowRequest, CreateWorkflowResponse, ExecuteFacetRequest, ExecuteFacetResponse, ExecuteWorkflowRequest, ExecuteWorkflowResponse, FetchFacetRequest, FetchFacetResponse, FetchWorkflowRequest, FetchWorkflowResponse } from './interfaces/facetParams'

const url = `https://${window.location.host}/api`


export const facetsAPI = createApi({
    reducerPath : 'facetsAPI',
    baseQuery: fetchBaseQuery({baseUrl : url,
    prepareHeaders : (headers) => {
        headers.set('content-type', 'application/json')
    }}),
    
    endpoints: (builder) => ({
        executeFacet : builder.mutation<ExecuteFacetResponse, ExecuteFacetRequest>({
            query : facet => ({
                url: `/boatswain/facets/executions`,
                method: 'POST',
                body: facet
            }),
        }),
        fetchFacet: builder.query<FetchFacetResponse, FetchFacetRequest>({
            query : facet_request => ({
                url: `/boatswain/facets/${facet_request.facet_id}/executions/${facet_request.execution_id}?role=${facet_request.role}`,
                method: 'GET',
            }),
        })
    })
})

export const workflowAPI = createApi({
    reducerPath : 'workflowAPI',
    baseQuery: fetchBaseQuery({baseUrl : url,
        prepareHeaders : (headers) => {
            headers.set('content-type', 'application/json')
        }}),
    tagTypes: ['Workflow'],
    endpoints: (builder) => ({
        createWorkflows : builder.mutation<CreateWorkflowResponse, CreateWorkflowRequest>({
            query : workflow => ({
                url: `/boatswain/workflows`,
                method: 'POST',
                body: workflow,
                // responseHandler: 'content-type'
            }),
            invalidatesTags: ['Workflow'],
        }),
        fetchWorkflow: builder.query<FetchWorkflowResponse, FetchWorkflowRequest>({
            query: (request) => ({
                url: `/boatswain/workflows/${request.workflow_id}/version/${request.workflow_version}/executions/${request.execution_id}?role=${request.role}`,
                method : 'GET',
            }),
            providesTags: ['Workflow'],
        }),
        refreshWorkflow: builder.mutation<FetchWorkflowResponse, FetchWorkflowRequest>({
            query: (request) => ({
                url: `/boatswain/workflows/${request.workflow_id}/version/${request.workflow_version}/executions/${request.execution_id}?role=${request.role}`,
                method : 'GET',
            }),
        }),
        executeWorkflow : builder.mutation<ExecuteWorkflowResponse, ExecuteWorkflowRequest>({
            query: workflowExecution => ({
                url: `/boatswain/workflows/${workflowExecution.workflow_id}/version/${workflowExecution.workflow_version}/executions/${workflowExecution.execution_id}`,
                method : 'POST',
                body : workflowExecution
            })
        })
    })
})

export const {
    useFetchFacetQuery,
    useExecuteFacetMutation
} = facetsAPI

export const {
    useCreateWorkflowsMutation,
    useFetchWorkflowQuery,
    useExecuteWorkflowMutation,
    useRefreshWorkflowMutation
} = workflowAPI
