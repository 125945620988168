import {Alert, FormField, Input, Modal, SpaceBetween} from "@amzn/awsui-components-react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {postSpanTitles, resetReportLibraryStatus} from "src/actions/reportLibrary.actions";
import {getSpanTitleApiLoading} from "src/reducers/apiLoading.reducer";
import {getReportLibraryStatus} from "src/reducers/reportLibrary.reducer";


interface ChangeSpanTitleModalProps {
    report_id: string
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    editingSpanTitle : string
    setEditingSpanTitle: React.Dispatch<React.SetStateAction<string>>
    spanTitleIndex: number
    setSpanTitles: React.Dispatch<React.SetStateAction<string[]>>
    spanTitles: string[]
}

const ChangeSpanTitleModal = ({
    report_id,
    visible,
    setVisible,
    editingSpanTitle,
    setEditingSpanTitle,
    spanTitleIndex,
    setSpanTitles,
    spanTitles
}: ChangeSpanTitleModalProps) => {
    const dispatch = useDispatch();
    const spanTitleApiLoading = useSelector(getSpanTitleApiLoading);
    const {savedSpanTitles, errorType, errorMessage} = useSelector(getReportLibraryStatus);

    // reset the library status when modal is opened
    useEffect(function resetLibraryStatus() {
        dispatch(resetReportLibraryStatus());
    }, [])

    // if api successfully saved title, clean-up, update and close the modal
    useEffect(() => {
        if(savedSpanTitles){
            const updatedGroupNames = [...spanTitles];
            updatedGroupNames.splice(spanTitleIndex, 1, editingSpanTitle);
            setSpanTitles(updatedGroupNames);
            onModalDismiss();
        }
    }, [savedSpanTitles]);

    // handler when modal is closed
    const onModalDismiss = () => {
        dispatch(resetReportLibraryStatus());
        setVisible(false);
    }

    return (
        <Modal
            onDismiss={() => onModalDismiss()}
            visible={visible}
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => onModalDismiss()}>
                            Cancel
                        </Button>
                        <Button variant="primary"
                                loading={spanTitleApiLoading}
                                disabled={editingSpanTitle.length > 30 || editingSpanTitle.length == 0}
                                onClick={() => {
                                    const updatedGroupNames = [...spanTitles];
                                    updatedGroupNames.splice(spanTitleIndex, 1, editingSpanTitle);
                                    dispatch(postSpanTitles(report_id, updatedGroupNames))
                                }}
                        >
                            Save
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header="Change Group Name"
        >
            <SpaceBetween size='m' direction='vertical'>
                { errorType && errorType.length > 0 &&
                    <Alert type='error'>{`${errorType}: ${errorMessage}`}</Alert>
                }
                <FormField
                    label="Group Name"
                    description="For groups with variance columns, the group name will be followed by whether or not FX is included."
                    errorText={editingSpanTitle.length > 30 ? "Span title length can not exceed 30 characters" : ""}
                >
                    <Input onChange={({ detail }) => setEditingSpanTitle(detail.value)}
                           value={editingSpanTitle}
                    />
                </FormField>
            </SpaceBetween>
        </Modal>
    );
}

export default ChangeSpanTitleModal;
