import {ButtonDropdown, SpaceBetween} from "@amzn/awsui-components-react";
import React from "react";
import {BridgeItem} from "src/components/BridgingCommentary/interfaces";
import {AdminActionsProps} from "src/components/ReportBridgingPage/interfaces";


export const actionsWidget = (props : AdminActionsProps) => {
    const {setDeletingBridge, setDeleteModalVisible} = props;
    return function actions(item : BridgeItem){
        return (<SpaceBetween direction="horizontal" size="s">
            <ButtonDropdown
                items={[
                    { text: "Delete", id: "rm"}]}
                ariaLabel="Control instance"
                variant="icon"
                onItemClick={()=>{setDeletingBridge(item);
                    setDeleteModalVisible(true);}}
                expandToViewport
            >
            </ButtonDropdown>
        </SpaceBetween>)
    }
}