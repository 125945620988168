import {ReduxAction} from "src/common/interfaces";
import {
    AlertData,
    GroupedThemes,
    Theme,
    ThemeItem,
    ThemeTableEvent,
} from "src/components/Themes/interfaces";
import {FastTheme} from "src/components/Themes/reportBridgingIntegration/fastThemeCreation";
import {API_PATH} from "src/constants/api";

export interface ThemesAction extends ReduxAction {
    payload?: {
        theme?: Theme,
        reportId?: string,
        themeItems?: ThemeItem[],
        action?: string,
        groupedThemes?: GroupedThemes,
        events?: ThemeTableEvent,
        properties?: object,
        alert?: AlertData | undefined
        location?: string,
        itemUpdates?: object,
        updatedThemeItems?: ThemeItem[]
        name?: string,
        bridgeIds?: string[],
        fastTheme?: FastTheme
    }
}

// Themes
export const THEMES = `THEMES`
export const GET_THEMES = `${THEMES} GET`
export const getThemes = (reportId: string): ThemesAction => ({
    type: GET_THEMES,
    payload: {reportId},
    meta: {apiCall: {method: 'GET', url: API_PATH.THEMES, entity: THEMES}}
})

export const POST_THEMES = `${THEMES} POST`
export const postThemes = (name: string, reportId: string, bridgeIds: string[], fastTheme: FastTheme | undefined): ThemesAction => ({
    type: POST_THEMES,
    payload: {name, reportId, bridgeIds, fastTheme},
    meta: {apiCall: {method: 'POST', url: API_PATH.THEMES, entity: THEMES}}
})

export const SET_THEMES = `${THEMES} SET`
export const setThemes = (groupedThemes: GroupedThemes): ThemesAction => ({
    type: SET_THEMES,
    payload: {groupedThemes},
})


// Theme
export const THEME = `THEME`
export const GET_THEME = `${THEME} GET`
export const getTheme = (themeId: string): ThemesAction => ({
    type: GET_THEME,
    payload: {},
    meta: {apiCall: {method: 'GET', url: API_PATH.THEME(themeId), entity: THEME}}
});

export const DELETE_THEME = `${THEME} DELETE`
export const deleteTheme = (themeId: string): ThemesAction => ({
    type: DELETE_THEME,
    payload: {},
    meta: {apiCall: {method: 'DELETE', url: API_PATH.THEME(themeId), entity: THEME}}
})

export const PATCH_THEME = `${THEME} PATCH`
export const patchTheme = (themeId: string, action?: string, properties?: object): ThemesAction => ({
    type: PATCH_THEME,
    payload: {action, properties},
    meta: {apiCall: {method: 'PATCH', url: API_PATH.THEME(themeId), entity: THEME}}
})

export const SET_THEME = `${THEME} SET`
export const setTheme = (theme: Theme): ThemesAction => ({
    type: SET_THEME,
    payload: {theme},
})

// Theme Export

export const THEME_EXPORT = `${THEME} EXPORT`
export const POST_THEME_EXPORT = `${THEME_EXPORT} POST`
export const postThemeExport = (themeId: string): ThemesAction => ({
    type: POST_THEME_EXPORT,
    payload: {},
    meta: {apiCall: {method: 'POST', url: API_PATH.THEME_EXPORT(themeId), entity: THEME_EXPORT}}
})

// Theme Items
export const THEME_ITEMS = `${THEME} ITEMS`
export const GET_THEME_ITEMS = `${THEME_ITEMS} GET`
export const getThemeItems = (themeId: string) => ({
    type: GET_THEME_ITEMS,
    payload: {},
    meta: {apiCall: {method: 'GET', url: API_PATH.THEME_ITEMS(themeId), entity: THEME_ITEMS}}
})
export const PATCH_THEME_ITEMS = `${THEME_ITEMS} PATCH`
export const patchThemeItems = (themeId: string, events: ThemeTableEvent): ThemesAction => ({
    type: PATCH_THEME_ITEMS,
    payload: {events},
    meta: {apiCall: {method: 'PATCH', url: API_PATH.THEME_ITEMS(themeId), entity: THEME_ITEMS}}
})

export const SET_THEME_ITEMS = `${THEME_ITEMS} SET`
export const setThemeItems = (themeItems: ThemeItem[]): ThemesAction => ({
    type: SET_THEME_ITEMS,
    payload: {themeItems},
})

export const UPDATE_THEME_ITEMS = `${THEME_ITEMS} UPDATE`
export const updateThemeItems = (updatedThemeItems: ThemeItem[]): ThemesAction => ({
    type: UPDATE_THEME_ITEMS,
    payload: {updatedThemeItems}
})

export const UPDATE_THEME_ITEM = `THEME ITEM UPDATE`
export const updateThemeItem = (location: string, itemUpdate: object): ThemesAction => ({
    type: UPDATE_THEME_ITEM,
    payload: {location, itemUpdates: itemUpdate}
})

// Alert
export const SET_THEMES_ALERT = `${THEMES} ALERT`
export const setThemesAlert = (alert?: AlertData): ThemesAction => ({
    type: SET_THEMES_ALERT,
    payload: {alert}
})