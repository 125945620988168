
export const EXTERNAL_REPORT_SOURCES = [
    { label: "WorkDocs" },
    { label: "QuickSight" },
    { label: "Tableau" },
    { label: "Roster" }
]

export const EXTERNAL_REPORT_TYPES = [
    { label: "T&E Dashboard" },
    { label: "AWS Board" },
    { label: "Amazon Board" },
    { label: "Day 5" },
    { label: "Day 8" },
    { label: "Day 10" },
    { label: "Margin bridges" },
    { label: "Corp submissions" },
    { label: "Headcount" },
    { label: "S&TP" },
    { label: "Other" },
]
