import {AppLayout, SplitPanel} from "@amzn/awsui-components-react";
import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {Tooltip} from 'react-tooltip';
import {getBridgeOwnerGroups} from "src/actions/bridgingCommentary.actions";
import {appLayoutLabels} from "src/common/labels";
import {finPressRoles, Roles} from "src/common/roles";
import AppRouter from "src/components/AppRouter";
import UserAccessException from "src/components/Common/UserAccessException";
import {Navigation} from "src/components/NavigationPanel/Navigation";
import {getPageSettings} from "src/components/PageConfig/PageSettings";
import {TopNavigationBar} from "src/components/TopNavigationBar/TopNavigationBar";
import Breadcrumb from "src/features/breadcrumbs/components/Breadcrumb";

import {getScenariosMetadata} from "../actions/mdxLibrary.actions";
import {getUserRoles} from "../actions/user.actions";
import {getAssumedRole, getAvailableRoles, getUser} from "../reducers/user.reducer";
import BackgroundSpinner from "./Common/BackgroundSpinner";

const App = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    // Selectors
    const loggedInUser = useSelector(getUser, shallowEqual);
    const currentAssumedRole = useSelector(getAssumedRole);
    const availableRoles = useSelector(getAvailableRoles, shallowEqual);
    // States
    const [navigationOpen, setNavigationOpen] = React.useState(false);
    // Constants
    const pageLayoutSettings = getPageSettings(location.pathname).layoutSetting;
    // Use effects
    useEffect(() => {
        // Make API calls only after we have loggedInUser and assumed Role available
        // APIs to be called only if user has Admin permissions
        if (loggedInUser !== 'undefined' && currentAssumedRole === Roles.Admin) {
            dispatch(getScenariosMetadata());
            dispatch(getBridgeOwnerGroups());
        }
    }, [loggedInUser, currentAssumedRole]);

    const initialize = () => loggedInUser === 'undefined' && dispatch(getUserRoles());
    if(availableRoles.length === 0){
        initialize();
        if(loggedInUser === "undefined")
            return <BackgroundSpinner />;
        else
            return <UserAccessException
                header="User Not Onboarded"
                user='User'
                allowedRoles={finPressRoles}
                availableRoles={availableRoles}
            />
    }

    return (<>
        <TopNavigationBar />
        <AppLayout
            ariaLabels={appLayoutLabels}
            navigationOpen={navigationOpen}
            breadcrumbs={<Breadcrumb/>}
            onNavigationChange={({detail}) => setNavigationOpen(detail.open)}
            toolsHide={true}
            navigation={<Navigation/>}
            content={<AppRouter />}
            headerSelector={"#top-nav-root"}
            splitPanelOpen={false}
            splitPanel={<SplitPanel header={""} closeBehavior={"hide"}>{""}</SplitPanel>}
            splitPanelPreferences={{position: 'side'}}
            {...pageLayoutSettings}
        />
        <Tooltip id={'global'} disableStyleInjection={'core'} />
    </>);
}

export default App;
