import { RowNumberingType } from "@amzn/aws-fintech-fluid-table";
import {StatusIndicatorProps} from "@amzn/awsui-components-react";
import {DriverCommentarySelect} from "src/components/Themes/header/interfaces";
import {BatchEvent} from "src/components/Themes/hooks";

export enum ThemeStates {
    NotStarted = "Not Started",
    InProgress = "In-progress",
    Published = "Published",
}

export const themeStateToIcon: {[key: string]: StatusIndicatorProps.Type} = {
    [ThemeStates.NotStarted]: "pending",
    [ThemeStates.InProgress]: "in-progress",
    [ThemeStates.Published]: "success",
}

export interface GroupedThemes {
    [key: string]: Theme[]
}

export interface Theme {
    id: string,
    reportId: string,
    name: string,
    leftScenario: string,
    rightScenario: string,
    lastModifiedBy: string,
    lastModified: number,
    state: ThemeStates,
    columns: ThemeColumn[]
}

export enum ThemeItemType {
    BRIDGE_SUB_ITEM = 'bridgeSubItem',
    BRIDGE = 'bridge',
    GROUPING = 'grouping',
    VARIANCE = 'variance'
}


export interface ThemeActionHandlers {
    onAddRow: () => void,
    onDelete: () => void,
    onDownload: () => void,
    onPublish: () => void,
    onUnpublish: () => void,
    setDriverCommentarySelect: (driverCommentarySelect: DriverCommentarySelect) => void,
    setSourceCommentary: (sourceCommentary: boolean) => void,
    setRowNumbering: (type: RowNumberingType) => void;
}

export interface ThemeColumn {
    id: string,
    name: string
}

export interface ThemeItem {
    id: string,
    name: string,
    commentary: string,
    commentaryAlias?: string,
    values: {[key: string]: number},
    type: ThemeItemType,
    position: number;
    subRows?: ThemeItem[]
}

export interface ThemeTableEvent extends BatchEvent {
    [objectId: string]: {
        commentary?: string,
        position?: number,
        itemType: ThemeItemType,
        currentParent?: string,
        deleted?: boolean,
        move?: { old?: string, new: string}
    }
}

export interface AlertData {
    type: 'success' | 'error' | 'warning' | 'info',
    message: string,
    reason: string,
}
