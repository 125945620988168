import {useCollection} from "@amzn/awsui-collection-hooks";
import {
    CollectionPreferences,
    CollectionPreferencesProps,
    Pagination,
    PropertyFilter
} from "@amzn/awsui-components-react";
import Header from "@amzn/awsui-components-react/polaris/header";
import Table from "@amzn/awsui-components-react/polaris/table";
import React, {ReactNode, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getReportBridges} from "src/actions/bridgingCommentary.actions";
import {getReportLibrary, getReportStatus,} from "src/actions/reportLibrary.actions";
import {ItemType, ReportDefinition, ReportStatus, ReviewFolderState} from "src/common/report";
import {Roles} from "src/common/roles";
import {EmptyState} from "src/components/CreateReportPage/MDXGroupSelection";
import {ExpandedFolder} from "src/components/CreateReviewPage/interfaces";
import {EmptyTableState} from "src/components/MDXLibraryPage/MDXLibraryTable";
import {constructReportTableRow} from "src/components/ReportLibraryPage/components/constructReportTableRow";
import {generateDisplayName} from "src/components/ReportLibraryPage/components/generateDisplayName";
import {
    ReportLibraryTableProps,
    ReportTableColumns
} from "src/components/ReportLibraryPage/interfaces";
import {
    DEFAULT_COLUMNS,
    FILTERING_PROPERTIES,
    getFilterCounterText,
    getReportTableColumnDefinitions,
    PAGE_SIZE_OPTIONS,
    PROPERTY_FILTERING_I18N_CONSTANTS,
    ReportTableRowItem
} from "src/components/ReportLibraryPage/ReportTableConfig";
import {
    selectAllReportBridges,
} from "src/features/reportBridges/reportBridgeSlice";
import {getReportLibraryApiLoading} from "src/reducers/apiLoading.reducer";
import {AppState} from "src/reducers/AppState";
import {getPaginationConfig, getReports} from "src/reducers/reportLibrary.reducer";
import {getAssumedRole} from "src/reducers/user.reducer";

export interface EmptyStateProps {
    title: string,
    subtitle: string,
}

const ReportLibraryTable = (props: ReportLibraryTableProps) => {
    const dispatch = useDispatch();
    // Selectors and states
    const currentAssumedRole = useSelector(getAssumedRole);
    const tableLoading = useSelector(getReportLibraryApiLoading)
    const {paginationKey, hasMore} = useSelector(getPaginationConfig);
    const tableData = useSelector(getReports)
    const [expandedGroups, setExpandedGroups] = useState<ExpandedFolder>({});
    const [filteringInProgress, setFilteringInProgress] = useState<boolean>(false);
    const reportBridges = useSelector((state : AppState) => selectAllReportBridges(state))
    const pageSizePref = 50;

    const excludedColumnsMap : {[p:string] : ReportTableColumns[]} =  {
        [Roles.Admin] : [],
        [Roles.BridgeOwner] : [ReportTableColumns.Actions, ReportTableColumns.BridgingAndCommentary],
        [Roles.BusinessLeader] : [ReportTableColumns.Actions, ReportTableColumns.BridgingAndCommentary],
        [Roles.FinanceLeader] : [ReportTableColumns.Actions, ReportTableColumns.BridgingAndCommentary],
        [Roles.FGBSLeader] : [ReportTableColumns.Actions, ReportTableColumns.BridgingAndCommentary],
        [Roles.SFPA_ReportAuthor] : [ReportTableColumns.BridgingAndCommentary, ReportTableColumns.FinanceCycle, ReportTableColumns.FinanceYear, ReportTableColumns.LastEdited],
        [Roles.SFPA_ReportViewer] : [ReportTableColumns.Actions, ReportTableColumns.BridgingAndCommentary, ReportTableColumns.FinanceCycle, ReportTableColumns.FinanceYear, ReportTableColumns.LastEdited],
        [Roles.DCFPA_ReportAuthor] : [ReportTableColumns.BridgingAndCommentary, ReportTableColumns.FinanceCycle, ReportTableColumns.FinanceYear, ReportTableColumns.LastEdited],
        [Roles.DCFPA_ReportViewer] : [ReportTableColumns.Actions, ReportTableColumns.BridgingAndCommentary, ReportTableColumns.FinanceCycle, ReportTableColumns.FinanceYear, ReportTableColumns.LastEdited],
    }

    const columnDefinitions = useMemo(() => {
        const excludedColumns = new Set(currentAssumedRole ? excludedColumnsMap[currentAssumedRole] : [])
        return getReportTableColumnDefinitions(excludedColumns);
    }, [currentAssumedRole]);

    // Use effect to fetch initial data
    useEffect(function initializeReportTable() {
        //Only fetch bridging and commentary data when the column is needed
        if(currentAssumedRole && !excludedColumnsMap[currentAssumedRole].includes(ReportTableColumns.BridgingAndCommentary)){
            tableData.forEach(({report_id}) => {
                //fetch bridges not in the redux state
                if(!(reportBridges?.find(bridge => bridge.report_id == report_id))){
                    dispatch(getReportBridges({role: currentAssumedRole, variance_header :"", report_id: report_id}));
                }
            });
        }
    }, [tableData]);

    useEffect(function fetchBridgeStatus() {
        if (!tableLoading) {
            dispatch(getReportLibrary({
                reportLibrary: props.reportLibrary,
                limit: pageSizePref + 1
            }));
        }
    }, [props.reportLibrary, currentAssumedRole]);

    // Use effect to refresh in-flight reports metadata
    useEffect(function setupTimerToRefreshReports(){
        const timer = setInterval(() => {
            tableData.forEach(({report_id, report_status}) => {
                if (report_status === 'Creating' || report_status === 'Updating'){
                    dispatch(getReportStatus(report_id));
                }
            });
        }, 10 * 1000);
        return function stopTimer() {
            clearInterval(timer)
        };
    }, [tableData]);


    const groupTreeViewControl = (item_id: string) => {
        const groupIsExpanded = expandedGroups[item_id];
        setExpandedGroups({...expandedGroups, [item_id]: !groupIsExpanded});
    };

    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: pageSizePref,
        visibleContent: props.showBridgeStatus ? [...DEFAULT_COLUMNS, ReportTableColumns.BridgingAndCommentary]
            : DEFAULT_COLUMNS
    });

    const constructTableRows = (items: ReportDefinition[], fullTable = false) => {
        const tableRows: ReportTableRowItem[] = [];

        const processedItems = new Set<string>(); // keep track of processed report ids
        const folderItems: ReviewFolderState[] = [];
        const reportMap: {[p:string]: ReportDefinition} = {};


        // Separate folders and reports
        items.forEach(item => {
            if (item.item_type === ItemType.ReviewFolder){
                folderItems.push({...item});
            } else {
                reportMap[item.report_id] = {...item}
            }
        }) ;
        // Build table rows for folders first
        folderItems.forEach(folder => {
            const iconName = (expandedGroups[folder.report_id] || fullTable) ? "treeview-collapse" : "treeview-expand";
            tableRows.push(constructTableRow(
                folder,
                "",
                {iconName, variant: "icon", onClick: () => groupTreeViewControl(folder.report_id)},
                props.reportActions(folder, "")
            ))
            processedItems.add(folder.report_id);
            // Add sub rows as needed
            folder.report_ids?.forEach(report_id => {
                processedItems.add(report_id)
                if (expandedGroups[folder.report_id] || fullTable) {
                    tableRows.push(constructTableRow(
                        reportMap[report_id],
                        folder.report_id,
                        {},
                        props.reportActions(reportMap[report_id], folder.report_id)
                    ));
                }

            });
        });
        // Add the remaining rows
        items.forEach(item => {
            if(!processedItems.has(item.report_id)){
                tableRows.push(constructReportTableRow(
                    item, "", {}, props.reportActions(item, ""), reportBridges
                ));

            }
        });
        return tableRows
    }

    const tableRows = constructTableRows(tableData, filteringInProgress);
    const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
        tableRows,
        {
            propertyFiltering: {
                filteringProperties: FILTERING_PROPERTIES,
                empty: <EmptyState title="No instances" subtitle="No instances to display."/>,
                noMatch: <EmptyState title="No matches" subtitle="We can’t find a match."/>,
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {},
        }
    );

    return (<>
        {props.confirmationModal}
        <Table
            {...collectionProps}
            variant="full-page"
            visibleColumns={preferences.visibleContent}
            columnDefinitions={columnDefinitions}
            items={filteringInProgress ? reconstructWithMissingRows(items, tableRows) : items}
            loading={tableLoading}
            loadingText="Loading resources"
            stickyHeader={true}
            empty={<EmptyTableState />}
            header={
                <Header
                    variant="h1"
                    counter={`(${tableData.length.toString()})`}
                    description={props.description}
                    actions={props.headerActions}
                >
                    {props.headerTitle}
                </Header>
            }
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                    {...propertyFilterProps}
                    onChange={event => {
                        setFilteringInProgress(event.detail.tokens.length > 0);
                        propertyFilterProps.onChange(event);
                    }}
                    countText={getFilterCounterText(filteredItemsCount || 0)}
                />
            }
            pagination={
                <Pagination
                    {...paginationProps}
                    onChange={(event) => {
                        if (event.detail.currentPageIndex === paginationProps.pagesCount && hasMore) {
                            dispatch(getReportLibrary({
                                reportLibrary: props.reportLibrary,
                                limit: pageSizePref + 1,
                                paginationKey
                            }));
                        }
                        paginationProps.onChange(event);
                    }}
                    openEnd={hasMore}
                />
            }
            preferences={
                <CollectionPreferences
                    onConfirm={({ detail }) => setPreferences(detail)}
                    title="Preferences"
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                    preferences={preferences}
                    pageSizePreference={{
                        title: "Select page size",
                        options: PAGE_SIZE_OPTIONS
                    }}
                    visibleContentPreference={{
                        title: "Select visible content",
                        options: [
                            {label: "Report columns", options: columnDefinitions}
                        ]
                    }}
                />
            }
        />
    </>);
};

export default ReportLibraryTable;

export const constructTableRow = (
    item: ReportDefinition,
    parentFolderId: string,
    folderButtonProps: object,
    actions: ReactNode,
    enableReportLink = true
): ReportTableRowItem => {
    return {
        id: item.report_id,
        displayName: generateDisplayName(item, folderButtonProps, parentFolderId !== "", enableReportLink),
        lastModified: item.last_modified || "",
        reportStatus:item.report_status || ReportStatus.ERROR,
        reportRole: item.report_role || Roles.Admin,
        actions,
        reportType: item.name_and_type?.report_type || "",
        parentFolderId,
        itemType: item.item_type || ItemType.Internal,
        // Keys used for filtering table
        reportName: item.name_and_type?.report_name || "",
        financeCycle: item.name_and_type?.finance_cycle || "",
        year: item.name_and_type?.year || "",
        period: item.name_and_type?.period || ""
    }
}


/**
 * Function to add missing rows during property filtering
 * @param collectionHookTableRows array of table items returned by collection hook
 * @param tableRows Full table rows
 * @return array of table rows with any missing rows
 */
export function reconstructWithMissingRows(collectionHookTableRows: Readonly<ReportTableRowItem[]>, tableRows: ReportTableRowItem[]) {
    const partialTableRows: ReportTableRowItem[] = []; // collect re-constructed table rows
    const validFolderIds = new Set<string>(); // keeps track of folder ids with some child rows
    const seenFolderIds = new Set<string>(); // keeps track of seen folder ids
    // Map row id -> {row, index} for easy lookup
    const idToRowMap: {[p:string]: {row: ReportTableRowItem, index: number}} = {}
    tableRows.forEach((row, index)=> idToRowMap[row.id] = {row, index});

    collectionHookTableRows.forEach(row => {
        // If row is folder add to seen set
        if (row.itemType === ItemType.ReviewFolder){
            seenFolderIds.add(row.id);
        }
        // If row has parent folder id
        if(row.parentFolderId) {
            // Check if folder row needs to added to new items array
            if(!seenFolderIds.has(row.parentFolderId)) {
                partialTableRows.push(idToRowMap[row.parentFolderId].row);
                seenFolderIds.add(row.parentFolderId);
            }
            // Since folder has this child it is a valid row
            validFolderIds.add(row.parentFolderId);
        }
        // Add the row
        partialTableRows.push(row)
    });

    const reconstructedTableRows: ReportTableRowItem[] = []; // collect re-constructed table rows
    partialTableRows.forEach(row => {
        reconstructedTableRows.push(row);
        // If current row is an empty folder add all of its children report rows
        if (row.itemType === ItemType.ReviewFolder && !validFolderIds.has(row.id)) {
            let currIndex = idToRowMap[row.id].index + 1
            while(currIndex < tableRows.length && tableRows[currIndex].parentFolderId === row.id){
                reconstructedTableRows.push(tableRows[currIndex]);
                currIndex++;
            }
        }
    })
    return reconstructedTableRows;
}
