import styled from "styled-components";

export const ConfigureColumnBox = styled.div `
    display: flex;
    overflow: auto;
    min-height: 450px;
`;

export const VarianceColumnBox = styled.div `
    max-width: 375px;
    min-width: 375px;
  	padding: 0 20px 20px 20px;
`

export const FixedColumnBox = styled.div `
    max-width: 215px;
    min-width: 215px;
    overflow-x: hidden;
  	padding: 0 20px 20px 20px;
`;

export const VarianceConfigurationBox = styled.div `
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 4%;
    row-gap: 12px;
    width: 100%
`;

export const HorizontalLine = styled.hr`
    margin: 0.5px 0;
    color: lightgray;
    height: 0.4px;
    background-color: lightgray;
`;

export const VerticalLine = styled.div `
    border-left: 2px solid grey;
  	margin-bottom: 20px;
`;