import {Button, Table} from "@amzn/awsui-components-react";
import {SpaceBetween} from "@amzn/awsui-components-react/uxdg";
import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {BridgeItem} from "src/components/BridgingCommentary/interfaces";
import {
    getColumnDefinitionForViewingAllBridges,
    getColumnDefinitionLayoutTwoForViewingAllBridges
} from "src/components/FillOutBridgePage/FillOutBridgeTableConfig";
import {Cell} from "src/components/FillOutBridgePage/InputComponents";
import {BridgeSubItem, FillOutTableItem} from "src/components/FillOutBridgePage/interfaces";
import {ExpandedBridge} from "src/components/MDXLibraryPage/interfaces";
import {EmptyTableState} from "src/components/MDXLibraryPage/MDXLibraryTable";
import {getCommentsApiLoading} from "src/reducers/apiLoading.reducer";
import {getBridgesBeingViewedCurrently} from "src/reducers/bridgingCommentary.reducer";
import styled from "styled-components";

interface ViewAllBridgesTableProps {
    bridgeItem: BridgeItem
    subBridgeItems: Array<BridgeSubItem>
    tableLayout?: number
}

export const CustomColumnLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  column-gap: 15px;
`;

export const ModalTableDiv = styled.div`
  display: grid;
  grid-template-rows: auto 80%;
  width: 550px;
  max-height: 58vh;
  text-overflow: ellipsis;
  overflow: scroll;

  .result-table {
    padding-right: 1px;
    padding-left: 1px;
    max-height: 50em;
    overflow-y: auto;
    width: auto;
    display: contents;
  }

  // This is for the Polaris table. It is to customize the inner table HTML tag inside the Polaris Table component
  #condensed-table div div:nth-child(2) div table tbody tr td {
    padding: 0 0;
  }
`;

const ViewAllBridgesTable = ({bridgeItem, tableLayout = 1}: ViewAllBridgesTableProps) => {
    // Selector
    const tableIsLoading = useSelector(getCommentsApiLoading);
    const allBridgesSubItems = useSelector(getBridgesBeingViewedCurrently);
    // States
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    // Constants
    const [expandedBridges, setExpandedBridges] = useState<ExpandedBridge>({});
    const arr: BridgeSubItem[] = []

    // Event handlers and hooks

    const columnDefinition = useMemo(() => {
        const {left_header, right_header, variance_header} = bridgeItem.variance_headers;
        return tableLayout === 1 ? getColumnDefinitionForViewingAllBridges(left_header, right_header, variance_header)
            : getColumnDefinitionLayoutTwoForViewingAllBridges(left_header, String(bridgeItem.variance_data.left), bridgeItem.account_rollup);
    }, [tableLayout, bridgeItem]);

    // Handler to control the tree view of current bridge in the table
    const bridgeTreeViewControl = (bridge: string) => {
        const bridgeIsExpanded = expandedBridges[bridge];
        setExpandedBridges({...expandedBridges, [bridge]: !bridgeIsExpanded});
    };

    const constructRow = (rowType: string, item: BridgeSubItem, index = -1): FillOutTableItem => {
        const {name, leftValue, rightValue, varianceValue, commentary} = item;
        const iconName = expandedBridges[name] ? "treeview-collapse" : "treeview-expand";

        const selectedItem = arr[selectedItemIndex];
        // console.log("is it the same: ", isRowSelected(selectedItem, item));
        const backgroundColor = isRowSelected(selectedItem, item) ? "aliceblue" : "inherit";

        if (rowType === "primary") {
            return {
                name:
                    <>
                        <SpaceBetween size={"s"} direction={"horizontal"}>
                            <Button iconName={iconName} variant="icon" onClick={() => bridgeTreeViewControl(name)}>
                            </Button>
                            <Cell style={{textOverflow: "ellipsis", overflow: "hidden"}} backgroundColor={backgroundColor}
                                  marginLeft={"9px"} marginTop={"5px"} fontWeight="800" touched={true}
                                  cursorPointer={true}>{name}</Cell>
                        </SpaceBetween>
                    </>,
                leftValue: <Cell backgroundColor={backgroundColor}
                                 touched={!(selectedItemIndex === index)} cursorPointer={true} valueCell={true}
                                 fontWeight="800"
                                 marginLeft="5px">{getFormattedValue(leftValue)} </Cell>,
                rightValue: <Cell backgroundColor={backgroundColor}
                                  touched={!(selectedItemIndex === index)} cursorPointer={true} valueCell={true}
                                  fontWeight="800"
                                  marginLeft="5px">{getFormattedValue(rightValue)}</Cell>,
                commentator: "",
                varianceValue: <Cell backgroundColor={backgroundColor}
                                     marginLeft="5px" fontWeight="800" valueCell={true} touched={true}
                                     cursorPointer={true}>
                    {getFormattedValue(varianceValue)}
                </Cell>,
                commentary: !index ? <Cell marginLeft={"11px"}> {commentary}</Cell> : ""
            }
        } else {
            return {
                name: <Cell backgroundColor={backgroundColor} fontStyle="italic"
                            marginLeft="20px" cursorPointer={true}
                            touched={!(selectedItemIndex === index)}>{name}</Cell>,
                leftValue: <Cell backgroundColor={backgroundColor}
                                 touched={!(selectedItemIndex === index)} cursorPointer={true} valueCell={true}
                                 marginLeft="5px">{getFormattedValue(leftValue)}</Cell>,
                rightValue: <Cell backgroundColor={backgroundColor}
                                  touched={!(selectedItemIndex === index)} cursorPointer={true} valueCell={true}
                                  marginLeft="5px">{getFormattedValue(rightValue)}</Cell>,
                varianceValue: <Cell backgroundColor={backgroundColor} valueCell={true} marginLeft="5px"
                                     cursorPointer={true}
                                     touched={true}>{getFormattedValue(varianceValue)}</Cell>,
                commentator: "",
                commentary: <Cell marginLeft={"11px"}> {commentary}</Cell>,
            }
        }
    };

    const constructTable = (): FillOutTableItem[] => {
        let tableRows: FillOutTableItem[] = [];

        for (const [, value] of Object.entries(allBridgesSubItems)) {
            value.forEach((val) => {
                arr.push(val)
            });
        }
        arr[selectedItemIndex] && console.log(arr[selectedItemIndex].name);
        for (const allBridgesSubItemsKey in allBridgesSubItems) {
            const items = allBridgesSubItems[allBridgesSubItemsKey];
            tableRows = tableRows.concat(constructTableRows(items));
        }
        console.log("array ", arr);
        return tableRows;
    };

    const constructTableRows = (subItems: BridgeSubItem[] = []): FillOutTableItem[] => {
        const tableRows: FillOutTableItem[] = [];
        subItems[0] && tableRows.push(constructRow("primary", subItems[0], 0));
        // Push the sub rows
        if (subItems[0] && expandedBridges[subItems[0].name]) {
            for (let index = 1; index < subItems.length; index++) {
                tableRows.push(constructRow("subItemRow", subItems[index], index));
            }
            // Push unexplained row
            // if (unexplainedRow) tableRows.push(constructRow("unexplained", unexplainedRow));
        }
        // console.log("tableRows ", tableRows);
        return tableRows;
    }

    const isRowSelected = (selectedRow: BridgeSubItem, item: BridgeSubItem): boolean => {
        return selectedRow.name === item.name && selectedRow.commentary === item.commentary && selectedRow.varianceValue === item.varianceValue
            && selectedRow.leftValue === item.leftValue && selectedRow.rightValue === item.rightValue;
    }

    return (
        <CustomColumnLayout>
            <ModalTableDiv>
                <div id={"condensed-table"} className={"result-table"}>
                    <Table
                        columnDefinitions={columnDefinition}
                        // items={constructTableRows(bridgeItem, subBridgeItems)}
                        items={constructTable()}
                        loadingText="Loading resources"
                        empty={<EmptyTableState/>}
                        variant="embedded"
                        loading={tableIsLoading}
                        wrapLines={true}
                        className={"custom-report-table"}
                        onRowClick={({detail}) => {
                            setSelectedItemIndex(detail.rowIndex);
                        }}
                    />
                </div>
            </ModalTableDiv>
            <div id={"commentsDiv"} style={{maxHeight: "58vh", textOverflow: "ellipsis", overflow: "scroll"}}>
                {
                    arr[selectedItemIndex] !== undefined ?
                    <>
                        <div>
                            <strong>{arr[selectedItemIndex].name} ${arr[selectedItemIndex].varianceValue}</strong>
                        </div>
                        <div><i>{`Comments by ${arr[selectedItemIndex].commentator}@`}</i></div>
                        <div>{arr[selectedItemIndex].commentary}</div>
                    </>
                        :
                    <>
                        <div>
                            <strong>{`Please click on a row to view it's commentary`}</strong>
                        </div>
                    </>
                }
            </div>
        </CustomColumnLayout>
    )
}

export default ViewAllBridgesTable;

export const getFormattedValue = (value: string | number) => {
    const valueFormatter = Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    return valueFormatter.format(Number(value));
};
