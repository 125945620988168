import '@amzn/aws-fintech-fluid-table/dist/style.css'

import {Cell, Column, ColumnDef, Row,Table} from '@amzn/aws-fintech-fluid-table';
import { Dictionary, } from '@reduxjs/toolkit';
import React from 'react';

import { AlignedText, LeftAlignedHeader } from './styling';
interface BYORITEM {
    id: string,
    name : string,
    values : {[key: string]: number},
    subRows? : BYORITEM[]
}

export interface BYORColumn {
    id: string,
    name: string,
    type? : string
}

const configureColumnSize = (column: string) => {
    const minColumnSize = 130
    const defaultColumnSize = 200
    const defaultMaxColumnSize = 300

    // const titleSize = column.length * 40 + 10
    const columnSizeMapping: Dictionary<{ default: number, max: number }> = {
      'Row Titles': {default: 320, max: 480},
    }
  
    return {
      width: columnSizeMapping[column]?.default || defaultColumnSize,
      maxWidth: columnSizeMapping[column]?.max || defaultMaxColumnSize,
      minWidth: minColumnSize,
      resizable: true
    }
  }

const createColumnDefs = (
    columns: BYORColumn[],
  ): ColumnDef<BYORITEM>[] => {
    const columnMapping: Dictionary<string> = columns.reduce((mapping: Dictionary<string>, col) => ({...mapping, [col.id]: col.name}), {})

    const textColumns: {[k: string]: keyof BYORITEM} = {'Row Titles': 'name'}
    const valueFormatterFn = (value: any) => value ? value : ""

    return columns.map((c) => {
        
        const column = c.name as keyof BYORITEM

        const isTextColumn = column in textColumns
        const valueAccessorFn = isTextColumn ?
        (row: Row<BYORITEM>) => row.data[textColumns[column]] || row.data[column]:
        (row: Row<BYORITEM>) => row.data.values[column]

        return {
            columnId: c.id,
            headerRendererFn: (column: Column) =>  isTextColumn ? "" : <LeftAlignedHeader>{columnMapping[column.columnId]}</LeftAlignedHeader>,
            cellRendererFn: (cell: any) => cellRendererFn(cell, c.type),
            valueAccessorFn,
            valueFormatterFn : valueFormatterFn,
            columnSizing: configureColumnSize(column),
        }}
    )    
}


const cellRendererFn = (cell: Cell<BYORITEM>, columnType : string | undefined) => {
    const alignLeft = columnType == 'Utf8' || columnType == 'String'
    return alignLeft ? <AlignedText align={'left'}>{cell.value}</AlignedText> :  <AlignedText align={'right'}> 
    {cell.value}</AlignedText>
  
  }

export const BYORTable = (json : any) => {

    const columns: BYORColumn[] = json['columns']

    const columnDefs = createColumnDefs(columns)

    const items : BYORITEM[] = json['rows']

    return (<Table<BYORITEM>
    columns= {columnDefs}
    data={items}
    rowExpansion={{ columnId: columns[0]?.id }} 
    columnLettering
    rowNumbering={{type: 'absolute'}}
    />)
}
