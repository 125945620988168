import {useCollection} from "@amzn/awsui-collection-hooks";
import {
    CollectionPreferences,
    Pagination,
    Table
} from "@amzn/awsui-components-react";
import {CollectionPreferencesProps} from "@amzn/awsui-components-react/uxdg";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getBridges} from "src/actions/bridgingCommentary.actions";
import {Roles} from "src/common/roles";
import {
    ADMIN_DEFAULT_COLUMNS, BRIDGE_OWNER_DEFAULT_COLUMNS,
    FILTERING_PROPERTIES,
    getBridgingTableColDefinition,
} from "src/components/BridgingCommentary/BridgingTableConfig";
import {
    adminBridgeTabStates,
    BridgingTableColumns, ownerBridgeTabStates,
} from "src/components/BridgingCommentary/constants";
import {BridgingCommentaryTableProps} from "src/components/BridgingCommentary/interfaces";
import AdminTableRows from "src/components/BridgingCommentary/tableComponents/AdminTableRows";
import {BridgeStatusFilter} from "src/components/BridgingCommentary/tableComponents/BridgeStatusFilter";
import OwnerTableRows from "src/components/BridgingCommentary/tableComponents/OwnerTableRows";
import {EmptyState} from "src/components/CreateReportPage/MDXGroupSelection";
import {EmptyTableState} from "src/components/MDXLibraryPage/MDXLibraryTable";
import {
    PAGE_SIZE_OPTIONS,
} from "src/components/ReportLibraryPage/ReportTableConfig";
import {getBridgesApiLoading} from "src/reducers/apiLoading.reducer";
import {getPaginationConfig} from "src/reducers/bridgingCommentary.reducer";
import {getAssumedRole} from "src/reducers/user.reducer";
import {filterStatus} from "src/utils/reportBridgingHelpers";

const BridgingCommentaryTable = (props : BridgingCommentaryTableProps ) => {
    const dispatch = useDispatch();
    // Redux selectors
    const assumedRole = useSelector(getAssumedRole);
    const {paginationKey, hasMore} = useSelector(getPaginationConfig)
    const tableIsLoading = useSelector(getBridgesApiLoading);
    // States for Bridging and Commentary table
    const initialBridgeItemsCount = 50;
    const [filteringInProgress, setFilteringInProgress] = useState<boolean>(false);
    const visibleColumns = assumedRole === Roles.Admin ? ADMIN_DEFAULT_COLUMNS : BRIDGE_OWNER_DEFAULT_COLUMNS
    const [activeBridgeStatus, setActiveBridgeStatus] = useState<string>("Requested")
    const [tablePreferences, setTablePreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: initialBridgeItemsCount,
        visibleContent: visibleColumns
    });
    // States for bridge owners modal

    //display status columns when at "All" tabs
    if(activeBridgeStatus == "All"){
        tablePreferences.visibleContent = [...visibleColumns, BridgingTableColumns.Status]
    }
    else{
        tablePreferences.visibleContent = [...visibleColumns]
    }

    const {bridgeItems, selectedItems, setSelectedItems,ownerWidget, dueDateWidget, actionWidget} = props;

    const bridgeTabStates = assumedRole == Roles.Admin? adminBridgeTabStates : ownerBridgeTabStates;
    const tableRowsProps = {
        bridgeItems : filterStatus(bridgeItems, activeBridgeStatus, bridgeTabStates),
        fullTable : filteringInProgress,
        dueDate : dueDateWidget,
        owners : ownerWidget,
        actions : actionWidget
    }
    const tableRows = assumedRole== Roles.Admin? AdminTableRows(tableRowsProps) : OwnerTableRows(tableRowsProps)

    const columnDefinitions = useMemo(() => {
        return getBridgingTableColDefinition(assumedRole);
    }, [assumedRole]);

    useEffect(() => {
        if(assumedRole === Roles.BridgeOwner || assumedRole === Roles.Admin) {
            dispatch(getBridges({role: assumedRole, limit: initialBridgeItemsCount + 1}))
        }
    }, [assumedRole]);

    // Event Handlers and collection hooks usage
    const {items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps} = useCollection(
        tableRows,
        {
            propertyFiltering: {
                filteringProperties: FILTERING_PROPERTIES,
                empty: <EmptyState title="No instance" subtitle="No instances to display"/>,
                noMatch: <EmptyState title="No matches" subtitle="We can't find a match"/>
            },
            pagination: {pageSize: tablePreferences.pageSize},
        }
    );


    return (
        <Table
            {...collectionProps}
            wrapLines={false}
            selectedItems={selectedItems}
            onSelectionChange={({detail}) => setSelectedItems(detail.selectedItems)}
            isItemDisabled={item => item.id === item.report_id}
            trackBy="id"
            selectionType="multi"
            variant="borderless"
            columnDefinitions={columnDefinitions}
            visibleColumns={tablePreferences.visibleContent}
            items={items}
            loadingText="Loading resources"
            loading={tableIsLoading}
            stickyHeader={true}
            empty={<EmptyTableState />}
            filter = {<BridgeStatusFilter propertyFilterProps={propertyFilterProps} setActiveBridgeStatus={setActiveBridgeStatus} setFilteringInProgress={setFilteringInProgress}
            filteredItemsCount={filteredItemsCount}/>}
            pagination={
                <Pagination
                    {...paginationProps}
                    onChange={event => {
                        if (event.detail.currentPageIndex === paginationProps.pagesCount && hasMore && assumedRole) {
                            dispatch(getBridges({
                                paginationKey,
                                limit: initialBridgeItemsCount + 1,
                                role: assumedRole
                            }))
                        }
                        paginationProps.onChange(event)
                    }}
                    openEnd={hasMore}
                />
            }
            preferences={
                <CollectionPreferences
                    onConfirm={({detail}) => setTablePreferences(detail)}
                    title="Table Preferences"
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                    preferences={tablePreferences}
                    pageSizePreference={{title: "Select page size", options: PAGE_SIZE_OPTIONS}}
                    visibleContentPreference={{
                        title: "Select visible content",
                        options: [{label: "Columns", options: columnDefinitions}]
                    }}
                />
            }
        />)
}


export default BridgingCommentaryTable;



