import {Alert, Container, Header} from "@amzn/awsui-components-react";
import * as React from 'react';
import styled from "styled-components";


const ThemeNotFoundDiv = styled.div`
  margin: 0;
  padding: 0;
  display: grid;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  place-content: center;
  align-items: center;
`;

export const ThemeNotFound = () => {
  return (
    <ThemeNotFoundDiv>
      <Container header={<Header variant="h2">Theme Bridge Not Found</Header>}>
        <Alert type={"error"}>
          This theme bridge does not seem to exist.
        </Alert>
      </Container>
    </ThemeNotFoundDiv>
  );
}