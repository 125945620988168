import {useCollection} from "@amzn/awsui-collection-hooks";
import {
    Alert,
    Button,
    CollectionPreferences,
    CollectionPreferencesProps,
    Pagination,
    SpaceBetween,
    TextFilter
} from "@amzn/awsui-components-react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Header from "@amzn/awsui-components-react/polaris/header";
import Table from "@amzn/awsui-components-react/polaris/table";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getJobGroups} from "src/actions/mdxLibrary.actions";
import {MDXGroupSelectionProps,} from "src/components/CreateReportPage/interfaces";
import {ExpandedGroup, MDXJob, MDXTableRow} from "src/components/MDXLibraryPage/interfaces";
import Status from "src/components/MDXLibraryPage/JobStatus";
import {EmptyTableState} from "src/components/MDXLibraryPage/MDXLibraryTable";
import {EmptyStateProps} from "src/components/ReportLibraryPage/ReportLibraryTable";
import {getJobGroupsApiLoading} from "src/reducers/apiLoading.reducer";
import {getGroups, getPaginationConfig, LibraryGroup} from "src/reducers/mdxLibrary.reducer";
import {sortTableData} from "src/utils/mdxLibraryHelpers";
import {humanizeEpoch} from "src/utils/timeHelpers";

const MDXGroupSelection = ({reportWorkflow, selectedMdxGroup, setSelectedMdxGroup, reportName, errorText, setStepErrors} : MDXGroupSelectionProps) => {
    const dispatch = useDispatch();
    const maxJobGroups = 25;
    const {paginationKey, hasMore} = useSelector(getPaginationConfig);
    const tableLoading: boolean = useSelector(getJobGroupsApiLoading)
    const groups = useSelector(getGroups);
    const [selectedItems, setSelectedItems] = useState<MDXTableRow[]>([]);

    // Use effect to fetch initial data for mdx group selection in redux store
    useEffect(function initializeMDXGroupSelection() {
        dispatch(getJobGroups({limit: maxJobGroups}));
    }, []);

    const sortedJobGroups = sortTableData(groups, "last_refreshed", false);

    // Column definition for MDX query library table
    const columnDefinitions = useMemo(() => {
        return [
            {
                id: "group",
                label: "MDX query group / TM1 instance",
                header: "MDX query group / TM1 instance",
                cell: (e: MDXTableRow) => e.instance || e.group,  // Try to use instance name first, fallback to group name
            },
            {
                id: "cube",
                label: "TM1 cube",
                header: "TM1 cube",
                cell: (e: MDXTableRow) => e.cube,
            },
            {
                id: "last_refreshed",
                label: "Last refresh",
                header: "Last refreshed",
                cell: (e: MDXTableRow) => e.last_refreshed,
            },
            {
                id: "status",
                label: "Status",
                header: "Status",
                cell: (e: MDXTableRow) => e.status
            },
        ];
    }, []);

    const [expandedGroups, setExpandedGroups] = useState<ExpandedGroup>({});

    const groupTreeViewControl = (groupName: string) => {
        const groupIsExpanded = expandedGroups[groupName];
        setExpandedGroups({...expandedGroups, [groupName]: !groupIsExpanded});
    };

    // General helper function to construct rows of mdx query table
    function constructTableRows(tableData : {[p:string]: LibraryGroup}) : MDXTableRow[] {
        const rows : MDXTableRow[] = [];

        Object.keys(tableData).forEach(groupName => {
            // determine the group expansion state icon name
            const iconName = expandedGroups[groupName] ? "treeview-collapse" : "treeview-expand";

            // add the group name first
            const row = {
                job_id: groupName,
                group: (
                    <>
                        <Button iconName={iconName} variant="icon" onClick={() => groupTreeViewControl(groupName)}/>
                        {groupName}
                    </>
                ),
                action: <></>,
                isGroup: true,
            };
            rows.push(row);
            // add the sub rows of the group if it is in expanded state
            if (expandedGroups[groupName]){
                tableData[groupName].jobs.forEach((row : MDXJob) => rows.push(constructJobRow(row)));
            }
            // set this group as selected group if the name matches
            selectedItems.length === 0 && groupName === selectedMdxGroup && setSelectedItems([row]);
        });
        return rows;
    }

    const tableRows = constructTableRows(sortedJobGroups);
    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: 50,
        visibleContent: columnDefinitions.map((obj) => obj.id)
    });

    const { items, collectionProps, filterProps, paginationProps } = useCollection(
        tableRows,
        {
            filtering: {
                empty: <EmptyState title="No instances" subtitle="No instances to display."/>,
                noMatch: <EmptyState title="No matches" subtitle="We can’t find a match."/>
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {},
        }
    );

    if (!tableLoading && hasMore && paginationProps.pagesCount === 1) {
        dispatch(getJobGroups({paginationKey, limit: maxJobGroups}))
    }

    return(
        <SpaceBetween size="s" direction="vertical">
            {errorText.length > 0 &&
                <Alert type="error">
                    {errorText}
                </Alert>
            }
            <Table
                {...collectionProps}
                onSelectionChange={({ detail }) =>{
                    errorText && setStepErrors((stepErrors) => {return {...stepErrors, 2: ""}});
                    setSelectedItems(detail.selectedItems);
                    setSelectedMdxGroup(detail.selectedItems[0].job_id);
                }}
                selectedItems={selectedItems}
                columnDefinitions={columnDefinitions}
                items={items}
                isItemDisabled={item => (!item.isGroup ?? true) || (!groups[item.job_id].valid)}
                loading={tableLoading}
                loadingText="Loading resources"
                selectionType="single"
                trackBy="job_id"
                empty={<EmptyTableState/>}
                header={
                    <Header description="Please select the MDX groups that you would like to inform your report">
                        {`${reportWorkflow} report: ${reportName}`}
                    </Header>
                }
                filter={
                    <TextFilter
                        {...filterProps}
                        filteringAriaLabel="Filter instances"
                        filteringPlaceholder="Search Group name"
                    />
                }
                visibleColumns={preferences.visibleContent}
                preferences={
                    <CollectionPreferences
                        onConfirm={({ detail }) => setPreferences(detail)}
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        preferences={preferences}
                        pageSizePreference={{
                            title: "Select page size",
                            options: [
                                {value: 10, label: "10 items"},
                                {value: 20, label: "20 items"},
                                {value: 30, label: "30 items"},
                                {value: 40, label: "40 items"},
                                {value: 50, label: "50 items"}
                            ]
                        }}
                        visibleContentPreference={{
                            title: "Select visible content",
                            options: [
                                {
                                    label: "MDX group selection columns",
                                    options: columnDefinitions
                                }
                            ]
                        }}
                    />
                }
                pagination={
                    <Pagination {...paginationProps}
                                onChange={(event) => {
                                    if (event.detail.currentPageIndex === paginationProps.pagesCount && hasMore) {
                                        dispatch(getJobGroups({paginationKey, limit: maxJobGroups}))
                                    }
                                    paginationProps.onChange(event);
                                }}
                    />
                }
            />
        </SpaceBetween>
    );
};

export default MDXGroupSelection;


export function EmptyState({ title, subtitle }: EmptyStateProps) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                {subtitle}
            </Box>
        </Box>
    );
}


// Helper function to construct jobs row of given group
function constructJobRow(row : MDXJob) : MDXTableRow {
    return {
        ...row,
        group: <>{row.group}</>,
        instance: <span style={{paddingLeft: "28px"}}>{row.instance}</span>,
        status: <Status status={row.status} lastRefreshed={row.last_refreshed}/>,
        last_refreshed: humanizeEpoch({epoch: row.last_refreshed, asDate: false}),
        action: <></>
    };
}
