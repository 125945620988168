import {BridgeItem, BridgeStatusType} from "src/components/BridgingCommentary/interfaces";
import {
    AdminTabStatus,
    bridgeTabType,
    OwnerTabStatus
} from "src/components/BridgingCommentary/summaryComponents/interfaces";

import {getEpoch} from "./timeHelpers";


export function filterStatus (items : BridgeItem[], filterStates : string, bridgeStates: bridgeTabType){
    const filteredItems : BridgeItem[] = [];
    if (filterStates == "All"){
        return items;
    }

    if (filterStates == "Past Due"){
        items.forEach(item => {
            const pastDue : boolean = getEpoch()>= item.due_timestamp;
            if(pastDue && bridgeStates[filterStates].bridgeStatusType.includes(item.status)){
                filteredItems.push(item)
            }
        })
        return filteredItems;
    }

    items.forEach(item =>{
        //Check which tab states should the current item status fall under
        const inState : boolean = bridgeStates[filterStates].bridgeStatusType.includes((item.status));

        //States other than Requested can be past due but still included in the tabs.
        const pastDueMatter : boolean = bridgeStates[filterStates].pastDue

        const pastDue : boolean = getEpoch() >= item.due_timestamp;

        if(inState && (!pastDueMatter || !pastDue)){
            filteredItems.push(item)
        }
    })
    return filteredItems
}

export function countTotalBridges(bridgeItems : BridgeItem[]) : number{
    return bridgeItems.length;
}

export function areBridgesPublished(bridgeItems : BridgeItem[]) : boolean {
    const publishedBridge = bridgeItems.filter(item => item.status == BridgeStatusType.Published)
    return publishedBridge.length == bridgeItems.length
}


export function countBridgesWithStatus(bridgeItems: BridgeItem[], bridgeStatus : AdminTabStatus | OwnerTabStatus, bridgeTabs : any): number{
    return filterStatus(bridgeItems, bridgeStatus, bridgeTabs).length;
}
