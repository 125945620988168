import {BridgeItem} from "src/components/BridgingCommentary/interfaces";

export function getBridgeOwnerGroups( bridgeItems: BridgeItem[]) {
	const updatedBridgeOwnerGroup: { [p: string]: string } = {}
	bridgeItems.map(originalItem => {
		const ownerGroup = originalItem.bridge_owner_group
		if (ownerGroup) {
			updatedBridgeOwnerGroup[originalItem.account_rollup] = ownerGroup
		}
	})
	return updatedBridgeOwnerGroup
}

/**
 * Function to combine two bridge item list. Items from second parameter will be used in case of duplicates
 * @param originalBridgeItems
 * @param newBridgeItems
 */
export function combineBridgeItemLists(originalBridgeItems: BridgeItem[], newBridgeItems: BridgeItem[]) {
	const bridgeIdToBridge: {[p: string]: BridgeItem}= {}
	const combinedBridgeItems: BridgeItem[] = [];

	originalBridgeItems.forEach(item => bridgeIdToBridge[item.bridge_id] = item);
	newBridgeItems.forEach(item => bridgeIdToBridge[item.bridge_id] = item);

	Object.entries(bridgeIdToBridge).forEach(([, bridge]) => {
		combinedBridgeItems.push(bridge);
	});

	return combinedBridgeItems;
}

/**
 * Function to encode string to Base64-encoded ASCII string
 * @param input
 */
export const encodeBinaryString = (input: string) => btoa(input);

/**
 * Function to decode Base64-encoded ASCII string to binary string
 * @param input
 */
export const decodeBinaryString = (input: string) => atob(input);

/**
 * Function to construct bridgeId
 * @param reportId
 * @param accountRollUpName
 * @param rowId 
 * @param varianceHeaderName
 * @param varianceHeaderColumnPosition
 */
export function constructBridgeId(
	reportId: string,
	accountRollUpName: string,
	rowId: string,
	varianceHeaderName: string,
	varianceHeaderColumnPosition: number
){
	return reportId + "." + encodeBinaryString(accountRollUpName) + "." + rowId
	+ "." + encodeBinaryString(varianceHeaderName) + "." + varianceHeaderColumnPosition
}

export const countWords = (text: string) => text.split(" ").filter(token => !!token).length

export const countCharacters = (text: string) => text.split("").filter(token => !!token).length

export const getLinkToBridge = (bridgeId: string, href: string) => {
	const url = href.split("/#")[0];
	return `${url}/#/fillout-bridge?bridge_id=${bridgeId}`;
}

export const formatValue = (value: string | number, currencyValue = true, dollarSign = false) => {
	const decimalPoints = currencyValue ? 1 : 0;
	const valueFormatter = Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: decimalPoints,
		maximumFractionDigits: decimalPoints,
		currencySign: "accounting",
	});
	const formattedValue = valueFormatter.format(Number(value));
	return dollarSign && currencyValue ? formattedValue : formattedValue.replace("$", "");
}

export const roundValue = (value: number, decimalPlaces: number) => {
	const p = Math.pow(10, decimalPlaces);
	return Math.round(value * p) / p;
}