import * as React from "react";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useSearchParams } from "react-router-dom";
import {getMarkers} from "src/actions/bridgingCommentary.actions";
import {getReport, getReportDefinition, getReportSynchronizationState} from "src/actions/reportLibrary.actions";
import {ReportNotFound} from "src/components/ViewReportPage/ReportNotFound";
import ViewReportContainer from "src/components/ViewReportPage/ViewReportContainer";
import {getReportLibraryStatus} from "src/reducers/reportLibrary.reducer";
import {getAssumedRole} from "src/reducers/user.reducer";

export const ViewReportPage = () => {
    const dispatch = useDispatch();
    const currentAssumedRole = useSelector(getAssumedRole);
    const reportLibraryStatus = useSelector(getReportLibraryStatus);
    const [searchParams] = useSearchParams();
    const reportId = searchParams.get('report_id')

    useEffect(() => {
        if(reportId) {
            dispatch(getReportDefinition(reportId));
            dispatch(getReport(reportId));
            dispatch(getReportSynchronizationState(reportId));
            currentAssumedRole && dispatch(getMarkers({report_id: reportId, role: currentAssumedRole}));
        }
    }, [reportId]);

    const reportExists = reportId && reportLibraryStatus.errorType !== "DynamoDBException" && reportLibraryStatus.errorType !== "MissingParams"
    return reportExists ? <ViewReportContainer reportLibraryStatus={reportLibraryStatus}/> : <ReportNotFound />
}

export default ViewReportPage;
