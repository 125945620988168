import Wizard from "@amzn/awsui-components-react/polaris/wizard";
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RESET_FILE_TRANSFER} from "src/actions/fileTransfer.actions";
import {postReportDefinition, resetReportLibraryStatus} from "src/actions/reportLibrary.actions";
import {ItemType, Libraries, ReportStatus} from "src/common/report";
import {Roles} from "src/common/roles";
import ConfigureColumns from "src/components/CreateReportPage/ConfigureColumns";
import {StylesheetDefaultFileNameKeys} from "src/components/CreateReportPage/constants";
import {Column, ColumnType, StepErrors, Stylesheet} from "src/components/CreateReportPage/interfaces";
import MDXGroupSelection from "src/components/CreateReportPage/MDXGroupSelection";
import StylesheetUpload from "src/components/CreateReportPage/StylesheetUpload";
import Paths from "src/components/PageConfig/Paths";
import {getReportApiLoading, getReportDefinitionsApiLoading} from "src/reducers/apiLoading.reducer";
import {getReportLibraryStatus, getUpdatingReport} from "src/reducers/reportLibrary.reducer";
import {getUser} from "src/reducers/user.reducer";
import {getInitialStageStatus} from "src/utils/reportHelpers";
import {getEpoch} from "src/utils/timeHelpers";
import {v4 as uuid} from "uuid";

import ReportNameAndType, {ReportNameAndTypeDescription} from "./ReportNameAndType";

const CreateReportPage = () => {
    const dispatch = useDispatch();
    // Selectors
    const userName = useSelector(getUser);
    const reportDefinitionsApiIsLoading = useSelector(getReportDefinitionsApiLoading);
    const reportApiIsLoading = useSelector(getReportApiLoading);
    const createReportProps = useSelector(getUpdatingReport);  // see if this edit/duplicate report workflow
    const {startedReportComputation, errorMessage, errorType} = useSelector(getReportLibraryStatus);
    // Constants
    const reportWorkflow = createReportProps.report_status === ReportStatus.UPDATING ? "Edit" : "Create";
    // Instantiate reportId
    const reportId = useRef(createReportProps.report_id || `report_id.${uuid()}`).current;
    // General page states
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [stepErrors, setStepErrors] = useState<StepErrors>({1: {}, 2: "", 3: ""});
    // States for step 1
    const [reportDescription, setReportDescription] = useState<ReportNameAndTypeDescription>({
        reportName: createReportProps.name_and_type?.report_name ?? "",
        reportType: createReportProps.name_and_type?.report_type ?? "",
        financeCycle: createReportProps.name_and_type?.finance_cycle ?? "",
        year: createReportProps.name_and_type?.year ?? "",
        period: createReportProps.name_and_type?.period ?? "",
    });
    // States for step 2
    const [selectedMdxGroup, setSelectedMdxGroup] = useState<string>(createReportProps.jobs_selection?.group_id ?? "");
    // States for step 3
    const [columns, setColumns] = useState<Column[]>(createReportProps.configure_columns ?? []);
    // States for step 4
    const [stylesheet, setStylesheet] = useState<Stylesheet>(createReportProps.stylesheet ?? {
        fileName: "",
        fileSize: 0,
        fileLastUpdated: "",
        reportId: reportId
    });

    const reportNameAndTypeStep = {
        title: "Report name & type",
        content: (
            <ReportNameAndType
                reportWorkflow={reportWorkflow}
                reportDescription={reportDescription}
                setReportDescription={setReportDescription}
                errorTexts={stepErrors["1"]}
                setStepErrors={setStepErrors}
            />
        )
    };

    //
    const mdxGroupSelectionStep = {
        title: "MDX group selection",
        content: (
            <MDXGroupSelection
                reportWorkflow={reportWorkflow}
                reportName={reportDescription.reportName}
                selectedMdxGroup={selectedMdxGroup}
                setSelectedMdxGroup={setSelectedMdxGroup}
                errorText={stepErrors["2"]}
                setStepErrors={setStepErrors}
            />
        )
    };

    const configureColumnsStep = {
        title: "Configure Columns",
        content: (
            <ConfigureColumns
                reportWorkflow={reportWorkflow}
                reportName={reportDescription.reportName}
                columns={columns}
                setColumns={setColumns}
                selectedMdxGroup={selectedMdxGroup}
                errorText={stepErrors["3"]}
                setStepErrors={setStepErrors}
            />
        )
    };

    const stylesheetUploadStep = {
        title: "Stylesheet file",
        content: (
           <StylesheetUpload
               reportWorkflow={reportWorkflow}
               reportName={reportDescription.reportName}
               reportType={reportDescription.reportType as StylesheetDefaultFileNameKeys}
               stylesheet={stylesheet}
               setStylesheet={setStylesheet}
               errorText={errorType ? `${errorType}: ${errorMessage}` : ""}
           />
        )
    };

    useEffect(function resetArtifacts(){
        dispatch(resetReportLibraryStatus());
        dispatch({type: RESET_FILE_TRANSFER, payload: {}});
    }, [])
    // Use effect to check api call status and close the wizard if api call was successful
    useEffect(function checkAPICallStatus() {
        // if start computation api call succeeded, close the wizard
        startedReportComputation && closeWizard(`#${Paths.ACTIVEREPORTS}`);
    }, [startedReportComputation]);

    const validateAndNavigate = (currentIndex: number, requestedIndex: number) => {
        // If navigating back and then no need to validate
        if (requestedIndex < currentIndex){
            (errorType) && dispatch(resetReportLibraryStatus());
            setActiveStepIndex(requestedIndex);
        } else if(currentIndex === 0){  // reportNameAndType step
            const {errors, navigationAllowed} = validateReportNameAndType(reportDescription);
            navigationAllowed ? setActiveStepIndex(requestedIndex) : setStepErrors({...stepErrors, 1: errors});
        } else if(currentIndex === 1){  // group selection step
            selectedMdxGroup !== "" ? setActiveStepIndex(requestedIndex)
                : setStepErrors({...stepErrors, 2: "One of the job group must be selected"});
        } else if (currentIndex === 2) { // configure column step
            const {errorMessage, navigationAllowed} = validateConfigureColumns([...columns]);
            navigationAllowed ? setActiveStepIndex(requestedIndex) : setStepErrors({...stepErrors, 3: errorMessage});
        } else {
            setActiveStepIndex(requestedIndex) ;
        }
    }

    const closeWizard = (href: string) => {
        dispatch(resetReportLibraryStatus());
        window.location.href = href;
    }

    const onCreateClick = () => {
       const updatedStylesheet = stylesheet.fileName ? {...stylesheet} : {...stylesheet, fileName: "default.xlsx"};
       //If error from stylesheet validation exists don't allow Admin to submit report definition.
       !errorType && dispatch(postReportDefinition({
            report_id: reportId,
            report_status: createReportProps.report_status ?? ReportStatus.CREATING,
            report_library: Libraries.Active,
            report_role: Roles.Admin,
            created_at: createReportProps.created_at ?? getEpoch(),
            created_by: createReportProps.created_by ?? userName,
            last_modified: getEpoch(),
            last_modified_by: userName,
            table_last_synced: [getEpoch()],
            name_and_type: {
                report_name: reportDescription.reportName,
                report_type: reportDescription.reportType,
                finance_cycle: reportDescription.financeCycle,
                year: reportDescription.year,
                period: reportDescription.period,
            },
            span_titles: getSpanTitles(createReportProps.span_titles || [], columns),
            stage_status: getInitialStageStatus(),
            jobs_selection: {group_id: selectedMdxGroup},
            configure_columns: columns,
            stylesheet: updatedStylesheet,
            item_type: ItemType.Internal,
           triggered_event: createReportProps.triggered_event
        }));
    }

    return (
        <Wizard
            i18nStrings={{
                stepNumberLabel: stepNumber =>
                    `Step ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) =>
                    `Step ${stepNumber} of ${stepsCount}`,
                cancelButton: "Cancel",
                previousButton: "Previous",
                nextButton: "Next",
                submitButton: reportWorkflow === "Create" ? "Create" : "Update",
            }}
            onNavigate={({detail}) => validateAndNavigate(activeStepIndex, detail.requestedStepIndex)}
            activeStepIndex={activeStepIndex}
            isLoadingNextStep={reportApiIsLoading || reportDefinitionsApiIsLoading}
            steps={[
                reportNameAndTypeStep,
                mdxGroupSelectionStep,
                configureColumnsStep,
                stylesheetUploadStep
            ]}
            onCancel={() => closeWizard(`#${Paths.REFERENCEREPORTS}`)}
            onSubmit={() => onCreateClick()}
        />
    );
}

export default CreateReportPage;


export function validateReportNameAndType(reportDescription: ReportNameAndTypeDescription) {
    const {reportName, reportType, financeCycle, year, period} = reportDescription
    const errors: {[p:string]: string} = {}
    const currentValues = {reportName, reportType, financeCycle, year, period}
    Object.entries(currentValues).forEach(([key, value]) => {
        if (!value.length){
            errors[key] = "This field can not be empty";
        }
    });
    return {errors, navigationAllowed: Object.keys(errors).length === 0}
}


export function validateConfigureColumns(columns: Column[]) {
    const sortedColumns = columns.sort((col1, col2) =>
        col1.column_position - col2.column_position
    );
    // ensure first and last column are not column breaks
    if (sortedColumns[0].column_type === ColumnType.ColumnBreak ||
        sortedColumns[sortedColumns.length - 1].column_type === ColumnType.ColumnBreak) {
        return {
            errorMessage: "First or last column can not be of type column break",
            navigationAllowed: false
        };
    }
    const seenColumnPositions = new Set<number>();
    const varianceKeys = new Set(["baseline_year", "baseline_scenario", "baseline_period"]);
    for (let i = 0 ; i < sortedColumns.length ; i++) {
        const column = sortedColumns[i];
        if (column.column_type === ColumnType.ColumnBreak) {
            continue;
        }
        const isVarianceColumn = column.column_type === ColumnType.Variance;
        const columnPosition = column.column_position;
        // First check if column position is unique
        if (seenColumnPositions.has(columnPosition)){
            return {
                errorMessage: `Duplicate column position. Multiple ${columnPosition} found`,
                navigationAllowed: false
            };
        }
        // Go through current column properties
        for(const [property, value] of Object.entries(column)){
            // for variance column all fields must have some value
            if (!value && isVarianceColumn){
                return {
                    errorMessage: `Field ${property} can not empty for column at position ${columnPosition}`,
                    navigationAllowed: false
                };
            } else if (!value && !isVarianceColumn && !varianceKeys.has(property)) {
                return {
                    errorMessage: `Field ${property} can not empty for column at position ${columnPosition}`,
                    navigationAllowed: false
                }
            }
        }
        if(isVarianceColumn && column.fx_impact) {
            for (const [property, value] of Object.entries(column.fx_impact)){
                if (!value){
                    return {
                        errorMessage: `Field ${property} for fx scenario can not empty for column at position ${columnPosition}`,
                        navigationAllowed: false
                    };
                }
            }
        }
        seenColumnPositions.add(columnPosition);
    }
    return {navigationAllowed: true, errorMessage: ""};
}


export function getSpanTitles(originalSpanTitles: Array<string>, columns: Column[]): Array<string> {
    const spanTitles = new Array<string>(originalSpanTitles[0] || "");
    let index = 1;
    columns.forEach((column: Column) => {
        if(column.column_type === ColumnType.ColumnBreak) {
            // Try to get span title for this group from old list or use "" by default
            spanTitles.push(originalSpanTitles[index++] || "");
        }
    });
    return spanTitles
}