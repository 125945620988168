import {APILoadingAction, RESET_LOADING, SET_LOADING} from "src/actions/apiLoading.actions";
import {
    BRIDGE,
    BRIDGE_BEING_VIEWED_CURRENTLY,
    BRIDGES, BRIDGES_BEING_VIEWED_CURRENTLY,
    CHANGE_BRIDGE_STATE,
    COMMENTS, COMMENTS_FOR_VARIANCE_COLUMN,
    REPORT_BRIDGES
} from "src/actions/bridgingCommentary.actions";
import {FILE_TRANSFER} from "src/actions/fileTransfer.actions";
import {JOB, JOB_GROUP, JOB_GROUPS} from "src/actions/mdxLibrary.actions";
import {
    BRIDGE_SYNC,
    REPORT,
    REPORT_DEFINITIONS,
    REPORT_EXPORT,
    REPORT_LIBRARY,
    REPORT_PUBLISHER,
    REVIEW_FOLDERS,
    SPAN_TITLES
} from "src/actions/reportLibrary.actions";
import {AppState} from "src/reducers/AppState";

export interface APILoadingState {
    [key: string]: boolean
}


const initState: APILoadingState = {};


export const apiLoadingReducer = (loaders = initState, action: APILoadingAction): APILoadingState => {
    const {type, payload} = action;
    if (payload) {
        const {entity, loading} = payload;
        switch (type) {
            case SET_LOADING:
                return {...loaders, [entity]: loading};
            case RESET_LOADING:
                return {...loaders, [entity]: false};
            default:
                return loaders
        }
    }
    return loaders
};


export const getJobGroupsApiLoading = (state: AppState) => state.apiLoading[JOB_GROUPS];
export const getJobGroupApiLoading = (state: AppState) => state.apiLoading[JOB_GROUP];
export const getJobApiLoading = (state: AppState) => state.apiLoading[JOB];
export const getReportDefinitionsApiLoading = (state: AppState) => state.apiLoading[REPORT_DEFINITIONS];
export const getReportPublisherApiLoading = (state: AppState) => state.apiLoading[REPORT_PUBLISHER];
export const getReportApiLoading = (state: AppState) => state.apiLoading[REPORT];
export const getBridgeSyncApiLoading = (state: AppState) => state.apiLoading[BRIDGE_SYNC];
export const getReportLibraryApiLoading = (state: AppState) => state.apiLoading[REPORT_LIBRARY];
export const getSpanTitleApiLoading = (state: AppState) => state.apiLoading[SPAN_TITLES];
export const getFileTransferApiLoading = (state: AppState) => state.apiLoading[FILE_TRANSFER];
export const getReportExportApiLoading = (state: AppState) => state.apiLoading[REPORT_EXPORT];
export const getReviewFolderApiLoading = (state: AppState) => state.apiLoading[REVIEW_FOLDERS];
export const getBridgesApiLoading = (state: AppState) => state.apiLoading[BRIDGES];
export const getBridgeApiLoading = (state: AppState) => state.apiLoading[BRIDGE];
export const getReportBridgesApiLoading = (state: AppState) => state.apiLoading[REPORT_BRIDGES];
export const getChangeStateApiLoading = (state: AppState) => state.apiLoading[CHANGE_BRIDGE_STATE];
export const getCommentsApiLoading = (state: AppState) => state.apiLoading[COMMENTS];
export const getBridgeBeingViewedCurrentlyLoading = (state: AppState) => state.apiLoading[BRIDGE_BEING_VIEWED_CURRENTLY];
export const getBridgesBeingViewedCurrentlyLoading = (state: AppState) => state.apiLoading[BRIDGES_BEING_VIEWED_CURRENTLY];
export const getCommentsForVarianceColumnLoading = (state: AppState) => state.apiLoading[COMMENTS_FOR_VARIANCE_COLUMN];
export const getThemesApiLoading = (state: AppState) => state.apiLoading['THEMES']; // TODO why does importing THEMES error?
