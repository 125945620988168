import Wizard from "@amzn/awsui-components-react/polaris/wizard";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {setUpdatingReviewFolderDefinitions} from "src/actions/reportLibrary.actions";
import {ReportDefinition} from "src/common/report";
import ConfirmationModal from "src/components/CreateReviewPage/ConfirmationModal";
import FolderDescriptionForm from "src/components/CreateReviewPage/FolderDescriptionForm";
import {FolderDescription, FolderWorkflow, StepErrors} from "src/components/CreateReviewPage/interfaces";
import ReportsSelection from "src/components/CreateReviewPage/ReportsSelection";
import Paths from "src/components/PageConfig/Paths";
import {constructTableRow} from "src/components/ReportLibraryPage/ReportLibraryTable";
import {ReportTableRowItem} from "src/components/ReportLibraryPage/ReportTableConfig";
import {getReports, getUpdatingReviewFolder} from "src/reducers/reportLibrary.reducer";

const CreateReviewPage = () => {
    const reviewFolderDefinitions = useSelector(getUpdatingReviewFolder);

    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [stepErrors, setStepErrors] = useState<StepErrors>({1: {}, 2: ""});
    const [confirmationModalVisible, setConfirmationModalVisible] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<ReportTableRowItem[]>([]);
    const tableData = useSelector(getReports)

    useEffect(() => {
        if(tableData.length && reviewFolderDefinitions.report_ids) {
            const initialSelectedRows: ReportTableRowItem[] = [];
            const reportMap: {[p: string]: ReportDefinition} =  {}
            tableData.forEach(reportState => {
                reportMap[reportState.report_id] = {...reportState}
            })
            reviewFolderDefinitions.report_ids.forEach(report_id => {
                initialSelectedRows.push(constructTableRow(
                    reportMap[report_id],
                    reviewFolderDefinitions.report_id,
                    {},
                    "",
                    false
                ));
            });
            setSelectedItems(initialSelectedRows);
        }
    }, [reviewFolderDefinitions, tableData]);


    const [folderDescription, setFolderDescription] = useState<FolderDescription>({
        folderName: reviewFolderDefinitions.name_and_type?.report_name ?? "",
        reviewDate: reviewFolderDefinitions.review_date ?? "",
        attendees: reviewFolderDefinitions.attendees ? reviewFolderDefinitions.attendees.join(",") : "",
        financeCycle: reviewFolderDefinitions.name_and_type?.finance_cycle ?? "",
        year: reviewFolderDefinitions.name_and_type?.year ?? "",
        period: reviewFolderDefinitions.name_and_type?.period ?? "",
    })
    const folderWorkflow: FolderWorkflow = reviewFolderDefinitions.report_status === "Updating" ? "Edit" : "Create";

    const reviewFolderDescription = {
        title: "Folder name",
        content: (
            <FolderDescriptionForm
                folderWorkflow={folderWorkflow}
                folderDescription={folderDescription}
                setFolderDescription={setFolderDescription}
                errorTexts={stepErrors["1"]}
                setStepErrors={setStepErrors}
            />
        )
    };

    const reportSelection = {
        title: "Report selection",
        content: (
            <ReportsSelection
                folderWorkflow={folderWorkflow}
                folderName={folderDescription.folderName}
                reviewFolderDefinitions={reviewFolderDefinitions}
                errorText={stepErrors["2"]}
                setStepErrors={setStepErrors}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
            />
        )
    };

    const validateAndNavigate = (currentIndex: number, requestedIndex: number) => {
        if (requestedIndex < currentIndex) {
            setActiveStepIndex(requestedIndex);
        } else if(currentIndex === 0) {
            const {errors, navigationAllowed} = validateReviewFolderDescription(folderDescription);
            navigationAllowed ? setActiveStepIndex(requestedIndex) : setStepErrors({...stepErrors, 1: errors});
        } else if (currentIndex === 1) {
            // todo - verify if its an edit flow, if its edit flow + has elements skip this step
            selectedItems.length > 0 ? setActiveStepIndex(requestedIndex) :
                setStepErrors({...stepErrors, 2: "At least one report must be selected"});
        } else {
            setActiveStepIndex(requestedIndex) ;
        }
    }

    const confirmationModal = ConfirmationModal(
        confirmationModalVisible,
        setConfirmationModalVisible,
        folderDescription,
        selectedItems,
        tableData,
        reviewFolderDefinitions
    );

    return (
        <>
            {confirmationModal}
            <Wizard
                i18nStrings={{
                    stepNumberLabel: stepNumber =>
                        `Step ${stepNumber}`,
                    collapsedStepsLabel: (stepNumber, stepsCount) =>
                        `Step ${stepNumber} of ${stepsCount}`,
                    cancelButton: "Cancel",
                    previousButton: "Previous",
                    nextButton: "Next",
                    submitButton: folderWorkflow === "Create" ? "Create" : "Update",
                }}
                onNavigate={({detail}) => validateAndNavigate(activeStepIndex, detail.requestedStepIndex)}
                activeStepIndex={activeStepIndex}
                isLoadingNextStep={false}
                steps={[
                    reviewFolderDescription,
                    reportSelection
                ]}
                onCancel={() => {
                    setUpdatingReviewFolderDefinitions({report_id: ""})
                    window.location.replace(`#${Paths.ACTIVEREPORTS}`);
                }}
                onSubmit={() => {
                    selectedItems.length > 0 ? setConfirmationModalVisible(true) :
                        setStepErrors({...stepErrors, 2: "At least one report must be selected"});
                }}
            />
        </>
    );
}

export default CreateReviewPage;

export function validateReviewFolderDescription(folderDescription: FolderDescription) {
    const {folderName, reviewDate, financeCycle, year, period} = folderDescription
    const errors: {[p:string]: string} = {}
    const currentValues = {folderName, reviewDate, financeCycle, year, period}
    Object.entries(currentValues).forEach(([key, value]) => {
        if (!value.length){
            errors[key] = "This field can not be empty";
        }
    });
    return {errors, navigationAllowed: Object.keys(errors).length === 0}
}