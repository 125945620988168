/* eslint-disable max-lines-per-function */
import {Wizard } from "@amzn/awsui-components-react"
import React, {useState } from "react"
import { Provider, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import {Roles} from "src/common/roles";
import Paths from "src/components/PageConfig/Paths";
import {getAssumedRole, getUser} from "src/reducers/user.reducer";

import {useCreateWorkflowsMutation, useExecuteFacetMutation} from "../apiSlice";
import {DUMMY_FACET_REQUEST} from "../constants/dummyFacetRequest"
import { CreateWorkflowRequest,ExecuteFacetRequest, TriggerType} from "../interfaces/facetParams"
import { isErrorWithMessage, isFetchBaseQueryError } from "../rtkQueryErrorHelpers";
import workflowStore from "../stores/workflowStore";
import { configureFacet } from "./configureFacet";
import { configureParams } from "./configureParams";
import { validateFacetParams } from "./helpers";

export const WorkflowWizard = ({userName, role} : {userName : any, role: Roles| null}) => {

  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const navigate = useNavigate()

  //use React State for now, gonna move to a redux state later. 
  //On submit, redirect us to a different page to view report 
  const [facetRequest, setFacetRequest] = useState<ExecuteFacetRequest>(DUMMY_FACET_REQUEST(role))
  const [errorText, setErrorText ] = useState<string>('')

  const [executeFacet, {isLoading : isLoadingMutation}] = useExecuteFacetMutation()

  const [createWorkflows] = useCreateWorkflowsMutation()

  const facetParams = {
    facetRequest : facetRequest,
    setFacetRequest : setFacetRequest,
    errorText : errorText
  }
  

  const [workflow, setWorkflow] = useState<CreateWorkflowRequest>(
    {
      input_type : "facet",
      input_id : "",
      input_version : "",
      workflow_name : "",
      description: "test",
      created_by: userName,
      triggered_by : userName,
      trigger_type : TriggerType.USER,
        role : role
    })

  //add is loading next step to a disabled spinner 
  return (
      <Wizard
      isLoadingNextStep = {isLoadingMutation}
      i18nStrings={{
          stepNumberLabel: stepNumber =>
            `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepsCount) =>
            `Step ${stepNumber} of ${stepsCount}`,
          skipToButtonLabel: (step) =>
            `Skip to ${step.title}`,
          navigationAriaLabel: "Steps",
          cancelButton: "Cancel",
          previousButton: "Previous",
          nextButton: "Next",
          submitButton: "Create Report",
          optional: "optional"
        }}
        onSubmit={async () => {
          await executeFacet({...facetRequest, created_by : userName}).unwrap().then(async facetExecutionRes =>
             {
              const workflowRequest : CreateWorkflowRequest ={...workflow, input_version: facetExecutionRes.execution_id, input_id:facetExecutionRes.facet_id}
              await createWorkflows(workflowRequest).unwrap().then(
                workflowRes =>{
                  const path = generatePath(`${Paths.VIEWCUSTOMREPORT}`, { workflow_id : workflowRes.workflow_id,
                      execution_id : workflowRes.execution_id})
                  navigate(path)
                }
              ).catch((error) => setErrorText(parseErrorMessage(error)))
             }).catch((error) => setErrorText(parseErrorMessage(error)))
        }}
        onNavigate={({ detail }) =>
        {
          setErrorText("")
          if(validateFacetParams(facetRequest)){
          setActiveStepIndex(detail.requestedStepIndex)
        }
        else{
          setErrorText('Please Select Continuous Periods')
        }
        }
      }
      activeStepIndex={activeStepIndex}
      allowSkipTo
      steps = {
          [configureFacet(facetParams, {workflow,setWorkflow}),
          configureParams(facetParams)]
      }
        />
    )    
}
export const createWorkflow = () => {
  const userName = useSelector(getUser)
    const role = useSelector(getAssumedRole)

  return <Provider store={workflowStore}>
    <WorkflowWizard {...{
        userName,
        role}
    }></WorkflowWizard>
    </Provider>
}

export function parseErrorMessage(
  error: any
){
  if(isFetchBaseQueryError(error) && isErrorWithMessage(error.data)){
    console.log(error)
    switch(error.data.message){
      case 'ValidationError':
        return error.data.reason
    }
  }
  return 'Failed to generate report'
}