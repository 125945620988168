import React from "react";
import DeleteBridgeModal from "src/components/BridgingCommentaryPage/DeleteBridgeModal";
import {AdminModalProps} from "src/components/ReportBridgingPage/interfaces";
import ReportBridgeDetailsModal from "src/components/ReportBridgingPage/ReportBridgeDetailsModal";
import UpdateDatesModal from "src/components/ReportBridgingPage/UpdateDatesModal";
import {CreateThemeModal} from "src/components/Themes/reportBridgingIntegration/CreateThemeModal";

export const adminModals = (props : AdminModalProps) => {
    return(
        <>
            {adminActions(props)}
            {adminDetails(props)}
            {adminUpdateDates(props)}
            {adminCreateTheme(props)}
        </>
    )
}
const adminActions = (props : AdminModalProps) => {
    const {reportId, deletingBridge, assumedRole,setDeleteModalVisible,
        deleteModalVisible, setDeletingBridge} = props;
    return ( deletingBridge && assumedRole &&
        <DeleteBridgeModal
            reportId={reportId}
            visible={deleteModalVisible}
            setVisible={setDeleteModalVisible}
            userRole={assumedRole}
            deletingBridge={deletingBridge}
            setDeletingBridge={setDeletingBridge}
        />)
}
const adminDetails = (props : AdminModalProps) => {
    const {focusedBridge, assumedRole,focusedModalVisible, setFocusedBridge, setFocusedModalVisible} = props
    return (focusedBridge && <ReportBridgeDetailsModal
        visible={focusedModalVisible}
        setVisible={setFocusedModalVisible}
        userRole={assumedRole}
        bridgeDetails={focusedBridge}
        setBridgeDetails={setFocusedBridge}
    />)}

const adminUpdateDates = (props : AdminModalProps) =>{
    const {updateDatesModalVisible,
        setUpdateDatesModalVisible,
        updatingBridges, setUpdatingBridges,
        focusedBridge, setFocusedBridge, assumedRole} = props;
    return (focusedBridge && updatingBridges && <UpdateDatesModal
        visible={updateDatesModalVisible}
        setVisible={setUpdateDatesModalVisible}
        userRole={assumedRole}
        primaryBridge={focusedBridge}
        setPrimaryBridge={setFocusedBridge}
        updatingBridges={updatingBridges}
        setUpdatingBridges={setUpdatingBridges}
    />)}

const adminCreateTheme = (props : AdminModalProps) =>{
    const {
        createThemeModalVisible,
        setCreateThemeModalVisible,
        setUpdatingBridges,
        updatingBridges: bridges,
        assumedRole,
        reportId,
        fastTheme
    } = props;
    return (bridges && <CreateThemeModal
        visible={createThemeModalVisible}
        setVisible={setCreateThemeModalVisible}
        userRole={assumedRole}
        setUpdatingBridges={setUpdatingBridges}
        bridges={bridges}
        reportId={reportId}
        fastTheme={fastTheme}
    />)}