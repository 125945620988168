import {Alert, Modal, SpaceBetween} from "@amzn/awsui-components-react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {resetLibraryStatus} from "src/actions/mdxLibrary.actions";
import {
    postReportDefinition,
    postReviewFolder,
    setUpdatingReviewFolderDefinitions
} from "src/actions/reportLibrary.actions";
import {
    ItemType,
    Libraries,
    ReportDefinition,
    ReportStatus,
    ReportTriggerEvent,
    ReviewFolderState
} from "src/common/report";
import {Roles} from "src/common/roles";
import {getReportStatus} from "src/components/ExternalReportPage/ConfirmationModal";
import Paths from "src/components/PageConfig/Paths";
import {ReportTableRowItem} from "src/components/ReportLibraryPage/ReportTableConfig";
import {
    getReportApiLoading,
    getReportDefinitionsApiLoading,
    getReviewFolderApiLoading
} from "src/reducers/apiLoading.reducer";
import {getReportLibraryStatus} from "src/reducers/reportLibrary.reducer";
import {getUser} from "src/reducers/user.reducer";
import {getEpoch} from "src/utils/timeHelpers";
import {v4 as uuid} from "uuid";

import {FolderDescription} from "./interfaces";


const ConfirmationModal = (
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    folderState: FolderDescription,
    selectedItems: ReportTableRowItem[],
    reports: ReportDefinition[],
    reviewFolderDefinitions: ReviewFolderState
) => {
    const workflow = reviewFolderDefinitions.report_id && reviewFolderDefinitions.report_status === ReportStatus.CREATING ?
    "Duplicate" : "NotDuplicate";

    const dispatch = useDispatch();
    const userName = useSelector(getUser);
    const folderApiLoading = useSelector(getReviewFolderApiLoading);
    const reportApiLoading = useSelector(getReportApiLoading);
    const reportDefinitionApiLoading= useSelector(getReportDefinitionsApiLoading);
    const {
        startedReportComputation,
        savedReportDefinitions,
        updatedReviewFolders,
        errorType,
        errorMessage
    } = useSelector(getReportLibraryStatus);

    useEffect(() => {

        const canDismiss = updatedReviewFolders &&
            (
                (workflow === "Duplicate" && (startedReportComputation || savedReportDefinitions))  // Duplicate workflow finished
                || (workflow !== "Duplicate")  // Create/Edit workflow finished
            )
        if(canDismiss){
            onModalDismiss();
            // Re-direct to active reports page
            setTimeout(() => {
                dispatch(setUpdatingReviewFolderDefinitions({report_id: ""}));
                window.location.replace(`#${Paths.ACTIVEREPORTS}`);
            }, 75);
        }
    }, [updatedReviewFolders, savedReportDefinitions, startedReportComputation]);

    const onModalDismiss = () => {
        dispatch(resetLibraryStatus());
        setVisible(false);
    }

    // Function to create folder state object
    const createReviewFolderState = (reportIdsInFolder: string[]) => ({
        report_id:  reviewFolderDefinitions.report_id || `review_folder_id.${uuid()}`,
        attendees: folderState.attendees ? folderState.attendees.split(",") : [],
        name_and_type: {
            report_name: folderState.folderName,
            finance_cycle: folderState.financeCycle,
            year: folderState.year,
            period: folderState.period,
        },
        report_role: Roles.Admin,
        report_library: Libraries.Active,
        created_by: reviewFolderDefinitions.created_by ?? userName,
        created_at: reviewFolderDefinitions.created_at ?? getEpoch(),
        last_modified: getEpoch(),
        last_modified_by: userName,
        item_type: ItemType.ReviewFolder,
        report_status: getReportStatus(reviewFolderDefinitions.report_status),
        review_date: folderState.reviewDate,
        report_ids: reportIdsInFolder
    });

    const duplicateReviewFolderHandler = () => {
        const duplicateReportDefinitions: ReportDefinition[] = [];
        const reportIdsInCurrentFolder: string[] = [];
        const reportMap: {[p:string]: ReportDefinition} = {}
        const timestamp = getEpoch();
        // Create report map for easy lookup
        reports.forEach(report => reportMap[report.report_id] = report);
        // Create duplicate report definitions
        selectedItems.forEach(selectedRow => {
            if(selectedRow.id && reportMap[selectedRow.id]){
                const report_id = `report_id.${uuid()}`;
                const currentReport = reportMap[selectedRow.id];
                reportIdsInCurrentFolder.push(report_id);
                duplicateReportDefinitions.push({
                    ...currentReport,
                    report_id: report_id,
                    report_status: currentReport.item_type === ItemType.Internal ? 'Creating' : 'Created',
                    report_role: Roles.Admin,
                    report_library: Libraries.Active,
                    created_at: timestamp,
                    created_by:userName,
                    last_modified: timestamp,
                    last_modified_by:userName,
                    table_last_synced: [timestamp],
                    triggered_event: ReportTriggerEvent.DUPLICATE,
                    name_and_type: {
                        ...currentReport.name_and_type,
                        report_name: `${currentReport.name_and_type?.report_name}-DUPLICATE`
                    }
                })

            }
        });
        // Create folder definition with duplicate definitions
        // Save all report definitions
        duplicateReportDefinitions.forEach(definition => {
            dispatch(postReportDefinition(definition));
        });
        // Save new folder definition
        const foldersToUpdate = [
            createReviewFolderState(reportIdsInCurrentFolder)
        ];
        setTimeout(() => {
            dispatch(postReviewFolder(foldersToUpdate));
        }, 100)
    }

    const createEditHandler = () => {
        const updatedReviewFolders: {[p: string]: ReviewFolderState} = {};
        const reportIdsInCurrentFolder: string[] = [];
        const reviewFoldersToUpdate: ReviewFolderState[] = []

        selectedItems.forEach(selectedRow => {
            reportIdsInCurrentFolder.push(selectedRow.id);
            // if this report belonged to current folder, no further processing is needed
            if (selectedRow.parentFolderId === reviewFolderDefinitions.report_id) return;
            // This was report from another folder, remove this from that folder and add to update list
            if (selectedRow.parentFolderId && selectedRow.parentFolderId !== "") {
                let foundFolder: ReviewFolderState;
                // Check if this folder was updated previously
                if(updatedReviewFolders.hasOwnProperty(selectedRow.parentFolderId)){
                    foundFolder = updatedReviewFolders[selectedRow.parentFolderId] as ReviewFolderState;
                } else {
                    foundFolder = reports.find(r => r.report_id === selectedRow.parentFolderId) as ReviewFolderState;
                }
                const updatedReportsInOriginalFolder = foundFolder?.report_ids?.filter(report_id => report_id !== selectedRow.id);
                updatedReviewFolders[foundFolder.report_id] =  {
                    ...foundFolder,
                    report_ids: updatedReportsInOriginalFolder
                };
            }
        });
        // Push all other folders that needs update
        Object.keys(updatedReviewFolders).forEach(folderId => {
            reviewFoldersToUpdate.push(updatedReviewFolders[folderId]);
        });
        // Push folder which is being acted upon
        reviewFoldersToUpdate.push(createReviewFolderState(reportIdsInCurrentFolder));
        // Send the updated folders to the back-end
        dispatch(postReviewFolder(reviewFoldersToUpdate));
    }

    return (
        <Modal
            onDismiss={() => {
                setVisible(false)
            }}
            visible={visible}
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => onModalDismiss()}>
                            Cancel
                        </Button>
                        <Button variant="primary"
                                loading={folderApiLoading || reportDefinitionApiLoading || reportApiLoading}
                                onClick={() => {
                                    workflow === "Duplicate" ? duplicateReviewFolderHandler() : createEditHandler();
                                }}
                        >
                            Submit
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header= {`${reviewFolderDefinitions.report_status === "Updating" ? "Edit" : "Create"} folder: ${folderState.folderName}`}
        >
            <SpaceBetween direction="vertical" size="m">
                {errorType && errorMessage &&
                    <Alert type='error'>{`${errorType}: ${errorMessage}`}</Alert>
                }
                <>
                    Please review your selections below to make sure it's correct.
                    <div>
                        <div><b>Name</b></div>
                        <div>- {folderState.folderName}</div>
                        <div><b>Year</b></div>
                        <div>- {folderState.year}</div>
                        <div><b>Period</b></div>
                        <div>- {folderState.period}</div>
                        <div><b>Finance cycle</b></div>
                        <div>- {folderState.financeCycle}</div>
                        <div><b>Review Date</b></div>
                        <div>- {folderState.reviewDate}</div>
                    </div>
                </>
            </SpaceBetween>
        </Modal>
    );
}

export default ConfirmationModal;