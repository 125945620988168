import {Roles} from "src/common/roles";
import {Column, Stylesheet} from "src/components/CreateReportPage/interfaces";

export type ReportDefinition = ReportState | ExternalReportState | ReviewFolderState;

export interface ReportItem {
    report_id: string,
    report_library?: string,
    created_at?: string | number,
    created_by?: string,
    last_modified?: string | number,
    last_modified_by?: string,
    item_type?: ItemType,
    report_role?: Roles,
    report_status?: string,
    name_and_type?: {
        report_name?: string,
        report_type?: string,
        finance_cycle?: string,
        year?: string,
        period?: string
    }
    // Report builder reports extra props
    workflow_id?: string,
    execution_id?: string,
    workflow_version?: string

}

export interface ReportState extends ReportItem {
    // Report Definition keys
    triggered_event?: string,
    table_last_synced?: number[],
    span_titles?: string[],
    stage_status?: StageStatus,
    jobs_selection?: {group_id: string},
    configure_columns?: Column[],
    stylesheet?: Stylesheet,
}

export interface ExternalReportState extends ReportItem {
    external_report_source?: string,
    link?: string,
}

export interface ReviewFolderState extends ReportItem {
    attendees?: string[],
    review_date?: string,
    report_ids?: string[],
}

export enum ReportStatus {
    CREATING = "Creating",
    CREATED = "Created",
    UPDATING = "Updating",
    EDITED = "Edited",
    PUBLISHED = "Published",
    REFRESHING = "Refreshing",
    ERROR = "Error",
    FAILED = "Failed"
}

export enum Libraries {
    Active = "Active",
    Reference = "Reference",
    Archive = "Archive"
}

export enum ItemType {
    External = "external_report",
    Internal = "internal_report",
    ReviewFolder = "review_folder"
}

export interface StageStatus {
    name_and_type: {
        is_ready: boolean
        is_complete: boolean
    }
    jobs_selection: {
        is_ready: boolean
        is_complete: boolean
    }
    configure_columns: {
        is_ready: boolean
        is_complete: boolean
    }
    stylesheet: {
        is_ready: boolean
        is_complete: boolean
    }
}

export enum ReportTriggerEvent {
    CREATE = "create",
    DUPLICATE = "duplicate",
    EDIT = "edit",
    NONE = "none"
}
