import _ from "lodash";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getTheme, getThemeItems} from "src/actions/themes.actions";
import {forTheme, forThemeItems, forThemesAlert} from "src/reducers/themes.reducer";

export const useThemeData = () => {
  const dispatch = useDispatch();
  const theme = useSelector(forTheme)
  const themeItems = useSelector(forThemeItems)
  const alert = useSelector(forThemesAlert)
  const url = new URL(window.location.href.replace('#/', ""));
  const themeId = url.searchParams.get('themeId');
  useEffect(() => {
    if (themeId) {
      dispatch(getTheme(themeId))
      dispatch(getThemeItems(themeId))
    }
  }, [themeId])

  return {theme, themeItems, alert}
}

export interface BatchEvent {
  [objectId: string]: object
}


const defaultMergeFn = <T extends BatchEvent>(prev: T, next: T) => ({...prev, ...next})
export const useBatchSave = <T extends BatchEvent>(onSave: (events: T) => void, mergeFn: (prev: T, next: T) => object = defaultMergeFn<T>, seconds=1.5) => {
  const periodInMillis = seconds * 1000 // Seconds to milliseconds
  const [batch, setBatch] = useState({} as T)
  const saveBatch = () => {
    if (!_.isEmpty(batch)){
      const batchToSave = {...batch};
      setBatch({} as T)
      onSave(batchToSave)
    }
  }

  useEffect(() => {
    const timer = setInterval(saveBatch, periodInMillis);
    return () => clearInterval(timer)
  }, [batch])

  const addToBatch = useCallback((event: T) => setBatch((prevState: T) => {
      const objectId = Object.keys(event)[0]
      return objectId in prevState ? {...prevState, [objectId]: mergeFn(prevState, event)} : {...prevState, ...event}
  }), []);
  return {addToBatch, forceSave: saveBatch}
}