import {Input, RadioGroup} from "@amzn/awsui-components-react";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import React from "react";
import {CustomDatePicker} from "src/components/BridgingCommentary/modalComponents/CustomDatePicker";
import {TimeWidgetProps} from "src/components/BridgingCommentary/modalComponents/interfaces";
import {epochToNewDate} from "src/utils/timeHelpers";

export const TimeWidget = (props : TimeWidgetProps) => {
    const {bridgeDetails,
    timeOption, setInputDays, inputDays, setTimeOption} = props;


    return (
        <> <RadioGroup
            onChange={({ detail }) => setTimeOption(detail.value)}
            value={timeOption}
            items={[
                {
                    value: "first",
                    label: "Change by amount of days",
                    description:
                        <>Extend due date by number of days. Associated time will stay the same.
                            { timeOption== "first" ? <><FormField label="Days">
                                <Input
                                    value={inputDays}
                                    onChange={({detail}) => {
                                        setInputDays(detail.value)
                                    }}
                                    inputMode="numeric"
                                    type = "number"
                                />
                            </FormField>
                                Updated due date: {epochToNewDate(bridgeDetails.due_timestamp, Number(inputDays))}
                            </>: ""}
                        </>
                },
                {
                    value: "second",
                    label: "Change by date and time",
                    description : "Update due date to a specific date and time"
                }
            ]}
        />{timeOption == "second" ? <CustomDatePicker{...props} ></CustomDatePicker> : ""}
        </>
    );

}