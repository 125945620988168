import {ContentLayout, Header, SpaceBetween} from "@amzn/awsui-components-react";
import React, {useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams} from "react-router-dom";
import { getReportBridges } from "src/actions/bridgingCommentary.actions";
import {getThemes} from "src/actions/themes.actions";
import { Roles } from "src/common/roles";
import BridgingCommentarySummary from "src/components/BridgingCommentary/BridgingCommentarySummary";
import ReportBridging from "src/components/ReportBridgingPage/ReportBridging";
import {ReportThemes} from "src/components/Themes/reportBridgingIntegration/ReportThemes";
import {useBreadcrumb} from "src/features/breadcrumbs/hooks/useBreadcrumb";
import {selectReportBridgeById} from "src/features/reportBridges/reportBridgeSlice";
import {AppState} from "src/reducers/AppState";
import {forThemes} from "src/reducers/themes.reducer";

const ReportBridgingPage = () => {
    const dispatch = useDispatch();
    const role = Roles.Admin;
    const { report_id = "" , report_name = ""} = useParams();

    const crumbs = useMemo(() => {
        return  [
            { text: "FinPress", href: "#" },
            { text: "Published Reports", href: "#published-reports" },
            { text: `${report_name}`, href: `#report-bridging/${report_id}/${report_name}`}
        ]
    }, [])
    useBreadcrumb({crumbs});


    const reportBridge = useSelector((state : AppState) => selectReportBridgeById(state, report_id))
    const groupedThemes = useSelector(forThemes)

    //populate redux state if it's empty in case the user is directed by a specific link
    useEffect(function resetReportBridgingPageArtifacts(){
        dispatch(getThemes(report_id))
        if(!reportBridge)
            dispatch(getReportBridges({role: Roles.Admin, variance_header :"", report_id: report_id}));
    }, []);
    const bridges = reportBridge? [...reportBridge.bridgeItems] : []
    return (
        <ContentLayout
            header={
                <SpaceBetween size="m">
                    <Header
                        variant="h1"
                        description="Review and publish commentary for bridges."
                    >
                        {report_name}
                    </Header>
                </SpaceBetween>
            }>
            <SpaceBetween size={"m"}>
                <BridgingCommentarySummary
                    bridges={bridges}
                    role={role}
                />
                <ReportBridging
                    reportId = {report_id}
                    bridgeItems={bridges}
                    role ={role}
                />
                <ReportThemes groupedThemes={groupedThemes}/>
            </SpaceBetween>
        </ContentLayout>
    );
}

export default ReportBridgingPage;
