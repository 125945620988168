import {createEntityAdapter, createSlice, EntityState, PayloadAction} from "@reduxjs/toolkit";
import {BridgeItem} from "src/components/BridgingCommentary/interfaces";
import {AppState} from "src/reducers/AppState";

export interface ReportBridge {
    report_id : string,
    bridgeItems : BridgeItem[]
}
export interface ReportBridgeState extends EntityState<ReportBridge>{
    status : string
}

const reportBridgeAdapter = createEntityAdapter<ReportBridge>({
    selectId : (item : ReportBridge) => item.report_id,
    sortComparer : (a,b) => a.report_id.localeCompare(b.report_id)
})

const initialState: ReportBridgeState = reportBridgeAdapter.getInitialState(
    {status : "idle"}
)

const reportBridgeSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {
        setReportBridge(state, {payload} : PayloadAction<ReportBridge>){
            reportBridgeAdapter.setOne(state, payload)
        },
        //update Information for a specific bridgeItem
        updateBridge(state, {payload} : PayloadAction<{report_id : string, bridgeItem : BridgeItem}>){
            const {report_id , bridgeItem} = payload
            const existingReportBridge = state.entities[report_id]
            if(existingReportBridge){
                const bridgeItems = existingReportBridge.bridgeItems;
                Object.values(bridgeItems).forEach((item : BridgeItem, index) => {
                    if(item.bridge_id == bridgeItem.bridge_id){
                                bridgeItems[index] = bridgeItem
                            }
                })
            }
        },
        removeBridge(state, {payload} : PayloadAction<{report_id : string, bridgeItem : BridgeItem}>){
            const {report_id , bridgeItem} = payload
            const existingReportBridge = state.entities[report_id]
            if(existingReportBridge){
                const bridgeItems = existingReportBridge.bridgeItems;
                bridgeItems.forEach((item, index) => {
                    if(item.bridge_id == bridgeItem.bridge_id) bridgeItems.splice(index, 1)
                })
            }
        }
    }
})
export const { setReportBridge, updateBridge, removeBridge} = reportBridgeSlice.actions

export default reportBridgeSlice.reducer

// Export the customized selectors for this adapter using `getSelectors`
export const {
    selectAll: selectAllReportBridges,
    selectById: selectReportBridgeById,
    selectIds: selectReportBridgeIds
    // Pass in a selector that returns the posts slice of state
} = reportBridgeAdapter.getSelectors((state : AppState) => state.reportBridge);
