import {Middleware} from "redux";
import {resetAPILoading, setAPILoading} from "src/actions/apiLoading.actions";
import {AppState as State} from "src/reducers/AppState";


export const apiLoadingMiddleware: Middleware<{}, State> = ({dispatch}) => next => action => {
    next(action);
    if (action.payload && action.meta) {
        const {meta} = action;
        if (meta.apiCall) {
            const {entity} = action.meta.apiCall;
            dispatch(setAPILoading(entity));
        } else if (meta.apiEnd) {
            const {entity} = action.meta;
            dispatch(resetAPILoading(entity));
        }
    }
};
