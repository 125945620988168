import {Button, Icon} from "@amzn/awsui-components-react";
import React from "react";
import {Roles} from "src/common/roles";
import {FooterInstructions} from "src/components/FillOutBridgePage/CommentVersioning/FooterInstructions";
import {LineItemHistory} from "src/components/FillOutBridgePage/CommentVersioning/LineItemHistoryComponent";
import {BridgeHistory} from "src/components/FillOutBridgePage/interfaces";
import styled from "styled-components";

const ItemHistoryFieldSet = styled.fieldset`
  font-family: Amazon Ember, Arial, sans-serif;
  font-weight: 400;
  display: grid;
  border-radius: 1rem;
  border-width: 2px;
  border-color: dodgerblue;
  position: relative;

  legend {
    font-size: larger;
    font-weight: normal;
    padding: 0 1rem;
    color: #0972d3; //color: #eb5f07; not orange
    right: 3em;
    position: absolute;
    top: -10px;
    float: left;
    background: white;
  }
`;

export const IconWrapper = styled.div`
  display: inline-block;
  position: relative;
  bottom: .2em;
  padding-right: .3em;
`

const ContentDiv = styled.div`
  padding: 1em;
  overflow: hidden;
`;

const ItemHistoryHeader = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1em;
  padding-top: .5em;
  align-items: center;
`

interface ItemHistoryDeepDiveProps {
    dismiss : () => void,
    unselect: () => void,
    followUpProps: any,
    row: {id: string, name: string},
    bridgeStatus: any,
    role: Roles,
    historyIds: string[],
    history: BridgeHistory,
    latestSelected: boolean
}

export const ItemHistoryDeepDive = ({dismiss, bridgeStatus, unselect, row, role, followUpProps, history, historyIds, latestSelected}: ItemHistoryDeepDiveProps) => {
    return <ItemHistoryFieldSet>
        <legend>Focus on {row.name}</legend>
        <ItemHistoryHeader>
            <Button iconName="arrow-left" variant="icon" onClick={unselect}/>
            <div>
                <IconWrapper><Icon size={'normal'} name={'status-info'}/></IconWrapper>
                <span>
                    Below is a focused history of one bridge item in reverse chronology. The follow-ups from
                    requesters to improve commentary are placed beneath each version.
                    {role === Roles.BridgeOwner && ' Please see the follow-ups from the requester on each version before submitting the bridge again.'}
                    {role === Roles.Admin && ' Add your follow-up on the latest version before requesting a new submission.'}
                </span>
            </div>
        </ItemHistoryHeader>
        <ContentDiv>
            <LineItemHistory
                rowId={row.id}
                bridgeStatus={bridgeStatus}
                latestSelected={latestSelected}
                history={history}
                historyIds={historyIds}
                followUpProps={followUpProps}
            />
        </ContentDiv>
        <FooterInstructions state={'itemHistory'} dismiss={dismiss}/>
    </ItemHistoryFieldSet>
}