import {JobDropdownOptions, MDXJob} from "src/components/MDXLibraryPage/interfaces";
import {AppState} from "src/reducers/AppState";
import {sortTableData} from "src/utils/mdxLibraryHelpers";

import {
    ADD_GROUP,
    MDXLibraryAction,
    MDXLibraryStatus,
    REMOVE_GROUP,
    RESET_STATUS,
    SET_GROUPS,
    SET_JOB_OPTIONS,
    SET_PAGINATION_CONFIG,
    SET_STATUS,
    UPDATE_GROUP
} from "../actions/mdxLibrary.actions";

export interface PaginationConfig {
    paginationKey?: {[p: string]: any}
    hasMore?: boolean | undefined
}

export interface LibraryGroup {
    last_refreshed: string,
    valid: boolean,
    jobs: MDXJob[]
}

export interface MDXLibraryState {
    paginationConfig: PaginationConfig,
    libraryStatus: MDXLibraryStatus,
    jobOptions: JobDropdownOptions,
    groups: {[p: string]: LibraryGroup}
}

const initialState: MDXLibraryState = {
    paginationConfig:{},
    libraryStatus: {},
    jobOptions: {},
    groups: {},
};


export const mdxLibraryReducer = (mdxLibrary=initialState, {type, payload}: MDXLibraryAction): MDXLibraryState => {
    if (payload) {
        const {jobOptions={}, groups={}, group={}, status={}, groupName=''} = payload;
        switch (type) {
            case SET_PAGINATION_CONFIG:
                const paginationConfig =  {
                    paginationKey: payload.paginationKey,
                    hasMore: payload.hasMore
                }
                return {...mdxLibrary, paginationConfig}
            case SET_JOB_OPTIONS:
                return {...mdxLibrary, jobOptions};
            case SET_GROUPS:
                return {...mdxLibrary, groups};
            case ADD_GROUP:
                return {...mdxLibrary, groups: {...group, ...mdxLibrary.groups}};
            case REMOVE_GROUP:
                const updatedGroups = {...mdxLibrary.groups}
                if (groupName && updatedGroups.hasOwnProperty(groupName)) {
                    delete updatedGroups[groupName];
                }
                return {...mdxLibrary, groups: {...updatedGroups}};
            case UPDATE_GROUP:
                return {...mdxLibrary, groups: {...mdxLibrary.groups, ...group}};
            case SET_STATUS:
                return {...mdxLibrary, libraryStatus: {...status}};
            case RESET_STATUS:
                return {...mdxLibrary, libraryStatus: {...status}};
            default:
                return mdxLibrary;
        }
    }
    return mdxLibrary;
};


export const getMdxLibraryStatus = (state: AppState) => state.mdxLibrary.libraryStatus;
export const getJobDropdownOptions = (state: AppState) => state.mdxLibrary.jobOptions;
export const getGroups = (state: AppState) => {
    return sortTableData(state.mdxLibrary.groups, 'last_refreshed', false);
};
export const getPaginationConfig = (state: AppState) => state.mdxLibrary.paginationConfig;
