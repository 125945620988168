import '@amzn/awsui-global-styles/polaris.css';
import '@amzn/rjsf-aws-fintech/dist/style.css';
import './styles/index.scss';
import 'react-tooltip/dist/react-tooltip.css'
import '@amzn/aws-fintech-stepped-workflow/dist/style.css';

import React from 'react';
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
import {HashRouter} from 'react-router-dom';
import App from "src/components/App";
import store from "src/stores/store";


const container = document.getElementById('app_base');
const root = createRoot(container!);
root.render(
    <Provider store={store}>
        <HashRouter>
            <App/>
        </HashRouter>
    </Provider>
);