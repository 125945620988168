import {ItemType, ReportState, ReportTriggerEvent} from "src/common/report";
import {Roles} from "src/common/roles";
import {Actions, DELETE_WORKFLOW, GO_TO_WORKFLOW} from "src/components/ReportLibraryPage/constants";
import {filterReportState} from "src/utils/reportHelpers";
import {v4 as uuid} from "uuid";

type LibraryTypes = "Active" | "ReferenceFinance" | "ReferenceBusiness" | "Archive"

export function getAvailableActions(role: Roles | null, library: LibraryTypes, itemType: ItemType | undefined, belongsToFolder: boolean){

    //return set of actions of SFPA author and SFPA_viewer
    if (role == Roles.SFPA_ReportAuthor || role == Roles.DCFPA_ReportAuthor) {
        return [
            {
                id : DELETE_WORKFLOW,
                text: DELETE_WORKFLOW,
                disabled: false
            },
            {
                id : GO_TO_WORKFLOW,
                text: GO_TO_WORKFLOW,
                disabled: false
            }
        ]
    }
    // If assumed role is not admin no actions are available
    if (role != Roles.Admin || !itemType) return []

    const parseItemType = (itemType: ItemType) => {
        if(itemType === ItemType.ReviewFolder) {
            return "Folder"
        } else if(itemType === ItemType.Internal) {
            return belongsToFolder ? "FolderInternalReport" : "InternalReport";
        } else {
            return belongsToFolder ? "FolderExternalReport" : "ExternalReport";
        }
    }
    return Actions[library][parseItemType(itemType)].map((action: string) => {
        return {
            id: action,
            text: action,
            // Todo: Enable Duplicate review button after the full feature is implemented
            disabled:  action === "No actions"
            // disabled: action === "Duplicate review" || action === "No actions"
        }
    })
}

export function createDuplicateReportDefinition(report: ReportState): ReportState{
    // Create copy report definition from existing report definition for report duplicate event
    const updatedInternalReportState = filterReportState(report);
    const reportId = `report_id.${uuid()}`
    const updatedStylesheet = {
        fileName: updatedInternalReportState.stylesheet?.fileName!,
        reportId: reportId,
        fileSize: updatedInternalReportState.stylesheet?.fileSize || 0,
        fileLastUpdated: updatedInternalReportState.stylesheet?.fileLastUpdated!
    }

    return {
        ...updatedInternalReportState,
        report_id: reportId,
        report_status: 'Creating',
        triggered_event: ReportTriggerEvent.DUPLICATE,
        name_and_type: {
            ...updatedInternalReportState.name_and_type,
            report_name: "name_duplicate"
        },
        stylesheet: updatedStylesheet
    }
}