/* This component displays text when a table row is clicked */
import {Button, Icon} from "@amzn/awsui-components-react";
import React from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {setSelectedBridgeId, setSelectedRow} from "src/actions/bridgingCommentary.actions";
import Paths from "src/components/PageConfig/Paths";
import {AppState} from "src/reducers/AppState";
import {
    forBridgeNaming,
    forInViewBridgeId,
    forInViewBridgeSubItem,
    forRowToBridgeRelation
} from "src/reducers/bridgingCommentary.reducer";
import styled from "styled-components";

/* this holds the crumbs and the button to open history*/
const ThisHeaderDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto; // this puts button on far right
  min-width: max-content; // stay in one line
  height: 4em; // this should be same as TreeTable THead to align
  border-bottom: thin solid #313131;
  align-items: center;
  //background-color: #1b91ff21;
  #crumbs-centered {
    display: flex;
    align-items: center;
  }

  overflow: hidden;
`;

/* this holds a text and a icon. this is clickable*/
const EachBridgeNameDiv = styled.div`
  padding: 0 1px;
  display: flex;
  width: max-content; // so that right arrow stay inline
  .bridge-name {
    padding: 0 4px;
    cursor: pointer;
    color: #0972d3
  }

  .bridge-name:hover {
    color: black;
    text-underline: #1a2029;
  }
`;

const HeaderWithCrumbs = () => {
    const dispatch = useDispatch();
    const bridgeId = useSelector(forInViewBridgeId, shallowEqual);
    const selectedRow = useSelector(forInViewBridgeSubItem, shallowEqual);
    const bridgeRowId= selectedRow && selectedRow.row_id;
    const itemNamesAndId = useSelector((s: AppState) => forBridgeNaming(s, bridgeId, bridgeRowId));
    const items = itemNamesAndId.map((item) => ({text: item.name, href: "#", id: item.id}))
    const {rowIdToBridgeId,rowIdToSubItem} = useSelector(forRowToBridgeRelation);

    // Why couldn't we use BreadCrumbGroup here ?
    // BreadCrumbs have mandatory href.
    // href cannot be used as we want user to stay on the same modal and just load another bridge item on click
    // href cannot be external in BreadCrumb implementation; hence we cannot open a FillOut Bridge page either.
    const newTabPath = `#${Paths.FILLOUTBRIDGE}?bridge_id=${bridgeId}`
    const changeRow: React.EventHandler<any> = (rowIdOfCrumb) => {
        const bridgeId = rowIdToBridgeId[rowIdOfCrumb]
        dispatch(setSelectedBridgeId(bridgeId));
        const bridgeSubItem = rowIdToSubItem[rowIdOfCrumb]
        dispatch(setSelectedRow(bridgeSubItem));
    }
    return <ThisHeaderDiv id={"header-with-crumbs"}>
        <div id="crumbs-centered">
            {items.map((item, index) => {
                return <EachBridgeNameDiv key={item.text + index} onClick={() => changeRow(item.id)}>
                    <strong className={"bridge-name"}> {item.text}</strong>
                    <Icon name="angle-right" variant="subtle"/>
                </EachBridgeNameDiv>
            })}
        </div>
        {/*"See Versions" navigates to Versions of Bridge submission in a new page.*/}
        <Button
            ariaLabel="See More Bridge Details"
            href={newTabPath}
            iconAlign="right"
            iconName="external"
            target="_blank"
            variant="link">
            See Versions
        </Button>
    </ThisHeaderDiv>
}

export default HeaderWithCrumbs;

