import {Alert, SpaceBetween, StatusIndicator, StatusIndicatorProps} from "@amzn/awsui-components-react";
import Table from "@amzn/awsui-components-react/polaris/table";
import React from "react";
import {BridgingPageStatus} from "src/actions/bridgingCommentary.actions";
import {RequestBridgeInputsTableColumns} from "src/components/Bridging/interfaces";
import {EmptyTableState} from "src/components/MDXLibraryPage/MDXLibraryTable";
import styled from "styled-components";

const RequestBridgeInputs = (props: {
    bridgeOwnerToItemMap: [string, Array<{ bridge_id: string, account_rollup: string }>][],
    tableHeader: JSX.Element,
    bridgingPageStatus:BridgingPageStatus,
    errorText: string,
}) => {
    const {bridgeOwnerToItemMap, tableHeader, bridgingPageStatus, errorText} = props

    const userThumbnail = (user: string) => {
        const src = "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" + user;
        const title = user + "@";
        const href = "https://phonetool.amazon.com/users/" + user;

        return (
            <Div>
                <ProfileDiv>
                    <A href={href} target={"_blank"}>
                        <Img src={src} title={title} alt={"pic"}/>
                    </A>
                </ProfileDiv>
                <span>{title}</span>
            </Div>
        );
    }

    const bridgeItemBadge = (bridgeItems: Array<{ bridge_id: string, account_rollup: string }>) => {
        if (bridgeItems.length > 1) {
            return <SpaceBetween size={"xs"} direction={"horizontal"}>
                {bridgeItems.map(bridge => {
                        const {status} = getStatusIconType(bridgingPageStatus, bridge.bridge_id)
                        return <StatusIndicator type={status} key={bridge.bridge_id}>{bridge.account_rollup}</StatusIndicator>
                    })
                }
            </SpaceBetween>
        } else{
            const {status} = getStatusIconType(bridgingPageStatus, bridgeItems[0].bridge_id)
            return <StatusIndicator type={status}>{bridgeItems[0].account_rollup}</StatusIndicator>
        }
    }

    const REQUEST_BRIDGE_INPUTS_COLUMN_DEFINITIONS = [
        {
            id: RequestBridgeInputsTableColumns.BridgeOwners,
            header: RequestBridgeInputsTableColumns.BridgeOwners,
            cell: (e: [string, Array<{ bridge_id: string, account_rollup: string }>]) => userThumbnail(e[0]),
            label: RequestBridgeInputsTableColumns.BridgeOwners
        },
        {
            id: RequestBridgeInputsTableColumns.AssignedLineItem,
            header: RequestBridgeInputsTableColumns.AssignedLineItem,
            cell: (e: [string, Array<{ bridge_id: string, account_rollup: string }>]) => bridgeItemBadge(e[1]),
            label: RequestBridgeInputsTableColumns.AssignedLineItem
        },
    ]

    return (
        <SpaceBetween size={"s"} direction={"vertical"}>
            {errorText.length > 0 &&
                <Alert type="error">{errorText}</Alert>
            }
            <Table
                columnDefinitions={REQUEST_BRIDGE_INPUTS_COLUMN_DEFINITIONS}
                items={bridgeOwnerToItemMap}
                loadingText="Loading resources"
                empty={<EmptyTableState/>}
                header={
                    tableHeader
                }
            />
        </SpaceBetween>
    )
}

export default RequestBridgeInputs

export const ProfileDiv = styled.div<{padding?: string}>`
    cursor: pointer;
    border: thin solid transparent;
    border-radius:0.3em;
    overflow:hidden;
    padding: ${props => props.padding || "0"};
`;

const Div = styled.div`
    display: flex;
    gap: 8px;
    place-items: center;
`;

export const A = styled.a<{padding?: string}>`
    overflow: visible;
    padding: ${props => props.padding || "0"};
`;

export const Img = styled.img<{imageHeight?: string}>`
    border-radius: 20%;
    --heightImg: ${props => props.imageHeight || "36px"};
    height: var(--heightImg);
    width: calc(var(--heightImg) / 4 * 3);
    cursor:pointer;
`;

const getStatusIconType = (bridgingPageStatus: BridgingPageStatus, bridge_id: string): { status: StatusIndicatorProps.Type, message: string } => {

    /*
        This is to show progress in status of bridgeItems submitted for bridge change_state api.
        i) Initial Status will be Pending.
        ii) Redux param bridgingCommentary.status.bridge is used to track status of change_state api.
    */

    if(bridgingPageStatus.wizardBridge[bridge_id]){
        const {saved = false} = bridgingPageStatus.bridge[bridge_id]
        if (saved) {
            return {status: 'success', message: ""}
        }else{
            return {status: 'pending', message: 'Not saved'}
        }
    }else{
        return {status: 'pending', message: 'Not saved'}
    }
}
