import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getJobGroups} from "src/actions/mdxLibrary.actions";
import {GROUPS_PER_PAGE, MDX_PAGE_REFRESH_INTERVAL_MIN} from "src/constants/mdxLibraryConstatnts";
import {getGroups} from "src/reducers/mdxLibrary.reducer";

import MDXLibraryTable from "./MDXLibraryTable";


const MDXLibraryPage = () => {
    const dispatch = useDispatch();
    const jobGroups = useSelector(getGroups);

    // Use effect to fetch initial data for mdx library in redux store
    useEffect(function initializeMDXLibrary() {
        // Fetch user first set of job groups for library page
        dispatch(getJobGroups({limit: GROUPS_PER_PAGE}));

        // Set up the fixed timer to do full reload of mdx groups in the page
        const timer = setInterval(() => {
            dispatch(getJobGroups({limit: GROUPS_PER_PAGE}))
        }, (MDX_PAGE_REFRESH_INTERVAL_MIN * 60 * 1000));

        // Clear the old interval to prevent unnecessary dispatch calls
        return function stopTimer() {
            clearInterval(timer)
        };
    }, []);

    return <MDXLibraryTable jobGroups={jobGroups}/> ;
};

export default MDXLibraryPage;
