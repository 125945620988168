import {SpaceBetween} from "@amzn/awsui-components-react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    BridgeStatus,
    setLatestForBridge,
    setSubItemsForBridgeToHistory
} from "src/actions/bridgingCommentary.actions";
import {BridgeStatusType} from "src/components/BridgingCommentary/interfaces";
import {EverArrow} from "src/components/FillOutBridgePage/CommentVersioning/ArrowComponents";
import {
    CommentVersionContainer, VersionTreeContent
} from "src/components/FillOutBridgePage/CommentVersioning/CommentVersionContainers";
import {
    VersionButton, VersionNumberVariety,
} from "src/components/FillOutBridgePage/CommentVersioning/VersionNumberComponent";
import {customRound} from "src/components/FillOutBridgePage/FillOutBridgeTableHelpers";
import {BridgeHistory, BridgeHistoryItem, BridgeSubItem} from "src/components/FillOutBridgePage/interfaces";
import {AppState} from "src/reducers/AppState";
import {
    forBridgeHistory,
    forBridgeHistoryIds,
} from "src/reducers/bridgingCommentary.reducer";

interface VersionTreeProps {
    bridgeId: string,
    setCommentHistory: Function,
    commentHistory: {current: string, latest: boolean},
    bridgeStatus: BridgeStatus,
    bridgeState: string,
    openChangeLog: boolean,
    rowTouched: boolean,
    setOpenChangeLog: Function,
    subItems: BridgeSubItem[]
}

//Main version tree component
export const VersionTree = ({bridgeId, setCommentHistory, commentHistory, rowTouched, openChangeLog, setOpenChangeLog, bridgeStatus, bridgeState, subItems}: VersionTreeProps) => {
    const dispatch = useDispatch();
    const historyIds: string[] = useSelector((state: AppState) => forBridgeHistoryIds(state, bridgeId))
    const history: BridgeHistory = useSelector((state: AppState) => forBridgeHistory(state, bridgeId))

    const [selectedSyncVersion, setSelectedSyncVersion] =  useState<boolean>(false)
    // This handles, when the bridge is in Requested state, we show "drafted" version by default
    // so that the user can see the prior follow-ups that have been added.
    useEffect(() => {
        if(bridgeState === BridgeStatusType.Requested){
            dispatch(setLatestForBridge(bridgeId, subItems))
            setCommentHistory({current: 'latest', latest: true, index: historyIds.length})
        }
    }, [bridgeState, historyIds.length])

    // This handles showing "drafted" version when an edit is made to the bridge
    useEffect(() => {
        if (rowTouched) {
            dispatch(setLatestForBridge(bridgeId, subItems))
            setCommentHistory({current: 'latest', latest: true, index: historyIds.length})
        }
    }, [rowTouched])

    if (historyIds.length <= 0 || !history) return null;

    if (!bridgeStatus.fetchedVersions){
        return null
    }

    const draftVersionStarted = 'latest' in history;
    return (
        <CommentVersionContainer className={'comment-version-container'} marginLeft={'3%'} marginRight={'2%'} dropdown={true}>
            <VersionTreeContent className={'version-tree-content'}>
                <b key={'version-tree-text'}>Version</b>
                <SpaceBetween direction={"horizontal"} size={"xs"}>
                    {historyIds.map((id, idx) => {
                        const isCurrentlySelected = commentHistory.current === id;
                        const isLastHistoryId = idx === historyIds.length - 1;

                        const selected = (draftVersionStarted && isCurrentlySelected) ||
                            (!draftVersionStarted && isLastHistoryId && commentHistory.latest) ||
                            (!draftVersionStarted && !isLastHistoryId && isCurrentlySelected)

                        // Handles changing the version icon if this version was created from a bridge sync action
                        let variety: VersionNumberVariety = 'default'
                        const historyItem = history[id]

                        // Comparing if the current variance is different from the prev version's variance
                        // The difference in variance tells us that this was created by sync bc user cannot change this.
                        if (idx > 0) {
                            const firstRowId = Object.keys(historyItem)[0]
                            const currentVarianceValue = firstRowId in historyItem ?
                                history[id][firstRowId].varianceValue :
                                0 // Accounting for case where history hasn't loaded yet
                            const prevHistoryItem = history[historyIds[idx-1]]
                            // firstRowId should always be in the prev history item because it is a static row in the bridge
                            // In the rare case it isn't, something is wrong, so we will not mark anything
                            const prevVarianceValue = firstRowId in prevHistoryItem ?
                                prevHistoryItem[firstRowId].varianceValue : currentVarianceValue
                            if (currentVarianceValue !== prevVarianceValue)
                                variety = 'sync'
                        } else if (idx == 0) {
                            const unexplainedVariance = getUnexplainedVariance(historyItem)
                            if(unexplainedVariance)
                                variety = 'sync'
                        }

                        const onClick = () => {
                            draftVersionStarted && commentHistory.latest && dispatch(setLatestForBridge(bridgeId, subItems))
                            dispatch(setSubItemsForBridgeToHistory(bridgeId, id))
                            if (!openChangeLog) setOpenChangeLog(true)
                            setCommentHistory({current: id, latest: !draftVersionStarted && isLastHistoryId, index: idx})
                            setSelectedSyncVersion(variety === 'sync')
                        }

                        return (
                            <>
                                {idx < historyIds.length &&
                                    <VersionButton
                                        key={idx}
                                        variety={variety}
                                        selected={selected}
                                        onClick={onClick}
                                    >
                                        {idx+1}
                                    </VersionButton>
                                }
                                {idx < historyIds.length-1 && <EverArrow key={`${idx}-arrow`}/>}
                            </>
                        )
                    })}
                    {draftVersionStarted &&
                        <>
                            <EverArrow key={`${historyIds.length}-arrow`}/>
                            <VersionButton
                                key={historyIds.length}
                                variety={'latest'}
                                selected={commentHistory.latest}
                                onClick={() => {
                                    if (!openChangeLog) setOpenChangeLog(true)
                                    !commentHistory.latest && dispatch(setSubItemsForBridgeToHistory(bridgeId, 'latest'))
                                    setCommentHistory({current: 'latest', latest: true, index: historyIds.length})
                                }}
                            >
                                {historyIds.length + 1}
                            </VersionButton>
                        </>
                    }
                </SpaceBetween>
                {selectedSyncVersion && <i>This version was generated to track a change from the report in expected variance.</i>}
            </VersionTreeContent>
        </CommentVersionContainer>
    )
}

export const getUnexplainedVariance = (historyItem: BridgeHistoryItem) => {
    if(!historyItem || !Object.keys(historyItem).length) return 0;

    const rowIds = Object.keys(historyItem)
    const {varianceValue} = historyItem[rowIds[0]];
    const sumOfRows = rowIds.slice(1).reduce((partialSum, rowId) => partialSum + Number(historyItem[rowId].varianceValue), 0);

    return customRound(varianceValue - sumOfRows)
}