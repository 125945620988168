export const ACTIVE_REPORTS_TITLE = "Active reports";
export const PUBLISHED_REPORTS_TITLE = "Published reports";
export const ARCHIVE_REPORTS_TITLE = "Archive reports";
export const WARNING_PUBLISH_TO_BUSINESS = "Are you sure you want to publish this report to business? Publishing this report to business will make this report visible to Business leaders. Are you sure you want to go ahead?"
export const WARNING_UNPUBLISH_FROM_BUSINESS = "Are you sure you want to un-publish this report from business? Un-publishing this report from business will make this report not visible to Business leaders. Are you sure you want to go ahead?"
export const WARNING_MOVE_TO_ARCHIVE = "Are you sure you want to archive this report? Archiving this report will make this report only visible to Finance leaders and Admins. Are you sure you want to go ahead?"
export const WARNING_MOVE_TO_REFERENCE = "Are you sure you want to move this report? Moving this report to the Reference Library will make this report visible to Financial leaders. Are you sure you want to go ahead?"
export const WARNING_DELETE = "Are you sure you want to delete this report?"
export const WARNING_REMOVE_FROM_FOLDER = "Are you sure you want to remove this report from the folder?"

// ACTIONS on report library line items
export const EDIT_REVIEW = "Edit review"
export const DUPLICATE_REVIEW = "Duplicate review"
export const DUPLICATE = "Duplicate"
export const GO_TO_WORKFLOW = "Go to workflow"
export const DELETE_WORKFLOW = "Delete workflow"
export const EDIT_INTERNAL = "Edit report"
export const EDIT_EXTERNAL = "Edit source"
export const MOVE_TO_PUBLSIHED = "Move to Published"
export const MOVE_TO_ARCHIVE = "Move to Archive"
export const MOVE_OUT_OF_REVIEW = "Move out of review"
export const DELETE_REVIEW = "Delete review"
export const DELETE = "Delete"
export const PUBLISH_TO_BUSINESS = "Publish to business"
export const UNPUBLISH_FROM_BUSINESS = "Unpublish from business"
export const NO_ACTIONS = "No actions"

export const Actions = {
    "Active": {
        "Folder": [EDIT_REVIEW, DUPLICATE_REVIEW, MOVE_TO_PUBLSIHED, MOVE_TO_ARCHIVE, DELETE_REVIEW] ,
        "FolderExternalReport": [EDIT_EXTERNAL, MOVE_OUT_OF_REVIEW],
        "FolderInternalReport": [EDIT_INTERNAL, MOVE_OUT_OF_REVIEW],
        "ExternalReport": [EDIT_EXTERNAL, DUPLICATE, MOVE_TO_PUBLSIHED, MOVE_TO_ARCHIVE, DELETE],
        "InternalReport": [EDIT_INTERNAL, DUPLICATE, MOVE_TO_PUBLSIHED, MOVE_TO_ARCHIVE, DELETE]
    },
    "ReferenceFinance": {
        "Folder": [PUBLISH_TO_BUSINESS, MOVE_TO_ARCHIVE, DUPLICATE_REVIEW] ,
        "FolderExternalReport": [NO_ACTIONS],
        "FolderInternalReport": [NO_ACTIONS],
        "ExternalReport": [PUBLISH_TO_BUSINESS, MOVE_TO_ARCHIVE, DUPLICATE],
        "InternalReport": [PUBLISH_TO_BUSINESS, MOVE_TO_ARCHIVE, DUPLICATE]
    },
    "ReferenceBusiness": {
        "Folder": [UNPUBLISH_FROM_BUSINESS, MOVE_TO_ARCHIVE] ,
        "FolderExternalReport": [NO_ACTIONS],
        "FolderInternalReport": [NO_ACTIONS],
        "ExternalReport": [UNPUBLISH_FROM_BUSINESS, MOVE_TO_ARCHIVE],
        "InternalReport": [UNPUBLISH_FROM_BUSINESS, MOVE_TO_ARCHIVE]
    },
    "Archive": {
        "Folder": [DUPLICATE_REVIEW, MOVE_TO_PUBLSIHED, DELETE_REVIEW] ,
        "FolderExternalReport": [NO_ACTIONS],
        "FolderInternalReport": [NO_ACTIONS],
        "ExternalReport": [DUPLICATE, MOVE_TO_PUBLSIHED, DELETE],
        "InternalReport": [DUPLICATE, MOVE_TO_PUBLSIHED, DELETE],
    }
}