import {StatusIndicatorProps} from "@amzn/awsui-components-react";
import {BridgeItem, BridgeStatusType} from "src/components/BridgingCommentary/interfaces";
import {bridgeTabType} from "src/components/BridgingCommentary/summaryComponents/interfaces";

export enum BridgingTableColumns {
    Name = "Report / P&L Line Item Bridge",
    VarianceName = "Variance to explain",
    Requester = "Requester",
    Owners = "Bridge Owner(s)",
    Status = "Status",
    LastEdit = "Date of last edit",
    DueDate = "Due By",
    Actions = "Actions"
}
export const iconName: {[key: string]: StatusIndicatorProps.Type} = {
    "Requested": "pending",
    "In-Progress": "in-progress",
    "Approved": "success",
    "Past Due": "warning"
}

export const BlankBridgeItem: BridgeItem = {
    bridge_id: "",
    analysis_id: "",
    report_id: "",
    report_name: "",
    account_rollup: "",
    variance_headers: {
        left_header: "",
        right_header: "",
        variance_header: ""
    },
    variance_data: {
        left: 0,
        right: 0,
        variance: 0
    },
    requester: "",
    bridge_owner_group: "",
    bridge_owners: [],
    note_to_bridge_owner: "",
    status: BridgeStatusType.Published,
    created_timestamp: 0,
    last_modified_timestamp: 0,
    due_timestamp: 0,
    last_modified_by: ""
}
//pastDue boolean means this tab states can display past Due Items.
export const ownerBridgeTabStates :bridgeTabType = {
    "Requested" : {
        pastDue : true,
        bridgeStatusType : [BridgeStatusType.Proposed, BridgeStatusType.Requested, BridgeStatusType.InProgress]
    },
    "Submitted" : {
        pastDue : false,
        bridgeStatusType : [BridgeStatusType.Submitted]
    },
    "Approved" : {
        pastDue : false,
        bridgeStatusType : [BridgeStatusType.Approved, BridgeStatusType.Published]
    },
    "Past Due" : {
        pastDue : true,
        bridgeStatusType : [BridgeStatusType.Proposed, BridgeStatusType.Requested, BridgeStatusType.InProgress]
    }
}

export const adminBridgeTabStates: bridgeTabType = {
    "Requested" : {
        pastDue : true,
        bridgeStatusType : [BridgeStatusType.Proposed, BridgeStatusType.Requested]
    },
    "In-Progress" : {
        pastDue : false,
        bridgeStatusType : [BridgeStatusType.InProgress]
    },
    "Submitted" : {
        pastDue : false,
        bridgeStatusType : [BridgeStatusType.Submitted]
    },
    "Approved" : {
        pastDue : false,
        bridgeStatusType : [BridgeStatusType.Approved]
    },
    "Published" : {
        pastDue : false,
        bridgeStatusType : [BridgeStatusType.Published]
    },
    "Past Due" : {
        pastDue : true,
        bridgeStatusType : [BridgeStatusType.Proposed, BridgeStatusType.Requested]
    }
}
export type BridgesOfReportVariance = Record<string, BridgeItem>