import {createSelector} from "reselect";
import {Roles} from "src/common/roles";
import {AppState} from "src/reducers/AppState";

import {
    ADD_EMPLOYEE_INFORMATION,
    RESET_USER_STATE,
    SET_ASSUMED_ROLE,
    SET_USER_ROLES,
    SET_USER_STATE,
    UserAction
} from "../actions/user.actions";

const PHONE_TOOL_URL = "https://phonetool.amazon.com/users";
const BADGE_URL = "https://internal-cdn.amazon.com/badgephotos.amazon.com";

export interface  EmployeeInformation {
    [alias: string]: {
        ManagerLogin: string
        EmployeeName: string
    }
}

export interface UserState {
    phoneToolUrl: string,
    badgeUrl: string,
    userLoggedIn: string,
    availableRoles: Roles[],
    assumedRole: Roles | null,
    employeeInformation: EmployeeInformation
}

const initialState : UserState = {
    phoneToolUrl: `${PHONE_TOOL_URL}/undefined`,
    badgeUrl: `${BADGE_URL}/?uid=undefined`,
    userLoggedIn: "undefined",
    availableRoles: [],
    assumedRole: null,
    employeeInformation: {}
};


export const userReducer = (userState= initialState, {type, payload} : UserAction): UserState => {
    if(payload) {
        const {userName='undefined', availableRoles = [], assumedRole = null, employeeInformation = {}} = payload;
        switch (type) {
            case SET_USER_STATE:
                return {
                    ...userState,
                    phoneToolUrl: `${PHONE_TOOL_URL}/${userName}`,
                    badgeUrl: `${BADGE_URL}/?uid=${userName}`,
                    userLoggedIn: userName,
                }
            case RESET_USER_STATE:
                return {
                    ...userState,
                    phoneToolUrl: `${PHONE_TOOL_URL}/undefined`,
                    badgeUrl: `${BADGE_URL}/?uid=undefined`,
                    userLoggedIn: "undefined",
                }
            case SET_ASSUMED_ROLE:
                return {...userState, assumedRole: assumedRole}
            case SET_USER_ROLES:
                return {...userState, availableRoles: availableRoles}
            case ADD_EMPLOYEE_INFORMATION:
                return {...userState, employeeInformation: {...userState.employeeInformation, ...employeeInformation}}
            default:
                return userState;
        }
    }
    return userState;
}


export const getUser = (state: AppState) => state.user.userLoggedIn;
export const getAvailableRoles = (state: AppState) => state.user.availableRoles;
export const getAssumedRole = (state: AppState) => state.user.assumedRole;
export const getEmployeeInformation = (state: AppState) => state.user.employeeInformation;
export const forEmployeeInformation = createSelector(
    [
        getEmployeeInformation,
        (state: AppState, alias: string) => (alias)
    ],
    (employeeInformation, alias) => {
        return employeeInformation[alias] || {ManagerLogin: "", EmployeeName: ""}
    }
)
