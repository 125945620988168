import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import styled from "styled-components";

export const CustomBridgeItemStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;	
  // color copied from StatusIndicator overwrite color Grey	
  color: var(--color-text-status-inactive-2o9czw, #687078);		
`;


export const PastDueTag = styled.div`
  line-height: ${awsui.lineHeightBodyM};
  display: inline-block;
  float : right;
  border-radius: ${awsui.borderRadiusBadge};
  padding: 0 ${awsui.spaceStaticXs};
  color: ${awsui.colorTextBodyDefault};
  background-color: ${awsui.colorBackgroundNotificationYellow};
  border-color: #8D6605;
  
`
export const OfStyle  = styled.span`
font-size : 20px;
color : ${awsui.colorTextHeadingDefault};
font-weight: 400;
margin: 5px;
`

export const SubmittedBridgeItemStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  font-weight: normal;
  // color copied from StatusIndicator overwrite color Blue
  color: var(--color-text-status-inactive-2o9czw, #0972D3);
`;

export const PublishedBridgeItemStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;	
  font-weight: normal;
  // color copied from StatusIndicator overwrite color Green	
  color: var(--color-text-status-inactive-2o9czw, #037F0C);		
`;

export const ProposedTag = styled.div`
  line-height: ${awsui.lineHeightBodyM};
  display: inline-block;
  float : right;
  border-radius: ${awsui.borderRadiusBadge};
  padding: 0 ${awsui.spaceStaticXs};
  color: ${awsui.colorTextBodyDefault};
  background-color: #E9EBED;

`

export const DueSoonTag = styled.div`
  line-height: ${awsui.lineHeightBodyM};
  display: inline-block;
  float : right;
  border-radius: ${awsui.borderRadiusBadge};
  padding: 0 ${awsui.spaceStaticXs};
  color: ${awsui.colorTextBodyDefault};
  background-color: #E9EBED;

`


export const VarianceIndent = styled.div`
 text-indent : 20px
`