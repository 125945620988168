import {Row} from "@amzn/aws-fintech-fluid-table";
import {ThemeItem, ThemeItemType, ThemeTableEvent} from "src/components/Themes/interfaces";
import {
  calculateRowPosition,
  parseLocation,
  repositionRowLevel,
  RowPositionError
} from "src/components/Themes/table/ordering";


export const getOriginalRow = (row: Row<ThemeItem> | undefined, data: ThemeItem[]) => {
  if (!row) return undefined;

  const location = parseLocation(row.rowId);
  if (location.length < 2) return data[location[0]];

  return location.reduce((currentRow: ThemeItem | ThemeItem[], nextParent) => {
    if (Array.isArray(currentRow)) return currentRow[nextParent];
    if ('subRows' in currentRow && currentRow.subRows) return currentRow.subRows[nextParent];
    return currentRow; // This should never be reached - parents will always either be an array or have 'subRows'
  }, data) as ThemeItem;
};

const isSameLevel = (a: Row<ThemeItem>, b: Row<ThemeItem>) => {
  const aLocation = parseLocation(a.rowId);
  const bLocation = parseLocation(b.rowId);

  if (aLocation.length !== bLocation.length) return false;

  return aLocation.reduce((sameLevel, parent, idx) => (parent !== bLocation[idx] ? false : sameLevel), true);
};


const isBridgeSubItem = (row: Row<ThemeItem> | undefined) => row && row.data.type === ThemeItemType.BRIDGE_SUB_ITEM;
const isDroppingOnChild = (droppedRow: Row<ThemeItem>, targetParentRow: Row<ThemeItem> | undefined) => {
  if(!targetParentRow) return false;

  const splitTargetParent = targetParentRow.rowId.split(".");
  return (
    droppedRow.rowId.length <= targetParentRow.rowId.length &&
    droppedRow.rowId.split(".").reduce((prev, rowIndex, idx) => (rowIndex === splitTargetParent[idx]) && prev, true)
  );
};


const deleteRow = (items: ThemeItem[], rowToDelete: Row<ThemeItem>, originalDroppedParent?: ThemeItem) => {
  const originalDroppedParentSubRows = originalDroppedParent ? (originalDroppedParent.subRows as ThemeItem[]) : items;
  originalDroppedParentSubRows.splice(rowToDelete.rowIndex, 1);
}

const insertRow = (
  index: number,
  items: ThemeItem[],
  droppedRow: Row<ThemeItem>,
  droppedParent: Row<ThemeItem>,
  targetParentRow?: Row<ThemeItem>,
  originalTargetParent?: ThemeItem
) => {
  const dropOffset = isSameLevel(droppedParent, targetParentRow || ({ rowId: '' } as Row<ThemeItem>)) && droppedRow.rowIndex < index ? 1 : 0;
  if(originalTargetParent && !('subRows' in originalTargetParent)) originalTargetParent.subRows = []
  const originalTargetParentSubRows = originalTargetParent?.subRows ?? items
  const rowBefore = index > 0 ? originalTargetParentSubRows[index - dropOffset - 1] : undefined
  const rowAfter = index <= originalTargetParentSubRows.length ? originalTargetParentSubRows[index-dropOffset] : undefined
  const newPosition = calculateRowPosition(rowBefore, rowAfter)
  const positionedDroppedRow: ThemeItem = {
    ...droppedRow.data,
    position: typeof newPosition === 'number' ? newPosition : 0
  }
  originalTargetParentSubRows.splice(index-dropOffset, 0, positionedDroppedRow)

  return newPosition
}

const addMoveEvent = (
  addEvent: (event: ThemeTableEvent) => void,
  droppedRow: Row<ThemeItem>,
  newPosition: number | RowPositionError,
  originalTargetParent?: ThemeItem,
  originalDroppedParent?: ThemeItem
) => addEvent({[droppedRow.data.id]: {
  position: typeof newPosition === 'number' ? newPosition : 0,
  itemType: droppedRow.data.type,
  currentParent: originalTargetParent?.id || "",
  move: {new: originalTargetParent?.id || "", old: originalDroppedParent?.id || ''}
}})

export const reorderRow = (
  droppedRow: Row<ThemeItem>,
  targetParentRow: Row<ThemeItem> | undefined,
  index: number,
  items: ThemeItem[],
  addEvent: (event: ThemeTableEvent) => void
): ThemeItem[] => {
  if (!items.length) return [];
  if (isBridgeSubItem(targetParentRow) || isDroppingOnChild(droppedRow, targetParentRow)) return items
  const newItems: ThemeItem[] = [...items];

  const droppedParent = { rowId: droppedRow.rowId.slice(0, droppedRow.rowId.length - 2) } as Row<ThemeItem>;
  const originalDroppedParent = getOriginalRow(droppedParent, newItems);
  const originalTargetParent = getOriginalRow(targetParentRow, newItems);

  deleteRow(newItems, droppedRow, originalDroppedParent)
  const newPosition = insertRow(index, newItems, droppedRow, droppedParent, targetParentRow, originalTargetParent);

  addMoveEvent(addEvent, droppedRow, newPosition, originalTargetParent, originalDroppedParent)

  return typeof newPosition !== 'number' && newPosition.name === 'RowPositionClash'  ? repositionRowLevel(
    targetParentRow?.rowId ?? '',
    newItems,
    (updatedItem) => !(new Set([ThemeItemType.VARIANCE, ThemeItemType.BRIDGE])).has(updatedItem.type) && addEvent({
      [updatedItem.id]: {position: updatedItem.position, itemType: updatedItem.type, currentParent: targetParentRow?.data.id ?? ''},
    })
  ) : newItems
};
