import styled from "styled-components";

export const ToggleWrapper = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
`

export const ThemeActionBarWrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    grid-gap: 2em;
    justify-content: left;
    align-items: center;
    background-color: #f2f8fd;
    padding: .75em 1em;
    border-radius: 1.5em;
    margin-bottom: 1em;
`

export const ControlButtonWrapper = styled.div<{length: number}>`
    display: grid;
    grid-template-columns: ${props => 'auto '.repeat(props.length)};
    grid-gap: 1em;
`

export const ActionBarDivider = styled.div`
    width: 2px;
    height: 25px;
    background-color: black;
`

export const ActionButton = styled.button<{selected?: boolean}>`
    background: ${props => props.selected ? '#bbe0ff': 'none'};
    color: inherit;
    border: none;
    padding: .5em;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    border-radius: .5em;

    :hover {
        background-color: ${props => props.selected ? '#bbe0ff' : '#dceefd'};
    }

    :active {
        background-color: #bbe0ff;
    }
`