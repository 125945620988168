import {Alert, Box, Button, Modal, SpaceBetween} from "@amzn/awsui-components-react";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {deleteBridge, resetBridgingPageStatus} from "src/actions/bridgingCommentary.actions";
import {Roles} from "src/common/roles";
import {BridgeItem} from "src/components/BridgingCommentary/interfaces";
import {BridgeInfo} from "src/components/BridgingCommentary/modalComponents/BridgeInfo";
import {removeBridge} from "src/features/reportBridges/reportBridgeSlice";
import {getBridgeApiLoading} from "src/reducers/apiLoading.reducer";
import {getBridgingPageStatus} from "src/reducers/bridgingCommentary.reducer";

interface DeleteBridgeModalProps {
	reportId : string
	visible: boolean
	setVisible: React.Dispatch<React.SetStateAction<boolean>>
	userRole: Roles
	deletingBridge: BridgeItem
	setDeletingBridge: React.Dispatch<React.SetStateAction<BridgeItem | null>>
}

const DeleteBridgeModal = (props: DeleteBridgeModalProps) => {
	const dispatch = useDispatch();
	const {reportId, visible, setVisible, userRole, deletingBridge, setDeletingBridge} = props;

	const bridgeApiLoading = useSelector(getBridgeApiLoading);
	const bridgeStatus = useSelector(getBridgingPageStatus).bridge[deletingBridge.bridge_id]
	const {deleted, error} = bridgeStatus || {deleted: false, error: ""};

	// Close the modal when delete is success
	useEffect(() => {
		if(deleted) onModalDismiss();
	}, [deleted])

	// Event handler when 'X' button is clicked
	const onModalDismiss = () => {
		setDeletingBridge(null);
		setVisible(false);
		dispatch(resetBridgingPageStatus());
	}

	return (
		<Modal
			onDismiss={() => onModalDismiss()}
			visible={visible}
			closeAriaLabel="Close modal"
			footer={
				<Box float="right">
					<Button variant="primary"
							onClick={() => {dispatch(deleteBridge(deletingBridge.bridge_id, userRole))
								dispatch(removeBridge({report_id : reportId, bridgeItem : deletingBridge}))
							}}
							loading={bridgeApiLoading}
					>
						Confirm
					</Button>
				</Box>
			}
			header="Delete Bridge"
		>
			<SpaceBetween direction="vertical" size="m">
				{!!error && <Alert type='error'>{error}</Alert>}
				<> Are you sure you want to delete this bridge permanently? This action can not be undone </>
			</SpaceBetween>
			{BridgeInfo({bridgeDetails : deletingBridge, userRole : userRole})}
		</Modal>
	)
}

export default DeleteBridgeModal;
