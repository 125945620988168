import {
    JobDropdownOptions,
    JobState,
    JobStatus,
    MDXJob,
    MDXQueryType,
    ScenarioOptions
} from "src/components/MDXLibraryPage/interfaces";
import {GROUPS_PER_PAGE} from "src/constants/mdxLibraryConstatnts";
import {LibraryGroup} from "src/reducers/mdxLibrary.reducer";
import {isLetter, isNumber, isUnderscore} from "src/utils/stringHelpers";
import {v4 as uuid} from 'uuid';


/**
 *
 * @param jobGroups  Grouped mdx jobs
 * @return unique set of groups names which contains at least one In-progress/Pending job
 */
export function collectInProgressGroups(jobGroups: {[p: string]: LibraryGroup}): Set<string> {
    const inProgressJobs = new Set<string>();

    Object.keys(jobGroups).forEach(groupName => {
        const jobs = jobGroups[groupName].jobs;  // jobs for current group
        // go through each job in current group and check its status
        for(let index = 0 ; index < jobs.length ; index++){
            const status = aggregateJobStatus(jobs[index].status);
            if (status === 'In-progress' || status === 'Pending') {
                // if the job is running then add the group to list and process next group
                inProgressJobs.add(groupName)
                break;
            }
        }
    });

    return inProgressJobs;
}


/**
 * Function to get a default react state for a job in ui
 * @return Default jobs state object required by create/edit mdx modal ui to maintain react state
 */
export function getDefaultJobState(queryType: MDXQueryType = MDXQueryType.FixedTotal): JobState {
    return {
        job_id: `job_id.${uuid()}`,
        query_type: queryType,
        disabled: false,
        instance: {selectedOption: {label: "-"}, options: []},
        cube: {selectedOption: {label: "-"}, options: []},
        year: {selectedOption: {label: "-"}, options: []},
        scenario: {selectedOption: {label: "-"}, options: []},
    };
}


/**
 * Function to convent given group to list of JobState objects
 * @param jobs      list of mdx jobs
 * @param disabled  boolean to indicate if constructed row is disabled for modification
 * @return          list of job state object for modal ui
 */
export function transformJobsToJobStateList(jobs: MDXJob[], disabled: boolean): JobState[] {
    const jobStateList: JobState[] = [];

    jobs.forEach(job => {
        jobStateList.push({
            job_id: job.job_id,
            query_type: job.query_type,
            disabled: disabled,
            instance: {selectedOption: {label: job.instance}, options: []},
            cube: {selectedOption: {label: job.cube}, options: []},
            year: {selectedOption: {label: job.year}, options: []},
            scenario: {selectedOption: {label: job.scenario}, options: []},
        });
    });

    return jobStateList;
}


/**
 * Function to get initial job status object
 * @return  Initial job status object
 */
export function getInitialJobStatus(): JobStatus {
    return {
        queued: true,
        fetcher: 'pending',
        aggregator: 'pending',
        cancelled: false,
        cancelled_at: undefined,
        errored_at: undefined,
    }
}


/**
 * Function to get single status for job
 */
export function aggregateJobStatus(status: JobStatus): string {
    if (status.fetcher === 'error' || status.aggregator === 'error')
        return 'Error';
    else if (status.cancelled)
        return 'Cancelled';
    else if (status.fetcher === 'success' && status.aggregator === 'success')
        return 'Success';
    else if (status.queued)
        return 'Pending';
    else
        return 'In-progress';
}


/**
 * Function to sort job groups according to given attribute
 * @param jobGroups     Grouped mdx jobs
 * @param attribute     Attribute name of a job (example job.group, job.last_refreshed)
 * @param ascending     Boolean value to determine sort order
 * @return              Sorted mdx job groups according to provided job attribute
 */
export function sortTableData(jobGroups: {[p:string]: LibraryGroup}, attribute: string, ascending=true){
    const sortedGroups: {[p:string]: LibraryGroup} = {}
    const notSortableGroupNames = new Array<string>();  // stores names of group which does not have finished jobs
    const sortableGroupNames = new Array<string>();  // stores names of group which has at least one finished job
    Object.keys(jobGroups).forEach((groupName: string) => {
        jobGroups[groupName].last_refreshed === "-" ? notSortableGroupNames.push(groupName)
            : sortableGroupNames.push(groupName)
    });
    const ascendSortedGroupNames = sortableGroupNames.sort((groupName1, groupName2) => {
        return attribute === 'group' ? groupName1.localeCompare(groupName2)
            : jobGroups[groupName1].last_refreshed.localeCompare(jobGroups[groupName2].last_refreshed);
    });
    let sortedGroupNames = ascending ? ascendSortedGroupNames : ascendSortedGroupNames.reverse();
    sortedGroupNames = [...notSortableGroupNames, ...sortedGroupNames];
    sortedGroupNames.forEach(groupName => {
        sortedGroups[groupName] = {
            ...jobGroups[groupName],
            jobs: [...jobGroups[groupName].jobs].sort((job1, job2) => {
                return job2.last_refreshed.localeCompare(job1.last_refreshed);
            })
        };
    });
    return sortedGroups
}


/**
 * Function to search job groups in mdx table according to search text
 * @param jobGroups   Grouped mdx jobs
 * @param searchText  Search query string
 * @return            All of mdx job groups which includes search string in its name
 */
export function findJobGroups(jobGroups: {[p: string]: LibraryGroup}, searchText: string) {
    // if no filter text is provided return the data as it is.
    if (searchText === '' || searchText === undefined){
        return jobGroups;
    }

    return Object.keys(jobGroups).reduce((groups, groupName) => {
        if (groupName.toLowerCase().includes(searchText.toLowerCase())){
            return {...groups, [groupName]: {...jobGroups[groupName]}};
        }
        return groups
    }, {});
}


/**
 * Function to collect job groups for given page.
 * @param jobGroups   All of mdx job groups fetched from server
 * @param pageNumber  Page number as number
 * @return            Mdx groups for given page number
 */
export function getGroupsForPage(jobGroups: {[p: string]: LibraryGroup}, pageNumber: number) {
    const groupsForPage: {[p: string]: LibraryGroup} = {};
    const groupNames = Object.keys(jobGroups);
    const totalGroups = groupNames.length;
    let startIndex = (pageNumber - 1) * GROUPS_PER_PAGE;
    let groupName;
    for(let groupsAdded = 0; groupsAdded < GROUPS_PER_PAGE && startIndex < totalGroups; startIndex++, groupsAdded++) {
        groupName = groupNames[startIndex];
        groupsForPage[groupName] = {...jobGroups[groupName]};
    }

    return groupsForPage;
}


/**
 * Function to calculate number of pages possible for job groups
 * @param jobGroups  Grouped mdx jobs
 * @return           Number of pages needed to distribute all of mdx job groups
 */
export function getTotalPages(jobGroups: {[p: string]: LibraryGroup}) {
    const totalGroups = Object.keys(jobGroups).length;
    const offset = totalGroups % GROUPS_PER_PAGE !== 0 ? 1 : 0
    const totalPages = Math.floor(totalGroups / GROUPS_PER_PAGE) + offset;
    return totalPages ? totalPages : 1;  // total pages can not be zero
}


/**
 * Function to validate group name entered by the user
 * @param groupName Name of the group
 * @return          Boolean value representing if group name is valid
 */
export function validateGroupName(groupName: string) {
    if (groupName.length > 0) {
        let ascii;
        // ensure the start is valid
        ascii = groupName.charCodeAt(0);
        if (!isLetter(ascii)){
            return false;
        }

        // check chars are valid
        for (let index = 0; index < groupName.length; index++ ){
            ascii = groupName.charCodeAt(index)

            if (!isLetter(ascii) && !isNumber(ascii) && !isUnderscore(ascii)){
                return false;
            }
        }
    }

    return true;
}


/**
 * Function to validate job group (useful for CreateMDXGroup and EditMDXGroup modal's)
 * @param groupName     Name of the group set by the user
 * @param currJobState  Current job state in CreateMDXGroup Modal
 * @return              Returns {validGroupName: boolean, duplicateIndexes: Set<number>, allJobsConfigured: boolean}
 */
export function validateJobGroup(groupName: string, currJobState: JobState[]){
    const validGroupName: boolean = validateGroupName(groupName);
    let allJobsConfigured = true;

    const duplicateIndexes = new Set<number>();
    const configuredJobs= new Set<string>();

    for(let i = 0; i < currJobState.length; i++){
        const jobItem: JobState = currJobState[i];
        const {instance, cube, year, scenario} = readJobStateLabels(jobItem)
        // Check if all attributes for this job are valid
        const validJobAttributes: boolean = (instance !== "-" && cube !== "-" && year !== "-" && scenario !== "-");

        if (validJobAttributes) {
            const job = `${instance}.${cube}.${year}.${scenario}`;
            configuredJobs.has(job) ? duplicateIndexes.add(i) : configuredJobs.add(job);
        } else {
            allJobsConfigured = false;
        }
    }

    return {validGroupName, allJobsConfigured, duplicateIndexes};
}


/**
 * Function to read labels string from JobState object
 * @param jobItem  Job state object from react state used by create/edit mdx modal
 * @return         Selected label strings from each attribute of job
 */
export function readJobStateLabels(jobItem: JobState){
    return {
        job_id: jobItem.job_id,
        query_type: jobItem.query_type,
        instance: jobItem.instance.selectedOption.label,
        cube: jobItem.cube.selectedOption.label,
        year: jobItem.year.selectedOption.label,
        scenario: jobItem.scenario.selectedOption.label,
    }
}


export function separateJobDropdownOptions(options: JobDropdownOptions): {fixedTotalDropdownOptions: ScenarioOptions,
    fxImpactDropdownOptions: ScenarioOptions} {
    // Deep copy the current options
    const fixedTotalDropdownOptions = JSON.parse(JSON.stringify(options));
    const fxImpactDropdownOptions = JSON.parse(JSON.stringify(options));
    // filter out scenarios
    Object.keys(options).forEach((instance: string) => {
        Object.keys(options[instance]).forEach((cube: string) => {
            Object.keys(options[instance][cube]).forEach((year: string) => {
                const fixedTotalScenarios: Array<string> = [];
                const fxImpactScenarios: Array<string> = [];
                const scenarios = options[instance][cube][year]["scenarios"]
                // fx_impact is enabled only for Financials and FCF cubes
                const fx_impact_scenarios = options[instance][cube][year]["fx_impact_scenarios"] || []
                scenarios.forEach(scenario => {
                    fixedTotalScenarios.push(scenario)
                })
                fx_impact_scenarios.forEach(scenario => {
                    fxImpactScenarios.push(scenario)
                })
                // remove the year key from both options object if there are no scenario available for that year
                if (fixedTotalScenarios.length === 0){
                    delete fixedTotalDropdownOptions[instance][cube][year];
                } else {
                    fixedTotalDropdownOptions[instance][cube][year] = fixedTotalScenarios;
                }
                if (fxImpactScenarios.length === 0){
                    delete fxImpactDropdownOptions[instance][cube][year];
                } else {
                    fxImpactDropdownOptions[instance][cube][year] = fxImpactScenarios;
                }
            });
            // remove the cube key from both options object if there are no year available for that cube
            !Object.keys(fixedTotalDropdownOptions[instance][cube]).length && delete fixedTotalDropdownOptions[instance][cube];
            !Object.keys(fxImpactDropdownOptions[instance][cube]).length && delete fxImpactDropdownOptions[instance][cube];
        });
        // remove the instance key from both options object if there are no cube available for that instance
        !Object.keys(fixedTotalDropdownOptions[instance]).length && delete fixedTotalDropdownOptions[instance];
        !Object.keys(fxImpactDropdownOptions[instance]).length && delete fxImpactDropdownOptions[instance];
    });
    // Delete manual input option from fx impact dropdown options
    // delete fxImpactDropdownOptions['AWS Reporting']['Manual Input'];
    return {fixedTotalDropdownOptions, fxImpactDropdownOptions}
}
