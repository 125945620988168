import {TopNavigation} from "@amzn/awsui-components-react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom'
import {setUserAssumedRole} from "src/actions/user.actions";
import {roleOrder, Roles} from "src/common/roles";
import {A} from "src/components/Bridging/RequestBridgeInputs";
import Paths from "src/components/PageConfig/Paths";
import FinPressLogo from "src/images/FinPress.png";
import {AppState} from "src/reducers/AppState";
import {forEmployeeInformation, getAssumedRole, getAvailableRoles, getUser} from "src/reducers/user.reducer";
import styled from "styled-components";


export const BadgeImage = styled.img` 
    border-radius: 20%;
    height: 3em;
    position: absolute;
    right: 0;
    bottom: -50%;
`

const TopNavigationContainer = styled.div`
    position: sticky;
    top: 0;
    z-index: 1002;
`;

const FinpressPersona = 'Finpress Persona'

export const TopNavigationBar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userName = useSelector(getUser);
    const availableRoles = useSelector(getAvailableRoles);
    const currentAssumedRole = useSelector(getAssumedRole);
    const employeeInfo = useSelector((state: AppState) => forEmployeeInformation(state, userName))
    const employeeName = employeeInfo.EmployeeName ?employeeInfo.EmployeeName.split(" ")[0] : userName

    if (!currentAssumedRole){
        const persona = localStorage.getItem(FinpressPersona) as Roles || getHighestRole(availableRoles) 

        dispatch(setUserAssumedRole(persona));
    }

    const items: { id: Roles, text: Roles }[] = availableRoles.reduce((roles: { id: Roles, text: Roles }[], role) => {
        role !== currentAssumedRole && roles.push({id: role, text: role})
        return roles;
    }, []);

    const badgePhoto = <A href={`https://phonetool.amazon.com/users/${userName}`}>
        <BadgeImage src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userName}`}/>
    </A>

    return (
        <TopNavigationContainer id={"top-nav-root"}>
            <TopNavigation
                data-testid={"top-navigation"}
                identity={{href: "#", logo: {src: FinPressLogo, alt: "AWS Logo"}}}
                utilities={[
                    {
                        type: "menu-dropdown",
                        iconSvg: badgePhoto,
                        text: `${employeeName} as ${currentAssumedRole}`,
                        items,
                        onItemClick: (event) => {
                            dispatch(setUserAssumedRole(event.detail.id as Roles))
                            localStorage.setItem(FinpressPersona, event.detail.id)
                            if(event.detail.id as Roles === Roles.BridgeOwner){
                                navigate(Paths.BRIDGINGCOMMENTARY)
                            } else {
                                navigate(Paths.REFERENCEREPORTS)
                            }
                        },
                    }
                ]}
                i18nStrings={{
                    overflowMenuTriggerText: "More",
                    overflowMenuTitleText: "All",
                    overflowMenuBackIconAriaLabel: "Back",
                    overflowMenuDismissIconAriaLabel: "Close menu"
                }}
            />
        </TopNavigationContainer>
    );
}

export const getHighestRole = (roles: Roles[]) => roles.sort((a, b) => roleOrder[a] - roleOrder[b])[0]