import {FormField, Grid, Input, Select} from "@amzn/awsui-components-react";
import {SelectProps} from "@amzn/awsui-components-react/polaris";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import React from "react";
import {FINANCE_PERIODS, getYears, PERIODS, REPORT_TYPES} from "src/components/CreateReportPage/constants";
import {ReportNameAndTypeProps} from "src/components/CreateReportPage/interfaces";

export interface ReportNameAndTypeDescription {
    reportName: string,
    reportType: string
    financeCycle: string,
    year: string,
    period: string,
}
type ReportDescriptionProperty = "reportName" | "reportType" | "financeCycle" | "year" | "period"

const ReportNameAndType = ({reportWorkflow, reportDescription, setReportDescription, errorTexts, setStepErrors} : ReportNameAndTypeProps) => {
    const resetErrorMessage = () => {
        const errorOccurred = Object.keys(errorTexts).length > 0;
        errorOccurred && setStepErrors((stepErrors) => {return {...stepErrors, 1: {}}});
    };

    function constructSelectField(property: ReportDescriptionProperty, selectedOption: string,
                                  options: SelectProps.Options, otherProps: object = {}){
        return(
            <Select
                selectedOption={{label: selectedOption}}
                options={options}
                selectedAriaLabel='Selected'
                onChange={ ({detail}) => {
                    resetErrorMessage();
                    setReportDescription({
                        ...reportDescription,
                        [property]: detail.selectedOption.label ?? ""
                    })
                }}
                {...otherProps}
            />
        )
    }

    const headerDescription = "Please select the report type and fill out the report name for what you would like " +
        "shown throughout the report creation journey."

    return (
        <Container
            header={
                <Header variant="h2" description={headerDescription}>
                    {`${reportWorkflow} report`}
                </Header>
            }
        >
            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}>
                <FormField label="Report Type" errorText={errorTexts["reportType"]}>
                    {constructSelectField('reportType', reportDescription.reportType, REPORT_TYPES)}
                </FormField>
                <FormField label="Report Name" stretch = {true} errorText={errorTexts['reportName']}>
                    <Input
                        data-testid="report-name-input"
                        value={reportDescription.reportName}
                        onChange={event => {
                            resetErrorMessage()
                            if (event.detail.value.length <= 60) {
                                setReportDescription({...reportDescription, reportName: event.detail.value});
                            }
                        }}
                        placeholder={"Enter report name."}
                    />
                </FormField>
            </Grid>
            <Grid gridDefinition={[{ colspan: 5 }, { colspan: 2 },  { colspan: 2 }]}>
                <FormField label="Finance Cycle" stretch = {true} errorText={errorTexts["financeCycle"]}>
                    {constructSelectField('financeCycle', reportDescription.financeCycle, FINANCE_PERIODS, {placeholder: "Make a selection"})}
                </FormField>
                <FormField label="Year" errorText={errorTexts["year"]}>
                    {constructSelectField('year', reportDescription.year, getYears())}
                </FormField>
                <FormField label="Period" errorText={errorTexts['period']}>
                    {constructSelectField('period', reportDescription.period, PERIODS)}
                </FormField>
            </Grid>
        </Container>
    );
}

export default ReportNameAndType;
