import {Button, FormField, Input, SpaceBetween, TokenGroup} from "@amzn/awsui-components-react";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {setBridgingPageStatus} from "src/actions/bridgingCommentary.actions";
import {BRIDGE_NOT_EDITED_ALERT} from "src/components/Bridging/constants";
import {findBridgeItemIdx} from "src/components/Bridging/helpers";
import {BridgeOwnersProps} from "src/components/Bridging/interfaces";

const BridgeOwners = (props: BridgeOwnersProps) => {
    const dispatch = useDispatch();
    const {id, bridge_id, bridgeItems, setBridgeItems, errorText, errors, setStepErrors, bridgingPageStatus} = props
    const [newOwner, setNewOwner] = useState("");

    const resetErrorMessage = () => {
        if (errorText) {
            setStepErrors((stepErrors) => {
                const updatedErrors = {...stepErrors[2]}
                updatedErrors[bridge_id] && (delete (updatedErrors[bridge_id]["bridge_owner"]))
                updatedErrors[bridge_id] && Object.keys(updatedErrors[bridge_id]).length === 0 && delete (updatedErrors[bridge_id])

                return {
                    ...stepErrors,
                    2: updatedErrors,
                }
            })
        }

        if (errors[BRIDGE_NOT_EDITED_ALERT]) {
            setStepErrors((stepErrors) => {
                const updatedErrors = {...stepErrors[2]}
                delete (updatedErrors[BRIDGE_NOT_EDITED_ALERT])
                return {
                    ...stepErrors,
                    2: updatedErrors,
                }
            })
        }

        dispatch(setBridgingPageStatus({
            ...bridgingPageStatus,
            wizardBridge: {...bridgingPageStatus.wizardBridge, [bridge_id]: {saved: false, fetching: false}}
        }));

    };

    const onAddNewOwner = () => {
        const bridgeItemIdx = findBridgeItemIdx(id, bridgeItems)
        const logins = newOwner.split(",").map(login => login.trim()).filter(login => !!login);
        if (bridgeItemIdx !== -1) {
            const updatedBridgeItems = [...bridgeItems]
            const focusedBridgeItem = {...updatedBridgeItems[bridgeItemIdx]}
            const updatedOwners = Array.from(new Set([...focusedBridgeItem.bridge_owners, ...logins])).sort()
            updatedBridgeItems[bridgeItemIdx] = {...focusedBridgeItem, bridge_owners: updatedOwners}
            setBridgeItems(updatedBridgeItems)
            setNewOwner("")
        }
    }

    return (
        <SpaceBetween direction={"vertical"} size={"xs"}>
            <SpaceBetween direction={"horizontal"} size={"xs"}>
                <div style={{minWidth: "80px", maxWidth: "80px"}}>
                    <Input
                        value={newOwner}
                        onChange={({detail}) => {
                            resetErrorMessage();
                            setNewOwner(detail.value);
                        }}
                    />
                </div>
                <div style={{paddingTop: "8px", minWidth: "20px", maxWidth: "20px"}}>
                    <Button iconName={"add-plus"} variant={"inline-icon"} formAction="none"
                            onClick={() => onAddNewOwner()}/>
                </div>
            </SpaceBetween>
            <FormField errorText={errorText}>
                <TokenGroup
                    items={bridgeItems[findBridgeItemIdx(id, bridgeItems)].bridge_owners.map(owner => (
                        {label: owner, dismissLabel: `Remove ${owner}`}
                    ))
                    }
                    onDismiss={({detail: {itemIndex}}) => {
                        resetErrorMessage();
                        const updatedBridgeItems = [...bridgeItems]
                        const bridgeItemIdx = findBridgeItemIdx(id, bridgeItems)
                        const focusedBridgeItem = {...updatedBridgeItems[bridgeItemIdx]}
                        const updatedOwners = [
                            ...focusedBridgeItem.bridge_owners.slice(0, itemIndex),
                            ...focusedBridgeItem.bridge_owners.slice(itemIndex + 1)
                        ]
                        updatedBridgeItems[bridgeItemIdx] = {...focusedBridgeItem, bridge_owners: updatedOwners}
                        setBridgeItems(updatedBridgeItems)
                    }}
                />
            </FormField>
        </SpaceBetween>
    )
}

export default BridgeOwners
