import {PropertyFilterProps} from "@amzn/awsui-components-react";
import React from "react";
import {FollowUpItem} from "src/components/FillOutBridgePage/interfaces";

export interface BridgingCommentaryTableProps {
    bridgeItems : BridgeItem[],
    selectedItems : BridgingTableItem[],
    setSelectedItems :React.Dispatch<React.SetStateAction<BridgingTableItem[]>>,
    ownerWidget? : Function,
    dueDateWidget? : Function,
    actionWidget? : Function
}

export interface TableRowsProps {
    bridgeItems: BridgeItem[],
    dueDate : Function | undefined,
    owners : Function | undefined,
    actions : Function | undefined,
    fullTable : boolean,
}

export interface StatusFilterProps {
    propertyFilterProps : PropertyFilterProps,
    setActiveBridgeStatus : React.Dispatch<React.SetStateAction<string>>,
    setFilteringInProgress : React.Dispatch<React.SetStateAction<boolean>>,
    filteredItemsCount : number | undefined
}

export interface BridgeItem {
    bridge_id: string
    analysis_id: string
    report_id: string
    report_name: string
    account_rollup: string
    variance_headers: {
        left_header: string
        right_header: string
        variance_header: string
    },
    variance_data: {
        left: number | string
        right: number | string
        variance: number | string
    },
    requester: string
    bridge_owner_group: string
    bridge_owners: Array<string>
    note_to_bridge_owner: string
    status: BridgeStatusType
    created_timestamp: number
    last_modified_timestamp: number
    due_timestamp: number
    last_modified_by: string
}

// Interface to define row item in bridging and commentary page table
export interface BridgingTableItem {
    id: string
    report_id: string
    displayName: JSX.Element | string
    varianceName: string
    requester: string
    owners: JSX.Element | string
    status: JSX.Element | string
    editDate: number
    dueDate: JSX.Element
    actions: JSX.Element | string
    // Keys used for searching/filtering
    name: string
}

// Interface to define the bridge item being shown in the Modal
export interface ModalBridgeItem {
    bridge_item: BridgeItem,
    bridge_sub_items: Array<BridgeSubItem>,
    commentators: Array<string>
}

export interface BridgeSubItem {
    row_id: string,
    name: string,
    leftValue: number,
    rightValue: number,
    treeLevel: number,
    varianceValue: number,
    commentary: string,
    commentator: string
    touched: boolean,
    follow_up: FollowUpItem
}

// export type BridgeStatusType = "Requested" | "In-progress" | "Submitted" | "Approved" | "Unpublished" | "Published"

export enum BridgeStatusType {
    Proposed = "Proposed",
    Requested = "Requested",
    InProgress = "In-progress",
    Submitted = "Submitted",
    Approved = "Approved",
    Published = "Published"
}
