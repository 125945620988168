import {Alert, FormField, SpaceBetween, Textarea, TimeInput} from "@amzn/awsui-components-react";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import Table from "@amzn/awsui-components-react/polaris/table";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {setBridgingPageStatus} from "src/actions/bridgingCommentary.actions";
import BridgeOwners from "src/components/Bridging/BridgeOwners";
import {BridgeTableRowItem} from "src/components/Bridging/BridgeTableConfig";
import {BRIDGE_NOT_EDITED_ALERT} from "src/components/Bridging/constants";
import {checkBridgeItemSubmittedOnce, findBridgeItemIdx} from "src/components/Bridging/helpers";
import {AssignBridgeProps, AssignBridgeTableColumns} from "src/components/Bridging/interfaces";
import {CustomGrid} from "src/components/BridgingCommentaryPage/BridgeDetailsModal";
import {Cell, EditableCell} from "src/components/FillOutBridgePage/InputComponents";
import {FooterText} from "src/components/FillOutBridgePage/TableSubComponents";
import {EmptyTableState} from "src/components/MDXLibraryPage/MDXLibraryTable";
import {countCharacters} from "src/utils/bridgingHelpers";
import {sanitizeString} from "src/utils/stringHelpers";
import {getTimeZone} from "src/utils/timeHelpers";

const AssignBridgeItems = (props: AssignBridgeProps) => {
    const {
        varianceName,
        leftVarianceHeader,
        rightVarianceHeader,
        bridgeItems,
        setBridgeItems,
        errors,
        setStepErrors,
        tableHeader,
        bridgingPageStatus
    } = props

    const dispatch = useDispatch();
    const minusDate = getDateMinusOne()

    function bridgeItemNameToolTipWrapper(bridgeName: string, bridge_owner_group_selected: string) {
        const dataTip = `Bridge owners should belong this Teams ${bridge_owner_group_selected}`
        return (
            <div>
                <div data-tooltip-content={dataTip} data-tooltip-id={'global'}>
                    {bridgeName}
                </div>
            </div>
        )
    }

    function bridgeOwnerTokenGroup(id: string, bridge_id: string) {
        let errorText = ""
        if (checkBridgeItemSubmittedOnce(bridge_id, bridgingPageStatus)) {
            if (bridgingPageStatus.wizardBridge[bridge_id].error) {
                errorText = bridgingPageStatus.wizardBridge[bridge_id].error!
            }
        }

        if (errorText === "" && errors[bridge_id]) {
            errorText = errors[bridge_id]["bridge_owner"] || ""
        }

        return (
            <BridgeOwners
                id={id}
                bridge_id={bridge_id}
                bridgeItems={bridgeItems}
                setBridgeItems={setBridgeItems}
                errorText={errorText}
                errors={errors}
                setStepErrors={setStepErrors}
                bridgingPageStatus={bridgingPageStatus}
            />
        )
    }

    const resetAlertBanner = () => {
        if (errors[BRIDGE_NOT_EDITED_ALERT]) {
            setStepErrors((stepErrors) => {
                const updatedErrors = {...stepErrors[2]}
                delete (updatedErrors[BRIDGE_NOT_EDITED_ALERT])
                return {
                    ...stepErrors,
                    2: updatedErrors,
                }
            })
        }
    }

    const resetError = (bridge_id: string, attrName: "due_date" | "due_time") => {
        if (errors[bridge_id]) {
            setStepErrors((stepErrors) => {
                const updatedErrors = {...stepErrors[2]}
                updatedErrors[bridge_id][attrName] ? delete (updatedErrors[bridge_id][attrName]) : delete (updatedErrors[bridge_id]['bridge_owner'])
                updatedErrors[bridge_id] && Object.keys(updatedErrors[bridge_id]).length === 0 && delete (updatedErrors[bridge_id])
                return {
                    ...stepErrors,
                    2: updatedErrors,
                }
            })
        }
    }

    const checkAndDispatchForAPiTrigger = (bridge_id: string) => {
        if (checkBridgeItemSubmittedOnce(bridge_id, bridgingPageStatus)) {
            dispatch(setBridgingPageStatus({
                ...bridgingPageStatus,
                wizardBridge: {...bridgingPageStatus.wizardBridge, [bridge_id]: {saved: false, fetching: false}}
            }));
        }
    }

    function bridgeOwnerNote(id: string, bridge_id: string) {
        const [focused, setFocused] = useState(false);
        const bridgeItemIdx = findBridgeItemIdx(id, bridgeItems)
        const selectedBridgeItem = bridgeItems[bridgeItemIdx]
        const wordLimit = 200
        const currentWordCount = countCharacters(selectedBridgeItem.note_to_bridge_owner);
        const textAreaRows = selectedBridgeItem.note_to_bridge_owner ? undefined : 1;

        return (
            <EditableCell tabIndex={0} touched={true} width="100%" onFocus={() => setFocused(true)}>
                {focused || !selectedBridgeItem.note_to_bridge_owner ?
                    <>
                        <Textarea rows={textAreaRows} autoFocus={true} onBlur={() => setFocused(false)}
                                  value={selectedBridgeItem.note_to_bridge_owner}
                                  invalid={currentWordCount > wordLimit}
                                  onChange={({detail}) => {
                                      if (countCharacters(detail.value) <= wordLimit) {
                                          const updatedBridgeItems = [...bridgeItems]
                                          updatedBridgeItems[bridgeItemIdx] = {
                                              ...selectedBridgeItem,
                                              note_to_bridge_owner: sanitizeString(detail.value)
                                          }
                                          setBridgeItems(updatedBridgeItems)
                                          checkAndDispatchForAPiTrigger(bridge_id)
                                          resetAlertBanner()
                                      }
                                  }}
                                  placeholder={"Note to bridge owner"}
                        />

                        <FooterText float={"right"}> {`${currentWordCount}/${wordLimit}`} </FooterText>
                    </>
                    : <Cell whiteSpace="normal">{selectedBridgeItem.note_to_bridge_owner}</Cell>
                }
            </EditableCell>
        )
    }

    function bridgeDueDate(id: string, bridge_id: string) {
        const bridgeItemIdx = findBridgeItemIdx(id, bridgeItems)
        const selectedBridgeItem = bridgeItems[bridgeItemIdx]
        return (
            <CustomGrid columnDistribution="70% 30%">
                <FormField stretch={true}
                           errorText={errors[bridge_id] && errors[bridge_id]["due_date"]}>
                    <DatePicker
                        value={selectedBridgeItem.due_date}
                        onChange={({detail}) => {
                            const updatedBridgeItems = [...bridgeItems]
                            updatedBridgeItems[bridgeItemIdx] = {...selectedBridgeItem, due_date: detail.value}
                            setBridgeItems(updatedBridgeItems)
                            checkAndDispatchForAPiTrigger(bridge_id)
                            resetAlertBanner()
                            resetError(bridge_id, "due_date")
                        }}
                        isDateEnabled={date => {
                            let range: Date
                            const existingDate = selectedBridgeItem.due_date
                            if (existingDate) {
                                range = new Date(existingDate) < minusDate ? new Date(existingDate) : minusDate
                            } else {
                                range = minusDate
                            }
                            return date >= range
                        }}
                        openCalendarAriaLabel={selectedDate =>
                            "Choose Date" +
                            (selectedDate
                                ? `, selected date is ${selectedDate}`
                                : "")
                        }
                        expandToViewport
                        nextMonthAriaLabel="Next month"
                        placeholder="YYYY/MM/DD"
                        previousMonthAriaLabel="Previous month"
                        todayAriaLabel="Today"
                    />
                </FormField>
                <FormField stretch={true}
                           errorText={errors[bridge_id] && errors[bridge_id]["due_time"]}>
                    <TimeInput
                        value={selectedBridgeItem.due_time}
                        onChange={({detail}) => {
                            const updatedBridgeItems = [...bridgeItems]
                            updatedBridgeItems[bridgeItemIdx] = {...selectedBridgeItem, due_time: detail.value}
                            setBridgeItems(updatedBridgeItems)
                            checkAndDispatchForAPiTrigger(bridge_id)
                            resetAlertBanner()
                            resetError(bridge_id, "due_time")
                        }}
                        format="hh:mm"
                        placeholder="hh:mm"

                    />
                </FormField>
            </CustomGrid>
        )
    }

    const ASSIGN_BRIDGE_ITEMS_COLUMN_DEFINITIONS = [
        {
            id: AssignBridgeTableColumns.LineItem,
            header: AssignBridgeTableColumns.LineItem,
            cell: (e: BridgeTableRowItem) => bridgeItemNameToolTipWrapper(e.account_rollup, e.bridge_owner_group_selected),
            label: AssignBridgeTableColumns.LineItem,
        },
        {
            id: AssignBridgeTableColumns.LeftHeader,
            header: leftVarianceHeader,
            cell: (e: BridgeTableRowItem) => <Cell marginLeft="1px" valueCell={true}>{e.variance_data.left}</Cell>,
            label: AssignBridgeTableColumns.LeftHeader,
        },
        {
            id: AssignBridgeTableColumns.RightHeader,
            header: rightVarianceHeader,
            cell: (e: BridgeTableRowItem) => <Cell marginLeft="1px" valueCell={true}>{e.variance_data.right}</Cell>,
            label: AssignBridgeTableColumns.RightHeader,
        },
        {
            id: AssignBridgeTableColumns.VarianceHeader,
            header: varianceName,
            cell: (e: BridgeTableRowItem) => <Cell marginLeft="1px" valueCell={true}>{e.variance_data.variance}</Cell>,
            label: AssignBridgeTableColumns.VarianceHeader,
        },
        {
            id: AssignBridgeTableColumns.BridgeOwner,
            header: AssignBridgeTableColumns.BridgeOwner,
            cell: (e: BridgeTableRowItem) => bridgeOwnerTokenGroup(e.id, e.bridge_id),
            label: AssignBridgeTableColumns.BridgeOwner,
            minWidth: 150,
        },
        {
            id: AssignBridgeTableColumns.NoteToBridgeOwner,
            header: AssignBridgeTableColumns.NoteToBridgeOwner,
            cell: (e: BridgeTableRowItem) => bridgeOwnerNote(e.id, e.bridge_id),
            label: AssignBridgeTableColumns.NoteToBridgeOwner,
        },
        {
            id: AssignBridgeTableColumns.DueBy,
            header: `${AssignBridgeTableColumns.DueBy} (${getTimeZone()})`,
            cell: (e: BridgeTableRowItem) => bridgeDueDate(e.id, e.bridge_id),
            label: AssignBridgeTableColumns.DueBy,
            minWidth: 280,
        }
    ]

    return (
        <SpaceBetween size={"s"} direction={"vertical"}>
            {errors[BRIDGE_NOT_EDITED_ALERT] &&
                <Alert type='error'>{errors[BRIDGE_NOT_EDITED_ALERT]["banner_alert"]}</Alert>}
            <Table
                columnDefinitions={ASSIGN_BRIDGE_ITEMS_COLUMN_DEFINITIONS}
                items={bridgeItems}
                loadingText="Loading resources"
                empty={<EmptyTableState/>}
                header={
                    tableHeader
                }
            />
        </SpaceBetween>
    )
}

export default AssignBridgeItems

export const getDateMinusOne = () => {
    const local = new Date();
    local.setDate(local.getDate() - 1);
    return local;
}


