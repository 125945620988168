import {Button, FormField, SpaceBetween, TextContent} from "@amzn/awsui-components-react";
import React from "react";
import {JobAttributeEditorProps, JobAttributeType, JobState} from "src/components/MDXLibraryPage/interfaces";
import JobAttributeControl from "src/components/MDXLibraryPage/JobAttributeContorl";
import {JOBS_PER_GROUP} from "src/constants/mdxLibraryConstatnts";
import {getDefaultJobState} from "src/utils/mdxLibraryHelpers";
import styled from "styled-components";


const JobAttributeEditor = (props: JobAttributeEditorProps) => {
    const {jobs, setJobs, queryType, duplicateIndexes, scenarioOptions, loading} = props;

    // Handler for add mdx query button
    const onAddButtonClick = () => {
        setJobs(jobs => [...jobs, getDefaultJobState(queryType)]);
    };

    // Handler for job remove button
    const onRemoveButtonClick = (itemIndex: number) => {
        setJobs(jobs => {
            const newJobs = [...jobs];
            newJobs.splice(itemIndex, 1);
            return newJobs;
        });
    };

    // Helper function to construct props for JobAttributeControl component
    function getAttributeProps(job: JobState, jobAttribute: JobAttributeType, index: number) {
        const isManualInputCubeJob = job.cube.selectedOption.label === 'Manual Input';
        const isManualInputJobAttr = isManualInputCubeJob && (jobAttribute === 'year' || jobAttribute === 'scenario');

        return {
            jobAttribute,
            index,
            scenarioOptions,
            attributeState: job[jobAttribute],
            "setJobs": setJobs,
            disabled: job.disabled || loading || isManualInputJobAttr,
        }
    }

    // Helper function to construct mdx query row inside create/edit modal
    function constructMDXJobRow (job: JobState, index: number): JSX.Element {
        return (
            <CustomGrid key={job.job_id}>
                <JobAttributeControl {...getAttributeProps(job, 'instance', index)} />
                <JobAttributeControl {...getAttributeProps(job, 'cube', index)} />
                <JobAttributeControl {...getAttributeProps(job, 'year', index)} />
                <JobAttributeControl {...getAttributeProps(job, 'scenario', index)}
                                     otherProps={{filteringType: "auto"}}
                />
                <ButtonBox>
                    <Button onClick={() => onRemoveButtonClick(index)} disabled={loading}>Remove</Button>
                </ButtonBox>
            </CustomGrid>
        );
    }

    return (
        <>
            { jobs.length > 0 &&
                <CustomGrid>
                    <TextContent>TM1 Instance</TextContent>
                    <TextContent>TM1 Cube</TextContent>
                    <TextContent>Year</TextContent>
                    <TextContent>Scenario</TextContent>
                </CustomGrid>
            }
            <SpaceBetween size="m" direction="vertical">
                {jobs.length > 0 && jobs.map((job, index) =>
                    {return duplicateIndexes.has(index) ?
                        <FormField errorText="Duplicate row." stretch={true}>
                            {constructMDXJobRow(job, index)}
                        </FormField>
                        : constructMDXJobRow(job, index)
                    }
                )}
                <SpaceBetween size="xxs" direction="vertical">
                    <Button onClick={onAddButtonClick} disabled={jobs.length >= 50 || loading}>
                        Add mdx query
                    </Button>
                    <TextContent>
                        <small>
                            {jobs.length >= JOBS_PER_GROUP ?
                                `You have reached the limit of ${JOBS_PER_GROUP} items.`
                                : `You can add up to ${JOBS_PER_GROUP - jobs.length} more items.`
                            }
                        </small>
                    </TextContent>
                </SpaceBetween>
            </SpaceBetween>
        </>
    );
};

export default JobAttributeEditor;


// Custom grid components for MDX Group Modal content area
const CustomGrid = styled.div`
    display: grid;
    grid-template-columns: 25% 25% 12% 21% 13%;
    gap: 8px;
`;
const ButtonBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
`;
