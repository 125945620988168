import {configureStore} from "@reduxjs/toolkit";
import {appMiddleware} from "src/middleware/app";
import {coreMiddleware} from "src/middleware/core";
import {reducers} from "src/reducers/rootReducer";
const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({serializableCheck: false, immutableCheck: false, thunk: true
        }).concat([...appMiddleware,...coreMiddleware]);
    },
});

export default store;
