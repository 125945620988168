import {Button, FormField, Grid, Input, Select, SelectProps} from "@amzn/awsui-components-react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setUpdatingExternalReportDefinitions} from "src/actions/reportLibrary.actions";
import {FINANCE_PERIODS, getYears, PERIODS} from "src/components/CreateReportPage/constants";
import {ReportNameAndTypeDescription} from "src/components/CreateReportPage/ReportNameAndType";
import ConfirmationModal from "src/components/ExternalReportPage/ConfirmationModal";
import {EXTERNAL_REPORT_SOURCES, EXTERNAL_REPORT_TYPES} from "src/components/ExternalReportPage/constants";
import Paths from "src/components/PageConfig/Paths";
import {getReportDefinitionsApiLoading} from "src/reducers/apiLoading.reducer";
import {getExternalUpdatingReport} from "src/reducers/reportLibrary.reducer";
import styled from "styled-components";

type ReportDescriptionProperty = "reportName" | "reportType" | "financeCycle" | "year" | "period" | "reportSource"

export interface ExternalReportNameAndType extends ReportNameAndTypeDescription {
    reportSource: string,
    link: string
}

const ExternalReportContainer = () => {
    const dispatch = useDispatch();

    const externalReportProps = useSelector(getExternalUpdatingReport);
    const [isConfirmationModalReferencesVisible, setIsConfirmationModalReferencesVisible] = useState<boolean>(false);

    const [reportDescription, setReportDescription] = useState<ExternalReportNameAndType>({
        reportName: externalReportProps?.name_and_type?.report_name ?? "",
        reportType: externalReportProps?.name_and_type?.report_type ?? "",
        financeCycle: externalReportProps?.name_and_type?.finance_cycle ?? "",
        year: externalReportProps?.name_and_type?.year ?? "",
        period: externalReportProps?.name_and_type?.period ?? "",
        reportSource: externalReportProps?.external_report_source ?? "",
        link: externalReportProps?.link ?? ""
    });

    const [errorTexts, setErrorTexts] = useState< {[p:string]: string}>({});

    const resetErrorMessage = () => {
        const errorOccurred = Object.keys(errorTexts).length > 0;
        errorOccurred && setErrorTexts({});
    };

    function constructSelectField(property: ReportDescriptionProperty, selectedOption: string,
                                  options: SelectProps.Options, otherProps: object = {}){
        return(
            <Select
                selectedOption={{label: selectedOption}}
                options={options}
                selectedAriaLabel='Selected'
                onChange={ ({detail}) => {
                    resetErrorMessage();
                    setReportDescription({
                        ...reportDescription,
                        [property]: detail.selectedOption.label ?? ""
                    })
                }}
                {...otherProps}
            />
        )
    }

    const reportDefinitionsApiIsLoading = useSelector(getReportDefinitionsApiLoading);

    const confirmationModal = ConfirmationModal(
        isConfirmationModalReferencesVisible,
        setIsConfirmationModalReferencesVisible,
        reportDescription,
        externalReportProps,
        reportDefinitionsApiIsLoading
    );

    return (
        <>
            {confirmationModal}
            <Container
                header={
                    <Header
                        variant="h2"
                        description="On this page you can edit the external source, type, name, link, finance cycle, year or period.
                        Please note, no two reports can have the same name in the report library."
                    >
                        {`${externalReportProps.report_status === "Updating" ? "Edit" : "Create"} source: ${reportDescription.reportName}`}
                    </Header>
                }
            >
                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 6 }]}>
                    <FormField label="External report source" errorText={errorTexts["reportSource"]}>
                        {constructSelectField('reportSource', reportDescription.reportSource, EXTERNAL_REPORT_SOURCES)}
                    </FormField>
                    <FormField label="Report Type" errorText={errorTexts["reportType"]}>
                        {constructSelectField('reportType', reportDescription.reportType, EXTERNAL_REPORT_TYPES)}
                    </FormField>
                    <FormField label="Report Name" stretch = {true} errorText={errorTexts['reportName']}>
                        <Input
                            value={reportDescription.reportName}
                            onChange={event => {
                                resetErrorMessage()
                                if (event.detail.value.length <= 60) {
                                    setReportDescription({...reportDescription, reportName: event.detail.value});
                                }
                            }}
                            placeholder={"Enter report name."}
                        />
                    </FormField>
                </Grid>
                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 2 },  { colspan: 2 },  { colspan: 2 }]}>
                    <FormField label="Add a link" stretch = {true} errorText={errorTexts['link']}>
                        <Input
                            value={reportDescription.link}
                            type="url"
                            onChange={event => {
                                resetErrorMessage()
                                setReportDescription({...reportDescription, link: event.detail.value});
                            }}
                            placeholder={"Add your link here.."}
                        />
                    </FormField>
                    <FormField label="Year" errorText={errorTexts["year"]}>
                        {constructSelectField('year', reportDescription.year, getYears())}
                    </FormField>
                    <FormField label="Period" errorText={errorTexts['period']}>
                        {constructSelectField('period', reportDescription.period, PERIODS)}
                    </FormField>
                    <FormField label="Finance Cycle" stretch = {true} errorText={errorTexts["financeCycle"]}>
                        {constructSelectField('financeCycle', reportDescription.financeCycle, FINANCE_PERIODS, {placeholder: "Make a selection"})}
                    </FormField>
                </Grid>
            </Container>
            <ButtonsDiv>
                <Button variant="normal"
                        onClick={() => {
                            dispatch(setUpdatingExternalReportDefinitions({report_id: ""}));
                            window.location.replace(`#${Paths.ACTIVEREPORTS}`);
                        }}
                    >
                    Cancel
                </Button>
                <Button variant="primary"
                        onClick={() => {
                            const {errors, navigationAllowed} = validateReportNameAndType(reportDescription);
                            if (!navigationAllowed) {
                                setErrorTexts(errors)
                            } else {
                                setIsConfirmationModalReferencesVisible(true);
                            }
                        }}
                    >
                    Confirm
                </Button>
            </ButtonsDiv>
        </>
    );
}

export default ExternalReportContainer;

export function isValidHttpUrl(link: string) {
    let url;
    try {
        url = new URL(link);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

export function validateReportNameAndType(reportDescription: ExternalReportNameAndType) {
    const {reportName, reportType, financeCycle, year, period, reportSource, link} = reportDescription
    const errors: {[p:string]: string} = {}
    const currentValues = {reportName, reportType, financeCycle, year, period, reportSource, link}
    Object.entries(currentValues).forEach(([key, value]) => {
        if (!value.length){
            errors[key] = "This field can not be empty";
        }
    });
    if (!errors['link'] && !isValidHttpUrl(link)) {
        errors['link'] = "This field must be a valid url. Valid url must start with http or https";
    }
    return {errors, navigationAllowed: Object.keys(errors).length === 0}
}

const ButtonsDiv = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    padding: 10px; 
    float: right;
`
