import {Box, Container, Header, Icon, SpaceBetween} from "@amzn/awsui-components-react";
import React from "react";
import {
    ColorDiv,
    HowItWorksDiv, IconHeader,
    ImageSectionsDiv,
    SingleImageSectionDiv,
    StyledLetter, StyledWord, TextWithLetterBullets
} from "src/components/HomePage/styling";
import CreateReport from "src/images/01CreateReport.png";
import RequestCommentary from "src/images/02_RequestCommentary.png";
import ReviewCommentary from "src/images/03_ReviewCommentary.png";
import FinalizeReport from "src/images/04_FinalizeReport.png";
import DistributeReport from "src/images/05_DistributeReport.png";
import NewReport from "src/images/NewReportIcon.png";


const createReportSection = <SingleImageSectionDiv>
    <StyledWord>Step 1</StyledWord>
    <img src={CreateReport} width={200} alt={"CreateReport"}/>
    <IconHeader>
        <img src={NewReport} width={20} alt={"NewReport"}/>
        <Header
            variant={"h3"}
        >
            Create report
        </Header>
    </IconHeader>
    <div>
        <Box variant="p">
            <TextWithLetterBullets>
                <StyledLetter>A</StyledLetter>
                <ColorDiv>
                    Source report contents from FinPress data inventory.
                </ColorDiv>
            </TextWithLetterBullets>
        </Box>
        <Box variant="p">
            <TextWithLetterBullets>
                <StyledLetter>B</StyledLetter>
                <ColorDiv>
                    Set report layout and design and provide report details.
                </ColorDiv>
            </TextWithLetterBullets>
        </Box>
    </div>
</SingleImageSectionDiv>

const requestCommentarySection = <SingleImageSectionDiv>
    <StyledWord>Step 2</StyledWord>
    <img src={RequestCommentary} width={200} alt={"RequestCommentary"}/>
    <IconHeader>
        <Icon name={"contact"}/>
        <Header
            variant={"h3"}
        >
            Request commentary
        </Header>
    </IconHeader>
    <Box variant="p">
        <ColorDiv>
            Tag collaborators to provide commentary on bridges in your report.
        </ColorDiv>
    </Box>
</SingleImageSectionDiv>

const reviewCommentarySection = <SingleImageSectionDiv>
    <StyledWord>Step 3</StyledWord>
    <img src={ReviewCommentary} width={200} alt={"ReviewCommentary"}/>
    <IconHeader>
        <Icon name={"search"}/>
        <Header
            variant={"h3"}
        >
            Review commentary
        </Header>
    </IconHeader>
    <Box variant="p">
        <ColorDiv>
            Review submitted commentary and ask follow-up questions or request edits.
        </ColorDiv>
    </Box>
</SingleImageSectionDiv>

const finalizeReportSection = <SingleImageSectionDiv>
    <StyledWord>Step 4</StyledWord>
    <img src={FinalizeReport} width={200} alt={"FinalizeReport"}/>
    <IconHeader>
        <Icon name={"check"}/>
        <Header
            variant={"h3"}
        >
            Finalize report
        </Header>
    </IconHeader>
    <Box variant="p">
        <ColorDiv>
            Make final edits to bridges and commentary.
        </ColorDiv>
    </Box>
</SingleImageSectionDiv>

const distributeReportSection = <SingleImageSectionDiv>
    <StyledWord>Step 5</StyledWord>
    <img src={DistributeReport} width={200} alt={"DistributeReport"}/>
    <IconHeader>
        <Icon name={"share"}/>
        <Header
            variant={"h3"}
        >
            Distribute report
        </Header>
    </IconHeader>
    <Box variant="p">
        <ColorDiv>
            Share reports with executives through FinPress' secure, unique URLs.
        </ColorDiv>
    </Box>
</SingleImageSectionDiv>

const HowItWorks =  <HowItWorksDiv>
        <Container>
            <SpaceBetween size={"m"}>
            <Header
                variant={"h2"}
            >
                How It Works
            </Header>
            <ImageSectionsDiv>
                {createReportSection}
                {requestCommentarySection}
                {reviewCommentarySection}
                {finalizeReportSection}
                {distributeReportSection}
            </ImageSectionsDiv>
            </SpaceBetween>
        </Container>
    </HowItWorksDiv>

export default HowItWorks;