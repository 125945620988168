import {Box, Icon, StatusIndicator} from "@amzn/awsui-components-react";
import React from "react";
import {iconName} from "src/components/BridgingCommentary/constants";
import {StatusBoxProps} from "src/components/BridgingCommentary/summaryComponents/interfaces";
import PublishedSVG from "src/images/bridgingAndReportStatus/Published.svg";
import {countBridgesWithStatus, countTotalBridges} from "src/utils/reportBridgingHelpers";

import {OfStyle, PublishedBridgeItemStatus, SubmittedBridgeItemStatus } from "../styling";

export const buildStatusIndicatorBox =(props :  StatusBoxProps) =>{
    return (
        <div>
            <Box variant="awsui-key-label" > {
                <StatusIndicator type={iconName[props.bridgeStatus]}>
                    {props.bridgeStatus}
                </StatusIndicator>
            }</Box>
            <Box fontSize="display-l" fontWeight="bold" color="text-status-info">
                {countBridgesWithStatus(props.bridges, props.bridgeStatus, props.bridgeTabs)}
            </Box>
        </div>
    )
}

export const buildSubmittedStatusBox = (props : StatusBoxProps) =>{
    return (
        <div>
            <Box variant="awsui-key-label" > {
                <SubmittedBridgeItemStatus>
                    <Icon name ={"file-open"}/>
                    {props.bridgeStatus}
                </SubmittedBridgeItemStatus>
            }</Box>
            <Box fontSize="display-l" fontWeight="bold" color="text-status-info">
                {countBridgesWithStatus(props.bridges, props.bridgeStatus, props.bridgeTabs)}
            </Box>
        </div>
    )
}
export const buildPublishedStatusBox = (props : StatusBoxProps) =>{
    return (
        <div>
            <Box variant="awsui-key-label"> {
                <PublishedBridgeItemStatus>
                    <Icon url ={PublishedSVG}/>
                    {props.bridgeStatus}
                </PublishedBridgeItemStatus>
            }</Box>
            <Box fontSize="display-l" fontWeight="bold" color="text-status-info">
                        <span>
                        {countBridgesWithStatus(props.bridges, props.bridgeStatus, props.bridgeTabs)}
                            <OfStyle> of </OfStyle>
                            {countTotalBridges(props.bridges)}
                        </span>
            </Box>
        </div>
    )
}

export const buildApprovedStatusBox = (props : StatusBoxProps) =>{
    return (
        <div>
            <Box variant="awsui-key-label"> {
                <StatusIndicator type={iconName[props.bridgeStatus]}>
                    {props.bridgeStatus}
                </StatusIndicator>
            }</Box>
            <Box fontSize="display-l" fontWeight="bold" color="text-status-info">
                        <span>
                        {countBridgesWithStatus(props.bridges, props.bridgeStatus, props.bridgeTabs)}
                            <OfStyle> of </OfStyle>
                            {countTotalBridges(props.bridges)}
                        </span>
            </Box>
        </div>
    )
}