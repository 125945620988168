import {BridgeItem, BridgeStatusType, BridgingTableItem} from "src/components/BridgingCommentary/interfaces";
import {getEpoch} from "src/utils/timeHelpers";

export function groupByVariance (items: BridgeItem[]) {
    const sortedItems = items.sort((item1, item2) => {
        return item2.last_modified_timestamp - item1.last_modified_timestamp;
    });
    const groupedItems: {[p:string]: BridgeItem[]} = {}
    sortedItems.forEach(item => {
        const header = item.variance_headers.variance_header;
        if(!groupedItems[header]){
            groupedItems[header] = [{...item}];
        } else {
            groupedItems[header].push({...item})
        }
    });
    return groupedItems;
}


//group bridges first by report then by variance
export function groupBridgesForOwners(items : BridgeItem[]) {
    const sortedItems = items.sort((item1, item2) => {
        return item2.last_modified_timestamp - item1.last_modified_timestamp;
    });
    const groupedItems :  {[p:string]: BridgeItem[]} = {}
    const nestedItems : {[p : string]: {[p:string] : BridgeItem[]}} = {};
    sortedItems.forEach(item => {
        const header = item.report_id;
        if(!groupedItems[header]){
            groupedItems[header] = [{...item}];
        } else {
            groupedItems[header].push({...item})
        }
    });
    Object.keys(groupedItems).forEach(report_id =>{
        items = groupedItems[report_id]

        nestedItems[report_id] = items.reduce((acc: { [p: string]: BridgeItem[] }, obj) => {
            const key = obj.variance_headers.variance_header;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    })
    return nestedItems;
}

//create a map of BridgeItems with key as it's bridgeID
export function createBridgeMap(items : BridgeItem[]){
    return items.reduce(
        (bridgeMap: {[p:string]: BridgeItem}, currentBridgeItem: BridgeItem) => {
            bridgeMap[currentBridgeItem.bridge_id]	= {...currentBridgeItem};
            return bridgeMap;
        },
        {}
    );
}
//get the list of selected bridges from selectedRow in the bridgingTableRow
export function selectBridges(selectedItems : BridgingTableItem[], bridgeMap : {[p: string]: BridgeItem}){
    const selectedBridges: BridgeItem[] = [];
    selectedItems.forEach(selectedRow => {
        if(bridgeMap[selectedRow.id]) {
            selectedBridges.push(bridgeMap[selectedRow.id]);
        }
    });
    return selectedBridges;
}


export function isPastDue(item : BridgeItem){
    return item.due_timestamp <= getEpoch() && (item.status == BridgeStatusType.Requested || item.status == BridgeStatusType.Proposed)
}