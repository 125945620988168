import {FileTransferActions, RESET_FILE_TRANSFER, SET_FILE_TRANSFER_STATUS,} from "src/actions/fileTransfer.actions";
import {AppState} from "src/reducers/AppState";

// fileKey is optional as this state will be used by both upload and download
// currently only for upload action backend resp will have fileKey
export interface FileTransferState {
    [p: string]: {
        urlType: string,
        transferStatus: string,
        fileKey?: string
    }
}

const initialFileTransferState: FileTransferState = {}


export const fileTransferReducer = (fileTransferState = initialFileTransferState,
                                    {type, payload}: FileTransferActions): FileTransferState => {
    if (payload) {
        const {fileEntity, urlType = "", transferStatus = "", fileKey = ""} = payload;
        switch (type) {
            case SET_FILE_TRANSFER_STATUS:
                return fileEntity ? {...fileTransferState, [fileEntity]: {urlType, transferStatus, fileKey}} : {...fileTransferState}
            case RESET_FILE_TRANSFER:
                return initialFileTransferState;
            default:
                return fileTransferState;
        }
    }
    return fileTransferState;
}

export const getFileTransferState = (state: AppState) => state.fileTransfer;