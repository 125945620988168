import {combineReducers} from "@reduxjs/toolkit";
import breadcrumbSlice from "src/features/breadcrumbs/breadcrumbSlice";
import reportBridgeSlice from "src/features/reportBridges/reportBridgeSlice";
import {bridgingCommentaryReducer} from "src/reducers/bridgingCommentary.reducer";
import {themesReducer} from "src/reducers/themes.reducer";

import {apiLoadingReducer} from "./apiLoading.reducer";
import {fileTransferReducer} from "./fileTransfer.reducer";
import {mdxLibraryReducer} from "./mdxLibrary.reducer";
import {reportLibraryReducer} from "./reportLibrary.reducer";
import {userReducer} from "./user.reducer";


export const reducers = combineReducers({
    user: userReducer,
    breadcrumb: breadcrumbSlice,
    fileTransfer: fileTransferReducer,
    apiLoading: apiLoadingReducer,
    mdxLibrary: mdxLibraryReducer,
    reportLibrary: reportLibraryReducer,
    bridgingCommentary: bridgingCommentaryReducer,
    themes: themesReducer,
    reportBridge: reportBridgeSlice
});