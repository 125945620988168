import React from "react";
import {getFormattedValue} from "src/components/FillOutBridgePage/FillOutBridgeTableHelpers";
import {FillOutTableColumns, FillOutTableItem} from "src/components/FillOutBridgePage/interfaces";

export const getColumnDefinition = (leftHeader: string, rightHeader: string, varianceHeader: string) => [
	{
		id: FillOutTableColumns.Name,
		label: FillOutTableColumns.Name,
		header: FillOutTableColumns.Name,
		minWidth: "200px",
		maxWidth: "200px",
		cell: (e: FillOutTableItem) => e.name,
	},
	{
		id: FillOutTableColumns.LeftScenario,
		label: FillOutTableColumns.LeftScenario,
		minWidth: "90px",
		maxWidth: "90px",
		header: leftHeader,
		cell: (e: FillOutTableItem) => e.leftValue
	},
	{
		id: FillOutTableColumns.RightScenario,
		label: FillOutTableColumns.RightScenario,
		minWidth: "90px",
		maxWidth: "90px",
		header: rightHeader,
		cell: (e: FillOutTableItem) => e.rightValue
	},
	{
		id: FillOutTableColumns.Variance,
		label: FillOutTableColumns.Variance,
		minWidth: "90px",
		maxWidth: "90px",
		header: varianceHeader,
		cell: (e: FillOutTableItem) => e.varianceValue
	},
	{
		id: FillOutTableColumns.Commentary,
		label: FillOutTableColumns.Commentary,
		header: FillOutTableColumns.Commentary,
		minWidth: "350px",
		maxWidth: "350px",
		cell: (e: FillOutTableItem) => e.commentary
	},
	{
		id: FillOutTableColumns.Actions,
		label: FillOutTableColumns.Actions,
		header: FillOutTableColumns.Actions,
		cell: (e: FillOutTableItem) => e.actions
	},
];

export const getColumnDefinitionForViewingBridges = (leftHeader: string, rightHeader: string, varianceHeader: string) => [
	{
		id: FillOutTableColumns.Name,
		label: FillOutTableColumns.Name,
		header: FillOutTableColumns.Name,
		minWidth: "200px",
		maxWidth: "200px",
		cell: (e: FillOutTableItem) => e.name,
	},
	{
		id: FillOutTableColumns.LeftScenario,
		label: FillOutTableColumns.LeftScenario,
		minWidth: "90px",
		maxWidth: "90px",
		header: leftHeader,
		cell: (e: FillOutTableItem) => e.leftValue
	},
	{
		id: FillOutTableColumns.RightScenario,
		label: FillOutTableColumns.RightScenario,
		minWidth: "90px",
		maxWidth: "90px",
		header: rightHeader,
		cell: (e: FillOutTableItem) => e.rightValue
	},
	{
		id: FillOutTableColumns.Variance,
		label: FillOutTableColumns.Variance,
		minWidth: "90px",
		maxWidth: "90px",
		header: varianceHeader,
		cell: (e: FillOutTableItem) => e.varianceValue
	},
	{
		id: FillOutTableColumns.Commentary,
		label: FillOutTableColumns.Commentary,
		header: FillOutTableColumns.Commentary,
		minWidth: "350px",
		maxWidth: "350px",
		cell: (e: FillOutTableItem) => e.commentary
	},
];

export const getColumnDefinitionForViewingAllBridges = (leftHeader: string, rightHeader: string, varianceHeader: string) => [
	{
		id: FillOutTableColumns.Name,
		label: FillOutTableColumns.Name,
		header: FillOutTableColumns.Name,
		minWidth: "220px",
		maxWidth: "220px",
		cell: (e: FillOutTableItem) => e.name,
	},
	{
		id: FillOutTableColumns.LeftScenario,
		label: FillOutTableColumns.LeftScenario,
		minWidth: "90px",
		maxWidth: "90px",
		header: leftHeader,
		cell: (e: FillOutTableItem) => e.leftValue
	},
	{
		id: FillOutTableColumns.RightScenario,
		label: FillOutTableColumns.RightScenario,
		minWidth: "90px",
		maxWidth: "90px",
		header: rightHeader,
		cell: (e: FillOutTableItem) => e.rightValue
	},
	{
		id: FillOutTableColumns.Variance,
		label: FillOutTableColumns.Variance,
		minWidth: "90px",
		maxWidth: "90px",
		header: varianceHeader,
		cell: (e: FillOutTableItem) => e.varianceValue
	},
];

export const getColumnDefinitionLayoutTwo = (leftHeader: string | JSX.Element, leftValue: string, rowItem: string) => [
	{
		id: FillOutTableColumns.LeftScenario,
		label: FillOutTableColumns.LeftScenario,
		header: leftHeader,
		minWidth: "230px",
		maxWidth: "230px",
		cell: (e: FillOutTableItem) => e.name
	},
	{
		id: FillOutTableColumns.Value,
		label: FillOutTableColumns.Value,
		header: <span style={{float: "right", marginRight: "15px"}}>{getFormattedValue(leftValue, rowItem)}</span>,
		minWidth: "150px",
		maxWidth: "150px",
		cell: (e: FillOutTableItem) => e.varianceValue
	},
	{
		id: FillOutTableColumns.Commentary,
		label: FillOutTableColumns.Commentary,
		header: FillOutTableColumns.Commentary,
		minWidth: "350px",
		maxWidth: "350px",
		cell: (e: FillOutTableItem) => e.commentary
	},
	{
		id: FillOutTableColumns.LastEditedBy,
		label: FillOutTableColumns.LastEditedBy,
		header: FillOutTableColumns.LastEditedBy,
		minWidth: "140px",
		maxWidth: "140px",
		cell: (e: FillOutTableItem) => e.commentator
	},
	{
		id: FillOutTableColumns.Actions,
		label: FillOutTableColumns.Actions,
		header: FillOutTableColumns.Actions,
		cell: (e: FillOutTableItem) => e.actions
	},
];

export const getColumnDefinitionLayoutTwoForViewingBridges = (leftHeader: string | JSX.Element, leftValue: string, rowItem: string) => [
	{
		id: FillOutTableColumns.LeftScenario,
		label: FillOutTableColumns.LeftScenario,
		header: leftHeader,
		minWidth: "35px",
		maxWidth: "35px",
		cell: (e: FillOutTableItem) => e.name
	},
	{
		id: FillOutTableColumns.Value,
		label: FillOutTableColumns.Value,
		header: <span style={{float: "right", marginRight: "5px"}}>{getFormattedValue(leftValue, rowItem)}</span>,
		minWidth: "20px",
		maxWidth: "20px",
		cell: (e: FillOutTableItem) => e.varianceValue
	},
	{
		id: FillOutTableColumns.Commentary,
		label: FillOutTableColumns.Commentary,
		header: FillOutTableColumns.Commentary,
		maxWidth: "150px",
		cell: (e: FillOutTableItem) => e.commentary
	},
];

export const getColumnDefinitionLayoutTwoForViewingAllBridges = (leftHeader: string | JSX.Element, leftValue: string, rowItem: string) => [
	{
		id: FillOutTableColumns.LeftScenario,
		label: FillOutTableColumns.LeftScenario,
		header: leftHeader,
		minWidth: "35px",
		maxWidth: "35px",
		cell: (e: FillOutTableItem) => e.name
	},
	{
		id: FillOutTableColumns.Value,
		label: FillOutTableColumns.Value,
		header: <span style={{float: "right", marginRight: "5px"}}>{getFormattedValue(leftValue, rowItem)}</span>,
		minWidth: "20px",
		maxWidth: "20px",
		cell: (e: FillOutTableItem) => e.varianceValue
	}
]
