import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppState} from "src/reducers/AppState";

interface Crumb {
    text: string,
    href: string
}

export interface BreadcrumbState {
    crumbs: Crumb []
}

const initialState: BreadcrumbState = {crumbs: []}

const breadcrumbSlice = createSlice({
    name: 'breadcrumb',
    initialState,
    reducers: {
        setBreadcrumb(breadcrumbState, {payload}: PayloadAction<Crumb[]>) {
            breadcrumbState.crumbs = payload;
        }
    }
});


export default breadcrumbSlice.reducer
// Export actions
export const {setBreadcrumb} = breadcrumbSlice.actions;
// Export selectors
export const selectBreadcrumbs = (state: AppState) => state.breadcrumb.crumbs;
