import {Alert, Box, FormField, Header, Input, Modal, SpaceBetween} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {postThemes, setThemesAlert} from "src/actions/themes.actions";
import {BridgeItem} from "src/components/BridgingCommentary/interfaces";
import {BridgeModalProps} from "src/components/BridgingCommentary/modalComponents/interfaces";
import {FastTheme, getFastTheme} from "src/components/Themes/reportBridgingIntegration/fastThemeCreation";
import {getThemesApiLoading} from "src/reducers/apiLoading.reducer";
import {forThemesAlert} from "src/reducers/themes.reducer";

export interface CreateThemeModalProps extends Required<BridgeModalProps> {
  bridges: BridgeItem[],
  setUpdatingBridges :React.Dispatch<React.SetStateAction<BridgeItem[] | null>>,
  reportId: string,
  fastTheme: FastTheme | undefined
}

export const CreateThemeModal = ({visible, setVisible, reportId, bridges, setUpdatingBridges, fastTheme}: CreateThemeModalProps) => {
  const dispatch = useDispatch();
  const alert = useSelector(forThemesAlert)
  const createLoading = useSelector(getThemesApiLoading)
  const {report_name = ""} = useParams();
  const [themeData, setThemeData] = useState<{name: string}>({name: ''})
  const bridgeIds = bridges.map(bridge => bridge.bridge_id)

  useEffect(() => {
    const fastTheme = getFastTheme(bridges)
    fastTheme && setThemeData(prevState => ({...prevState, name: `${report_name} - ${fastTheme}`}))
  }, [bridges])

  const onModalDismiss = () => {
    dispatch(setThemesAlert(undefined))
    setUpdatingBridges(null);
    setVisible(false);
  }

  const onCreate = () => dispatch(postThemes(themeData.name, reportId, bridgeIds, fastTheme))
  return  <Modal
    onDismiss={onModalDismiss}
    visible={visible}
    closeAriaLabel="Close modal"
    footer={
      <Box float={"right"}>
        <Button
          variant={'primary'}
          disabled={!themeData.name}
          onClick={onCreate}
          loading={createLoading}
        >
          Create
        </Button>
      </Box>
    }
    header={
      <>
        {alert && <Alert type={alert.type}>{alert.message}</Alert>}
        <Header variant="h1">Theme Details</Header>
      </>
    }
  >
    <SpaceBetween direction="vertical" size="m">
      <FormField label="Name">
        <Input onChange={event => setThemeData({...themeData, name: event.detail.value})} value={themeData.name}/>
      </FormField>
    </SpaceBetween>
  </Modal>
}