import {Middleware} from "redux";
import {
	addHistoryForBridge,
	addSubItemsForBridge,
	BRIDGE,
	BRIDGE_BEING_VIEWED_CURRENTLY,
	BRIDGE_OWNER_GROUPS,
	BRIDGES,
	BRIDGES_BEING_VIEWED_CURRENTLY,
	CHANGE_BRIDGE_STATE,
	COMMENTS,
	COMMENTS_FOR_VARIANCE_COLUMN,
	getComments,
	getCommentsForVarianceColumn,
	MARKERS,
	REPORT_BRIDGES,
	setBridgeBeingViewedCurrently,
	setBridgeItems,
	setBridgeOwnerGroups,
	setBridgesBeingViewedCurrently,
	setBridgeStatus,
	setBridgeSubItems,
	setBridgingPageStatus,
	setBridgingPaginationConfig,
	setCommentators,
	setFillingBridges,
	setMarkers,
	setReportBridgeItems,
	setWizardBridgeStatus,
	WIZARD_BRIDGE
} from "src/actions/bridgingCommentary.actions";
import {Roles} from "src/common/roles";
import {BridgeItem} from "src/components/BridgingCommentary/interfaces";
import {defaultBridgeStatus} from "src/components/FillOutBridgePage/FillOutBridgePage";
import {BridgeSubItem} from "src/components/FillOutBridgePage/interfaces";
import {setReportBridge, updateBridge} from "src/features/reportBridges/reportBridgeSlice";
import {AppState as State} from "src/reducers/AppState";
import {combineBridgeItemLists, getBridgeOwnerGroups} from "src/utils/bridgingHelpers";


const bridgingCommentaryMiddleware: Middleware<{}, State> = ({dispatch, getState}) => next => action => {
    next(action);
	if (action.payload) {
		const {data = {}, errorData = {}, params = {}, method = ''} = action.payload;
		const currentState = getState();
		const currentStatus = currentState.bridgingCommentary.status;
		let error = "";
		let bridgeId = "";
		if (errorData) {
			const errorType = errorData.message;
			const errorMessage = errorData.reason || errorData.parameter || 'Empty error message';
			error = `${errorType}: ${errorMessage}`;
		}
		switch (action.type) {
			/*********************************** Bridges API ***********************************/
			case `${BRIDGES} API_SUCCESS`:
				if (method === 'GET') {
					const {bridge_items, nextPaginationKey, hasMore} = data;
					// Get current groups in redux store
					const currentBridges = getState().bridgingCommentary.bridgeItems;
					// Append groups or reset the library with refreshed groups
					const fetched_bridges = params.paginationKey ? combineBridgeItemLists(currentBridges, bridge_items)
						: bridge_items;
					// Dispatch to modify the mdxLibrary key in redux
					dispatch(setBridgeItems(fetched_bridges));
					dispatch(setBridgingPageStatus({
						...currentStatus,
						bridges: {fetched: true}
					}));
					dispatch(setBridgingPaginationConfig({paginationKey: nextPaginationKey, hasMore}));
				}
				break;
			case `${BRIDGES} API_ERROR`:
				console.error({error, params});
				dispatch(setBridgingPageStatus({
					...currentStatus,
					bridges: {fetched: false, error}
				}));
				break;
			/*********************************** Bridge API ***********************************/
			case `${BRIDGE} API_SUCCESS`:
				const currentBridgeItems = currentState.bridgingCommentary.bridgeItems;
				if (method === 'GET'){
					const updateBridgeItem = data.bridge_item as BridgeItem;
					const fillingOutBridges = currentState.bridgingCommentary.fillingBridges.bridgeItems;
					const updatedFillingOutBridges = combineBridgeItemLists(fillingOutBridges, [updateBridgeItem]);
					dispatch(setFillingBridges(updatedFillingOutBridges));
				} else if (method === 'POST') {
					const newBridgeItem = params.bridge_item as BridgeItem;
					const currentBridgeStatus = currentStatus.bridge[newBridgeItem.bridge_id] || defaultBridgeStatus;
					dispatch(setBridgeItems(combineBridgeItemLists(currentBridgeItems, [newBridgeItem])));
					dispatch(setBridgeStatus(
						newBridgeItem.bridge_id,
						{...currentBridgeStatus, saved: true, fetching: false}
					));
				} else if (method === 'DELETE') {
					bridgeId = params.bridge_id;
					const currentBridgeStatus = currentStatus.bridge[bridgeId] || defaultBridgeStatus;
					dispatch(setBridgeItems(currentBridgeItems.filter(bridgeItem => bridgeItem.bridge_id !== bridgeId)));
					dispatch(setBridgeStatus(bridgeId, {...currentBridgeStatus, deleted: true}));
				}
				break;
			case `${BRIDGE} API_ERROR`:
				console.error({error, params});
				if (method === 'POST') {
					const bridgeItem = params.bridge_item as BridgeItem;
					dispatch(setBridgingPageStatus({
						...currentStatus,
						bridge: {
							...currentStatus.bridge,
							[bridgeItem.bridge_id]: {saved: false, error}
						}
					}));
				} else if (method === 'DELETE') {
					bridgeId = params.bridge_id;
					dispatch(setBridgingPageStatus({
						...currentStatus,
						bridge: {
							...currentStatus.bridge,
							[bridgeId]: {deleted:false, error}
						}
					}));
				}
				break;
			/*********************************** WIZARD Bridge API ***********************************/
			case `${WIZARD_BRIDGE} API_SUCCESS`:
				const existingBridgeItems = currentState.bridgingCommentary.bridgeItems;
				if (method === 'POST') {
					const newBridgeItem = params.bridge_item as BridgeItem;
					const currentBridgeWizardStatus = currentStatus.wizardBridge[newBridgeItem.bridge_id] || defaultBridgeStatus;
					dispatch(setBridgeItems(combineBridgeItemLists(existingBridgeItems, [newBridgeItem])));
					dispatch(setWizardBridgeStatus(
						newBridgeItem.bridge_id,
						{...currentBridgeWizardStatus, saved: true, fetching: false, requestInputs: {saved: false, requestInput: false}}
					));
				}
				break;
			case `${WIZARD_BRIDGE} API_ERROR`:
				console.error({error, params});
				if (method === 'POST') {
					const bridgeItem = params.bridge_item as BridgeItem;
					dispatch(setBridgingPageStatus({
						...currentStatus,
						wizardBridge: {
							...currentStatus.wizardBridge,
							[bridgeItem.bridge_id]: {saved: false, error, requestInputs: {saved: false, requestInput: false}}
						}
					}));
				}
				break;
            /*********************************** Bridge Owner Groups API ***********************************/
            case `${BRIDGE_OWNER_GROUPS} API_SUCCESS`:
                if (method === 'GET') {
                    dispatch(setBridgeOwnerGroups(data.groups));
                    dispatch(setBridgingPageStatus({
                        ...currentStatus,
                        bridgeOwnerGroups: {fetched: true}
                    }));
                }
                break;
            case `${BRIDGE_OWNER_GROUPS} API_ERROR`:
                console.error({error, params});
                dispatch(setBridgingPageStatus({
                    ...currentStatus,
                    bridgeOwnerGroups: {error, fetched: false}
                }));
                break;

            /*********************************** Report Bridges API ***********************************/
            case `${REPORT_BRIDGES} API_SUCCESS`:
                if (method === 'GET') {
                    const bridgeOwnerGroup = getBridgeOwnerGroups(data.bridge_items)
                    dispatch(setReportBridgeItems(data.bridge_items, bridgeOwnerGroup))
					dispatch(setReportBridge({report_id : params.report_id, bridgeItems :data.bridge_items }))
                }
                break;
            case `${REPORT_BRIDGES} API_ERROR`:
                console.error({errorData, params});
                break;
            /*********************************** Bridges being viewed currently API ***********************************/
            case `${BRIDGES_BEING_VIEWED_CURRENTLY} API_SUCCESS`:
                if (method === 'GET') {
                    // dispatch(setBridgesBeingViewedCurrently(data.bridge_items));
                    for (let i = 0; i < data.bridge_items.length; i++) {
                        dispatch(getCommentsForVarianceColumn(data.bridge_items[i].bridge_id, Roles.Admin));
                    }
                }
                break;
            case `${BRIDGES_BEING_VIEWED_CURRENTLY} API_ERROR`:
                console.error({errorData, params});
                break;
            /*********************************** Change State API ***********************************/
			case `${CHANGE_BRIDGE_STATE} API_SUCCESS`:
				if (method === 'POST') {
					const updatedBridgeItem = data.bridge_item as BridgeItem;
					const fillingBridgeItems = currentState.bridgingCommentary.fillingBridges.bridgeItems;
					const currentBridgeStatus = currentStatus.bridge[updatedBridgeItem.bridge_id] || defaultBridgeStatus;
					const wizardBridgeStatus = currentStatus.wizardBridge[updatedBridgeItem.bridge_id]

					dispatch(setFillingBridges(combineBridgeItemLists(fillingBridgeItems, [updatedBridgeItem])));
					dispatch(setBridgeStatus(
						updatedBridgeItem.bridge_id,
						{...currentBridgeStatus, refreshingVersions: true, saved: true}
					));
					dispatch(updateBridge({report_id: updatedBridgeItem.report_id,  bridgeItem : updatedBridgeItem}))

					// TODO refactor backend response to include timestamp - the line below wouldn't be needed
					params.action !== 'save' && dispatch(getComments(updatedBridgeItem.bridge_id, params.role))

					/*
					 This dispatch for redux param is wizardBridgeStatus is required as in CreateBridge
					 checkBridgeSubItemsSaved method, refreshingComments param is used to check all subItems
					 of BridgeItem is saved successfully
					*/
					if(wizardBridgeStatus){
						const updatedWizardBridgeStatus = {...wizardBridgeStatus}

						!wizardBridgeStatus.requestInputs?.requestInput && dispatch(setWizardBridgeStatus(
							updatedBridgeItem.bridge_id, {...updatedWizardBridgeStatus, refreshingComments: false}
						));

						wizardBridgeStatus.requestInputs?.requestInput && dispatch(setWizardBridgeStatus(
							updatedBridgeItem.bridge_id,
							{...updatedWizardBridgeStatus,
								requestInputs: {saved: true, requestInput: false}
							}
						));
					}
					console.log("Change state complete")
				}
				break;
			case `${CHANGE_BRIDGE_STATE} API_ERROR`:
				console.error({error, params});
				bridgeId = params.bridge_id;
				const wizardBridgeStatus = currentStatus.wizardBridge[bridgeId]
				dispatch(setBridgingPageStatus({
					...currentStatus,
					bridge: {
						...currentStatus.bridge,
						[bridgeId]: {saved: false, error}}
				}));

				if(wizardBridgeStatus){
					dispatch(setWizardBridgeStatus(
						bridgeId,
						{...wizardBridgeStatus,
							requestInputs: {saved: false, requestInput: false, error}
						}
					));
				}

				break;

			/*********************************** Comments API ***********************************/
			case `${COMMENTS} API_SUCCESS`:
				if (method === 'GET') {
					bridgeId = data.bridge_id;
					if ('history_id' in params && params.history_id) {
						// Add version to history
						const historyId = params.history_id
						const subItemHistory = data.bridge_sub_items
						dispatch(addHistoryForBridge(bridgeId, historyId, subItemHistory))

						// Set bridge status
						const currentBridgeStatus = currentStatus.bridge[bridgeId]
						const newBridgeStatus = {
							...currentBridgeStatus,
							// We have successfully process GET call, thus remove 1 from the tracker
							fetchingVersions: currentBridgeStatus.fetchingVersions ?
								currentBridgeStatus.fetchingVersions - 1 : 0,
						}

						// Only update fetched versions when we initially fetch comment versions
						newBridgeStatus.fetchedVersions = currentBridgeStatus.refreshingVersions ?
							currentBridgeStatus.fetchedVersions :
							!newBridgeStatus.fetchingVersions

						// Update refreshingVersions only when we are refreshing versions
						newBridgeStatus.refreshingVersions = currentBridgeStatus.refreshingVersions ?
							!!newBridgeStatus.fetchingVersions :
							currentBridgeStatus.refreshingVersions

						dispatch(setBridgeStatus(bridgeId, newBridgeStatus))
					} else {
						dispatch(setBridgeSubItems(data));
						dispatch(setCommentators(data));
						const bridgeSubItems = data.bridge_sub_items as BridgeSubItem[]
						dispatch(addSubItemsForBridge(bridgeId, bridgeSubItems));

						// Batch GET all history for the bridge
						const bridgeHistoryIds = data.history as string[]
						const historyForBridges = currentState.bridgingCommentary.fillingBridges.historyForBridges
						const currentHistoryIds = historyForBridges[bridgeId] ? Object.keys(historyForBridges[bridgeId]) : []
						const currentIdSet = new Set(currentHistoryIds)
						const newHistoryIds = bridgeHistoryIds.filter(id => !currentIdSet.has(id))
						newHistoryIds.forEach(id => {
							dispatch(getComments(bridgeId, params.role, id, 'submitted'))
							dispatch(addHistoryForBridge(bridgeId, id, {})) // Dispatch as placeholder to ensure order
						})

						const currentBridgeStatus = currentStatus.bridge[bridgeId] || defaultBridgeStatus;

						// Refreshing previous "most recent" version since the follow-up is added there
						const filteredHistoryIds = currentHistoryIds.filter(id => id !== 'latest')
						if (currentBridgeStatus.refreshingVersions && currentHistoryIds.length > 0 && filteredHistoryIds.length > 0) {
							dispatch(getComments(bridgeId, params.role, filteredHistoryIds[filteredHistoryIds.length - 1], 'submitted'))
							newHistoryIds.push(filteredHistoryIds[filteredHistoryIds.length - 1])
						}

						// Set bridge status
						dispatch(setBridgeStatus(
							bridgeId,
							{
								...currentBridgeStatus,
								fetchedComments: true,
								fetchingComments: false,
								refreshingComments: false,
								// If refreshing versions, don't touch fetchedVersions as it is used for initial loader
								fetchedVersions: currentBridgeStatus.refreshingVersions ?
									currentBridgeStatus.fetchedVersions :
									!newHistoryIds.length,
								// fetchingVersions tracks outbound GET calls for fetching a version
								fetchingVersions: currentBridgeStatus.fetchingVersions ?
									currentBridgeStatus.fetchingVersions + newHistoryIds.length :
									newHistoryIds.length
							}
						))
					}
					console.log("Get comments complete")
				} else if(method === 'POST') {
					bridgeId = params.bridge_id
					const currentBridgeStatus = currentStatus.bridge[bridgeId] || defaultBridgeStatus;
					dispatch(setBridgeStatus(
						bridgeId,
						{...currentBridgeStatus, saved: true}
					))
				}
				break;
			case `${COMMENTS} API_ERROR`:
				console.error({error, params});
				bridgeId = params.bridge_id;
				dispatch(setBridgingPageStatus({
					...currentStatus,
					bridge: {
						...currentStatus.bridge,
						[bridgeId]: {fetchedComments: false, error, fetchingComments: false}
					}
				}));
				break;
			/*********************************** COMMENTS_FOR_VARIANCE_COLUMN API ***********************************/
			case `${COMMENTS_FOR_VARIANCE_COLUMN} API_SUCCESS`:
				bridgeId = data.bridge_id;
				const bridgeSubItems = data.bridge_sub_items as BridgeSubItem[]
				if (method === 'GET') {
					dispatch(setBridgesBeingViewedCurrently({
						...currentState.bridgingCommentary.bridgesBeingViewedCurrently,
						[bridgeId]: bridgeSubItems
					}));
				}
				break;
			case `${COMMENTS_FOR_VARIANCE_COLUMN} API_ERROR`:
				console.error({errorData, params});
				break;
			/*********************************** BRIDGE_BEING_VIEWED_CURRENTLY API ***********************************/
			case `${BRIDGE_BEING_VIEWED_CURRENTLY} API_SUCCESS`:
				if (method === 'GET') {
					dispatch(setBridgeBeingViewedCurrently(data));
				}
				break;
			case `${BRIDGE_BEING_VIEWED_CURRENTLY} API_ERROR`:

				console.error({errorData, params});

				break;
			/*********************************** MARKERS API ***********************************/
			case `${MARKERS} API_SUCCESS`:
				if (method === 'GET') {
					dispatch(setMarkers(data.markers));
				}
				break;
			case `${MARKERS} API_ERROR`:
				console.error({errorData, params});
				break;
		}
	}
};

export default bridgingCommentaryMiddleware;
