import {Alert, Modal, SpaceBetween} from "@amzn/awsui-components-react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {getReportLibrary, resetReportLibraryStatus} from "src/actions/reportLibrary.actions";
import {
    WARNING_DELETE,
    WARNING_PUBLISH_TO_BUSINESS,
    WARNING_REMOVE_FROM_FOLDER,
    WARNING_UNPUBLISH_FROM_BUSINESS
} from "src/components/ReportLibraryPage/constants";

// Action which causes these confirmation text will not require page re-direct
const nonRedirectConfirmationTexts = new Set<string>([
    WARNING_DELETE, WARNING_REMOVE_FROM_FOLDER, WARNING_PUBLISH_TO_BUSINESS, WARNING_UNPUBLISH_FROM_BUSINESS
])
const ConfirmationModal = (
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    redirectLink: string,
    confirmationText: string,
    onClickFunction: Function,
    currentLibrary: string,
    confirmButtonLoading: boolean,
    canDismiss: boolean,
    errorText: string
) => {
    const dispatch = useDispatch();

    useEffect(function dismissModal() {
        if(canDismiss) {
            // Fetch current library for deletion and move out of folder, publish and up-publish to business actions
            if(nonRedirectConfirmationTexts.has(confirmationText)) {
                dispatch(getReportLibrary({reportLibrary: currentLibrary, limit: 51}));
            } else {
                setTimeout(() => {
                    window.location.replace(redirectLink);
                }, 40)
            }
            onModalDismiss();
        }
    }, [canDismiss]);

    const onModalDismiss = () => {
        dispatch(resetReportLibraryStatus());
        setVisible(false);
    }

    return (
        <Modal
            onDismiss={() => {
                setVisible(false)
            }}
            visible={visible}
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link"
                                disabled={confirmButtonLoading}
                                onClick={() => onModalDismiss()}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary"
                                loading={confirmButtonLoading}
                                onClick={() => onClickFunction()}
                        >
                            Confirm
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header="Confirm Report Change"
        >
            <SpaceBetween direction='vertical' size='s'>
                {errorText && errorText.length > 0 &&
                    <Alert type='error'>{errorText}</Alert>
                }
                {confirmationText.toString()}
            </SpaceBetween>
        </Modal>
    );
}

export default ConfirmationModal;