import {Button, SpaceBetween} from "@amzn/awsui-components-react";
import React from "react";
import {ConfirmReportSyncModalFooterProps} from "src/components/ViewReportPage/interfaces";

export const ConfirmReportSyncModalFooter = ({onCancel, onConfirm}: ConfirmReportSyncModalFooterProps) => {
    const confirmHandler = () => {
        onConfirm()
        onCancel()
    }

    return(
        <SpaceBetween direction={"horizontal"} size={"xs"}>
            <Button formAction={"none"} onClick={() => onCancel()}>
                Cancel
            </Button>
            <Button variant={"primary"} formAction={"none"} onClick={() => confirmHandler()}>
                Confirm
            </Button>
        </SpaceBetween>
    );
}
