import React from "react"
import {useSelector} from "react-redux";
import {Outlet, useLocation} from "react-router-dom";
import {Roles} from "src/common/roles";
import UserAccessException from "src/components/Common/UserAccessException";
import {getPageSettings} from "src/components/PageConfig/PageSettings";
import {getAssumedRole, getAvailableRoles, getUser} from "src/reducers/user.reducer";


const RouteGuard = () => {
    const location = useLocation();

    // Selectors
    const user = useSelector(getUser);
    const assumedRole = useSelector(getAssumedRole);
    const availableRoles = useSelector(getAvailableRoles);
    // Constants
    const allowedRoles = getPageSettings(location.pathname).permissionSetting.read;
    const canAccessPage = allowedRoles.has(assumedRole || Roles.UNKNOWN)

    return canAccessPage ?
        <Outlet />
        : <UserAccessException
            user={user}
            assumedRole={assumedRole}
            allowedRoles={[...allowedRoles]}
            availableRoles={availableRoles}
        />
}

export default RouteGuard


