import { configureStore } from '@reduxjs/toolkit'

import {facetsAPI, workflowAPI} from '../apiSlice'
import {workflowReducer} from '../workflowSlice'

export const workflowStore = configureStore({
    reducer: {
      workflow : workflowReducer,
      [facetsAPI.reducerPath]: facetsAPI.reducer,
      [workflowAPI.reducerPath]: workflowAPI.reducer,
    },
    middleware: (gDM) => gDM().concat(facetsAPI.middleware).concat(workflowAPI.middleware),
  })
export default workflowStore