import {FocusProps} from "src/components/ViewReportPage/interfaces";
import styled, {css} from "styled-components";

export const TDCell = styled.td`
  padding: 0 10px 0 4px;
  border-top: thin solid transparent;
  border-bottom: thin solid transparent;
  border-right: thin solid transparent;

  .underlined {
    border-bottom-color: darkgray;
  }
`;

export const THCell = styled.th`
  border-bottom: 1px solid gray;
  padding-right: 4px;
  padding-left: 4px;
  background: var(--table-header-bg-color);
  color: white;
  min-width: 6em;
  max-width: 9em;
  white-space: normal; // wrap as this tends to be long
  text-align: right;
`;

export const TBodyRow = styled.tr<FocusProps>`
  border: none; // cell borders are easier to control

  &:hover { // though entire row gets borders when hovering. cells allow control during focus.
    td {
      cursor: pointer;
      border-top-color: dodgerblue;
      border-bottom-color: dodgerblue;
    }

    td:last-child {
      border-right-color: dodgerblue;
    }
  }

  ${props => !!props.focus && css` // hover foreshadows focus
    td {
      font-weight: 600;
      color: var(--focused-cell-font-color);
      background-color: var(--focused-cell-bg-color);
    }

    td:last-child {
      border-right: 3px solid dodgerblue;
    }
  `
  }
  td:first-child {
    text-overflow: ellipsis; // first cell with line-item name may be long
    overflow: hidden;
  }
`;

export const THead = styled.thead`
  height: 4em; // this should be same as HeaderWithCrumb's height to align left & right panel 
`;

export const TBody = styled.tbody`
  transition: background 0.2s ease-in;

  tr:nth-child(odd) {
    background: ghostwhite; // alternate colors at row, so that cell can later override for focus
  }

  tr:focus-visible {
    outline: 1.5px solid var(--outline-color);
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 2em; // pushes the contour down and perception of "bridged-by" is in a new line
  // font-size: medium; same as 16px
  font-size: 14px;
  table-layout: fixed; // non-responsive after load
  white-space: nowrap; // not wrapping as a global rule
  --focused-cell-bg-color: #1b91ff21;
  --focused-cell-font-color: #005eb9;
  --table-header-bg-color :#005eb9;
  --outline-color : #1388f0;
`;



