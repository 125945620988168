import {ButtonDropdown} from "@amzn/awsui-components-react";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteReportDefinition,
    deleteReviewFolder,
    postReportPublisher,
    setUpdatingExternalReportDefinitions,
    setUpdatingReportDefinitions,
    setUpdatingReviewFolderDefinitions
} from "src/actions/reportLibrary.actions";
import {ItemType, Libraries, ReportDefinition} from "src/common/report";
import {Roles} from "src/common/roles";
import Paths from "src/components/PageConfig/Paths";
import ConfirmationModal from "src/components/ReportLibraryPage/ConfirmationModal";
import {
    ARCHIVE_REPORTS_TITLE,
    DELETE,
    DELETE_REVIEW,
    DUPLICATE,
    DUPLICATE_REVIEW,
    MOVE_TO_PUBLSIHED,
    WARNING_DELETE,
    WARNING_MOVE_TO_REFERENCE
} from "src/components/ReportLibraryPage/constants";
import {createDuplicateReportDefinition, getAvailableActions} from "src/components/ReportLibraryPage/helpers";
import ReportLibrary from "src/components/ReportLibraryPage/ReportLibrary";
import {
    getReportDefinitionsApiLoading,
    getReportPublisherApiLoading,
    getReviewFolderApiLoading
} from "src/reducers/apiLoading.reducer";
import {getReportLibraryStatus} from "src/reducers/reportLibrary.reducer";
import {getAssumedRole} from "src/reducers/user.reducer";
import {filterExternalReportState} from "src/utils/reportHelpers";
import {v4 as uuid} from "uuid";

const ArchiveReportLibraryPage = () => {
    const dispatch = useDispatch();

    const assumedRole = useSelector(getAssumedRole);
    const {publishedReport, deletedReportDefinitions,updatedReviewFolders, deletedReviewFolder, errorType, errorMessage} = useSelector(getReportLibraryStatus);
    const reportDefinitionsApiIsLoading = useSelector(getReportDefinitionsApiLoading);
    const reportPublisherApiIsLoading = useSelector(getReportPublisherApiLoading);
    const reviewFolderApiIsLoading = useSelector(getReviewFolderApiLoading);

    const [isConfirmationModalReferencesVisible, setIsConfirmationModalReferencesVisible] = useState<boolean>(false);
    const [confirmationText, setConfirmationText] = useState<string>("");
    const [onClickFunction, setOnClickFunction] = useState(() => () => console.log("default function"));
    const [redirectUrl, setRedirectUrl] = useState<string>(window.location.href);

    const reportActions = (report: ReportDefinition, reviewFolderId: string) => <ButtonDropdown
        expandToViewport = {true}
        items={getAvailableActions(assumedRole, "Archive", report.item_type, reviewFolderId !== "")}
        ariaLabel="Control instance"
        variant="icon"
        onItemClick={event => {
            let updatedReportState: ReportDefinition;
            switch (event.detail.id) {
                case DELETE_REVIEW:
                    const deleteReviewHandler= () => () => {
                        dispatch(deleteReviewFolder(report));
                    }
                    setOnClickFunction(deleteReviewHandler);
                    setIsConfirmationModalReferencesVisible(true);
                    setConfirmationText(WARNING_DELETE);
                    break
                case DUPLICATE_REVIEW:
                    updatedReportState = {
                        ...report,
                        report_id: `review_folder_id.${uuid()}`,
                        report_status: "Creating",
                        name_and_type: {
                            ...report.name_and_type,
                            report_name: `${report.name_and_type?.report_name}-DUPLICATE`
                        }
                    };
                    dispatch(setUpdatingReviewFolderDefinitions(updatedReportState));
                    window.location.replace(`#${Paths.CREATEREVIEW}`);
                    break
                case DELETE:
                    const deleteReportHandler = () => () => {
                        dispatch(deleteReportDefinition(report.report_id));
                    }
                    setOnClickFunction(deleteReportHandler);
                    setIsConfirmationModalReferencesVisible(true);
                    setConfirmationText(WARNING_DELETE);
                    break
                case DUPLICATE:
                    if(report.item_type === ItemType.External) {
                        updatedReportState = filterExternalReportState(report);
                        dispatch(setUpdatingExternalReportDefinitions({
                            ...updatedReportState,
                            report_status: 'Creating',
                            report_id: `report_id.${uuid()}`,
                            name_and_type: {
                                ...updatedReportState.name_and_type,
                                report_name: "name_duplicate"
                            }
                        }));
                        window.location.replace(`#${Paths.EXTERNALREPORT}`);
                    } else {
                        const updatedInternalReportState = createDuplicateReportDefinition(report)
                        dispatch(setUpdatingReportDefinitions(updatedInternalReportState));
                        window.location.replace(`#${Paths.CREATEREPORT}`);
                    }
                    break
                case MOVE_TO_PUBLSIHED:
                    const libraryPublisherToReferenceLibrary = () => () => {
                        dispatch(postReportPublisher(
                            report.report_id,
                            Roles.FinanceLeader,
                            Libraries.Reference
                        ));
                        setRedirectUrl(`#${Paths.REFERENCEREPORTS}`);
                    }
                    setOnClickFunction(libraryPublisherToReferenceLibrary);
                    setIsConfirmationModalReferencesVisible(true);
                    setConfirmationText(WARNING_MOVE_TO_REFERENCE);
                    break;
            }
        }}
    />

    const confirmationModal = ConfirmationModal(
        isConfirmationModalReferencesVisible,
        setIsConfirmationModalReferencesVisible,
        redirectUrl,
        confirmationText,
        onClickFunction,
        "Archive",
        reportPublisherApiIsLoading || reportDefinitionsApiIsLoading || reviewFolderApiIsLoading,
        !!(publishedReport || deletedReportDefinitions || updatedReviewFolders || deletedReviewFolder),
        errorType ? `${errorType}: ${errorMessage}` : ""
    );

    return ReportLibrary(
        ARCHIVE_REPORTS_TITLE,
        "This is a collection of all the archived reports",
        `#${Paths.ARCHIVEREPORTS}`,
        null,
        reportActions,
        confirmationModal,
        "Archive",
        false
    );
}

export default ArchiveReportLibraryPage;