import {Button, Popover, StatusIndicator} from "@amzn/awsui-components-react";
import React from "react";

export const CopyToClipboard = (props: {textToCopy: string, message: string}) => {
	const {textToCopy, message} = props;
	return (
		<Popover
			position="top"
			triggerType="custom"
			content={
				<StatusIndicator type="success">
					{message}
				</StatusIndicator>
			}
		>
			<Button
				data-tooltip-id={'global'}
				data-tooltip-content={"Copy link to bridge"}
				iconName="copy"
				variant="inline-icon"
				onClick={() => {
					const promise = navigator.clipboard.writeText(textToCopy);
				}}
			/>
		</Popover>
	);
}

export default CopyToClipboard
