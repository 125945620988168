export const appLayoutLabels = {
  navigation: 'Side navigation',
  navigationToggle: 'Open side navigation',
  navigationClose: 'Close side navigation',
  notifications: 'Notifications',
  tools: 'Help panel',
  toolsToggle: 'Open help panel',
  toolsClose: 'Close help panel'
};


export const paginationAriaLabels = {
  nextPageLabel: "Next page",
  previousPageLabel: "Previous page",
  pageLabel: (pageNumber:number) => `Page ${pageNumber} of all pages`
};

export const externalLinkProps = {
  external: true,
  externalIconAriaLabel: 'Opens in a new tab'
};