import bridgingCommentaryMiddleware from "src/middleware/app/bridgingCommentary.middleware";
import {themesMiddleware} from "src/middleware/app/themes.middleware";

import fileTransferMiddleware from "./fileTransfer.middleware";
import mdxLibraryPageMiddleware from "./mdxLibrary.middleware";
import reportLibraryPageMiddleware from "./reportLibrary.middleware";
import {userMiddleware} from "./user.middleware";


export const appMiddleware = [
    userMiddleware,
    mdxLibraryPageMiddleware,
    fileTransferMiddleware,
    reportLibraryPageMiddleware,
    bridgingCommentaryMiddleware,
    themesMiddleware
];
