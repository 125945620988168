/* eslint-disable */
import classnames from 'classnames'
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setSelectedBridgeId, setSelectedRow} from "src/actions/bridgingCommentary.actions";
import {BridgingTableColumns} from "src/components/BridgingCommentary/constants";
import {getFormattedValue} from "src/components/FillOutBridgePage/FillOutBridgeTableHelpers";
import {nonDollarValueRowItems} from "src/components/FillOutBridgePage/InputComponents";
import {Table, TBody, TBodyRow, TDCell, THCell, THead} from "src/components/ViewReportPage/BridgeModals/Styled";
import {forInViewBridgeSubItem, forRowToBridgeRelation} from "src/reducers/bridgingCommentary.reducer";

TreeTable.defaultProps = {
    data: [{"No": "", "Data": ""}],
    headerData: [],
    disableCollapse: false,
    defaultLevelsRendered: 5,
    trimAtLevel: 100,
    fullToggle: null,
    rowClick: () => false,
    overrideHeaders: [],
    overrideRowDisplay: [], // give up control of filtering rows to render and disable self actions.
    liftRowDisplay: () => {
        return false
    },
    errorClassAt: {errors: {}, class: null},//errors is a map of maps.
    numHeaders: [],
    numTdClass: "num-right",
};

const arrayFromRange = (start, stop, step = 1) => Array(stop - start).fill(start).map((x, y) => x + y * step);

const treeClass = {
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
    6: "six",
    7: "seven"
};

export function TreeTable(props) {
    const tbodyRef = React.useRef(null);
    const dispatch = useDispatch();
    const {rowIdToBridgeId} = useSelector(forRowToBridgeRelation);
    const viewingRow = useSelector(forInViewBridgeSubItem);
    const [focusedRow, setFocusedRow] = useState({row_id: null});
    const [allowToggle, setAllowToggle] = useState(true);
    // rowDisplay is an Array of booleans which filter rows to render
    const [rowDisplay, setRowDisplay] = useState(props.data.map(r => r.treeLevel <= props.defaultLevelsRendered));
    useEffect(() => {
        const newRowDisplay = props.data.map(r => r.treeLevel <= props.defaultLevelsRendered);
        setRowDisplay(newRowDisplay);
        props.liftRowDisplay(newRowDisplay);
    }, [props.data]);

    if (viewingRow !== undefined) {
        useEffect(() => {
            viewingRow && setFocusedRow({row_id: viewingRow.row_id})
        }, [viewingRow.row_id])
    }

    useEffect(() => {
        if (props.fullToggle !== null && props.overrideHeaders.length > 0) {
            props.fullToggle ? setRowDisplay(props.data.map(r => r.tree_level <= 1)) : setRowDisplay(Array(props.data.length).fill(true))
        }
    }, [props]);

    useEffect(() => {
        const shouldToggle = !props.disableCollapse && props.overrideRowDisplay.length === 0;
        setAllowToggle(shouldToggle);
        if (props.data.length === props.overrideRowDisplay.length) {
            setRowDisplay(props.overrideRowDisplay);
        }
    }, [props.overrideRowDisplay]);

    const handleRowClick = (operation, rowIndex, treeLevel) => {
        if (props.disableCollapse || props.overrideRowDisplay.length > 0) {
            return false
        }
        let newRowDisplay = [...rowDisplay];
        const updateArray = (indexToUpdate, bool) => {
            newRowDisplay = [...newRowDisplay.slice(0, indexToUpdate - 1), bool, ...newRowDisplay.slice(indexToUpdate)];
        };

        const tail = props.data.slice(rowIndex + 1);

        const tailAtSameLevelOrAbove = tail.filter(nextRow => nextRow.treeLevel <= treeLevel); // If 2, then return 2 or 1 or 0
        const stopAt = tailAtSameLevelOrAbove[0];
        let stopIndex = tail.includes(stopAt) ? props.data.indexOf(stopAt) : props.data.length; // This is only for terminal branch that will undefined stopAt

        const childIndices = arrayFromRange(rowIndex + 1, stopIndex);
        (operation === "collapse") && childIndices.forEach(c_idx => newRowDisplay[c_idx] = false);

        const tailAtOneLevelBelow = tail.filter(nextRow => nextRow.treeLevel === (treeLevel + 1));
        let indices = tailAtOneLevelBelow.map(c => props.data.indexOf(c));
        const directChildIndices = indices.filter(i => i <= stopIndex);
        (operation === "expand") && directChildIndices.forEach(c_idx => newRowDisplay[c_idx] = true);

        setRowDisplay(newRowDisplay);
        props.liftRowDisplay(newRowDisplay)
    };

    function getHeaderList(data) {
        let keyArr = data[0] ? Object.keys(data[0]) : [];
        // Drop Index is used to filter out all unneeded keys from data so that we don't have unwanted columns
        const dropIndex = ["initIndex", "row_id", "treeLevel", "last_edited_timestamp", "touched", "commentary", "commentator", 'follow_up'];

        function deleteElementFromArray(el) {
            let index = keyArr.indexOf(el);
            (index > -1) && keyArr.splice(index, 1);
        }

        dropIndex.forEach(d => deleteElementFromArray(d));
        return keyArr
    }

    function generateTableHeadRow(data, props) {
        let arr = getHeadersArray(props.headerData);
        return (
            <tr>
                {
                    arr.map((th, idx) => {
                        return <THCell key={idx}>{th}</THCell>
                    })
                }
            </tr>
        )

    }

    const getHeadersArray = (headers) => {
        const map = new Map();
        Object.keys(headers).forEach((key) => {
            map.set(key, headers[key]);
        });
        const left_header = map.get('left_header');
        const right_header = map.get('right_header');
        const variance_header = map.get('variance_header');
        return [BridgingTableColumns.Name, left_header, right_header, variance_header];
    }

    const getTdClasses = ({row_idx, cell_idx, headerKey}) => {
        try {
            const numClass = props.numHeaders.includes(headerKey) ? props.numTdClass : null;
            const errorClass = props.errorClassAt.errors[row_idx][cell_idx] ? null : props.errorClassAt.class;
            return [numClass, errorClass].filter(Boolean).join(" ")
        } catch (e) {
            return ""
        }
    };

    const getTrClasses = ({tree_level, row_idx, row_id}) => {
        //const isErrorInCells = Object.values(props.errorClassAt.errors[row_idx]).reduce((prev, next) => prev && next, true);
        const errorClass = props.errorClassAt.errors[row_idx] ? null : props.errorClassAt.class;
        const markerClass = props.errorClassAt.hasOwnProperty("rowClass") ? props.errorClassAt.rowClass[row_idx] : "";
        const focusClass = focusedRow.row_id === row_id ? ".focused-row" : null;
        return [treeClass[tree_level], errorClass, markerClass, focusClass].filter(Boolean).join(" ");
    };

    const onRowClick = (rowId) => {
        const fullData = props.data.map((d, i) => ({...d, initIndex: i}));
        const dataToRender = fullData.filter((d, i) => rowDisplay[i] === true);
        const row = dataToRender.filter((d) => d.row_id === rowId)[0];
        dispatch(setSelectedRow(row));
        const bridgeId = rowIdToBridgeId[row.row_id];
        dispatch(setSelectedBridgeId(bridgeId));
        setFocusedRow({row_id: row.row_id})
    }

    // Function to handle key press on a row
    // https://github.com/TanStack/table/discussions/2752
    const handleKeyDown = (event, row) => {
        event.stopPropagation();
        const currentRow = tbodyRef.current?.children.namedItem(row.row_id);
        // https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#navigation_keys
        switch (event.key) {
            case "ArrowUp":
                currentRow?.previousElementSibling?.focus();
                break;
            case "ArrowDown":
                currentRow?.nextElementSibling?.focus();
                break;
            case "Enter":
            case " ": // same as Space Bar
                onRowClick(row.row_id)
                break;
            default:
                break;
        }
    };

    // console.log("sel ", selectedRow);
    function generateTableBodyRows(data, props) {
        const fullData = data.map((d, i) => ({...d, initIndex: i}));
        // console.log("fullData ", fullData)
        const ArrayMaxReducer = (maxValue, currentValue) => Math.max(maxValue, currentValue);
        // console.log("ArrayMaxReducer ", ArrayMaxReducer);
        const maxTreeLevel = fullData.map(r => r.treeLevel).reduce(ArrayMaxReducer, 0);
        // console.log("maxTreeLevel ", maxTreeLevel);
        const headers = getHeaderList(fullData);
        // console.log("headers ", headers);
        const dataToRender = fullData.filter((d, i) => rowDisplay[i] === true);
        let isAHeadCountRow = false;
        return (
            dataToRender.map((row, row_idx) => {
                const thisLevel = row['treeLevel'];
                // console.log("thisLevel ", thisLevel);
                const initIndex = row.initIndex;
                // console.log("initIndex ", initIndex);
                const hasChild = initIndex === fullData.length - 1 ? false : thisLevel < fullData[initIndex + 1]['treeLevel'];
                // console.log("hasChild ", hasChild);
                const nextLevelRendered = row_idx === dataToRender.length - 1 ? -1 : dataToRender[row_idx + 1]['treeLevel'];
                // console.log("nextLevelRendered ", nextLevelRendered);
                const expandable = hasChild ? nextLevelRendered <= thisLevel : false;
                // console.log("expandable ", expandable);
                const isLeaf = thisLevel === maxTreeLevel || thisLevel === props.trimAtLevel;

                if (thisLevel === 1 && isAHeadCountRow) {
                    isAHeadCountRow = false;
                }
                if (nonDollarValueRowItems.has(row.name)) {
                    isAHeadCountRow = true;
                }
                if (thisLevel <= props.trimAtLevel) {
                    const focusProps = {focus: row.row_id === focusedRow.row_id}
                    return (
                        <TBodyRow key={row_idx}
                                  id={row.row_id}
                                  tabIndex={0}
                                  onKeyDown={(e) => handleKeyDown(e, row)}
                                  onClick={props.rowClick}
                                  className={getTrClasses({
                                      tree_level: thisLevel,
                                      row_idx: initIndex,
                                      row_id: row.row_id
                                  })}
                                  {...focusProps}
                        >
                            {headers.map((headerKey, idx) =>
                                <TDCell key={idx}
                                        className={getTdClasses({row_idx: initIndex, cell_idx: idx, headerKey})}
                                >
                                    {(idx === 0) && // for 1st Cell in a row
                                    getExpansionMarker({
                                        thisLevel,
                                        noChild: !hasChild || isLeaf,
                                        expandable,
                                        initIndex,
                                    })}
                                    <span style={{
                                        cursor: "pointer",
                                        textOverflow: "ellipsis",
                                        overflow: "clip",
                                        width: "100%",
                                        display: "inline-block",
                                        textAlign: idx === 0 ? "left" : "right"
                                    }}
                                          onClick={() => onRowClick(row.row_id)}>
                                        {(thisLevel > 1 && (idx === 1 || idx === 2)) ? "-" :
                                            (idx !== 0 ? getFormattedValue(row[headerKey], isAHeadCountRow ? "Fixed HC" : "") :
                                                row[headerKey])}
                                    </span>
                                </TDCell>
                            )}
                        </TBodyRow>)
                }
            })
        )
    }

    const getExpansionMarker = ({thisLevel, noChild, expandable, initIndex}) => {
        const getPointerStyle = () => !allowToggle ? "none" : noChild ? "none" : "all";
        const indicator = noChild ? "" : expandable ? "+" : "-";
        const markerClass = noChild ? "no-action" : expandable ? "expand-tree" : "collapse-tree";
        const clickOperation = expandable ? "expand" : "collapse";
        if (!allowToggle) {
            return null
        }
        return (
            <div
                className={classnames('toggle-marker', markerClass)}
                style={{marginLeft: [(thisLevel - 1) * 10] + "px", pointerEvents: getPointerStyle()}}
                onClick={() => handleRowClick(clickOperation, initIndex, thisLevel)}>
                {indicator}
            </div>
        )
    };

    let className = classnames('sticky-header sticky-first-col', 'Table', 'tree-table');
    return (
        <>
            <Table
                className={className}>
                <THead>
                    {generateTableHeadRow(props.data, props)}
                </THead>
                <TBody ref={tbodyRef}>
                    {generateTableBodyRows(props.data, props)}
                </TBody>
            </Table>
        </>
    );
}


