import {Button, ButtonDropdown, ButtonDropdownProps} from "@amzn/awsui-components-react";
import React from "react";
import {useDispatch} from "react-redux";
import {setFillingBridges} from "src/actions/bridgingCommentary.actions";
import {createBridgeMap, selectBridges} from "src/components/BridgingCommentary/helpers";
import {BridgeItem} from "src/components/BridgingCommentary/interfaces";
import Paths from "src/components/PageConfig/Paths";
import {TableHeaderProps} from "src/components/ReportBridgingPage/interfaces";
import {ButtonPadding} from "src/components/ReportBridgingPage/styling";
import {
    FastTheme,
    filterFastThemeBridges,
    getFastTheme,
    validateBridgesInSameScenario} from "src/components/Themes/reportBridgingIntegration/fastThemeCreation";

interface CreateThemeButtonProps {
    createThemeHandler: () => void;
    createFastThemeHandler: (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => void;
    fastTheme: FastTheme | undefined;
    disabled: boolean;
}

const CreateThemeButton = ({createThemeHandler, createFastThemeHandler, fastTheme, disabled}: CreateThemeButtonProps) => {
    return fastTheme ? (
      <ButtonDropdown
        items={[{text: `Create ${fastTheme} Theme with all sub-bridges`, id: fastTheme}]}
        onItemClick={createFastThemeHandler}
        mainAction={{text: 'Create Theme', onClick: createThemeHandler, disabled}}
        disabled={disabled}
      />
    ): (
        <Button
          data-testid={'create-theme-button'}
          disabled={disabled}
          onClick={createThemeHandler}
        >
            Create Theme
        </Button>
    )
}

const createThemeHandler = (setUpdatingBridges: React.Dispatch<React.SetStateAction<BridgeItem[] | null>>, selectedBridges: BridgeItem[], setCreateThemeModalVisible: React.Dispatch<React.SetStateAction<boolean>>) => {
    setUpdatingBridges(selectedBridges)
    setCreateThemeModalVisible(true)
}


export const headerActions = (props : TableHeaderProps) =>{
    const dispatch = useDispatch();
    const {
        bridgeItems,
        selectedItems,
        setUpdatingBridges,
        setUpdateDatesModalVisible,
        setFocusedBridge,
        setCreateThemeModalVisible,
        setFastTheme
    } = props;
    const bridgeMap =createBridgeMap(bridgeItems);
    const selectedBridges = selectBridges(selectedItems, bridgeMap);

    const takeActionHandler = () => {
        if(selectedBridges.length > 1) {
            dispatch(setFillingBridges(selectedBridges));
            setTimeout(() => window.location.replace(`#${Paths.FILLOUTBRIDGE}`), 20)
        } else
            setTimeout(() => window.location.replace(`#${Paths.FILLOUTBRIDGE}?bridge_id=${selectedBridges[0].bridge_id}`), 20)
    }
    const reviewBridgeHandler = () => {
        setUpdatingBridges(selectedBridges)
        setFocusedBridge(selectedBridges[0])
        setUpdateDatesModalVisible(true)
    }

    const createFastThemeHandler = (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
        if (selectedBridges) {
            const bridgesForTheme = filterFastThemeBridges(event.detail.id as FastTheme, bridgeItems, selectedBridges[0].variance_headers.variance_header)
            setUpdatingBridges(bridgesForTheme)
            setCreateThemeModalVisible(true)
            setFastTheme(event.detail.id as FastTheme)
        }
    }
    return <ButtonPadding>
        <CreateThemeButton
          createThemeHandler={() => createThemeHandler(setUpdatingBridges, selectedBridges, setCreateThemeModalVisible)}
          createFastThemeHandler={createFastThemeHandler}
          fastTheme={getFastTheme(selectedBridges)}
          disabled={selectedItems.length === 0 || !validateBridgesInSameScenario(selectedBridges)}
        />
        <Button data-testid="update-dates-button" disabled={selectedItems.length === 0} onClick={reviewBridgeHandler}>
            Update Dates
        </Button>
        <Button data-testid="review-bridge-button" disabled={props.selectedItems.length === 0} onClick={takeActionHandler}>
            Review Bridges
        </Button>
    </ButtonPadding>
}
