import {Box, Spinner} from "@amzn/awsui-components-react";
import React from 'react'
import {ViewBridgeTableHeaderProps} from "src/components/ViewReportPage/SingleBridgeView/interfaces";
import {LegendList, TableHeader} from "src/components/ViewReportPage/SingleBridgeView/Styled";
import {humanizeEpoch} from "src/utils/timeHelpers";

export const ViewBridgeTableHeader = (props: ViewBridgeTableHeaderProps) => {
    const {bridgeItem, reportDefinition, commentators} = props;

    const publishedDate = humanizeEpoch({epoch: bridgeItem.last_modified_timestamp, asDate: true});
    return(
        <TableHeader>
            <LegendList textAlign="left">
                <li>{`Published at ${publishedDate} by ${bridgeItem.last_modified_by}@`}</li>
                <li>{`Commentary by ${commentators.map(i => i + '@')}`}</li>
            </LegendList>
            <LegendList>
                <li>{`${reportDefinition.name_and_type?.finance_cycle} ${reportDefinition.name_and_type?.year}`}</li>
                <li>All values in USD MM</li>
            </LegendList>
        </TableHeader>
    )
}

export const TableStatus = (props: { loading: boolean }) => {
    if (!props.loading) return <></>;

    return (
        <Box textAlign="center" color="text-body-secondary">
            <Spinner size='normal' variant='disabled'/>
            <Box variant="span" padding={{left: "xs"}} color="text-body-secondary">
                Loading bridge
            </Box>
        </Box>
    );
}