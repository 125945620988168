import {
    Popover,
    SpaceBetween,
    StatusIndicator,
} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import Header from "@amzn/awsui-components-react/polaris/header";
import {ReactNode} from "react";
import * as React from "react";
import {
    Theme, ThemeActionHandlers,
    ThemeStates, themeStateToIcon,
} from "src/components/Themes/interfaces";
import {humanizeEpoch} from "src/utils/timeHelpers";

interface ThemeStateIndicatorProps {
    state: ThemeStates,
    lastModified: number,
    lastModifiedBy: string
}

const ThemeStateIndicator = ({state, lastModified, lastModifiedBy}: ThemeStateIndicatorProps) => {
    const humanizedTime = humanizeEpoch({epoch: lastModified, asDate: false});
    const stateText = `${state} by ${lastModifiedBy} on ${humanizedTime}`

    return(
        <Popover
            dismissButton={false}
            size="small"
            content={stateText}
        >
            <StatusIndicator type={themeStateToIcon[state]}>{state}</StatusIndicator>
        </Popover>
    );
}

interface ThemeActionsProps {
    state: ThemeStates,
    actionHandlers: ThemeActionHandlers,
}

const ThemeActions = ({state, actionHandlers}: ThemeActionsProps) => {
    const inProgressActions = [
        <Button onClick={actionHandlers.onDelete} key={'delete-button'} data-testid={'delete-button'}>Delete</Button>,
        <Button onClick={actionHandlers.onDownload} key={'download-button'}>Download</Button>,
        <Button onClick={actionHandlers.onPublish} variant={'primary'} key={'publish-button'}>Publish</Button>
    ]

    const publishedActions = [
        <Button onClick={actionHandlers.onUnpublish} key={'unpublish-button'}>Unpublish</Button>,
        <Button onClick={actionHandlers.onDownload} key={'download-button'}>Download</Button>,
        <Button onClick={() => navigator.clipboard.writeText(window.location.toString())} iconName={'anchor-link'} key={'copy-link-button'}>Copy Link</Button>
    ]

    const stateActionMapping: {[key: string]: ReactNode} = {
        [ThemeStates.NotStarted]: inProgressActions,
        [ThemeStates.InProgress]: inProgressActions,
        [ThemeStates.Published]: publishedActions
    }

    return <SpaceBetween size={'s'} direction={'horizontal'}>{stateActionMapping[state]}</SpaceBetween>
}

interface ThemeHeaderProps {
    theme: Theme,
    actionHandlers: ThemeActionHandlers
}

const ThemeHeader = ({theme, actionHandlers}: ThemeHeaderProps) => {
    const {
        name,
        state,
        lastModified,
        lastModifiedBy,
        leftScenario,
        rightScenario
    } = theme

    return (
        <Header
            variant={'h2'}
            actions={<ThemeActions state={state} actionHandlers={actionHandlers}/>}
            description={`${leftScenario} vs ${rightScenario}`}
        >
            {name} <ThemeStateIndicator state={state} lastModified={lastModified} lastModifiedBy={lastModifiedBy}/>
        </Header>
    )
}

export default ThemeHeader