import {Alert, Box, Button, Header, Modal, SpaceBetween, TextContent} from "@amzn/awsui-components-react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {deleteJob, resetLibraryStatus} from "src/actions/mdxLibrary.actions";
import {JobDeleteModalProps} from "src/components/MDXLibraryPage/interfaces";
import {getJobApiLoading} from "src/reducers/apiLoading.reducer";
import {getMdxLibraryStatus} from "src/reducers/mdxLibrary.reducer";


const JobDeleteModal = ({job, visible, setVisible}: JobDeleteModalProps) => {
    const dispatch = useDispatch();
    const deleteInProgress = useSelector(getJobApiLoading);
    const {deletedJob, errorType, errorMessage} = useSelector(getMdxLibraryStatus);
    const [alertVisible, setAlertVisible] = useState(false);

    // Use effect to initialize modal
    useEffect(function initializeModal() {
        dispatch(resetLibraryStatus());
    }, [])

    // Use effect to handler delete api call result
    useEffect(function handleApiCallResult() {
        deletedJob && onModalDismiss();
        errorType &&  setAlertVisible(true);
    }, [deletedJob, errorType]);

    // Handler for modal close buttons
    const onModalDismiss = () => {
        dispatch(resetLibraryStatus());
        setVisible(false);
        setAlertVisible(false)
    };

    // Handler for modals delete button
    const onDeleteButtonClick = () => {
        job && dispatch(deleteJob(job.group, job.job_id));
    };

    return (
        <Modal
            onDismiss={() => onModalDismiss()}
            visible={visible}
            size="medium"
            closeAriaLabel="Close modal"
            header={<Header variant="h2">Delete Job</Header>}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => onModalDismiss()}>Cancel</Button>
                        <Button variant="primary"
                                onClick={onDeleteButtonClick}
                                loading={deleteInProgress}
                        >
                            Delete
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction='vertical' size='s'>
                <Alert
                    onDismiss={() => {
                        dispatch(resetLibraryStatus())
                        setAlertVisible(false)}
                    }
                    visible={alertVisible}
                    dismissAriaLabel="Close alert"
                    dismissible={true}
                    type="error"
                >
                    {errorMessage}
                </Alert>
                <TextContent>
                    {job && <ul style={{"listStyleType": "none"}}>
                        <li>Group: <small>{job.group}</small></li>
                        <li>TM1 Instance: <small>{job.instance}</small></li>
                        <li>TM1 Cube: <small>{job.cube}</small></li>
                        <li>Year: <small>{job.year}</small></li>
                        <li>Scenario: <small>{job.scenario}</small></li>
                    </ul>}
                    <p>Are you sure, you want to delete this job?</p>
                </TextContent>
            </SpaceBetween>
        </Modal>
    );
};

export default JobDeleteModal;
