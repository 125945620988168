import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import React, {ReactNode} from "react";
import {ItemType, ReportDefinition, ReportStatus} from "src/common/report";
import {Roles} from "src/common/roles";
import {BridgeAndCommentaryStatus} from "src/components/ReportLibraryPage/components/BridgeAndCommentaryStatus";
import {generateDisplayName} from "src/components/ReportLibraryPage/components/generateDisplayName";
import {ReportTableRowItem} from "src/components/ReportLibraryPage/ReportTableConfig";
import {ReportBridge} from "src/features/reportBridges/reportBridgeSlice";

export const constructReportTableRow = (
    item: ReportDefinition,
    parentFolderId: string,
    folderButtonProps: object,
    actions: ReactNode,
    reportBridge? : ReportBridge[],
    enableReportLink = true
): ReportTableRowItem => {
    const showReport = reportBridge?.find(bridge => bridge.report_id == item.report_id)
    return {
        id: item.report_id,
        displayName: generateDisplayName(item, folderButtonProps, parentFolderId !== "", enableReportLink),
        lastModified: item.last_modified || "",
        reportStatus:item.report_status || ReportStatus.ERROR,
        reportRole: item.report_role || Roles.Admin,
        actions,
        reportType: item.name_and_type?.report_type || "",
        parentFolderId,
        itemType: item.item_type || ItemType.Internal,
        bridgeAndCommentary:  showReport ? BridgeAndCommentaryStatus({item : item, bridgeItems : showReport.bridgeItems}) : <Spinner/>,
        // Keys used for filtering table
        reportName: item.name_and_type?.report_name || "",
        financeCycle: item.name_and_type?.finance_cycle || "",
        year: item.name_and_type?.year || "",
        period: item.name_and_type?.period || ""
    }
}
