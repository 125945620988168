import {Link} from "@amzn/awsui-components-react";
import React from "react";
import {BridgeItem} from "src/components/BridgingCommentary/interfaces";
import {AdminDetailsProps} from "src/components/ReportBridgingPage/interfaces";


export const detailsWidget = (props : AdminDetailsProps) => {
    const {setFocusedBridge, setFocusedModalVisible} = props;
    return function details(item : BridgeItem) {
        return (
                (<Link href="#" onFollow={(event) => {
                    event.preventDefault()
                    setFocusedBridge({...item});
                    setFocusedModalVisible(true);
                }}>
                    {`${item.bridge_owners.length} owners`}
                </Link>)
        )
    }
}
