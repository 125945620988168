import {Icon, Link, StatusIndicator} from "@amzn/awsui-components-react";
import React from "react";
import {generatePath} from "react-router-dom";
import {ReportDefinition} from "src/common/report";
import {BridgeItem} from "src/components/BridgingCommentary/interfaces";
import Paths from "src/components/PageConfig/Paths";
import {CustomReportItemStatus} from "src/components/ReportLibraryPage/styling";
import PublishedSVG from "src/images/bridgingAndReportStatus/Published.svg";
import {areBridgesPublished} from "src/utils/reportBridgingHelpers";


export interface BridgeAndCommentaryStatusProps  {
    item : ReportDefinition,
    bridgeItems : BridgeItem[]
}
export const BridgeAndCommentaryStatus = (props : BridgeAndCommentaryStatusProps) => {
    const {item, bridgeItems} = props;
    const path = generatePath(`#${Paths.REPORTBRIDGING}`, {report_id : item.report_id,
        report_name : item.name_and_type?.report_name})
    if (!bridgeItems.length){
        return(
            <> {"No commentary requests"}</>
        )
    }
    if (areBridgesPublished(bridgeItems)){
        return PublishedCommentaryStatus(path)
    }
    else{
       return PendingCommentaryStatus(path)
    }
};

const PublishedCommentaryStatus = (path : string) =>{
    return(
        <CustomReportItemStatus>
            <Icon url ={PublishedSVG}
            />
            {<Link href={path} variant="primary"
            >
                View published commentary
            </Link>}
        </CustomReportItemStatus>
    );
}

const PendingCommentaryStatus =(path : string) => {
    return (< StatusIndicator type="pending">
        {<Link href={path} variant="primary"
        >
            Review and publish commentary
        </Link>}
    </StatusIndicator>)
}