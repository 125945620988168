import {IconProps} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import * as React from "react";
import {ChangeEvent, useRef} from "react";
import {useDispatch} from "react-redux";
import {resetReportLibraryStatus} from "src/actions/reportLibrary.actions";


export interface FileUploadProps {
    id?: string
    accept?: string
    label?: string
    buttonText?: string
    buttonIconName?: IconProps.Name
    buttonLoading?: boolean,
    descriptionText?: string
    constraintText?: string
    errorText?: string
    onChange: (file: File) => void
}

const FileUpload: React.FC<FileUploadProps> = (
    {
        id="_UPLOAD_BUTTON",
        accept='text/plain',
        label='Upload File',
        buttonText='Choose file',
        buttonIconName='upload',
        buttonLoading=false,
        descriptionText,
        constraintText,
        errorText,
        onChange,
    }: FileUploadProps
) => {
    const dispatch = useDispatch();
    const fileInput = useRef<HTMLInputElement>(null);
    const handleButtonClick = () => {
        //If error exists from previous upload, refresh status
        errorText && dispatch(resetReportLibraryStatus());
        fileInput.current && fileInput.current.click()
    };
    const handleChange = ({target}: ChangeEvent<HTMLInputElement>) => {
        if (target.files && target.files[0]){
            const file = target.files[0];
            onChange(file);
        }
    }

    return (
        <FormField label={label} constraintText={constraintText} description={descriptionText}>
            <Button iconName={buttonIconName} loading={buttonLoading} formAction="none" onClick={handleButtonClick}>
                <input ref={fileInput}
                       id={id}
                       accept={accept}
                       type="file"
                       hidden
                       multiple={false}
                       onChange={handleChange}
                />
                <span>{buttonText}</span>
            </Button>
        </FormField>
    );
}

export default FileUpload;
