import {Middleware} from "redux";
import {
    addGroup,
    JOB,
    JOB_GROUP,
    JOB_GROUPS,
    MDXLibraryStatus,
    removeGroup,
    SCENARIOS_METADATA,
    setGroups,
    setJobDropdownOptions,
    setLibraryStatus,
    setMDXPaginationConfig,
    updateGroup
} from "src/actions/mdxLibrary.actions";
import {AppState as State} from "src/reducers/AppState";
import {LibraryGroup} from "src/reducers/mdxLibrary.reducer";


const mdxLibraryPageMiddleware: Middleware<{}, State> = ({dispatch, getState}) => next => action => {
    next(action);
    if (action.payload) {
        const {data = {}, errorData = {}, params = {}, method = ''} = action.payload;
        let status: MDXLibraryStatus = {};
        let group: {[p: string]: LibraryGroup};
        switch (action.type) {
            /*********************************** Job Groups API ***********************************/
            case `${JOB_GROUPS} API_SUCCESS`:
                if (method === 'GET') {
                    const {nextPaginationKey, hasMore} = data;
                    // Get current groups in redux store
                    const currentGroups = getState().mdxLibrary.groups;
                    // Append groups or reset the library with refreshed groups
                    const groups = params.paginationKey ? {...currentGroups, ...data.groups}: data.groups;
                    // Dispatch to modify the mdxLibrary key in redux
                    dispatch(setGroups(groups));
                    dispatch(setLibraryStatus({fetchedGroups: true}))
                    dispatch(setMDXPaginationConfig({paginationKey: nextPaginationKey, hasMore}));
                }
                break;
            case `${JOB_GROUPS} API_ERROR`:
                if (method === 'GET') {
                    dispatch(setLibraryStatus({
                        errorType: errorData.message,
                        errorMessage: errorData.reason || errorData.parameter || 'Empty error message'
                    }));
                }
                break;


            /*********************************** Job Group API ***********************************/
            case `${JOB_GROUP} API_SUCCESS`:
                group = data.group;
                if (method === 'GET'){
                    dispatch(updateGroup(group));
                    dispatch(setLibraryStatus({updatedGroup: true}));
                }
                if (method === 'POST') {
                    if (params.action === 'create') {
                        status = {createdGroup: true};
                        dispatch(addGroup(group));
                    }
                    if (params.action === 'update') {
                        status = {updatedGroup: true};
                        dispatch(updateGroup(group));
                    }
                    dispatch(setLibraryStatus(status));
                }
                break;
            case `${JOB_GROUP} API_ERROR`:
                console.log({errorData, method, params});
                // Set error status for create/edit mdx group modal UI
                dispatch(setLibraryStatus({
                    errorType: errorData.message,
                    errorMessage: errorData.reason || errorData.parameter || 'Empty error message'
                }));
                break;


            /*********************************** Job API ***********************************/
            case `${JOB} API_SUCCESS`:
                group = data.group;
                if (method === 'POST') {
                    status = params.action === 'refresh' ? {refreshedJob: true} : {cancelledJob: true};
                    dispatch(updateGroup(group))
                    dispatch(setLibraryStatus(status));
                }
                if (method === 'DELETE'){
                    const groupName = Object.keys(group)[0]
                    group[groupName].jobs.length > 0 ? dispatch(updateGroup(group)): dispatch(removeGroup(groupName));
                    dispatch(setLibraryStatus({deletedJob: true}));
                }
                break;
            case `${JOB} API_ERROR`:
                console.log({errorData, method, params});
                // Set error status for confirm delete modal UI
                dispatch(setLibraryStatus({
                    errorType: errorData.message,
                    errorMessage: errorData.reason || errorData.parameter || 'Empty error message'
                }));
                break;


            /*********************************** Scenarios Metadata API ***********************************/
            case `${SCENARIOS_METADATA} API_SUCCESS`:
                const dropdownOptions = data.scenariosMetadata;
                dispatch(setJobDropdownOptions(dropdownOptions));
                break;
            case `${SCENARIOS_METADATA} API_ERROR`:
                console.log({errorData, method, params});
                break;
        }
    }
};

export default mdxLibraryPageMiddleware;
