import {Button, Link} from "@amzn/awsui-components-react";
import React from "react";
import {generatePath} from "react-router-dom";
import {ExternalReportState, ItemType, ReportDefinition, ReportStatus} from "src/common/report";
import Paths from "src/components/PageConfig/Paths";


//ItemType instead of report role 
const hrefLink = (reportDefinition: ReportDefinition) => {
    if(reportDefinition.item_type == ItemType.External){
        return (reportDefinition as ExternalReportState).link;
    }
    if(reportDefinition.name_and_type?.report_type == "SFPA"){
        const parts = reportDefinition.report_id.split("_")
        const workflow_id = parts[0]
        const execution_id = parts[1]
        return generatePath(`#${Paths.VIEWCUSTOMREPORT}`, {
            workflow_id: workflow_id, execution_id: execution_id
        })
    } else if (reportDefinition.name_and_type?.report_type == "DC FPA") {
        const {workflow_id, workflow_version, execution_id} = reportDefinition;
        return `#/report-builder/reports/${workflow_id}?versionId=${workflow_version}&executionId=${execution_id}`;
    }

    return `#${Paths.VIEWREPORT}?report_id=${reportDefinition.report_id}`;
}

export const generateDisplayName = (
    reportDefinition: ReportDefinition,
    folderButtonProps: object = {},
    belongsToFolder = false,
    enableReportLinks: boolean
) => {
    const {report_status, item_type, name_and_type} = reportDefinition;
    if(item_type === ItemType.ReviewFolder) {
        return (<>
            <Button {...folderButtonProps}/>
            {name_and_type?.report_name}
        </>);
    } else {
        const href = hrefLink(reportDefinition)
        const linkProps = item_type === ItemType.External ? {target: "_blank", external: true} : {};
        const reportIsReady = report_status === ReportStatus.CREATED || report_status === ReportStatus.EDITED;
        const displayComponent = (
            <span style={{paddingLeft: belongsToFolder ? "28px" : "6px"}}>
                {name_and_type?.report_name}
            </span>
        );
        return (enableReportLinks && reportIsReady) ? <Link href={href} {...linkProps}>{displayComponent}</Link>
            : displayComponent;
    }
}