import dotPropImmutable from "dot-prop-immutable";
import {createSelector} from "reselect";
import {
    SET_THEME,
    SET_THEME_ITEMS,
    SET_THEMES,
    SET_THEMES_ALERT,
    ThemesAction,
    UPDATE_THEME_ITEM, UPDATE_THEME_ITEMS
} from "src/actions/themes.actions";
import {
    AlertData,
    GroupedThemes,
    Theme,
    ThemeItem,
    ThemeStates,
} from "src/components/Themes/interfaces";
import {parseLocation} from "src/components/Themes/table/ordering";
import {AppState} from "src/reducers/AppState";

const defaultTheme = {
    id: '',
    reportId: '',
    name: '',
    leftScenario: '',
    rightScenario: '',
    lastModifiedBy: '',
    lastModified: 0,
    state: ThemeStates.NotStarted,
    columns: []
}

export interface ThemesReducerState{
    groupedThemes: GroupedThemes,
    theme: Theme,
    themeItems: ThemeItem[],
    alert: AlertData | undefined
}

const initialThemesReducerState: ThemesReducerState = {
    theme: defaultTheme,
    groupedThemes: {},
    themeItems: [],
    alert: undefined
}

export const themesReducer = (
    themesReducerState=initialThemesReducerState, {type, payload}: ThemesAction
): ThemesReducerState => {
    if (!payload) return themesReducerState;
    const {
        theme= defaultTheme,
        groupedThemes = {},
        themeItems = [],
        alert = undefined,
        location='',
        itemUpdates={},
        updatedThemeItems=[]
    } = payload;
    switch (type) {
        case SET_THEMES:
            return {...themesReducerState, groupedThemes}
        case SET_THEME:
            return {...themesReducerState, theme: {...themesReducerState.theme, ...theme}}
        case SET_THEME_ITEMS:
            return {...themesReducerState, themeItems}
        case UPDATE_THEME_ITEM:
            const locationWithSubItem = parseLocation(location).reduce<string[]>((locList, curLoc, idx, parsedList) =>
              [...locList, curLoc.toString(), ...(idx != parsedList.length - 1 ? ['subRows'] : [])],
              []
            ).join('.')
            const newThemeItems = dotPropImmutable.set(themesReducerState.themeItems, locationWithSubItem, (prev: ThemeItem) => ({...prev, ...itemUpdates}))
            return {...themesReducerState, themeItems: newThemeItems}
        case UPDATE_THEME_ITEMS:
            return {...themesReducerState, themeItems: updateThemeItems([...themesReducerState.themeItems], updatedThemeItems)}
        case SET_THEMES_ALERT:
            return {...themesReducerState, alert}
        default:
            return themesReducerState
    }
}

const updateThemeItems = (currentItems: ThemeItem[], newItems: ThemeItem[]) => {
    currentItems.map(currentItem => {
        const newItem = newItems.find(newItem => newItem.id === currentItem.id)
        if (newItem) {
            if (currentItem.subRows && newItem.subRows) updateThemeItems(currentItem.subRows, newItem.subRows)
            currentItem.values = newItem.values
        }
    })
    return currentItems
}


export const forThemes = createSelector(
    [(state: AppState) => state.themes.groupedThemes],
    (themes) => themes
)

export const forTheme = createSelector(
    [(state: AppState) => state.themes.theme],
    (theme) => theme
)

export const forThemeItems = createSelector(
    [(state: AppState) => state.themes.themeItems],
    (themeItems) => themeItems
)
export const forThemesAlert = createSelector(
    [(state: AppState) => state.themes.alert],
    (alert) => alert
)