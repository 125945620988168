import {Alert, SpaceBetween} from "@amzn/awsui-components-react";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getBridge, getComments, setBridgingPageStatus} from "src/actions/bridgingCommentary.actions";
import {Roles} from "src/common/roles";
import {BridgeTableRowItem} from "src/components/Bridging/BridgeTableConfig";
import {StepErrors} from "src/components/Bridging/interfaces";
import {BridgeStatusType} from "src/components/BridgingCommentary/interfaces";
import {defaultBridgeStatus} from "src/components/FillOutBridgePage/FillOutBridgePage";
import FillOutBridgeTable from "src/components/FillOutBridgePage/FillOutBridgeTable";
import {
    getBridgingPageStatus,
    getFillingBridgeItems,
    getSubItemsForBridges
} from "src/reducers/bridgingCommentary.reducer";
import {getUser} from "src/reducers/user.reducer";

const DraftBridges = (props: {bridgeItems:  BridgeTableRowItem[], role: Roles, errorText: string, setStepErrors: React.Dispatch<React.SetStateAction<StepErrors>>}) => {
    const { bridgeItems, role, errorText, setStepErrors } = props
    const dispatch = useDispatch();
    const status = useSelector(getBridgingPageStatus);
    const bridges = useSelector(getFillingBridgeItems);
    const bridgeSubItems = useSelector(getSubItemsForBridges);
    const user = useSelector(getUser);

    useEffect(() => {
        const wizardBridgeIds: string[] = [];
        /*
          As Step 3 & Step 4 of Creating Bridge Wizard deals with Proposed State and Request Inputs,
          Only BridgeItems with bridge status in Proposed State are valid for both steps.BridgeItems will have Other
          than Proposed state when admin tries to add more bridges to already existing report variance column.
        */
        bridgeItems.forEach(bridgeItem => bridgeItem.status === BridgeStatusType.Proposed &&
            wizardBridgeIds.push(bridgeItem.bridge_id))
        // Making api calls to populate Redux params fillingBridges.bridgeItems & fillingBridges.subItemsForBridges
        wizardBridgeIds.forEach(bridgeId => {
            dispatch(getBridge(bridgeId, role));
            dispatch(getComments(bridgeId, Roles.Admin))
            dispatch(setBridgingPageStatus({
                ...status,
                bridge: {...status.bridge, [bridgeId]: {fetchingComments: true}}
            }));
        })
    }, [])

    return (
        <SpaceBetween direction="vertical" size="xxl">
            {errorText.length > 0 &&
                <Alert type="error">{errorText}</Alert>
            }
            {bridges.map((bridge, index) => {
                return (
                    <FillOutBridgeTable
                        key={index}
                        bridgeItem={bridge}
                        subBridgeItems={bridgeSubItems[bridge.bridge_id] || []}
                        user={user}
                        role={role}
                        bridgeStatus={status.bridge[bridge.bridge_id] || defaultBridgeStatus}
                        tableLayout={2}
                        setStepErrors={setStepErrors}
                        errorText={errorText}
                    />
                )
            })}
        </SpaceBetween>
    )

}

export default DraftBridges