const orderingInterval = 1000;
export interface RowPositionError {
  name: 'RowPositionClash',
}

interface PositionableRow {
  position: number,
  subRows?: PositionableRow[]
}

export const calculateRowPosition = <T extends PositionableRow>(rowBefore: T | undefined, rowAfter: T | undefined): number | RowPositionError => {
  if (!rowBefore) return rowAfter ? rowAfter.position - orderingInterval : 0;
  if (!rowAfter) return rowBefore.position + orderingInterval;

  const newRowPosition = Math.floor((rowBefore.position + rowAfter.position) / 2)

  if(rowBefore.position >= newRowPosition || rowAfter.position <= newRowPosition) return {name: 'RowPositionClash'}
  return newRowPosition
}

export const parseLocation = (location: string): number[] => (location.length === 0 ? [] : location.split('.').map((i) => parseInt(i, 10)));

export const getLevelItems = <T extends PositionableRow>(level: string, items: T[]) => parseLocation(level).reduce(
  // The level passed guarantees that there will always be subRows - if no subRows something is wrong
  (previousLevel, curLevelIndex) => previousLevel[curLevelIndex].subRows as T[],
  items
)

export const repositionRowLevel = <T extends PositionableRow>(level: string, items: T[], addEvent: (updatedItem: T) => void) => {
  const newItems = [...items]
  getLevelItems(level, newItems).forEach((item, idx) => {
    item.position = (idx + 1) * orderingInterval
    addEvent(item)
  })
  return newItems
}

