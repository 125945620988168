import React from "react";
import BridgeDetailsModal from "src/components/BridgingCommentaryPage/BridgeDetailsModal";
import {BridgeOwnerDetailsProps} from "src/components/BridgingCommentaryPage/interfaces";

export const bridgeOwnerDetails = (props : BridgeOwnerDetailsProps) => {

    const {focusedBridge, ownersModalVisible, setOwnersModalVisible, assumedRole, setFocusedBridge} = props;
    return (focusedBridge && <BridgeDetailsModal
    visible={ownersModalVisible}
    setVisible={setOwnersModalVisible}
    userRole={assumedRole}
    bridgeDetails={focusedBridge}
    setBridgeDetails={setFocusedBridge}
/>)}

